/* ^ 평판 공통 사용 com_repu */
.com_reputation {}
.com_repu_gray_container{
	border:1px solid #e5e6e8; background-color: #fafafa; }
.com_reputation .requtation_txt { font-size: 20px; line-height: 1.5; letter-spacing: -0.05em; }
.com_reputation .requtation_txt.bold { font-weight: 700; }
.com_reputation .requtation_txt.blue { color: #3d7df2; }
.com_reputation .requtation_txt.black { color: #000; }

/* ^ 동료 평판 com_repu_main */
.com_repu_main{ text-align:center; margin-top:40px; }
.com_repu_main.st_popup{padding:1rem}
.com_repu_main.st_popup .btn-right-box{display: flex;justify-content: flex-end;align-items: center;width:100%}
.com_repu_main.st_popup .btn-pop-close{background: url('../img/common/btn_close.png') center center no-repeat;width:20px;height: 22px;font-size:0;}

.com_repu_main .repu_top_info_wrap { padding:36px; }
.com_repu_main .repu_go_btn_wrap {
	display: flex; align-items: center; justify-content: center;
	margin-top: 34px; }
.com_repu_main .repu_go_btn_wrap .btn { width: 210px; }
.com_repu_main.p_acq_repu .repu_go_btn_wrap .btn { width:250px; }
.com_repu_main .repu_go_btn_wrap .btn + .btn { margin-left:20px; }
.com_repu_main .requtation_txt { margin-top:30px; }
.com_repu_main .repu_request_form_wrap {
	display: inline-block; margin-top: 4px; max-width: 440px;
}
.com_repu_main .field { display:flex; align-items: center; width: 100%; }
.com_repu_main .field :is(select, [type="text"], [type="number"], [type="password"]) { width: 100%;}
.com_repu_main .field.type_tel .between_txt { display:inline-block; padding-inline: 6px;}
.com_repu_main .field + .field { margin-top:15px; }
.com_repu_main .repu_request_form_wrap .btn_wrap { margin-top: 20px; margin-bottom: 40px;}
.com_repu_main .repu_request_form_wrap .btn { width:100%; }

.com_repu_main .repu_preview_wrap { position: relative; display:block; width:100%; margin-top:66px; }
.com_repu_main .repu_preview_wrap img { width: 100%; }
.com_repu_main .repu_preview_wrap .bg_wrap { position: absolute; top:0; left:0; display: flex; align-items: flex-start; justify-content: center; background-color: rgba(0,0,0,0.6); width: 100%; height: 100%; }
.com_repu_main .repu_preview_wrap .bg_wrap h1 { color: #fff; font-weight:700; font-size: 30px; line-height: 1.666; letter-spacing: -0.025em; margin-top:300px;}
.com_repu_main .repu_preview_wrap .bg_wrap .btn_area{margin-top:20px;}
.com_repu_main .repu_preview_wrap .bg_wrap .btn_area .btn{padding-inline: 15px;}

/* & 지인평판 메인 p_acq_repu */
.p_acq_repu .requtation_txt.blue { margin-top:0; }


/* ^ 동료 평판 작성,결과 com_repu_w_r */
/* & 작성자 인포 */
.com_repu_w_r .setting { width:72px; height:30px; margin-left:20px; }
.com_repu_w_r .setting input[type="checkbox"] { width:72px; height:30px; }
.com_repu_w_r .setting input[type="checkbox"]::before { content:'익명'; font-size: 15px; line-height: 30px; font-weight: 500; color:#aaa; letter-spacing: -0.025em; position:absolute; right:11px; transition: all 0.3s;}
.com_repu_w_r .setting input:checked[type="checkbox"]::before { content:'실명'; right:36px; color:#fff; }
.com_repu_w_r .setting input[type="checkbox"]::after { width:25px; height:25px; left: 3px; }
.com_repu_w_r .setting input:checked[type="checkbox"]::after { left:44px; }
.com_repu_w_r .repu_writer_info_wrap { display:flex; align-items: center; justify-content: center; padding:40px 0; border: 1px solid #e5e6e8; margin-top: 45px; }
.com_repu_w_r .repu_writer_info_wrap > div { display:flex; align-items: center; justify-content: center;}
.com_repu_w_r .repu_writer_info_wrap h1 {
	font-size:26px; font-weight:700; color:#000; letter-spacing: -0.025em; }
.com_repu_w_r .repu_writer_info_wrap .notice_area{display: flex; flex-direction: column; justify-content: center;}
.com_repu_w_r .repu_writer_info_wrap .notice_area .txt{margin-top:15px; font-size:15px; color:#666}
.com_repu_w_r .repu_writer_info_wrap .notice_area .btn{padding-inline: 15px; margin-top:20px;}
.com_repu_w_r .repu_writer_info_wrap .repu_writer_name .name{font-size:26px;  color:#000; letter-spacing: -0.025em;}
.com_repu_w_r .repu_writer_info_wrap .repu_writer_name .name+.name{margin-left: 40px;}
.com_repu_w_r .repu_writer_info_wrap .repu_writer_name .chk+label{margin-left:40px;}
.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) ul { display:flex; align-items: center; }
.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li { font-size:17px; color:#666; letter-spacing: -0.025em; margin-left:40px; }
.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li b { color:#000; font-weight:400; padding-left:10px; width: 42px; height: 42px; }
.com_repu_w_r .btn_wrap { margin-top: 22px; text-align: center; }
.com_repu_w_r .btn_wrap .btn { min-width: 210px; }


/* & 응답자 인포 */
.com_repu_w_r .repu_result_info_wrap { background-color: #e5e6e8; display:flex; align-items: center; padding: 20px 50px; margin-bottom:50px; }
/* .com_repu_w_r .repu_result_info_wrap select { background-color: transparent; border: none; width: auto; font-size: 26px; font-weight: 700; letter-spacing: -0.025em; color:#000; background-image:url(../img/sub/repu_select_arrow.png); } */
.com_repu_w_r .repu_result_info_wrap select { width: auto; padding: 0 35px 0 10px; font-size:18px; color:#000; }
.com_repu_w_r .repu_result_info_wrap li { margin-left: 30px; }
.com_repu_w_r .repu_result_info_wrap li b { padding-left: 6px; }

/* & 평판 작성 타이틀 */
.com_repu_w_r .repu_write_title { display:flex; align-items: center; justify-content: center; font-size:20px; line-height:1.2; letter-spacing: -0.025em; color: #000; margin-top: 30px; margin-bottom: 26px; }
.com_repu_w_r .repu_write_title span.red.small { margin-left: 20px; color:#cc3333; font-size: 15px; }
.com_repu_w_r .repu_write_title img { margin-right:20px; }

/* & 평판 설문조사 */
/* ? 질문 */
.com_repu_w_r .repu_question { display:inline-flex; width:100%; align-items:center; padding:30px 50px; }
.com_repu_w_r .repu_question > h1 { font-size: 18px; font-weight: 500; color: #000; min-width:36%; text-align: left; }
.com_repu_w_r .repu_question.only_txtbox { align-items: flex-start; }
.com_repu_w_r .repu_question.only_txtbox > h1 span { color:#ff6666; }
/* ? 답변 */
.com_repu_w_r .repu_answer { display:flex; align-items: center; flex-wrap: wrap;}
.com_repu_w_r .repu_answer :is([type="checkbox"],[type="radio"]) { position:absolute; left:-9999px; }
.com_repu_w_r .repu_answer li { margin-right:35px; }
.com_repu_w_r .repu_answer li:last-child { margin-right:0; }
.com_repu_w_r .repu_answer li label { font-size: 17px; color:#333; letter-spacing: -0.025em; }
.com_repu_w_r :is(.repu_question, .repu_question_wrap) + :is(.repu_question, .repu_question_wrap) {
	border-top: 1px solid #e5e6e8; }
.com_repu_w_r .repu_answer .has_textbox { display:flex; align-items:center; justify-content:flex-start; width:100%; margin-top: 10px; }
.com_repu_w_r .repu_answer .has_textbox label { min-width: 60px; }
.com_repu_w_r .repu_answer .has_textbox [type="text"] { max-width:50%; }
.com_repu_w_r .repu_answer.long_answer { flex-direction: column; }
.com_repu_w_r  .repu_answer.long_answer li { width:100% !important; margin-top:20px; }
.com_repu_w_r  .repu_answer.long_answer li:not(:first-of-type) { margin-top:34px; }
.com_repu_w_r .repu_question.align_v .repu_answer.long_answer li label { flex-direction:row; font-size:15px; line-height:2; color:#666; }
.com_repu_w_r .repu_question.align_v .repu_answer.long_answer li label::before { top:10px; margin-right:15px; margin-top:5px; }
/* ? 질문이 위, 선택이 아래 있는 형식 */
.com_repu_w_r .repu_question.align_v { flex-direction: column; }
.com_repu_w_r .repu_question.align_v h1 { margin-bottom:14px; }
.com_repu_w_r .repu_question.align_v .repu_answer li { width:20%; margin-right:0; }
.com_repu_w_r .repu_question.align_v .repu_answer li label { display:flex; flex-direction: column; }
/* ? 질문이 한줄에 2개 있는 형식 */
.com_repu_w_r .repu_question_wrap.horizon2 .repu_question { width:50%; align-items: flex-start; }
.com_repu_w_r .repu_question_wrap.horizon2 .repu_answer { width:100%; }
.com_repu_w_r .repu_question_wrap .repu_question + :is(.repu_question, .repu_question_wrap) { border-top:none; }
/* ? 좌/우 섹션이 나뉘는 형식 */
.com_repu_w_r .repu_question_wrap.has_section { display:flex; align-items: flex-start; flex-wrap: wrap; padding-bottom: 60px; }
.com_repu_w_r .repu_question_wrap.has_section > h1 { width:100%; }
.com_repu_w_r .repu_question_wrap.has_section > section { width:50%; }
.com_repu_w_r .repu_question_wrap.has_section > section .repu_question { width:100%; }
/* ? 답변이 텍스트박스 하나밖에 없는 형식 */
.com_repu_w_r .repu_question.only_txtbox .repu_answer { width:100%; }
.com_repu_w_r .repu_question.only_txtbox .repu_answer li { width:100%; }
/* ? 질문 묶음 타이틀 */
.com_repu_w_r .repu_question_title { font-size: 26px; font-weight: 700; color: #000; margin:60px 50px 10px; }
.com_repu_w_r .repu_question_title span { color:#666; font-size:18px; font-weight: 400; padding-left:10px; }
/* ? 와이드 radio 선택 */
.com_repu_w_r .repu_answer li:not(:first-of-type) .wide + label::before { border-left:0; }

input[type=radio].chk.wide.square+label { position:relative; }
input[type=radio].chk.wide.square+label span { position:absolute; top:46px; width:100%; word-break: keep-all; }
input[type=radio].chk.wide.square+label.long_text span { top:46px; }
input[type=radio].chk.wide.square+label.left span { left:0; text-align:left; }
input[type=radio].chk.wide.square+label.right span { right:0; text-align:right; }

input[type=radio].chk.wide.square+label { padding-left: 0; text-align:center; font-size:15px; color:#666; }
input[type=radio].chk.wide.square+label::before { width:100%; height:40px; margin-left:0; margin-bottom:6px; margin-right:0; vertical-align: top; background-image:url(../img/sub/chk_wide.png); background-repeat: no-repeat; background-position: center center;}
input[type=radio].chk.wide.square:checked+label:before { background-color: #3d7df2; background-image:url(../img/sub/chk_wide_active.png); }
input[type=radio].chk.wide.square+label.long_text { position:relative; }
input[type=radio].chk.wide.square+label.long_text span { position:absolute; top:46px; width:160%; }
input[type=radio].chk.wide.square+label.long_text.left span { left:0; text-align:left; }
input[type=radio].chk.wide.square+label.long_text.right span { right:0; text-align:right; }
input[type=radio].chk.wide.square+label.center span { left:-30%; }
.p_repu_r input[type=radio].chk.wide.square:not(:checked)+label::before { background-image: none; }
/* ~ 평판작성 p_acq_w */
.p_acq_w .com_repu_gray_container { margin-top: 40px; }
.p_acq_w .repu_question { background-color: #fff; border-top: 1px solid #e4e5e5; padding:0; }
.p_acq_w .repu_question h1 { padding-inline:37px; font-weight: 400; font-size:15px; display:inline-flex; align-items: center; }
.p_acq_w .repu_answer { width:64%; height:70px; }
.p_acq_w .repu_answer li { display:inline-flex; align-items: center; justify-content: center; width:20%; height:100%; border-left:1px solid #e4e5e5; padding-inline:20px; margin-right:0; }
.p_acq_w .repu_answer li label { font-size:15px; color:#000; }
.p_acq_w .repu_answer li .chk+label::before { margin-right:7px; }
.p_acq_w .btn_wrap { padding-block:39px; border-top: 1px solid #e4e5e5; margin-top:0; }

/* & 지인 평판 결과보기 p_acq_repu_r */
.p_acq_repu_r { padding-top:40px; }
.p_acq_repu_r .com_repu_gray_container { display:flex; align-items: stretch; }
.p_acq_repu_r .com_repu_gray_container > section { width:50%; padding:40px; }
.p_acq_repu_r .who_see_container { position:relative; }
.p_acq_repu_r .who_see_area { display:inline-flex; align-items: center; justify-content: center; background-color: #e5e6e8; width:300px; height:60px; font-size: 20px; font-weight: 400; color:#000; line-height:1.2; }
.p_acq_repu_r .who_see_area .icon_arrow { display: inline-block; width: 20px; height: 20px; background:url(../img/sub/repu_select_arrow.png) no-repeat center; margin-left:15px; }
.p_acq_repu_r .who_see_area .txt_blue { font-weight: 700; }
.p_acq_repu_r .who_see_area .update_date { font-size: 14px; letter-spacing: -0.05em; color:#666; padding-left: 8px; }
.p_acq_repu_r .who_see_area_options { position:absolute; top:60px; left:50%; transform: translateX(-50%); width:300px; z-index: 1; display:none; }
.p_acq_repu_r .who_see_area_options.active { display:block; }
.p_acq_repu_r .who_see_area_options li { display:inline-flex; align-items: center; justify-content: center; width:100%; height: 40px; border: 1px solid #e5e6e8; border-width: 0 1px 1px 1px;  background-color: #fff; cursor: pointer; font-size: 18px; }
.p_acq_repu_r .who_see_area_options li:hover { background-color: #3d7df2; color:#fff; }
.p_acq_repu_r .acq_repu_graph { margin-top:54px;}
.p_acq_repu_r .com_repu_gray_container .right_section { border-left:1px solid #e5e6e8; }
.p_acq_repu_r .com_repu_gray_container .left_section .btn{padding-inline:20px;}

.p_acq_repu_r .repu_compare_container { padding:70px 30px; border: 1px solid #e5e6e8; border-width: 0 1px 1px 1px;}
.p_acq_repu_r .repu_compare_txt { display:flex; justify-content: space-between; }
.p_acq_repu_r .repu_compare_txt h1 { font-size:20px; color:#000; letter-spacing: -0.025em; font-weight: 700;}
.p_acq_repu_r .repu_compare_txt span { font-weight: 400; padding-inline: 10px;}
.p_acq_repu_r .repu_compare_txt button { font-size:20px; color:#000; letter-spacing: -0.025em; }
.p_acq_repu_r .repu_compare_txt button .question { width:32px; height:32px; display:inline-flex; align-items: center; justify-content: center; font-size:24px; font-weight: 500; color: #fff; margin-left:10px; background-color: #3d7df2; border-radius: 50%; line-height: 24px; }
.p_acq_repu_r .resume_progress { margin-top:28px; height:33px; border-radius: 16.5px; }
.p_acq_repu_r .resume_progress_bar { height:100%; border-radius: 16.5px;}

.p_acq_repu_r .repu_review_container { padding:50px 30px; border: 1px solid #e5e6e8; border-width: 0 1px 1px 1px;}
.p_acq_repu_r .oneline_review { text-align: left; }
.p_acq_repu_r .oneline_review h1 { font-size:30px; letter-spacing: -0.05em; color:#000; font-weight: 700; display:inline-flex; align-items: center; justify-content: space-between; }
.p_acq_repu_r .oneline_review .btn { min-width: 210px; margin-left:37px; }
.p_acq_repu_r .oneline_review p { margin-top:20px; font-size: 15px; color:#333; letter-spacing: -0.025em; }

.p_acq_repu_r .repu_review_explain { padding:50px 30px; border: 1px solid #e5e6e8; border-width: 0 1px 1px 1px; }
.p_acq_repu_r .repu_review_explain h1 { font-size:20px; color:#000; letter-spacing: -0.025em; font-weight: 700; text-align: left; margin-bottom: 20px; }

.p_acq_repu_r .repu_tip_container { padding:40px 30px; border: 1px solid #e5e6e8; border-width: 0 1px 1px 1px; text-align: left;}
.p_acq_repu_r .myresume_tip { margin: 0; }
.p_acq_repu_r .repu_setting_container { margin-left:37px; margin-top:30px; }
.p_acq_repu_r .repu_setting_container .text{font-size:15px; display: inline-block; margin-right: 14px; padding-left: 14px;}
.p_acq_repu_r .repu_setting_container .text:before{content: '';width:5px; height: 5px; background: #3d7df2; border-radius: 10px; display: inline-block; margin-right: 9px; vertical-align: 3px; margin-left: -14px}


.p_colle_r.tab02{margin-top:40px; margin-bottom:0}

/* & 평판 팝업 pop_repu */
.popup01.pop_repu .notice_area + .field { margin-top: 28px !important; }
.popup01.pop_repu .content_wrap .field input { border-color:#b3b9c4; }

.popup01.pop_repu_ex { background-color: #f6f8fb; width:100%; max-width:1260px; }
.popup01.pop_repu_ex .title_wrap { border-bottom:0; }
.popup01.pop_repu_ex .content_wrap { padding:0; }
.popup01.pop_repu_ex .close_button_wrap { margin-top:0; }
.p_acq_repu_r table { border:1px solid #e5e6e8; border-width: 0 1px 1px 1px; }
.p_acq_repu_r table th { background-color: #999a9d; color:#fff; font-size:15px; line-height: 500; letter-spacing: -0.025em; line-height: 1.2; padding-inline: 40px; padding-block: 16px; }
.p_acq_repu_r table th:not(:first-of-type) { border-left:1px solid #fff; }
.p_acq_repu_r table th:nth-child(n + 3) { background-color: #3d7df2; }
.p_acq_repu_r table td { font-size:15px; line-height: 2; color:#000; letter-spacing: -0.025em; border-top: 1px solid #dfdfdf; padding:16px; }
.p_acq_repu_r table td:first-of-type { text-align: center; }
.p_acq_repu_r table td:not(:first-of-type) { border-left:1px solid #dfdfdf; }
.p_acq_repu_r table tr:nth-child(odd) td { background-color: #f6f8fb; }

.com_reputation ~ .popup .notice_area .icon { padding: 8px; }
.com_reputation ~ .popup .notice_area .icon img { max-width: 100%; object-fit: cover; }

@media screen and (max-width:1260px){
	.p_acq_repu_r .oneline_review h1 { width: 100%; }
	.p_acq_repu_r .oneline_review .btn { margin-left: 0; margin-top: 20px; }

	.p_acq_w .repu_question { flex-direction: column; align-items: flex-start; padding:20px 25px; }
	.p_acq_w .repu_question h1 { padding: 0; width:100%; }
	.p_acq_w .repu_answer { width: 100%; height: auto; padding-top:14px; padding-left: 14px;}
	.p_acq_w .repu_answer li { width: auto; padding: 0; border-left:0; }
	.p_acq_w .repu_answer li:not(:first-of-type) { margin-left:20px; }

	.p_acq_repu_r table th { padding: 12px 0; font-size:20px; }

	.com_repu_w_r .repu_result_info_wrap { flex-wrap: wrap; padding-bottom: 26px; padding-inline: 20px;}
	.com_repu_w_r .repu_result_info_wrap select { padding-left:0; padding-right: 40px; }
	.com_repu_w_r .repu_result_info_wrap ul { width: 100%; margin-top: 4px; }
	.com_repu_w_r .repu_result_info_wrap li { display:flex; align-items: flex-start; justify-content: flex-start; flex-wrap: wrap; margin-left: 22px; }
	.com_repu_w_r .repu_result_info_wrap li:first-child { margin-left:0; }
	.com_repu_w_r .repu_result_info_wrap li b { width:100%; padding-left:0; padding-top: 6px;}
}
@media screen and (max-width:1024px){
	.p_acq_repu_r { padding: 0; padding-top:25px; }
	.p_acq_repu_r .com_repu_gray_container { flex-direction: column; }
	.p_acq_repu_r .com_repu_gray_container > section { width:100%; padding:20px; }
	.p_acq_repu_r .com_repu_gray_container .right_section { border-left:0; border-top: 1px solid #e5e6e8; }
	.p_acq_repu_r .who_see_area { width: 100%; font-size:13px; height:36px; }
	.p_acq_repu_r .who_see_area .icon_arrow { width: 13px; height: 13px; background-size:cover; margin-left:7px; }
	.p_acq_repu_r .who_see_area .update_date { font-size:11px; }
	.p_acq_repu_r .repu_compare_container { border: none; width:100%; padding:50px 4%; }
	.p_acq_repu_r .repu_compare_txt h1 { font-size:13px; }
	.p_acq_repu_r .repu_compare_txt button { font-size:13px; }
	.p_acq_repu_r .repu_compare_txt button .question { width: 14px; height: 14px; font-size:12px; margin-left:6px; }
	.p_acq_repu_r .resume_progress { margin-top:20px; height: 20px; }
	.p_acq_repu_r .repu_review_container { border: none; width:100%; padding:10px 4%; }
	.p_acq_repu_r .oneline_review h1 { font-size:18px; }
	.p_acq_repu_r .oneline_review .btn { /*max-width: 126px; width:126px;*/ min-width: auto; }
	.p_acq_repu_r .oneline_review p { font-size:11px; }
	.p_acq_repu_r .repu_tip_container { border: none; width:100%; padding:20px 4%; }
	.p_acq_repu_r .myresume_tip .list { margin-left: 20px; }
	.p_acq_repu_r .repu_setting_container { display: flex; align-items: center; justify-content: space-between; width:calc(100% - 20px); margin-top:20px; margin-left:20px; }
	.p_acq_repu_r .repu_setting_container .text { font-size:12px; width:calc(100% - 72px); word-break: keep-all;}
	.p_acq_repu_r .repu_setting_container .setting { width:52px; }

	.p_acq_repu_r table th { font-size:15px; word-break: keep-all; }
	.p_acq_repu_r table td { font-size:12px; padding: 10px 4px; word-break: keep-all; }
	
	.com_repu_w_r { padding-inline: 0; }
	.com_repu_w_r .repu_result_info_wrap ul { flex-wrap: wrap; }
	.com_repu_w_r .repu_result_info_wrap li { margin-left: 0 !important; width: 100%; flex-wrap: nowrap;}
	.com_repu_w_r .repu_result_info_wrap li:not(:first-of-type) { margin-top: 10px; }
	.com_repu_w_r .repu_result_info_wrap li b { padding-top: 0; padding-left: 10px; width: auto;}
  

	.com_repu_w_r .repu_question_wrap.has_section > section { width: 100%; }

	.com_repu_w_r .repu_question_wrap.horizon2 .repu_question { width: 100%; }

  /* & 지인 평판 결과보기 p_acq_repu_r */
  .p_acq_repu_r .who_see_area_options{width:100%; top:36px; z-index: 12;}
  .p_acq_repu_r .who_see_area_options li{font-size:13px;}

  .com_repu_w_r .repu_writer_info_wrap .repu_writer_name{display: flex; flex-direction: column;}
  .com_repu_w_r .repu_writer_info_wrap .repu_writer_name .name+.name{margin-left:0;margin-top:10px;}
  .com_repu_w_r .repu_writer_info_wrap .repu_writer_name .chk+label{margin-left:0; margin-top:10px}

}
@media screen and (max-width:768px){
	.com_repu_main { border-width: 1px 0; margin-top:20px; }
	.com_repu_main img { width: 36px; }
	.com_repu_main .repu_top_info_wrap { padding: 20px 0; }
	.com_repu_main .repu_go_btn_wrap { margin-top:10px;  }
	.com_repu_main .repu_go_btn_wrap .btn { max-width: 126px; }
	.com_repu_main .repu_preview_wrap { margin-top:20px; }
	.com_repu_main .repu_preview_wrap .bg_wrap h1 { font-size:15px; line-height:25px; margin-top:60px; }

	.com_reputation .requtation_txt.black { margin-top:15px; }
	.com_reputation .requtation_txt { font-size:13px; }

	.com_repu_w_r .repu_write_title { font-size:13px; margin-top: 15px; margin-bottom: 10px; }

	.p_colle_repu.com_repu_main img { margin-bottom: 20px; }
	.p_colle_repu.com_reputation .requtation_txt.blue { margin-top: 20px; }

	.p_acq_w { padding: 0 !important; width:100% !important; margin:0 !important;}
	.p_acq_w .com_repu_gray_container { border-width: 1px 0 1px 0; }
	.p_acq_w .repu_question h1 { font-size:13px; }
	.p_acq_w .repu_answer { padding-left: 0; }
	.p_acq_w .repu_answer li label { font-size: 13px; }
	.p_acq_w .repu_answer li:not(:first-of-type) { margin-left:0; }
	.p_acq_w .repu_answer li:nth-child(n + 4) { margin-top:10px; }
	.p_acq_w .repu_answer li:nth-child(4) { margin-left: 0; }
	.p_acq_w .btn_wrap { padding-block: 20px; }

	.com_repu_w_r { padding: 0; width: calc(100% + 8%); margin-left:-4%; }
	.com_repu_w_r .setting,
	.com_repu_w_r .setting input[type="checkbox"] { width: 58px; height: 22px; position: relative; top: 4px;}
	.com_repu_w_r .setting input[type="checkbox"]::before { font-size: 12px; line-height: 20px; }
	.com_repu_w_r .setting input[type="checkbox"]::after { width: 18px; height: 18px; top: 15px; }
	.com_repu_w_r .setting input:checked[type="checkbox"]::before { right: 28px; }
	.com_repu_w_r .setting input:checked[type="checkbox"]::after {
		left: 37px;
	}
	.com_repu_w_r .repu_writer_info_wrap { padding: 20px 0; }
	.com_repu_w_r .repu_writer_info_wrap h1 { font-size:15px; }
	.com_repu_w_r .repu_writer_info_wrap > div { flex-wrap: wrap; width: 100%;  }
  .com_repu_w_r .repu_writer_info_wrap .repu_writer_name .name{font-size:15px;}
	.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) ul { width:100%; margin-top: 14px; }
	.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li { width:26%; margin-left:0; text-align: center; font-size:13px; }
	.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li b { padding-left:4px; }
	.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li:last-child { width:48%; } 
	.com_repu_w_r .repu_result_info_wrap li b { height: auto; }
	.com_repu_w_r .repu_write_title { flex-wrap: wrap; }
	.com_repu_w_r .repu_write_title img { max-width: 40px; margin-right:6px; }
	.com_repu_w_r .repu_write_title span.red.small { width:100%; font-size:11px; text-align: center; margin-left: 0; margin-top: 10px; }
	.com_repu_w_r .com_repu_gray_container { border-inline: 0;}
	.com_repu_w_r .repu_question { padding:20px 15px; flex-wrap: wrap; }
	.com_repu_w_r .repu_question > h1 { width: 100%; font-size: 13px; }
	.com_repu_w_r .repu_answer { width: 100%; margin-top: 10px; }
	.com_repu_w_r .repu_answer li { margin-right: 0; width:33.333%; }
	.com_repu_w_r .repu_answer li:nth-child(n + 4) { margin-top:10px; }
	.com_repu_w_r .repu_answer li label { font-size:13px; }
	:is(.p_colle_r, .p_colle_w) .repu_question:nth-child(2) .repu_answer li { width:50%; }
	:is(.p_colle_r, .p_colle_w) .repu_question:nth-child(2) .repu_answer li:nth-child(n + 3) { margin-top: 10px; }
	:is(.p_colle_r, .p_colle_w) .repu_question:nth-child(2) .repu_answer li:last-child { width:100%; }
  :is(.p_colle_r, .p_colle_w).com_repu_w_r{width:100%; margin:0;}
  
	.com_repu_w_r .repu_answer .has_textbox [type="text"] { max-width: calc(100% - 60px); }

	.com_repu_w_r .repu_question_title { margin-top:25px; margin-inline: 4%; font-size: 15px; }
	.com_repu_w_r .repu_question_title span { font-size: 13px; padding-left:4px; }
	.com_repu_w_r .repu_question_wrap .repu_question:nth-child(2) .repu_answer li { width: 20%; }
	.com_repu_w_r .repu_question_wrap .repu_question:nth-child(2) .repu_answer li:nth-child(n + 3) { margin-top: 0; }
	.com_repu_w_r .repu_question_wrap .repu_answer li:nth-child(n + 4) { margin-top:0; }

	.com_repu_w_r .repu_question.align_v h1 { margin-bottom: 10px; }
	.com_repu_w_r .repu_question_wrap.horizon2 .repu_answer { margin-top: 0; }

	input[type=radio].chk.wide.square+label { font-size: 12px; }
	input[type=radio].chk.wide.square+label::before { height: 25px; background-size: 14px; }

	.com_repu_w_r .repu_question.align_v .repu_answer.long_answer li label { font-size: 12px; }
	.com_repu_w_r .repu_answer.long_answer li { margin-top:0; }
	.com_repu_w_r .repu_answer.long_answer li:not(:first-of-type) { margin-top: 10px; }

	.com_repu_w_r .repu_question.only_txtbox .repu_answer { margin-top:0; }

	input[type=radio].chk.wide.square+label span { top:31px; }
	input[type=radio].chk.wide.square+label.long_text span { top:31px; }

	.com_repu_w_r .repu_writer_info_wrap { padding: 20px 0; }
	.com_repu_w_r .repu_writer_info_wrap h1 { font-size: 14px; }
  .com_repu_w_r .repu_writer_info_wrap .notice_area .txt{font-size:12px; padding-inline: 20px;}
	.com_repu_w_r .repu_writer_info_wrap .notice_area .btn{margin-top:10px;}
  .com_repu_w_r .repu_result_info_wrap select { font-size: 14px; background-size: 14px 14px; padding-right: 30px; min-height: auto;}
	.com_repu_w_r :is(.repu_writer_info_wrap, .repu_result_info_wrap) li { font-size: 12px;}

	.com_repu_w_r .repu_result_info_wrap li { width: 100% !important; }
	.com_repu_w_r .repu_result_info_wrap { margin-bottom: 20px; }

	.com_reputation ~ .popup .notice_area .icon { padding: 6px; }

  .p_colle_r.tab02{margin-top:20px;}

  /*지인평판>상황별설명*/
  .p_acq_repu_r .repu_review_explain{padding:50px 4%}
}
@media screen and (max-width:640px){
	
}