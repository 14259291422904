@charset "utf-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500;600&family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap&family=Nanum+Gothic&display=swap");
/* font-family: 'NanumBarunGothic', sans-serif; */
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
 }
 
 @font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 700;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebBold.ttf') format('truetype')
 }
 
 @font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 300;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWebLight.ttf') format('truetype');
 }
/* @font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 100;
  src: url(../fonts/NotoSansKR-Thin.eot);
  src: url(../fonts/NotoSansKR-Thin.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Thin.woff) format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 300;
  src: url(../fonts/NotoSansKR-Light.eot);
  src: url(../fonts/NotoSansKR-Light.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Light.woff) format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 400;
  src: url(../fonts/NotoSansKR-Regular.eot);
  src: url(../fonts/NotoSansKR-Regular.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Regular.woff) format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 500;
  src: url(../fonts/NotoSansKR-Medium.eot);
  src: url(../fonts/NotoSansKR-Medium.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Medium.woff) format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 700;
  src: url(../fonts/NotoSansKR-Bold.eot);
  src: url(../fonts/NotoSansKR-Bold.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Bold.woff) format("woff");
}
@font-face {
  font-family: "Noto Sans KR";
    font-style: normal;
    font-weight: 900;
  src: url(../fonts/NotoSansKR-Black.eot);
  src: url(../fonts/NotoSansKR-Black.eot) format("embedded-opentype"),
    url(../fonts/NotoSansKR-Black.woff) format("woff");
}
@font-face {
  font-family: "NanumGothic";
    font-style: normal;
    font-weight: 400;
  src: url(../fonts/NanumGothic-Regular.eot);
  src: url(../fonts/NanumGothic-Regular.eot) format("embedded-opentype"),
    url(../fonts/NanumGothic-Regular.woff) format("woff");
} */
html {
  /* Prevent font scaling in landscape */
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
}
/*body{max-width: 640px;}*/
fieldset {
  border: none;
}
html,
body,
div,
ul,
li,
a,
button {
  color: #0f0f0f;
}
a,
body,
dd,
div,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
input,
li,
ol,
p,
html,
ul,
button,
textarea,
select {
  font-family: "Noto Sans KR", Sans-serif, "Malgun Gothic", "맑은 고딕", Roboto,
    Arial;
  font-weight: 400;
  letter-spacing: -0.025em;
  line-height: 1.3;
  font-size: 14px;
  word-break: break-all;
  box-sizing: border-box;
}
a,
body,
dd,
div,
dl,
dt,
h1,
h2,
h3,
h4,
h5,
html,
li,
ol,
p,
ul {
  padding: 0;
  margin: 0;
}
li,
ol,
ul {
  list-style: none;
}
a,
img {
  border: none;
}
img {
  max-width: 100%;
}
a + a,
img,
input + a,
input + button {
  vertical-align: middle;
}
a {
  text-decoration: none;
  display: inline-block;
}
.no_inline_block {
  text-decoration: none;
  display: contents;
}
a:hover {
  text-decoration: none;
}
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="text"],
input[type="email"],
select,
textarea {
  border: 1px solid #cfcfcf;
  background: #fff;
  padding: 0 14px;
  box-sizing: border-box;
  min-height: 48px;
  color: #333;
  font-size: 15px;
  vertical-align: middle;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
textarea {
  padding: 14px;
}
select {
  padding: 0 25px 0 5px;
  background: url("../img/common/select_arrow.png") no-repeat #fff;
  background-position: calc(100% - 6px) center;
}
input[type="chekbox"],
input[type="radio"] {
  padding-right: 8px;
}
input + input,
input + input + label,
select + input + label,
button + input + label,
select + select,
select + input,
input + select {
  margin-left: 5px;
}
.chk {
  display: none;
}
label {
  margin-right: 5px;
}
label:last-child {
  margin-right: 0;
}
label + input[type="checkbox"] + label,
label + input[type="radio"] + label {
  margin-left: 10px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
i {
  font-style: normal;
}
*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
main {
  display: block;
}
table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}
th {
  text-align: center;
}
td {
  text-align: left;
}
table > caption {
  position: absolute;
  text-indent: -9999px;
}
button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
button:disabled {
  cursor: not-allowed;
}
button,
input,
textarea {
  -webkit-appearance: none;
}
select,
textarea,
input[type="password"],
input[type="email"],
input[type="text"] {
  width: 100%;
}
textarea {
  min-height: 45px;
  text-align: left;
}
input[type="tel"] {
  width: 40px;
  min-width: 40px;
  margin-right: 4px;
}
input[type="number"] {
  -moz-appearance: textfield;
}
/*input:disabled{background-color:rgba(255,255,255,0);cursor:not-allowed}*/
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #999;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bbb;
  opacity: 1;
}
input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #bbb;
  opacity: 1;
}
input::placeholder,
textarea::placeholder {
  color: #bbb;
  opacity: 1;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bbb;
  opacity: 1;
}
/* select:disabled, 
input[readonly],
textarea[readonly]{opacity: 1; cursor:url("../img/common/icon_cursor.png"),auto;} */
input[readonly] {
  background-color: #f8f8f8;
  border-color: #cfcfcf;
}
input[readonly]:focus,
textarea[readonly]:focus {
  outline: none;
}
input:focus {
  outline: none;
}
input:disabled::-webkit-input-placeholder,
input[readonly]::-webkit-input-placeholder,
textarea:disabled::-webkit-input-placeholder,
textarea[readonly]::-webkit-input-placeholder {
  color: #111;
  opacity: 1;
}
input:disabled:-ms-input-placeholder,
input[readonly]:-ms-input-placeholder,
textarea:disabled:-ms-input-placeholder,
textarea[readonly]:-ms-input-placeholder {
  color: #111;
  opacity: 1;
}
input:disabled::-ms-input-placeholder,
input[readonly]::-ms-input-placeholder,
textarea:disabled::-ms-input-placeholder,
textarea[readonly]::-ms-input-placeholder {
  color: #111;
  opacity: 1;
}
input:disabled::placeholder,
input[readonly]::placeholder,
textarea:disabled::placeholder,
textarea[readonly]::placeholder {
  color: #111;
  opacity: 1;
}
input:disabled:-ms-input-placeholder,
input[readonly]:-ms-input-placeholder,
textarea:disabled:-ms-input-placeholder,
textarea[readonly]:-ms-input-placeholder {
  color: #111;
  opacity: 1;
}
input::-ms-clear,
input::-ms-reveal,
textarea::-ms-clear,
textarea::-ms-reveal {
  display: none;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button,
textarea::-webkit-inner-spin-button,
textarea::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
textarea {
  line-height: 1.5;
}
textarea.content,
textarea {
  resize: none;
  padding: 14px;
}
img {
  border: 0;
  vertical-align: middle;
}
hr {
  display: none;
}
input + button,
textarea + button {
  margin-left: 3px;
}

select::-ms-expand {
  border: none;
  background: none;
}
.loading {
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
} /*로딩중*/
.loading .loading_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.loading .loading_wrap > div {
  color: #fff;
  font-size: 16px;
}
.loading img {
  width: 50px;
  border-radius: 100px;
  display: block;
  margin-bottom: 10px;
}
/*레이아웃*/
.center_wrap {
  width: 1260px;
  margin: 0 auto;
}
#sub_wrap {
  margin-top: 40px;
}
.box {
  border: 1px solid #999999;
}
.center_wrap_member {
  max-width: 640px;
  margin: 0 auto;
}
.center_wrap_member .sub_content_title {
  font-size: 30px;
  text-align: center;
  margin-bottom: 30px;
  font-weight: bold;
}
.content {
  padding: 0;
}

/*체크박스*/
.radio_wrap {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.box + .chk_box {
  padding: 20px;
}
.chk_box {
  font-size: 14px;
}
.chk {
  display: none;
}
.chk + label,
.chk + label:before {
  cursor: pointer;
}
.chk + label {
  padding-left: 25px;
  display: inline-block;
}
.chk + label:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #cdcdcd;
  margin-right: 5px;
  vertical-align: -3px;
  margin-left: -25px;
}
.chk:checked + label:before {
  background: url(../img/common/chk_blue.png) no-repeat;
  background-position: center;
  background-size: 20px 22px;
  border-color: #3d7df2;
}
input[type="radio"].chk + label {
  position: relative;
}
input[type="radio"].chk + label:before {
  border-radius: 20px;
  background-color: #fff;
}
input[type="radio"].chk.square + label:before {
  border-radius: 0;
}
.circle + label {
  font-size: 15px;
  letter-spacing: -0.38px;
}
.circle:checked + label::before {
  background: none;
}
.circle:checked + label::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #3d7df2;
  position: absolute;
  left: 5px;
  top: 5px;
}

/*textarea 높이값*/
.textarea_h50 {
  height: 50px;
}
.textarea_h100 {
  height: 100px;
}
.textarea_h150 {
  height: 150px;
}
.textarea_h200 {
  height: 200px;
}
.textarea_h250 {
  height: 250px;
}
.textarea_h400 {
  height: 400px;
}

/***********
버튼
************/
.btn {
  color: #fff;
  background: #3d7df2;
  font-size: 20px;
  text-align: center;
  padding: 0 5px;
  min-height: 56px;
  height: 56px;
  -ms-display: inline-flex;
  display: inline-flex;
  justify-content: center;
  -webkit-box-align: center;
  -moz-align-items: center;
  -moz-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
}
.btn.darkblue {
  background: #3d7df2;
}
.btn.navy {
  background: #193b65;
}
.btn.gray {
  background: #b3b9c4;
  border-color: #b3b9c4;
}
.btn.white {
  background: #fff;
  border: 1px solid #3d7df2;
  color: #3d7df2;
}
.btn.basics {
  background: #fff;
  border: 1px solid #cccccc;
  color: #333333;
}
.btn.red {
  background-color: #d02928;
}
.btn.line {
  background: #fff;
  border: 1px solid #3d7df2;
  color: #3d7df2;
}
.btn.line.black {
  border: 1px solid #333;
  color: #333;
}
.btn.line.gray {
  border-color: #d1d4da;
  color: #000000;
}
.btn.txt {
  background-color: transparent;
  color: #3d7df2;
}
.btn img {
  margin-right: 5px;
}
.btn_bottom_area {
  margin-top: auto;
  margin-bottom: 20px;
  display: flex;
  padding-top: 18px;
  justify-content: center;
  align-items: center;
}
.btn_bottom_area.right {
  justify-content: flex-end;
}
.btn_bottom_area.one {
  justify-content: center !important;
}
.btn_bottom_area .btn {
  padding: 0 40px;
  min-width: 190px;
  vertical-align: middle;
  font-weight: 600;
}
.btn_bottom_area .btn + .btn {
  margin-left: 10px;
}
.page_bottom_button_area {
  margin-top: 30px;
  margin-bottom: 190px;
  text-align: center;
}
.page_bottom_button_area .btn {
  min-width: 196px;
  vertical-align: middle;
}
.page_bottom_button_area.two .btn + .btn {
  margin-left: 9px;
}
.btn_excel_download {
  font-size: 15px;
  line-height: 1;
  letter-spacing: -0.75px;
  color: #333;
  height: 35px;
  border: 1px solid #333;
  width: 135px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn_excel_download img {
  margin-left: 10px;
}
.btn.m {
  min-height: 46px;
  height: 46px;
  font-size: 18px;
  font-weight: 500;
}
.btn_recommend_area {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.btn_recommend {
  cursor: pointer;
  background-color: #eee;
  border: 1px solid #3269cc;
  height: 45px;
  width: 117px;
  padding: 10px 0 14px;
  font-size: 15px;
  letter-spacing: -0.025em;
  color: #333;
  font-weight: 400;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
  line-height: 1;
}
.btn_recommend i {
  display: inline-block;
  width: 22px;
  height: 20px;
  background-image: url(../img/sub/btn_recommend_icon.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
}
.btn_recommend.active {
  background-color: #3269cc;
  color: #fff;
}
.btn_recommend.active i {
  background-image: url(../img/sub/btn_recommend_icon_active.png);
}

/*밑줄형*/
.btn2 {
  color: #3d7df2;
  border-bottom: 1px solid #6195f4;
  padding-bottom: 7px;
  vertical-align: middle;
  font-size: 15px;
}
.btn2 + .btn2 {
  margin-left: 30px;
}
.btn2.black {
  color: #000;
  border-bottom-color: #2f2f2f;
}
.btn2.darkgray {
  color: #373737;
  border-bottom-color: #373737;
}
td .btn2 + .btn2 {
  margin-left: 8px;
}

/*배너*/
.sub_bottom_banner {
  display: block;
}

/*달력*/
input[type="text"].datepicker {
  background-image: url("../img/sub/btn_calendar.png");
  background-repeat: no-repeat;
  background-position: 95% center;
}

/* 텍스트 */
.nanumGothic {
  font-family: "NanumGothic", sans-serif;
}
.txt {
  font-size: 15px;
  letter-spacing: -0.38px;
}
.txt_red {
  color: #d02928 !important;
}
.txt_blue {
  color: #3d7df2 !important;
}
.txt_black {
  color: #000 !important;
}

/* 말줄임 */
.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  overflow: hidden;
}
.ellipsis.line2 {
  -webkit-line-clamp: 2;
  -ms-line-clamp: 2;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis.line3 {
  -webkit-line-clamp: 3;
  -ms-line-clamp: 3;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.ellipsis.line4 {
  -webkit-line-clamp: 4;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.view_pc {
  display: block;
}
.view_m {
  display: none;
}
/*********************
paging
**********************/
.paging {
  text-align: center;
  clear: both;
  margin-top: 28px;
}
.paging a {
  min-width: 33px;
  padding: 0 5px;
  height: 33px;
  line-height: 33px;
  vertical-align: middle;
  border: 1px solid #e2e2e2;
  margin-right: 5px;
  color: #666;
  font-size: 15px;
}
.paging a.selected {
  background: #fff;
  color: #000;
  border-color: #000;
}
.paging .btn_first,
.paging .btn_prev,
.paging .btn_next,
.paging .btn_last {
  border: 1px solid #e2e2e2;
  margin: 0 3px;
  line-height: 28px;
}
.paging .btn_prev img,
.paging .btn_next img {
  opacity: 0.2;
}
.paging .btn_prev:hover img,
.paging .btn_next:hover img {
  opacity: 1;
}
.paging .btn_prev {
  margin-right: 27px;
}
.paging .btn_next {
  margin-left: 22px;
}

/***************
Swiper Pagination
***************/
.swiper-pagination {
  position: relative !important;
}
.pagination a {
  border-radius: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 3px;
  background: #cccccc;
  font-size: 1px;
  color: transparent;
  opacity: 1;
}
.pagination a.swiper-pagination-bullet-active {
  background: #3d7df2;
}

/***************
설정
****************/
.setting {
  position: relative;
  display: inline-block;
}
.setting input[type="checkbox"] {
  width: 52px;
  height: 20px;
  display: inline-block;
  -webkit-appearance: none;
  margin-right: 3px;
  background-color: #d9dadc;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  margin-top: -15px;
  transition: all 0.3s ease;
  cursor: pointer;
}
.setting input[type="checkbox"]:focus {
  outline: none;
}
.setting input[type="checkbox"]:after {
  content: "";
  display: inline-block;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -13px;
  transition: all 0.3s ease;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.setting input:checked[type="checkbox"] {
  background-color: #3d7df2;
  transition: all 0.3s ease;
}
.setting input:checked[type="checkbox"]:after {
  left: 27px;
  transition: all 0.3s ease;
}
_:-ms-fullscreen,
:root .setting input[type="checkbox"] {
  width: auto;
  background: none;
}

/*************
스크롤
**************/
/* html,body{overflow-x: hidden;} */
/*스크롤*/
.scroll {
  scrollbar-track-color: #efefef;
  scrollbar-face-color: #e2e2e2;
}
.scroll::-webkit-scrollbar {
  opacity: 0;
  width: 10px;
}
.scroll::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
  height: 10px;
}
.scroll::-webkit-scrollbar-track {
  background-color: #f3f3f3;
}

.frm-input-box {
  width: 100%;
  border: 1px solid #cfcfcf;
  background: #fff;
  padding: 0 14px;
  box-sizing: border-box;
  min-height: 48px;
  color: #333;
  font-size: 15px;
  vertical-align: middle;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
.mt1rem {
  margin-top: 1rem;
}

@media screen and (max-width: 1260px) {
  /*레이아웃*/
  .center_wrap {
    width: 100%;
    padding: 0 30px;
  }
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 768px) {
  /*모바일 디자인 적용*/
  /*레이아웃*/
  .center_wrap_member {
    width: 100%;
    padding: 0 4%;
  }
  .center_wrap {
    padding: 0 4%;
  }
  #sub_wrap {
    margin-top: 0; /*14px*/
  }
  .box {
    border: none;
  }
  .loading .loading_wrap > div {
    font-size: 12px;
  }

  .center_wrap_member {
    margin-top: 24px !important;
  }
  .center_wrap_member .sub_content_title {
    font-size: 21px;
    margin-bottom: 15px;
  }
  .txt {
    font-size: 11px;
    letter-spacing: normal;
  }
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  input[type="email"],
  select,
  textarea {
    font-size: 13px;
  }
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  select,
  textarea {
    min-height: 40px;
  }
  input[type="number"],
  input[type="password"],
  input[type="tel"],
  input[type="text"],
  input[type="email"],
  select,
  textarea {
    padding: 0 6px;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    font-size: 13px;
  }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    font-size: 13px;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 13px;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    font-size: 13px;
  }
  .view_pc {
    display: none;
  }
  .view_m {
    display: block;
  }
  select {
    background-image: url("../img/common/select_arrow_m.png");
    padding: 0 22px 0 5px;
  }

  /*textarea 높이값*/
  .textarea_h50 {
    height: 25px;
  }
  .textarea_h100 {
    height: 50px;
  }
  .textarea_h150 {
    height: 75px;
  }
  .textarea_h200 {
    height: 100px;
  }
  .textarea_h250 {
    height: 125px;
  }
  .textarea_h400 {
    height: 200px;
  }

  /****체크박스***/
  .chk + label {
    font-size: 13px;
    padding-left: 21px;
  }
  /*.chk+label:before{width:16px; height: 16px;}*/
  /* ▼▼▼ 충돌! 보미님 작업 ▼▼▼ */
  .chk + label:before {
    width: 16px;
    height: 16px;
    margin-left: -21px;
  }
  .circle:checked + label::after {
    width: 8px;
    height: 8px;
    top: 4px;
    left: 4px;
  }
  /****버튼****/
  .btn {
    min-height: 40px;
    height: 40px;
    font-size: 12px;
  }
  .btn2 {
    font-size: 12px;
  }
  .btn_bottom_area .btn {
    width: 100%;
    min-width: 0;
    padding: 0 10px;
    font-size: 13px;
  }
  .box_out_button_area .btn {
    font-size: 14px;
  }
  .page_bottom_button_area {
    margin: 20px 0 30px;
  }
  .write .page_bottom_button_area.one button,
  .page_bottom_button_area.one .btn {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .page_bottom_button_area.two {
    display: flex;
    justify-content: space-between;
  }
  .page_bottom_button_area.two .btn {
    width: 49%;
    min-width: auto;
  }
  .page_bottom_button_area.two .btn + .btn {
    margin-left: 0;
  }
  .btn.m {
    width: auto;
    height: 36px;
    min-height: 36px;
  }
  .btn_recommend {
    font-size: 12px;
    height: 31.5px;
    width: 82.5px;
    padding: 7px 0 10px;
  }
  .btn_recommend i {
    margin-left: 7px;
    width: 16px;
    height: 14px;
    background-size: contain;
  }

  /**********paging********/
  .paging {
    margin-top: 24px;
  }
  .paging a {
    min-width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
  }
  .paging .btn_prev {
    margin-right: 10px;
  }
  .paging .btn_next {
    margin-left: 5px;
  }
  .paging .btn_first,
  .paging .btn_prev,
  .paging .btn_next,
  .paging .btn_last {
    line-height: 22px;
  }
}

@media screen and (max-width: 640px) {
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    font-size: 11px;
  }
  input::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    font-size: 11px;
  }
  input::placeholder,
  textarea::placeholder {
    font-size: 11px;
  }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    font-size: 11px;
  }
  .write select,
  select {
    font-size: 11px;
  }
}

/* 다음 post 주소찾기 팝업 관련 */
.poplayerCompanySearch {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
}
.poplayerCompanySearch > div {
  width: 400px;
  position: relative;
  max-width: 90vw;
  margin: 0 auto;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
}
.poplayerCompanySearch .btnclose {
  position: absolute;
  right: 0;
  top: -30px;
  cursor: pointer;
}

.text-lines {
  white-space: pre-line;
}

/** 회원 가입 폼 탈퇴회원 확인 창*/
/* 다음 post 주소찾기 팝업 관련 */
.poplayer_Withdrawal_confirmation {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  width: 100%;
  height: 100%;
}
.poplayer_Withdrawal_confirmation > div {
  width: 400px;
  position: relative;
  max-width: 90vw;
  margin: 0 auto;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
}

input[type=date]{
  height:40px !important;
  min-height: 48px !important;
  padding-left: 10px !important;
  border: 1px solid #cfcfcf !important;
  color: #333 !important;
  box-sizing: border-box !important;
  font-size: 15px;
}

.warring {
  color: red;
  font-size: large;
}
@media screen and (max-width:1024px){
  .warring{font-size:13px;}
  .warring+.pdf_download_wrap{margin-left:10px;}
}