@charset "utf-8"; /* 공통 */
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-kr.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100; 300; 400; 500; 700; 900&display=swap");
html {
  height: 100%;
}


/* body { font:400 16px 'Noto Sans KR', 'Malgun Gothic', Dotum, Arial, Helvetica, sans-serif; color:#000; min-width:1900px; height:100%; background:#fff; } 
body, html, p, div, ul, li , h1, h2, h3, h4, h5, h6, span,br, dl, dt, dd { padding:0; margin:0; } */
body {
}
a {
  text-decoration: none;
  color: #333;
}
/* a, img { border:none; vertical-align:middle; }  */
/* ul, li { list-style:none; }  */
img {
  max-width: 100%;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
}

/* ::-webkit-scrollbar { width: 4px; } 
::-webkit-scrollbar-thumb { background-color: #464650; border-radius: 2px; background-clip: padding-box; } 
::-webkit-scrollbar-track { background-color: #111118; border-radius: 2px; }  */

.ck-content {
  height: 300px;
}
/* .login { width: 100%; height: 100vh; background-color: #24292e; }  */
.top-menu .selected {
  background-color: #243b54;
}
.selectedmenu > a {
  background-color: #243b54;
}
.selectedlimenu {
  color: #258afe;
}
.f-rightt {
  float: right;
}

#head-wrap {
  width: calc(100% - 240px);
  background: #24292e;
  height: 50px;
  line-height: 50px;
  position: fixed;
  top: 0;
  left: 240px;
  z-index: 1;
  box-sizing: border-box;
  border-bottom: 1px solid #383c41;
  padding: 0 40px 0 30px;
  border-bottom: 1px solid #383c41;
  border-left: 1px solid #383c41;
}
#head-wrap .center-wrap {
  width: 100%;
}
#head-wrap .center-wrap .logo {
  display: inline-block;
  line-height: 50px;
}
#head-wrap .info {
  display: inline-block;
  margin-right: 28px;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
}
#head-wrap .info span {
  color: #818181;
}
#head-wrap .info img {
  display: inline-block;
  margin-right: 10px;
  vertical-align: -11px;
}
#head-wrap .f-right a {
  display: inline-block;
  height: 31px;
  line-height: 28px;
  min-width: 105px;
  padding: 0;
  font-size: 14px;
  background: #fff !important;
  font-weight: bold;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  color: #24292e;
  font-weight: 400 !important;
}
#head-wrap .f-right a img {
  display: inline-block;
  margin-right: 6px;
  vertical-align: -3px;
}
#head-wrap .f-right a.blue {
  color: #258afe;
}

#head-wrap .f-rightt{padding-top:10px;    display: flex;
  align-items: center;}
#head-wrap .f-rightt .btn-s {
  cursor: pointer;
  display: inline-block;
  height: 31px;
  line-height: 28px;
  min-width: 93px;
  padding: 0;
  font-size: 14px;
  background: #fff !important;
  font-weight: bold;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  color: #24292e;
  font-weight: 400 !important;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
#head-wrap .f-rightt .btn-s img {
  display: inline-block;
  margin-right: 6px;
  vertical-align: -3px;
}

.top-menu {
  line-height: 50px;
  position: fixed;
  top: 50px;
  left: 0;
  left: 240px;
  width: calc(100% - 240px);
  box-sizing: border-box;
  background: #24292e;
  border-left: 1px solid #383c41;
  padding-left: 20px;
  vertical-align: top;
  z-index: 9;
}
.top-menu a {
  display: inline-block;
  font-size: 17px;
  line-height: 52px;
  padding: 0 20px;
  color: #fff;
  vertical-align: top;
}
.top-menu a.on {
  background: #243b54;
}

#left-wrap {
  width: 240px;
  background: #24292e;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 1;
  overflow-y: auto;
}
#left-wrap .logo{width: 100%; height: 100px; text-align: center;display: flex;justify-content: center;align-items: center;flex-direction: column;}
#left-wrap .h1{color: rgb(255, 255, 255); font-size: 24px; padding-bottom: 5px; font-weight: 600;}
#left-wrap .h1-s{font-size: 16px; color: rgb(39, 142, 250);}

#gnb .depth1 > li {
  padding: 0 0;
  position: relative;
  overflow: hidden;
}
#gnb .depth1 > li > a {
  font-size: 17px;
  color: #fff;
  display: block;
  padding: 0 0 0 25px;
  position: relative;
  font-weight: 400;
  line-height: 50px;
}
#gnb .depth2 {
  padding: 10px 0;
  background: #131619;
}
#gnb .depth2 > li a {
  color: #b3b3b3;
  font-size: 15px;
  line-height: 24px;
  padding: 3px 25px;
  display: block;
  position: relative;
}

#gnb .depth2 > li > a.active {
  color: #258afe;
}

#gnb .depth3 {
  padding: 10px 0;
}
#gnb .depth3 li a {
  color: #818181;
  font-size: 14px;
  line-height: 30px;
  padding: 0 35px;
  display: block;
}
#gnb .depth3 li a.active {
  color: #fff;
  background: none;
}
#gnb .depth3 li a:before {
  content: "-";
  display: inline-block;
}

#gnb .depth1 > li.active > a {
  background: #243b54;
}
#gnb .active .depth2 {
  display: block;
}
#gnb .depth2 .active .depth3 {
  display: block;
}
#gnb .depth1 > a {
  font-size: 18px;
  color: #fff;
  display: block;
  padding: 0 0 0 35px;
  position: relative;
  font-weight: 400;
  line-height: 60px;
  margin: 10px 0;
}
#gnb .depth1 > a:after {
  content: " ";
  background: url(../img/menu-down.png) no-repeat left top;
  background-size: 100% auto;
  width: 13px;
  height: 7px;
  position: absolute;
  right: 35px;
  top: 50%;
  margin-top: -5px;
}
#gnb .depth1 > a.active {
  color: #02a499;
  border-radius: 4px;
}

#contents {
  padding: 100px 0 0 240px;
  box-sizing: border-box;
  min-width: calc(100% - 240px);
  background: #fff;
  height: 100%;
}
#contents .tit {
  line-height: 50px;
  padding: 0 40px;
  border-bottom: 1px solid #eee;
  font-size: 20px;
  color: #202020;
  font-weight: bold;
}
#contents .tit .path {
  font-size: 14px;
  color: #999;
  display: inline-block;
  float: right;
  font-weight: 400;padding-top:16px;
}
#contents .tit .path img {
  display: inline-block;
  margin: 0 5px;
  vertical-align: 0px;
}

#contents .content .tit {
  font-size: 20px;
  color: #333333;
  padding: 0 0 10px 0;
  border-bottom: 0;
  line-height: 40px;
}
#contents .content .tit:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  background: #258afe;
  border-radius: 50%;
  margin: 0 5px 0 10px;
}

#contents .content .tit .btn {
  margin-left: 10px;
  padding: 0 25px;
}
/* #contents tbody > tr > td { text-align: center; } 
#contents thead > tr > td > p { text-align: center; } 
#contents tbody > tr > td { text-align: center; } 
#contents tbody > tr > td > p { text-align: center; } 
#contents tbody > tr > td > button { height: 40px; line-height: 38px; min-width: 70px; font-size: 15px; text-align: center; display: inline-block; font-weight: 400; margin: 0 2px; background-color: #fff; box-sizing: border-box; border-radius: 4px; padding: 0 20px; border: 1px solid #bbb; color: #000; } 
 #contents tbody > tr > td > a> button { height: 40px; line-height: 38px; min-width: 70px; font-size: 15px; text-align: center; display: inline-block; font-weight: 400; margin: 0 2px; background-color: #fff; box-sizing: border-box; border-radius: 4px; padding: 0 20px; border: 1px solid #bbb; color: #000; } 
*/

.txt-type001.terms_chk {
  margin-left: 20px;
}
.txt-type001.terms_chk label {
  color: #333;
  padding-left: 30px;
}
.txt-blue {
  color: #258afe;
}

table.type01 {
  border: 1px solid #eee;
  margin-bottom: 20px;
}
table.type01 th {
  font-weight: 400;
  text-align: center;
  border-right: 1px solid #eee;
  padding-left: 0;
  line-height: 40px;
  font-size: 15px;
}
table.type01 td {
  line-height: 40px;
  border-right: 1px solid #eee;
  text-align: center;
  padding: 5px 10px;
}
table.write.type0002 th {
  text-align: center;
  border-right: 1px solid #eee;
  padding: 0;
}
table.write.type0002 td {
  text-align: center;
  border-right: 1px solid #eee;
  padding: 0;
  font-weight: 300;
}

.write td.text-write-box textarea {
  height: 100px;
  width: 89%;
  display: inline-block;
  margin-right: 0;
  vertical-align: top;
}
.write td.text-write-box input[type="button"] {
  margin-top: 10px;
  vertical-align: top;
  height: 100px;
  width: 10%;
  display: inline-block;
  background: #666a6e;
  border: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  border-radius: 8px;
}

.write td.text-write-box {
  line-height: 30px;
  padding-bottom: 20px;
}
.write td.text-write-box .txt-info {
  color: #999999;
  font-size: 14px;
  margin-top: 25px;
}
.write td.text-write-box .txt-con {
  color: #333;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 15px;
}

.login-wrap-box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;
}
.login-wrap {
  background: #24292e;
  width: 610px;
  text-align: center;
  border: 3px solid #fff;
  padding: 50px 80px 70px;
  box-sizing: border-box;
  border-radius: 14px;
}

.login-wrap p {
  display: block;
  font-size: 20px;
  color: #3a7afe;
  font-weight: bold;
}
.login-wrap b {
  display: block;
  font-size: 45px;
  color: #fff;
}
.login-wrap span {
  display: block;
  font-size: 17px;
  line-height: 25px;
  color: #7f8183;
  padding: 20px 0 30px;
}

.login-wrap input[type="text"],
.login-wrap input[type="password"] {
  height: 50px;
  line-height: 48px;
  margin-bottom: 20px;
  padding-left: 30px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  font-size: 15px;
  color: #999999;
  margin-left: 0 !important;
}
.login-wrap input[type="button"] {
  height: 50px;
  line-height: 48px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  border: 0;
  border-radius: 5px;
  font-size: 18px;
  color: #fff;
  background: #3a7afe;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-left: 0 !important;
}

.terms_chk-login {
  text-align: left;
  font-size: 16px;
  color: #fff;
  padding: 0px 0 20px 0;
  position: relative;
  display: block;
  margin-left: 15px;
}
.terms_chk-login .chk1 + i {
  top: 0px;
}
.terms_chk-login .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk-login .chk1 {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk-login .chk1 + i {
  display: inline-block;
  width: 19px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 0px;
  background: #fff;
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  border-radius: 5px;
}
.terms_chk-login .chk1 + i {
  pointer-events: none;
}
.terms_chk-login .chk1:checked + i {
  text-align: center;
}
.terms_chk-login .chk1:checked + i:after {
  content: "";
  display: inline-block;
  background: url(../img/chk-on.png) no-repeat center center;
  width: 19px;
  height: 19px;
  border: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 5px;
}
.terms_chk-login label {
  display: inline-block;
  position: relative;
  left: 0;
  padding-left: 30px;
  line-height: 15px;
  margin-left: 0;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  vertical-align: top;
}

.f-box {
  text-align: center;
  padding: 20px 0 0;
  color: #999999;
  font-size: 14px;
}

.btn {
  height: 40px;
  line-height: 38px;
  background: #258afe;
  min-width: 70px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  display: inline-block;
  font-weight: 400;
  margin: 0 2px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  padding: 0 10px;
  cursor: pointer;
}
.btn.line {
  border: 1px solid #bbb;
  color: #000;
  background: #fff;
}
.btn.gray {
  background: #2c2f3e;
}
.btn.gray-s {
  background: #666a6e;
}
.btn.gray-b {
  background: #24292e;
  margin-left: -5px;
}
.btn.small {
  height: 35px;
  line-height: 35px;
  font-size: 15px;
}
.line-red {
  border: 1px solid #e71515;
  color: #e71515;
  background: #fff;
}
#contents .main-box {
  border: 1px solid #eee;
  border-radius: 4px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px #f9f9f9;
}
#contents .main-box2 {
  border-radius: 4px;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
}
#contents .main-box .mtit {
  line-height: 58px;
  padding-left: 20px;
  font-size: 21px;
  color: #333;
  font-weight: bold;
  border-bottom: 1px solid #eee;
}
#contents .main-box .stit {
  line-height: 50px;
  padding-left: 20px;
  font-size: 18px;
  color: #333;
  font-weight: 400;
  border-bottom: 1px solid #eee;
  background: #f9f9f9;
}
#contents .main-box .stit a {
  float: right;
  margin-right: 22px;
  display: inline-block;
}
#contents .main-box.w50 {
  width: 49.2%;
  display: inline-block;
}
#contents .main-box.w50.f-right {
  float: right;
}
#contents .main-box {
  margin-bottom: 20px;
  padding: 0;
}
#contents .main-box2 {
  overflow: auto;
}

.content {
  padding: 30px 40px 30px !important;
}
.content:after {
  content: "";
  display: block;
  clear: both;
}

.list th {
  position: relative;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 57px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
}
.list td {
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #666;
  border-bottom: 1px solid #eee;
  line-height: 57px;
  font-weight: 300;
  padding: 0 20px;
  border-spacing: 0;
  margin: 0;
  box-sizing: border-box;
}
.list tr:hover td {
  background: #f9f9f9;
}
.list td img {
  margin-right: 6px;
}
.list td .f-right {
  margin-top: 10px;
}

.list01 th {
  position: relative;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 20px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  vertical-align: middle;
  padding: 20px 0;
}
.list01 td {
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #666;
  border-bottom: 1px solid #eee;
  line-height: 20px;
  font-weight: 300;
  padding: 20px 20px;
  border-spacing: 0;
  margin: 0;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;
}
.list01 tr:hover td {
  background: #f9f9f9;
}
.list01 td img {
  margin-right: 6px;
}
.list01 td .f-right {
  margin-top: 10px;
}

.terms_chk {
  text-align: left;
  font-size: 16px;
  color: #fff;
  padding: 0;
  position: relative;
  display: inline-block;
  margin-left: 0;
  line-height: 20px;
}
.terms_chk .chk1 + i {
  top: 0px;
}
.terms_chk .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk .chk1 {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk .chk1 + i {
  display: inline-block;
  width: 19px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 0px;
  background: #fff;
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #999;
}
.terms_chk .chk1 + i {
  pointer-events: none;
}
.terms_chk .chk1:checked + i {
  text-align: center;
}
.terms_chk .chk1:checked + i:after {
  content: "";
  display: inline-block;
  background: url(../img/chk-on.png) no-repeat center center;
  width: 19px;
  height: 19px;
  border: 0;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 5px;
}
.terms_chk label {
  display: inline-block;
  position: relative;
  left: 0;
  padding-left: 20px;
  line-height: 15px;
  margin-left: 0;
  cursor: pointer;
  color: #fff;
  font-weight: 400;
  vertical-align: top;
}

.terms_chk001 {
  text-align: left;
  font-size: 16px;
  color: #333;
  padding: 0;
  position: relative;
  display: inline-block;
  margin-left: 0;
  line-height: 20px;
}
.terms_chk001 .chk1 + i {
  top: 0px;
}
.terms_chk001 .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk001 .chk1 {
  width: 19px;
  height: 19px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk001 .chk1 + i {
  display: inline-block;
  width: 19px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 0px;
  background: #fff;
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #999;
}
.terms_chk001 .chk1 + i {
  pointer-events: none;
}
.terms_chk001 .chk1:checked + i {
  text-align: center;
}
.terms_chk001 .chk1:checked + i:after {
  content: "";
  display: inline-block;
  background: url(../img/chk-on.png) no-repeat center center;
  width: 19px;
  height: 19px;
  border: 0;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 5px;
}
.terms_chk001 label {
  display: inline-block;
  position: relative;
  left: 0;
  padding-left: 25px;
  line-height: 19px;
  margin-left: 0;
  cursor: pointer;
  color: #333;
  font-weight: 400;
  vertical-align: top;
}

.center-con {
  display: inline-block;
  padding-left: 40px;
  width: 100%;
}
/* #paging { text-align:center; padding:23px 0; } 
#paging a { width:30px; height:30px; line-height:28px; text-align:center; display:inline-block; color:#45464e; font-size:16px; margin:0 5px; } 
#paging a.active { background:#258afe; color:#fff; border-radius:50% } */

.terms_chk03 {
  text-align: left;
  font-size: 18px;
  color: #333;
  padding: 0px 0 0 0;
  position: relative;
  display: inline-block;
  margin-right: 0;
}
.terms_chk03 .chk1 + i {
  top: 0px;
}
.terms_chk03 .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk03 .chk1 {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk03 .chk1 + i {
  display: inline-block;
  width: 28px;
  height: 28px;
  position: absolute;
  left: 0;
  top: 0px;
  background: url(../img/chk.png) no-repeat center center;
  width: 28px;
  height: 28px;
  box-sizing: border-box;
}
.terms_chk03 .chk1 + i {
  pointer-events: none;
}
.terms_chk03 .chk1:checked + i {
  text-align: center;
}
.terms_chk03 .chk1:checked + i:after {
  content: "";
  display: inline-block;
  background: url(../img/ceh-on.png) no-repeat center center;
  width: 28px;
  height: 28px;
  border: 0;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0px;
}
.terms_chk03 label {
  display: inline-block;
  position: relative;
  left: 0;
  padding-left: 30px;
  line-height: 25px;
  margin-left: 0;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  vertical-align: top;
}

.search-box {
  line-height: 80px;
  font-size: 16px;
  color: #333333;
  background: #fff;
  padding: 0 30px;
}
.search-box p {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
  color: #333;
  font-size: 16px;
}
.search-box input[type="text"],
.search-box input[type="password"] {
  height: 40px;
  border-radius: 4px;
  display: inline-block;
  line-height: 36px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #e2e3e5;
  color: #333;
  font-size: 16px;
  box-sizing: border-box;
}
.search-box a.btn {
  height: 40px;
  line-height: 40px;
  background: #24292e;
  border: 0;
  font-size: 15px;
  font-weight: 400;
}
.search-box a.f-right {
  margin-top: 20px;
}

.day-box {
  display: inline-block;
}
.day-box p {
  display: inline-block;
  margin-right: 15px;
}
.day-box input {
  height: 40px;
  border-radius: 4px;
  display: inline-block;
  line-height: 36px;
  padding-left: 10px;
  width: 200px;
  border: 1px solid #e2e3e5;
  color: #333;
  font-size: 16px;
  box-sizing: border-box;
}

.top-gray-txt {
  font-size: 15px;
  color: #999;
  padding: 0 0 20px 20px;
}

.top-txt {
  font-size: 15px;
  padding: 0 0 20px 20px;
}

.re-con {
  padding-left: 50px !important;
}

.terms_chk02 {
  text-align: left;
  font-size: 18px;
  color: #333;
  padding: 0px 0 0 0;
  position: relative;
  display: inline-block;
  margin-right: 0;
}
.terms_chk02 .chk1 + i {
  top: 0px;
}
.terms_chk02 .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk02 .chk1 {
  width: 70px;
  height: 38px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk02 .chk1 + i {
  display: inline-block;
  width: 70px;
  height: 38px;
  position: absolute;
  left: 0;
  top: 25px;
  box-sizing: border-box;
  background: #dfeeff;
  border: 1px solid #dfeeff;
  border-radius: 5px;
}
.terms_chk02 .chk1 + i {
  pointer-events: none;
}
.terms_chk02 .chk1:checked + i {
  text-align: center;
}
.terms_chk02 .chk1:checked + i:after {
  content: "";
  display: inline-block;
  width: 70px;
  height: 38px;
  border: 0;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 3px;
  border: 1px solid #258afe;
  background: #fff;
}
.terms_chk02 label {
  display: inline-block;
  position: relative;
  left: 0;
  width: 70px;
  height: 80px;
  line-height: 87px;
  margin-left: 0;
  cursor: pointer;
  color: #258afe;
  font-weight: 400;
  vertical-align: top;
  text-align: center;
  font-size: 15px;
}

.btn-center {
  padding: 24px;
  text-align: center;
}
/* .btn-right { text-align:right; padding:24px; } */
.btn-right {
  float: right;
  padding-top: 0;
  margin: 10px 0px;
}
.btn-right > button {
  height: 40px;
  line-height: 38px;
  background: #258afe;
  min-width: 70px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  display: inline-block;
  font-weight: 400;
  margin: 0 2px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  padding: 0 10px;
}
.btn-center > button {
  height: 40px;
  line-height: 38px;
  background: #258afe;
  min-width: 70px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  display: inline-block;
  font-weight: 400;
  margin: 0 2px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 0;
  padding: 0 10px;
}

.write th {
  background: #f9f9f9;
  font-size: 16px;
  text-align: left;
  padding-left: 25px;
  width: 270px;
  color: #333333;
  border-bottom: 1px solid #eee;
  line-height: 42px;
  vertical-align: top;
  font-weight: bold;
}
.write td {
  line-height: 42px;
  padding: 0 15px;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  vertical-align: top;
}
.write td input[type="text"],
.write td input[type="password"] {
  height: 34px;
  border-radius: 4px;
  display: inline-block;
  line-height: 32px;
  min-height:32px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #e2e3e5;
  color: #333;
  font-size: 15px;
  box-sizing: border-box;
}
.write select {
  height: 34px;
  border-radius: 4px;
  display: inline-block;
  line-height: 32px;
  min-height:32px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #e2e3e5;
  color: #333;
  font-size: 15px;
  box-sizing: border-box;
}
.write td textarea {
  height: 110px;
  border-radius: 4px;
  padding-left: 10px;
  width: 100%;
  border: 1px solid #e2e3e5;
  color: #333;
  font-size: 16px;
  margin-top: 10px;
  margin-right: 15px;
  box-sizing: border-box;
}
.write td input[type="button"] {
  border: 0;
  line-height: 40px;
  height: 40px;
  vertical-align: middle;
  width: 106px;
  margin-left: 2px;
  font-size: 16px;
}

input[type=number], input[type=password], input[type=tel], input[type=text], input[type=email], select, textarea{min-height:32px;height: 32px;line-height: 30px;}

.view th {
  background: #f9f9f9;
  position: relative;
  text-align: center;
  font-size: 16px;
  color: #333;
  line-height: 57px;
  font-weight: 500;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  vertical-align: middle;
}
.view td {
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #666;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
  line-height: 57px;
  font-weight: 300;
  padding: 0 20px;
  border-spacing: 0;
  margin: 0;
  box-sizing: border-box;
  color: #666666;
  font-size: 16px;
}
.view tr:hover td {
  background: #f9f9f9;
}
.view td img {
  margin-right: 6px;
}
.view td .f-right {
  margin-top: 10px;
}
.view td.txt-bold {
  font-weight: bold;
}

.w300 {
  width: 300px !important;
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
}

.w30 {
  width: 30%;
  display: inline-block;
  vertical-align: top;
}
.w65 {
  width: 68%;
  display: inline-block;
  vertical-align: top;
  float: right;
}
.w66 {
  width: 78%;
  display: inline-block;
  vertical-align: top;
}
.w20 {
  width: 20%;
  display: inline-block;
  vertical-align: top;
  float: right;
}
.w80 {
  width: 80% !important;
  display: inline-block;
  margin-right: 15px;
}
.w340 {
  width: 340px !important;
  margin-right: 15px;
}
.delet-box span {
  display: inline-block;
  font-size: 17px;
  color: #333333;
}
.delet-box span a {
  display: inline-block;
  margin-left: 8px;
}
.red-txt {
  display: inline-block;
  color: #ff3333;
  font-size: 15px;
}
.txt-left {
  text-align: left !important;
}

.btns.gray {
  display: inline-block;
  height: 30px;
  line-height: 28px;
  width: 65px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  background: #6d6f78;
  border-radius: 3px;
  padding: 0;
}

.top-line {
  border-top: 1px solid #eee;
}

.full {
  width: 100% !important;
}

.f-right.chk-box {
  float: right;
}
.f-right.chk-box p {
  font-size: 17px;
  color: #333333;
  display: inline-block;
  margin-right: 60px;
}

.terms_chk01 {
  text-align: left;
  font-size: 16px;
  color: #333333;
  padding: 0px 0 0 0;
  position: relative;
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
  line-height: 22px;
}
.terms_chk01 .chk1 + i {
  top: 0px;
}
.terms_chk01 .chk1:checked + i:after {
  vertical-align: 0px !important;
}

.terms_chk01 .chk1 {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
  display: none;
}
.terms_chk01 .chk1 + i {
  display: inline-block;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 0px;
  border: 1px solid #9c9c9c;
  border-radius: 50%;
  box-sizing: border-box;
}
.terms_chk01 .chk1 + i {
  pointer-events: none;
}
.terms_chk01 .chk1:checked + i {
  text-align: center;
}
.terms_chk01 .chk1:checked + i:after {
  content: "";
  display: inline-block;
  background: url(../img/ceh-on.png) no-repeat center center;
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  box-sizing: border-box;
  position: absolute;
  top: -1px;
  left: -1px;
}
.terms_chk01 label {
  display: inline-block;
  position: relative;
  left: 0;
  padding-left: 27px;
  line-height: 18px;
  margin-left: 0;
  cursor: pointer;
  color: #000;
  font-weight: 400;
  vertical-align: top;
}

.txt-right {
  text-align: right !important;
}
.txt-left {
  text-align: left !important;
}

.h250 {
  height: 250px !important;
}

.f-right {
  float: right;
  display: inline-block;
}

.clr {
  clear: both;
}

.footer-con {
  text-align: center;
  font-size: 14px;
  color: #999;
  padding-left: 260px;
  padding-bottom: 15px;
}

/* .page_nav { width: 100%; height: 50px; background-color: #02a49.page_nav>ul { width: 100%; margin-left: 50%; transform: translateX(-50%); } */
/* .page_nav ul li { float: left; } */

.collegebox {
  display: block;
  width: 100%;
  cursor: pointer;
}

.f-middle {
  margin-left: 420px;
  width: 480px;
  display: inline-block;
}

.search {
  margin-left: 10px;
}

.content > .menu {
  display: flex;
  align-items: center;
  border: 1px solid #d4d4d4;
  overflow: hidden;
}
.content > .menu a {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  border-left: 1px solid #ddd;
}
.content > .menu a:first-child {
  border-left: none;
}
.content > .menu .active {
  color: #fff;
  background: #0070eb;
}

.write-list .term-textarea {
  height: 500px;
  width: 100%;
}
.write-list .term-textarea textarea {
  height: 100%;
  width: 95%;
  border: 1px solid #eee;
  padding: 5px;
}

.term-textarea textarea::-webkit-scrollbar {
  width: 16px;
}
.term-textarea textarea::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.term-textarea textarea::-webkit-scrollbar-thumb {
  background-color: #02a499;
  border-radius: 10px;
}
.term-textarea textarea::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.term-textarea textarea::-webkit-scrollbar-button:start:decrement,
.term-textarea textarea::-webkit-scrollbar-button:end:increment {
  width: 16px;
  height: 16px;
  background: #02a499;
}

.amin_input {
  -webkit-appearance: checkbox;
}
.size_guide { font-weight: 400; color: red !important; }

.main-box .scrollbox {
  max-height: 500px;
  overflow: scroll;
}

.pointer {
  cursor: pointer;
}