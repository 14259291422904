@charset "utf-8";/**************
인재검색
***************/
/*이용권*/
.icon_banner { display: flex; justify-content: center; align-content: center; padding:52px 0 40px; } 
.icon_banner li { display: flex; align-items: center; } 
.icon_banner li:nth-child(2n) { padding:0 100px; } 
.icon_banner .icon { border-radius: 100px; width:36px; height: 36px; background: #ececec; display:block; line-height: 36px; text-align: center; } 
.icon_banner .title_field { font-size:15px; color:#000; } 
.icon_banner .point { color:#3d7df2; font-size:20px; font-weight: bold; padding-left: 15px; } 
.icon_banner .button { background: url("../img/sub/arrow_right.png") no-repeat right center; padding-right: 34px; } 

/*검색*/
.talent_search { background: #fafbfb; border:1px solid #cccccc; } 
.talent_search .search { padding:30px; margin-left:0; } 
.talent_search .search .title_field { font-size:20px; font-weight: bold; color:#000; letter-spacing: -0.05em; margin-bottom: 15px; }
.talent_search .search .input_wrap { display: flex; align-items: center; } 
.talent_search .search .input_wrap [type="text"] { width:18%; margin-left: 5px;}
.talent_search .search .input_wrap .has_auto_text { width:36%; position:relative; } 
.talent_search .search .input_wrap .has_auto_text input { width:100%; margin-left:0; }
.talent_search .search .btn { min-height: 48px; height:48px; font-size:20px; padding:0 30px; margin-left: 8px; white-space: nowrap; margin-right:0; } 
.talent_search .search .btn.white { border-color:#cccccc; color:#000; padding:0 55px 0 20px; background: url("../img/sub/icon_open2.png") no-repeat 102px center #fff; } 
.talent_search .search .auto_text_area { display:none; position:absolute; left:0; top:47px; width:100%; height:170px; background-color: #fff; border: 1px solid #e2e2e2; flex-direction: column; justify-content: space-between;}
.talent_search .search .auto_text_area.active { display:flex; }
.talent_search .search .auto_text_wrap { overflow: auto; }
.talent_search .search .auto_text_wrap a { width:100%; padding:6px; cursor:pointer; }
.talent_search .search .auto_text_wrap a:hover { text-decoration: underline; }
.talent_search .search .auto_text_close_wrap { background-color: #f2f2f2; text-align:right; padding:6px; border-top:1px solid #e2e2e2; }

.talent_search .search_detail { padding:30px; border-top:1px solid #e4e5e5; } 
.talent_search .talent_reset_mobile { display:none; }

/* .talent_search .search_detail .job { width: 38%; margin-right: 15px; } 
.talent_search .search_detail .place { width: 13% } 
.talent_search .search_detail .career { margin-left: 50px; width: calc(49% - 65px) } 
.talent_search .search_detail .career select { width: auto; min-width: 70px; } 
.talent_search .search_detail .career  select.hide {  pointer-events: none; background-color: #eee;} 
.talent_search .search_detail .title_field { font-size:18px; letter-spacing: -0.05em; color:#000; margin-bottom: 8px; } 
.talent_search .search_detail .input_wrap { display: flex; align-items: center; } 
.talent_search .search_detail .input_wrap .chk+label { color:#666666; font-size:14px; white-space: nowrap; } 
.talent_search .search_detail .career .input_wrap:last-child { flex-wrap: wrap; } 
.talent_search .search_detail .chk+label { margin-left: 0; margin-right: 10px; margin-bottom: 10px; width: calc(25% - 10px); } 
.talent_search .search_detail .chk+label:before { vertical-align: -4px; } 
.talent_search .search_detail .input_wrap select+.text { color:#000; font-size:15px; margin:0 5px; white-space: nowrap; } 
.talent_search .search_detail .input_wrap+.title_field { margin-top: 25px; } 
.talent_search .search_detail .select_box .wrap { height: 150px; } 
.talent_search .search_detail .select_box .wrap+.wrap { border-left:1px solid #ddd; } 
.talent_search .search_detail .select_box.col2 .wrap { padding:0; } 
.talent_search .search_detail .select_box .button.active,
.talent_search .search_detail .select_box .button:hover { background: #f2f8fe; } 
.talent_search .search_detail .btn_bottom_area { width: 100%; margin-top: 30px; margin-bottom: 0; padding-top: 0 } 
.talent_search .search_detail .btn_bottom_area .white { border-color:#cccccc; color:#222222; background: #fff; }  */

.talent_search .choose_detail_list { display: flex; background-color: transparent; border-bottom: 2px solid #3d7df2; }
.talent_search .choose_detail_list li { border: 1px solid #e2e2e2; border-width: 1px 1px 0 0; width:12.5%; height: 42px; display:inline-flex; align-items: center; justify-content: center; font-size:15px; cursor: pointer;}
.talent_search .choose_detail_list li:first-of-type { border-width:1px 1px 0 1px; }
.talent_search .choose_detail_list li.active { background-color: #3d7df2; color: #fff; border-color: #3d7df2; }
.talent_search .choose_detail_area { border: 1px solid #e2e2e2; background-color: #fff; height: 170px; }
.talent_search .choose_detail_type { display: flex; align-items: flex-start; height:100%; }
.talent_search .choose_detail_type .scroll_wrap { padding:4px 6px 6px; overflow-y:auto; }
.talent_search .choose_detail_type .scroll_wrap .btn_detail_selector { height:30px; display:inline-flex; align-items: center; justify-content: flex-start; padding:0 10px; font-size:15px; color:#333; margin-top:2px; }
.talent_search .choose_detail_type .scroll_wrap .btn_detail_selector.active { background-color: rgba(0,112,235,0.05); color: #3269cc; }
.talent_search .choose_detail_type .scroll_wrap.left { width: 160px; height: 100%; }
.talent_search .choose_detail_type .scroll_wrap.left a { width:100%; }
.talent_search .choose_detail_type .scroll_wrap.right { width: calc(100% - 160px); max-height:100%; display:flex; flex-wrap: wrap; align-items: flex-start;}
.talent_search .choose_detail_type .scroll_wrap.right.has_input { display:flex; align-items: center; height:100%; }
.talent_search .choose_detail_type .scroll_wrap.right a { width:calc(20% - 2px); margin-right:2px; }
.talent_search .choose_detail_type .scroll_wrap.all { width:100%; display: flex; flex-wrap: wrap; align-items: flex-start; max-height:100%; }
.talent_search .choose_detail_type .scroll_wrap.all a { width:calc(12.5% - 2px); margin-right:2px; }
.talent_search .selected_area h1 { font-size:18px; letter-spacing: -0.025em; color:#000; font-weight: 400; margin-top:15px; margin-bottom:10px;  }
.talent_search .selected_wrap { background-color: #fff; border: 1px solid #e2e2e2; padding:12px 0 16px 22px; display: flex; min-height: 38px;}
.talent_search .selected_wrap .scroll { width: calc(100% - 106px); border-right:1px solid #e2e2e2; }
.talent_search .selected_wrap .selected { display: inline-flex; color: #333; align-items: center; margin-right: 32px; font-size:15px; margin-top:4px; } 
.talent_search .selected_wrap .selected .btn_selected_delete {
    display: flex; justify-content: center; align-items: center; margin-left: 6px; width: 19px;height: 19px;background: #d7dde6;border-radius: 10px; text-align: center; cursor: pointer; background: #d7dde6 url(../img/sub/btn_delete01.png) no-repeat center; }
.talent_search .btn_selected_reset { cursor: pointer; width:106px; font-size:15px; display:inline-flex; align-items: center; justify-content: center;}
.talent_search .btn_selected_reset img { margin-right: 4px; }
.talent_search .choose_detail_type .scroll_wrap .input_wrap { display:flex; align-items: center; width:100%; }
.talent_search .choose_detail_type .scroll_wrap .input_wrap [type="text"] { width:70px; min-height:34px; height:34px; }
.talent_search .choose_detail_type .scroll_wrap .input_wrap select { width:150px; min-height:34px; height:34px; }
.talent_search .choose_detail_type .scroll_wrap .input_wrap .btn { min-height: 34px; height: 34px; width:70px; margin-left:10px; font-size:15px; margin-right:0; }

/*인재 미리보기*/
.talent_short { margin-top: 63px; } 
.talent_short .title_area { width: 100%; color:#000000; font-size:20px; display: flex; justify-content: space-between; align-items: center; padding-left: 12px; font-weight: bold; margin-bottom: 21px; } 
.talent_short .title_area .button { border:1px solid #cccccc; display: inline-flex; min-height: 31px; height: 31px; justify-content: center; align-items: center; font-size:15px; font-weight: 300; padding:0 11px; } 
.talent_short .title_area .button img { margin-left: 14px; } 
.talent_short { display: flex; flex-wrap: wrap; } 
.talent_short .item { border:1px solid #cfcfcf; padding:18px 15px 22px; margin-right: 1.3%; width: 13.17%; display: flex; flex-direction: column; } 
.talent_short .item:last-of-type { margin-right: 0 } 
.talent_short .item .profile { align-items: center; margin-bottom: 23px; display: flex; align-items: center; } 
.talent_short .item .profile .img { background-repeat: no-repeat; background-size:cover; border-radius: 100px; width: 44px; height: 44px; margin-right: 9px; background-image: url("../img/sub/icon_profile.png"); background-color: #999999; } 
.talent_short .item .profile .name { font-size:15px; color:#000; letter-spacing: 0; } 
.talent_short .item .info_area { margin-bottom: 20px; display: flex; flex-direction: column; height: 100%;} 
.talent_short .item .info_area .school{margin-top: auto;}
.talent_short .item .info_field { color:#666666; font-size:15px; letter-spacing: -0.05em; margin-bottom: 2px; white-space: nowrap; display: block; overflow: hidden; text-overflow: ellipsis; } 
.talent_short .item .career { color:#3d7df2; } 
.talent_short .item .company { color:#000; } 
.talent_short .item .school { margin-top:10px; } 
.talent_short .item .button { margin-top: auto; color:#333; font-size:14px; height: 30px; width: 100%; display:flex; align-items: center; border:1px solid #cccccc; justify-content: center; } 
.talent_short .item .button img { margin-left:12px; } 

/*검색결과*/
.talent_result { margin-top: 50px; } 
.talent_result .title_area { font-size:20px; color:#000; font-weight: bold; letter-spacing: -0.05em; padding-left: 10px; margin-bottom: 15px; } 
.talent_result .title_area .total { font-size:15px; margin-left: 10px; display: inline-block; } 
.talent_result .title_area .total .point { color:#3d7df2; } 
.talent_result .item { border:1px solid #999999; border-bottom-color:#cfcfcf; padding:30px 23px; overflow: hidden; } 
.talent_result .item+.item { border-top:none; } 
.talent_result .item:last-child { border-bottom-color:#999; } 
.talent_result .item .profile { width: 75px; float: left; } 
.talent_result .item .profile .img { width: 56px; height: 56px; border-radius: 100px; background-size: cover !important; background-position: center !important; background-image: url("../img/sub/icon_profile.png"); background-color: #999999; background-repeat: no-repeat !important; } 
.talent_result .item .name { font-size:24px; color:#000; letter-spacing: -0.05em; margin-bottom: 13px; } 
.talent_result .item .name .text { color:#666666; font-size:15px; margin-left: 15px; display: inline-block; } 
.talent_result .item .info_area { float: left; width:calc(100% - 310px); } 
.talent_result .item .info_area .info_field { display: flex; flex-wrap: wrap; align-items: center; } 
.talent_result .item .info_area .info_field li { font-size:15px; color:#666; letter-spacing: -0.05em; margin-bottom: 5px; } 
.talent_result .item .info_area .info_field li:after { content:''; width: 1px; height: 10px; background: #ddd; margin: 0 10px; display: inline-block; } 
.talent_result .item .info_area .info_field li:last-child:after,
.talent_result .item .info_area .info_field li:nth-child(3):after { display: none; } 
.talent_result .item .info_area .info_field .career { color:#3d7df2; } 
.talent_result .item .info_area .info_field .company { color:#000; } 
.talent_result .item .info_area .info_field .school { width: 100%; } 
.talent_result .item .add_info_area { float: left; margin-left: 75px; width:calc(100% - 300px); } 
.talent_result .item .add_info_area { margin-top: 10px; } 
.talent_result .item .add_info_area li { color:#000; font-size:15px; letter-spacing: -0.05em; margin-bottom: 5px; } 
.talent_result .item .add_info_area .title { color:#666;; } 
.talent_result .item .btn_area { float: right; display: flex; } 
.talent_result .item .btn_area .btn+.btn { margin-left: 10px; } 
.talent_result .item .btn_area .btn { min-height: 30px; height:30px; font-size:15px; } 
.talent_result .item .btn_area .btn.gray { background: #fff; color:#000; border:1px solid #cccccc } 
.talent_result .item .btn_area .btn.gray img { margin-left: 10px; margin-top: 2px; } 
.talent_result .item .write_date{float:right; text-align: right; font-size:13px; padding-top: 10px;}

/*인재상세*/
.tab01+ .page_talent_view { margin-top:30px; } 
.page_talent_view .btn_bottom_area { padding-right: 30%; } 
.page_talent_view .sub_content_title { margin-bottom: 20px; width: 100%; } 
.page_talent_view .container { display: flex; justify-content: space-between; flex-wrap: wrap; } 
.page_talent_view .myresume_view { width: 70%; } 
.page_talent_view .myresume_view .resume_title { padding:18px 20px 40px; } 
.page_talent_view .myresume_view .section .title_area { width: 165px; padding-left: 20px; } 
.page_talent_view .myresume_view .section .info_area, 
.page_talent_view .myresume_view .section .tip_field, 
.page_talent_view .myresume_view .section .total_field { width:calc(100% - 165px); white-space: pre-line;} 
.page_talent_view .myresume_view .profile_area .info_area { display: flex; flex-direction: column; margin-left:40px; } 
.page_talent_view .myresume_view .profile_area .info_area .top { display: flex; align-items: flex-end; width: 100%; flex-wrap:wrap; margin-bottom: 40px; } 
.page_talent_view .myresume_view .profile_area .info_area .title_field { width: 126px !important; }
.page_talent_view .myresume_view .profile_area .info_area li { padding:10px 35px 10px 0; white-space: nowrap; width:100%; line-height: 1; padding-left:126px !important; } 
.page_talent_view .myresume_view .profile_area .info_area li.name_field { float: none; font-size:26px; letter-spacing: 0.05em; padding-left: 0 !important;} 

.page_talent_view .aside { width: 26%; } 
.page_talent_view .aside .btn { width: 100%; margin-left: 0; margin-bottom:10px } 

/**후보자 관리*/
.page_candidate_management { overflow: hidden; } 
.page_candidate_management .aside { width: 25%; float: left; margin-top:30px; border:1px solid #999; } 
.page_candidate_management .aside .top { border-bottom:1px solid #999; background: #fafbfb; padding:16px 15px; display:flex; align-items: center; justify-content: space-between; } 
.page_candidate_management .aside .top select { min-height: 40px; width: auto; max-width: 40%; } 
.page_candidate_management .aside .top .state_tab { display: flex; justify-content: space-between; max-width: 45%; } 
.page_candidate_management .aside .top .state_tab li:not(:first-of-type) { border-left: none; } 
.page_candidate_management .aside .top .state_tab a { width: 69px; min-height: 40px; height: 40px; border:1px solid #d9d9d9; background: #ffffff; font-size:15px; color:#000; display: inline-flex; justify-content: center; align-items: center; } 
.page_candidate_management .aside .top .state_tab .active a { background: #cc3333; color:#fff; border:none; } 

.page_candidate_management .aside .content_sec { overflow-y: auto; padding:0 10px; max-height: 359px; } 
.page_candidate_management .aside .list li:not(:first-child) { border-top:1px solid #dedede; } 
.page_candidate_management .aside .list li { display: flex; justify-content: space-between; align-items: flex-end; padding:10px 10px; } 
.page_candidate_management .aside .list li p { color:#222222; font-size:15px; } 
.page_candidate_management .aside .list li .num_field { color:#3d7df2; padding-left: 20px; white-space: nowrap; } 

.page_candidate_management .container { float: right; width: 73%; margin-top: 30px; } 

.candidate_top { display: flex; justify-content:space-between; align-items:center; font-size:15px; border:1px solid #999; padding:18px 30px; } 
.candidate_top .input_wrap { display: flex; align-items: center; } 
.candidate_top .input_wrap select { width: auto; min-width: 100px; margin-left: 20px; } 
.candidate_top .title_field { font-size:20px; font-weight: 500; } 
.candidate_top .title_field .state_field { color:#cc3333; margin-left: 10px; } 

.page_candidate_management .table_wrap { border:1px solid #999; border-top:none; padding:0 20px; } 
.page_candidate_management .table_list01 { border:none; } 
.page_candidate_management .table_list01 .td_button { width: 100px; padding:30px 10px; } 
.page_candidate_management .table_list01 .th_button { width: 100px; min-width: 100px } 
.page_candidate_management .table_list01 .td_button .btn { background-repeat: no-repeat; background-position: 90% center; background-image: url(../img/sub/icon_arrow4.png); min-width: 100px; white-space: nowrap; text-align: left; padding-left:10%; } 
.page_candidate_management .table_list01 .td_button .btn.basics { background-image: url(../img/sub/icon_arrow2.png); } 
.page_candidate_management .table_list01 .td_button .btn.white { background-image: url(../img/sub/icon_arrow3.png); } 
.page_candidate_management .table_list01 .td_chk, 
.page_candidate_management .table_list01 .th_chk { width:35px; } 

.page_candidate_management .board_bottom_btn { margin-top:14px; } 
.page_candidate_management .board_bottom_btn .btn { min-height: 31px; height: 31px; font-size:15px; } 

.th_candidate { width: 33% !important; } 
.td_candidate { text-align: left; width: 33%; padding:20px 0; } 
.td_candidate .profile { display:flex; } 
.td_candidate .profile .img { background-repeat: no-repeat; background-size:contain; border-radius: 100px; min-width: 44px; width: 44px; height: 44px; margin-right: 9px; background-image: url("../img/sub/icon_profile.png"); background-color: #999999; } 
.td_candidate .candidate_area { display: flex; flex-wrap: wrap; align-items:baseline; } 
.td_candidate .profile p { display: inline-block; font-size:15px; color:#666666; text-align: left; margin-bottom:3px; } 
.td_candidate .profile .name { color:#000; font-size:24px; font-weight: bold; } 
.td_candidate .profile .name:after,
.td_candidate .profile .company:after { content:''; display: inline-block; width: 1px; height: 14px; border-right: 1px solid #f1f1f1; box-sizing:content-box; background: #e1e1e1; margin: 0 10px; vertical-align: -2px; } 
.td_candidate .profile .company { color:#000; } 
.td_candidate .profile .career { color:#3d7df2; width: 100%; padding-top: 3px; } 
.td_candidate .profile .shcool { width: 100%; } 

/* 관심인재 */
.page_interested_people .list_top form { justify-content: flex-end; margin-top: 20px; } 
.interestedPeople_list .table_list01 .th,
.interestedPeople_list .table_list01 .td { padding: 0 20px; min-height: 50px;} 
.interestedPeople_list .table_list01 .th { line-height: 50px; } 
.interestedPeople_list .table_list01 .td { padding-top: 19px; padding-bottom: 19px; display: inline-flex; align-items: center; justify-content: center; color: #666; } 
/*.interestedPeople_list .table_list01 .icon_star { cursor: pointer; } */
.interestedPeople_list .table_list01 .btn { width: 105px; min-width: 105px; min-height: 32px; height: 32px; font-size: 15px; letter-spacing: -0.75px; } 
.interestedPeople_list .table_list01 .btn + .btn { margin-left: 10px; } 
.interestedPeople_list .table_list01 .btn .nanumGothic { margin-left: 10px; font-size: 12px; } 
.interestedPeople_list .table_list01 .row_name { width: 22%; } /*후보자명*/
.interestedPeople_list .table_list01 .td.row_name { color: #000; width: 19.8%;}
.interestedPeople_list .table_list01 .row_total { width: 12%; } /*총경력*/
.interestedPeople_list .table_list01 .row_career { width: 25%; } /*직장*/
.interestedPeople_list .table_list01 .row_career b { font-weight: 400; color: #000; } 
.interestedPeople_list .table_list01 .row_edu { width: 26%; } /*학력*/
.interestedPeople_list .table_list01 .row_state { width: 23%; /*min-width: 240px;*/ } /*관리*/
.interestedPeople_list .m_colgroup{width:80.2%; display: flex;}
.interestedPeople_list .table_list01 .m_colgroup .row_total{width: 14.5%;}
.interestedPeople_list .table_list01 .m_colgroup .row_career{width: 29.5%;}
.interestedPeople_list .table_list01 .m_colgroup .row_edu{width:29.6%;}
.interestedPeople_list .table_list01 .m_colgroup .row_state{width:26.4%;}

/*우측메뉴*/
.page_talent_view .aside .section { border:1px solid #dedede; margin-top: 20px; padding:20px; } 
.page_talent_view .aside .section+.section { border-top:none; margin-top: 0 } 
.page_talent_view .aside .section .title_field { font-size:20px; letter-spacing: -0.05em; font-weight: bold; color:#000; margin-bottom: 20px; } 

.page_talent_view .aside .section .list_dot.basics li a { overflow: hidden; text-overflow: ellipsis; white-space: nowrap; display:inline-block; width: 100%; font-size:15px; } 
.page_talent_view .aside .section .list_dot.basics li:before { vertical-align: 7px; } 



@media screen and (max-width:1260px) { /*인재상세*모바일 레이아웃 적용*/
 .page_talent_view .sub_content_title.indent { margin-left: 0; padding-left:20px; } 
 .page_talent_view .container { flex-direction: column; } 
 .page_talent_view .container.center_wrap { padding:0; } 
 .page_talent_view .sub_content_title { border-bottom:1px solid #999; padding:0 4% 18px; margin-bottom: 0 } 
 .page_talent_view .myresume_view { width: 100%; padding:0; } 
 .page_talent_view .myresume_view .section .title_area { width:270px; min-width: 270px; } 
 .page_talent_view .myresume_view .section .info_area, 
 .page_talent_view .myresume_view .section .tip_field, 
 .page_talent_view .myresume_view .section .total_field { width: calc(100% - 270px) } 
 .page_talent_view .myresume_view .change_jobs .input_area { width: 100%; text-align: left; } 
 .page_talent_view .myresume_view .resume_title { padding:24px 0 18px; } 
 .page_talent_view .aside { width: 100%; padding:20px 0; } 
 .page_talent_view .btn_bottom_area { padding-right: 0; padding-left: 0; } 
 .page_talent_view .btn_bottom_area .btn { margin:0; } 
 .tab01+ .page_talent_view { margin-top:0; } 
 /*후보자관리*/
 .page_candidate_management .aside .top .state_tab li { width: 50%; } 
 .page_candidate_management .aside .top .state_tab a { width: 100%; white-space: nowrap; padding:0 10px; } 

 /* 관심인재 */
 .interestedPeople_list .table_list01 .th,
 .interestedPeople_list .table_list01 .td { padding: 0 10px; min-height: 50px; } 
 .interestedPeople_list .table_list01 .td { padding: 19px 10px; } 
 .interestedPeople_list.center_wrap { padding:0; } 
 /*.interestedPeople_list .table_list01 .m_colgroup{width: 81%;}
 .interestedPeople_list .table_list01 .row_name{width:19%}
 .interestedPeople_list .table_list01 .m_colgroup .row_total{width: 19%;}
 .interestedPeople_list .table_list01 .m_colgroup .row_career{width: 19%;}*/
 .interestedPeople_list .table_list01 .td.row_name {width:auto;}
 .interestedPeople_list .table_list01 .row_state{min-width:auto;}
 .interestedPeople_list .table_list01 .btn { font-size: 13px; min-width: auto; min-height: 27.5px; height: 27.5px; width: auto; padding: 0 6px; } 

 }
@media screen and (max-width:1024px) {
    /**********************
    검색(모바일 디자인 적용)
    ***********************/
    /*이용권*/
    .icon_banner { flex-direction: column; margin: 11px 0 23px; padding:0 4%; } 
    .icon_banner .icon { margin-right: 8px; } 
    .icon_banner li { margin-top: 9px; justify-content: space-between; } 
    .icon_banner li:nth-child(2n) { padding:0; } 
    .icon_banner .point { margin-left:auto; padding-right: 34px; } 
    
    /*검색*/
    .talent_search .search,
    .talent_search .search_detail { padding:30px 4%; } 
    .talent_search .search_detail .job { width: 70%; margin-right: 10px; } 
    .talent_search .search_detail .place { width:calc(30% - 10px); } 
    .talent_search .search_detail .career { width:100%; margin-left: 0; margin-top: 25px; } 
    .talent_search .search .btn.white { padding:0 5px; background:#fff; } 

    .talent_search .choose_detail_type .scroll_wrap.right a { width:calc(33.333% - 2px); }
    .talent_search .choose_detail_type .scroll_wrap.all a { width:calc(25% - 2px); }
    .talent_search .selected_wrap .scroll { width:100%; border-right:0; }
    .talent_search .btn_selected_reset { display: none; }
    .talent_search .talent_reset_mobile { display:block; width:100%; text-align: center; }
    .talent_search .talent_reset_mobile .btn { border-color: #e2e2e2; color
    :#333; }
    
    /*인재 미리보기*/
    .talent_short { flex-direction: column; border-bottom:1px solid #ccc; } 
    .talent_short .item { width: 100%; border-bottom:none; flex-direction: row } 
    .talent_short .item { display: flex; align-items: center; } 
    .talent_short .item .profile { margin-right: 22px; margin-bottom: 0; } 
    .talent_short .item .profile .name { white-space: nowrap; } 
    .talent_short .item .info_area { margin-bottom: 0 ; flex-direction: row;} 
    .talent_short .item .button { display: none; } 
    .talent_short .title_area { padding-left: 0; } 
    .talent_short .info_area { display: flex; flex-wrap: wrap; margin-top: -7px; align-items: center; } 
    .talent_short .info_area .info_field { max-width:45%; margin-top: 7px; margin-bottom: 0 } 
    .talent_short .info_area .division_bar { width: 1px; min-width: 1px; height: 10px; display: inline-block; background: #ccc; margin: 7px 5px 0; } 
    .talent_short .item .school { max-width: 95%; } 
    .talent_short .item .school:after { display: none; } 

    /*후보자 관리*모바일 디자인 적용*/
    .page_candidate_management .aside { float:none; width: 100%; } 
    .page_candidate_management .aside .content_sec { max-height: 242px; } 
    
    .page_candidate_management .container { float: none; width: calc(100% + 60px); margin-left: -30px; } 
    .candidate_top { border-left:none; border-right: none; padding:18px 30px; } 
    .page_candidate_management .table_wrap { border-left:none; border-right: none; } 
    .candidate_top .title_field .state_field { margin-left: 0 } 
    
    .page_candidate_management .table_wrap { padding:0; } 
    .page_candidate_management .table_list01 tr { display: flex; justify-content: flex-start; align-items:flex-start; flex-wrap: wrap; padding-left:calc(30px + 25px + 53px) } 
    .page_candidate_management .table_list01 .td_button { width: auto; padding:5px 0 0 0; } 
    .table_list01 td.td_candidate { width:calc( 100% - 50px); padding:0 0 10px 0; margin-left: -53px; } 

    /* 관심인재 */
    .page_interested_people .list_top { padding: 0 !important; margin-bottom: 0; } 
    .page_interested_people .list_top .wrap { justify-content: flex-end;; } 
    .page_interested_people .list_top .search_wrap { margin-top: 10px; } 
    .page_interested_people .list_top .search_wrap .style_select { width: auto; } 
    .interestedPeople_list { padding: 0; width:108%; margin-left: -4%;} 
    .interestedPeople_list .table_list01 { border: 0; border-top: 1px solid #ececec; border-bottom: 1px solid #ececec; } 
    .interestedPeople_list .table_list01 .pic_name_wrap { min-width: 100px; } 
    .interestedPeople_list .table_list01 .btn_bookmark { position: absolute; right: 4%; width: 19px; height: 17px; background-size: auto 17px;}
    .interestedPeople_list .table_list01 .tr { position: relative; display: flex; padding:17px 4%; } 
    .interestedPeople_list .table_list01.with_bookmark .tr{padding-right:calc(4% + 20px) }
    .interestedPeople_list .table_list01 .td { padding: 0; justify-content: flex-start; align-items: flex-start; } 
    .interestedPeople_list .table_list01 .tr:first-of-type { display: none; } 
    .interestedPeople_list .table_list01 .btn .nanumGothic { font-size: 9px; } 
    .interestedPeople_list .table_list01 .row_name { width: auto; } 
    .interestedPeople_list .table_list01 .row_career::before { content:'|'; display: inline-block; margin: 0 4px; font-size: 12px; color: #d1d1d1; transform: translateY(-1px); } 
    .interestedPeople_list .table_list01 .m_colgroup { flex-wrap: wrap; width: 100%; } 
    .interestedPeople_list .table_list01  .td { width: auto; min-height: auto; text-align: left;} 
    .interestedPeople_list .table_list01  .td.row_edu { width: 100%; margin-top: 6px; margin-bottom: 6px; } 
    .interestedPeople_list .table_list01  .td.row_state { width: 100%; } 
    .interestedPeople_list .table_list01 .m_colgroup .row_total{width: auto;}
 }
@media screen and (max-width:768px){
    .page_talent_search { margin-top: 24px !important; } 
    /*이용권*/
    .icon_banner .title_field,
    .icon_banner .point { font-size:13px; } 
    .icon_banner .point,
    .icon_banner .button { background-size: auto 13px; padding-right:12px } 
    
    /*검색*/
    .talent_search .search,
    .talent_search .search_detail { padding:20px 4%; } 
    .talent_search .search .title_field { font-size:16px; } 
    .talent_search .search .btn { min-height: 40px; height: 40px; font-size:13px; } 
    
    .talent_search .search_detail .title_field { font-size:14px; font-weight: bold; } 
    .talent_search .search_detail .input_wrap select+.text { font-size:13px; } 
    .talent_search .search_detail .input_wrap .chk+label { font-size:13px; min-width: calc(25% - 10px); width: auto; } 
    .talent_search .search .btn { min-width: 62px; padding: 0; margin-left: 3px; } 
    .talent_search .search_detail .job { margin-right: 3px; } 
    .talent_search .search_detail .place { width: calc(30% - 3px); } 
    .talent_search .search_detail .btn_bottom_area .white img { height: 13px; } 

    .talent_search .choose_detail_list { overflow-x: scroll; font-size:13px; height:36px; }
    .talent_search .choose_detail_list li { min-width:60px; height:34px; font-size:13px; }
    .talent_search .choose_detail_type .scroll_wrap.left { width:140px; }
    .talent_search .choose_detail_type .scroll_wrap.right { width:calc(100% - 140px); }
    .talent_search .choose_detail_type .scroll_wrap .btn_detail_selector { font-size:13px; height:26px; padding:0 6px; }
    .talent_search .selected_area h1 { font-size:16px; }
    .talent_search .selected_wrap { padding:6px 12px; }
    .talent_search .selected_wrap .selected { font-size:13px; margin-right:16px; }
    .talent_search .selected_wrap .selected .btn_selected_delete { width:13px; height:13px; }
    .talent_search .choose_detail_type .scroll_wrap .input_wrap .btn { font-size:13px; width:auto; }

    .talent_search .search .input_wrap { display: flex; flex-wrap: wrap; width:100%; }
    .talent_search .search .input_wrap .has_auto_text { width:66.66667%; }
    .talent_search .search .input_wrap [type="text"] { width:calc(33.3333% - 5px); }
    .talent_search .search .input_wrap :nth-child(3) { margin-left:0; width:33.3333%; }
    .talent_search .search .input_wrap :nth-child(n + 3) { margin-top:6px; }
    .talent_search .search .btn { width:calc(33.3333% - 5px); margin-left: 5px;}
    
    /*인재 미리보기*/
    .talent_short { margin-top: 22px; } 
    .talent_short .title_area { margin-bottom: 15px; font-size:16px; } 
    .talent_short .title_area .button { min-height: 26px; height: 26px; font-size:12px; padding:0 8px; white-space: nowrap; } 
    .talent_short .title_area .button img { height: 10px; margin-left: 8px; } 
    .talent_short .item .profile .img { width: 30px; height: 30px; margin-right: 7px; } 
    .talent_short .item .profile .name { font-size:13px; } 
    .talent_short .info_area { width:calc(100% - 76px) } 
    .talent_short .item .info_field { font-size:13px; margin-right:16px; } 
    .talent_short .item .info_field.career { overflow: visible; max-width:inherit; } 
    
    /************
    검색결과 (모바일디자인 적용)
    *************/
    .talent_result { margin-top: 22px; padding:0; } 
    .talent_result .title_area { font-size:14px; justify-content: space-between; display: flex; align-items: flex-end; padding:0 4%; } 
    .talent_result .title_area .total { font-size:11px; } 
    .talent_result .item { border-color:#e2e2e2; border-left:none; border-right:none; display: flex; flex-wrap: wrap; padding:20px 4%; } 
    .talent_result .item:last-child { border-bottom-color:#e2e2e2; } 
    .talent_result .item .profile { width: 47px; order:1; } 
    .talent_result .item .profile .img { width: 35px; height: 35px; } 
    .talent_result .item .name { font-size:14px; margin-bottom: 5px; } 
    .talent_result .item .name .text { font-size:12px; } 
    .talent_result .item .info_area .info_field li { font-size:13px; } 
    .talent_result .item .info_area { width:calc(100% - 47px); order:2; } 
    .talent_result .item .add_info_area { width: 100%; order:3; margin-left: 0; clear: both; } 
    .talent_result .item .add_info_area li { font-size:13px; } 
    .talent_result .item .btn_area { width: 100%; order:4; clear: both; margin-top: 15px; } 
    .talent_result .item .btn_area .btn { min-height: 30px; height: 30px; font-size:13px; } 
    .talent_result .item .btn_area .btn img { height: 10px; } 
    .talent_result .item .write_date{float: none; order:4; font-size:11px}
    
    /*************
    상세
    **************/
    .page_talent_view .sub_content_title.indent { padding-top:24px; padding-left: 4%; } 
    .page_talent_view .myresume_view .section .title_area { width:100%; padding-left: 0; } 
    .page_talent_view .myresume_view .section .info_area, .page_talent_view .myresume_view .section .tip_field, .page_talent_view .myresume_view .section .total_field { width: 100%; } 
    .page_talent_view .myresume_view .profile_area .info_area .title_field { width: 104px !important; }
    .page_talent_view .myresume_view .profile_area .info_area li { padding-left:104px !important; padding-block: 2px;} 
    
    /*프로필*/
    .page_talent_view .myresume_view .profile_area .info_area{margin-left:20px;}
    .page_talent_view .myresume_view .profile_area .info_area .top { margin-bottom: 20px; flex-wrap: wrap;  } 
    .page_talent_view .myresume_view .profile_area .info_area .top li{word-break: break-all; white-space: break-spaces;}
    .page_talent_view .myresume_view .profile_area .info_area .top li:not(:first-child) { margin-top: 10px; } 
    .page_talent_view .myresume_view .profile_area .name_field { font-size:15px; width: 100% !important; line-height: 1.5 !important } 
    
    .page_talent_view .myresume_view .change_jobs { flex-direction: column; } 
    
    /*우측*/
    .page_talent_view .aside { padding:20px 0 15px; } 
    .page_talent_view .aside .section .title_field { font-size:14px; margin-bottom: 10px; } 
    .page_talent_view .aside .section .list_dot.basics li a { font-size:13px; } 
    .page_talent_view .aside .section { padding:20px 4%; margin-top: 10px; } 
    .page_talent_view .aside .btn { margin-bottom: 5px; } 
    
    
    
    /*********
    후보자 관리
    **********/
    .page_candidate_management .aside { margin-top: 15px; } 
    .page_candidate_management .aside .top { padding:10px; } 
    .page_candidate_management .aside .top .state_tab a { font-size:13px; } 
    .page_candidate_management .aside .list li p { font-size:13px; } 
    .page_candidate_management .aside .content_sec { max-height: 226px; } 
    
    .page_candidate_management .container { margin-top: 15px; width: calc(100% + (4%*2)); margin-left: -4%; } 
    .candidate_top { flex-direction: column; justify-content: flex-start; align-items: flex-start; font-size:13px; border-bottom-color:#e2e2e2; padding:25px 4% 10px } 
    .candidate_top .title_field { margin-bottom: 20px; } 
    
    .candidate_top .title_field { font-size:15px; } 
    
    .page_candidate_management .table_list01 .td_button .btn { min-width: 90px; } 
    .page_candidate_management .table_list01 tr { padding-left:calc(4% + 20px + 39px) } 
    .table_list01 td.td_candidate { margin-left: -39px; height: auto; } 
    .table_list01 td.td_candidate .candidate_area { width:calc(100% - 39px); white-space: normal; } 
    .td_candidate .profile .img { width: 30px; height: 30px; min-width: 30px } 
    .td_candidate .profile .name { font-size:15px; } 
    .td_candidate .profile p { font-size:13px; } 

    /* 관심인재 */
    .interestedPeople_list .table_list01 .td{font-size:12px;}
    .interestedPeople_list .table_list01 .m_colgroup .row_career{width:auto;}
    
 }
 @media screen and (max-width:640px){
    .talent_search .choose_detail_type .scroll_wrap.right a { width: calc(50% - 2px); }
    .talent_search .choose_detail_type .scroll_wrap.all a { width: calc(50% - 2px); }
 }
 @media screen and (max-width:560px){
    .talent_search .choose_detail_type .scroll_wrap.right a { width: calc(100% - 2px); }
    .talent_search .choose_detail_type .scroll_wrap .input_wrap { flex-wrap: wrap; }
    .talent_search .choose_detail_type .scroll_wrap .input_wrap [type="text"] { width:38%; }
    .talent_search .choose_detail_type .scroll_wrap .input_wrap select { width: 100%; }
    .talent_search .choose_detail_type .scroll_wrap .input_wrap .btn { width:100%; margin-left:0; margin-top:4px; }
 }
@media screen and (max-width:370px){
    .talent_search .choose_detail_type .scroll_wrap.left { width: 120px; }
    .talent_search .choose_detail_type .scroll_wrap.right { width: calc(100% - 120px); }
    .interestedPeople_list .table_list01 .tr{padding-bottom:50px}
    .interestedPeople_list .table_list01 .td.row_state{position:absolute; left:4%; bottom:10px; width:92%;}
    .interestedPeople_list .table_list01 .td.row_state .btn{width: 100%;}
    .talent_search .choose_detail_type .scroll_wrap .input_wrap [type="text"] { width:35%; }
}
@media screen and (max-width:350px){
    .icon_banner { padding:0; } 
    .talent_search .search, .talent_search .search_detail { padding:15px 4%; } 
    .talent_short .title_area { margin-bottom: 10px; } 
    .talent_short .item { padding:15px 4% 18px } 
 }