@charset "utf-8";
/* 약관등 */

.terms_area p,
.terms_area div,
.terms_area ul,
.terms_area li,
.terms_area ol,
.terms_area span,
.terms_area i,
.terms_area b,
.terms_area dl,
.terms_area dt,
.terms_area dd,
.terms_area h1,
.terms_area h2,
.terms_area h3,
.terms_area h4,
.terms_area h5,
.terms_area a{font-size:initial; color: initial;}
/*.terms_area{letter-spacing: -0.05em; padding:50px 40px; border:1px solid #999;}
.terms_area .section{margin-bottom: 60px;}
.terms_area .title_field{font-size:20px; font-weight: bold; margin-bottom: 20px}
.terms_area .text_field{color:#666666; margin-bottom: 20px; font-size:16px; font-weight: 300; line-height: 1.8; word-break: keep-all;}

@media screen and (max-width:768px){
    .terms_area{padding:30px 20px;}
    .terms_area .section{margin-bottom: 35px;}
    .terms_area .title_field{font-size:16px; margin-bottom: 10px}
    .terms_area .text_field{font-size:13px; margin-bottom: 10px;}
}*/