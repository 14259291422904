@charset "UTF-8";
/* Company 폴더 공통 */
.company { font-size: 15px; letter-spacing: -0.75px; } 
.subtop_info_area { width: 100%; border-bottom: 1px solid #cfcfcf; } 
.subtop_info_area ul { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: stretch; -ms-flex-align: stretch; align-items: stretch; } 

@media screen and (max-width: 1024px){
 .subtop_info_area ul { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; padding: 0; } 
 }

.subtop_info_area li { width: 50%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; color: #000; font-size: inherit; letter-spacing: inherit; padding: 40px 0; } 

@media screen and (max-width: 1024px){
 .subtop_info_area li { width: 100%; padding: 23px 15px; font-size: 13px; letter-spacing: -0.6px; } 
 }

.subtop_info_area li b { font-size: 20px; letter-spacing: -1px; color: #3d7df2; margin-right: 14px; } 

@media screen and (max-width: 1024px){
 .subtop_info_area li b { font-size: inherit; letter-spacing: inherit; margin-right: 7px; } 
 }

.subtop_info_area li.remain_wrap { border-left: 1px solid #cfcfcf; -ms-flex-pack: distribute; justify-content: space-around; } 

@media screen and (max-width: 1024px){
 .subtop_info_area li.remain_wrap { border-left: none; border-top: 1px solid #e2e2e2; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; border-bottom: 0; justify-content: space-around;} 
 }
@media screen and (max-width: 768px){
	.subtop_info_area li.remain_wrap { flex-direction: column; align-items: flex-start; padding:8px 15px; justify-content: flex-start;} 
 }

.subtop_info_area li.remain_wrap > div { font-size: inherit; letter-spacing: inherit; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

@media screen and (max-width: 1024px){
 .subtop_info_area li.remain_wrap > div { padding-inline: 17px; } 
 .page_company_main .subtop_info_area li.remain_wrap > div { padding: 17px; } 
 }
@media screen and (max-width: 768px){
.subtop_info_area li.remain_wrap > div { padding-block: 4px; text-align: left; padding-inline: 0;} 
}

@media screen and (max-width: 1024px){
 .subtop_info_area li.remain_wrap div + div { margin-left: 10px; } 
 }
 @media screen and (max-width: 768px){
	.subtop_info_area li.remain_wrap div + div { margin-left: 0; } 
}

.subtop_info_area li.remain_wrap b { margin-right: 0; margin-left: 14px; } 

@media screen and (max-width: 1024px){
 .subtop_info_area li.remain_wrap b { margin-left: 7px; } 
 }

.subtop_info_area .gray_icon { display: inline-block; width: 36px; height: 36px; border-radius: 50%; background-color: #ececec; background-repeat: no-repeat; background-position: center; margin-right: 7px; } 

@media screen and (max-width: 1024px){
 .subtop_info_area .gray_icon { width: 18px; height: 18px; } 
 }

.subtop_info_area .gray_icon.voucher { background-image: url(../img/sub/icon_voucher.png); background-size: 25px 17px; } 

.subtop_info_area .gray_icon.remain01 { background-image: url(../img/sub/icon_remain01.png); background-size: 22px 20px; } 
.subtop_info_area .gray_icon.voucher { background-image: url(img/sub/icon_voucher.png); background-size: 25px 17px; } 
.subtop_info_area .gray_icon.remain01 { background-image: url(img/sub/icon_remain01.png); background-size: 22px 20px; } 
.subtop_info_area .gray_icon.remain02 { background-image: url(../img/sub/icon_remain02.png); background-size: 23px 22px; } 

@media screen and (max-width: 1024px){
 .subtop_info_area .gray_icon.remain01 { background-size: 11px 10px; } 
 .subtop_info_area .gray_icon.remain02 { background-size: 11.5px 11px; } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .subtop_info_area ul { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; padding: 0; } 
 .page_company_main .subtop_info_area li.remain_wrap { margin-left: 0; border-left: 0; border-top: 1px solid #e2e2e2; } 
 }

@media screen and (max-width: 768px){
 .subtop_info_area .gray_icon.voucher { background-size: 12.5px 8.5px; } 
 }

.page_company_main .subtop_info_area li { padding: 0; } 

@media screen and (max-width: 1024px){
 .page_company_main .subtop_info_area li { width: 100%; } 
 .page_company_main .subtop_info_area li:first-child { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 
 }

.company_logo_image_wrap { position: relative; } 

.company_logo_image_wrap [type="file"] { position: absolute; left: -9999px; } 

.company_logo_image_wrap [type="file"] + label { position: absolute; bottom: 30px; right: 0; width: 30px; height: 30px; border-radius: 50%; background-color: #c2c2c2; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; cursor: pointer; } 

@media screen and (max-width: 1024px){
 .company_logo_image_wrap [type="file"] + label { width: 15px; height: 15px; bottom: 12.5px; } 
 .company_logo_image_wrap [type="file"] + label img { max-width: 8px; } 
 }

.page_company_main .company_logo_image_wrap { padding-right: 30px; } 

.page_company_main .company_logo_image_wrap ~ h1 { font-size: 26px; letter-spacing: -1.3px; color: black; font-weight: 500; margin-left: 30px; } 

@media screen and (max-width: 1024px){
 .page_company_main .company_logo_image_wrap ~ h1 { font-size: 13px; } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .company_logo_image_wrap { padding-top: 6px; } 
 }

.company_logo_image { position: relative; height: 0; width: 200px; min-width: 200px; padding-top: calc(200px / 10 * 6.25); overflow: hidden; } 

@media screen and (max-width: 768px){
 .company_logo_image { width: 100%; min-width: 100%; padding-top: calc(100% / 10 * 6.25); } 
 }

.company_logo_image > img { position: absolute; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); width: 100%; height: 100%; min-width: 100%; -o-object-fit: contain; object-fit: contain; } 

@media screen and (max-width: 1024px){
 .page_company_main .company_logo_image { width: 100px; min-width: 100px; padding-top: calc(100px / 10 * 6.25); } 
 }
.company_main_body .sub_bottom_banner { margin:60px auto 40px; } 
@media screen and (max-width: 768px){
 .company_main_body .sub_bottom_banner { margin:44px 0 0; } 
 }

.pic_name_wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; width: 60%; } 

@media screen and (max-width: 1024px){
 .pic_name_wrap { min-width: 90px; padding-right: 10px; width:90px; } 
 }

.pic_name_wrap figure { min-width: 44px; width: 44px; height: 44px; border-radius: 50%; margin-right: 14px; background-image: url(../img/sub/company_user.png); background-size: cover; background-repeat: no-repeat; background-position: center; display: inline-block; } 

.talent_list .pic_name_wrap figure { margin-right: 10px; } 

@media screen and (max-width: 1260px){
 .talent_list .pic_name_wrap figure { min-width: 31px; width: 31px; height: 31px; margin-right: 6px; } 
 }

@media screen and (max-width: 1024px){
 .pic_name_wrap figure { min-width: 31px; width: 31px; height: 31px; margin-right: 6px; } 
 }

.pic_name_wrap b { display: inline-block; min-width: 70px; text-align: left; margin-right: 10px; color: black; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 768px){
 .pic_name_wrap b { font-size: 13px; letter-spacing: -0.6px; } 
 }

.talent_list .pic_name_wrap b { display: block; font-weight: 500; margin-right: 0; width: calc(100% - 44px - 10px); } 

@media screen and (max-width: 1260px){
 .talent_list .pic_name_wrap b { width: calc(100% - 31px - 6px); } 
 }

@media screen and (max-width: 1024px){
 .pic_name_wrap b { font-weight: 500; min-width: auto; margin-right: 0; } 
 }

.sub_content_title_wrap { -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

.sub_content_title_wrap h1 { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

.sub_content_title_wrap h1.indent { margin-left: 10px; } 

.sub_content_title_wrap span.txt_blue { position: relative; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 1024px){
 .sub_content_title_wrap span.txt_blue { font-size: 13px; letter-spacing: -0.6px; } 
 }

.sub_content_title_wrap span.txt_blue::before { content: ''; display: inline-block; margin: 0 10px; width: 1px; height: 11px; background-color: #e2e2e2; } 

.sub_content_title_wrap .btn.line.gray { font-size: 15px; letter-spacing: -0.38px; color: #333; min-height: 32px; height:32px; width: 90px; } 

@media screen and (max-width: 1024px){
 .sub_content_title_wrap .btn.line.gray { font-size: 13px; letter-spacing: -0.6px; min-height: 27.5px; height: 27.5px; } 
 }

.sub_content_title_wrap .btn.line.gray .nanumGothic { font-size: 12px; margin-left: 6px; } 

/* 컴퍼니 메인 */
@media screen and (max-width: 1024px){
 .page_company_main .sub_content_title { font-size: 16px; margin-left: 0 !important; } 
 }

.page_company_main .main_center { overflow-x: hidden; } 

.page_company_main [class^="container"] { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; margin-top: 40px; width: 100%; } 

@media screen and (max-width: 1024px){
 .page_company_main [class^="container"] { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; margin-top: 20px; } 
 }

.page_company_main .container1 > article, .page_company_main .container2 > article { min-width: calc(50% - 10px); } 

.page_company_main .container1 > article:last-child, .page_company_main .container2 > article:last-child { margin-left: 20px; } 

@media screen and (max-width: 1024px){
 .page_company_main .container1 > article:last-child, .page_company_main .container2 > article:last-child { margin-top: 10px; margin-left: 0; } 
 }

.page_company_main .container1 .list_box, .page_company_main .container2 .list_box { height: 190px; } 

@media screen and (max-width: 1024px){
 .page_company_main .container1 .list_box, .page_company_main .container2 .list_box { height: auto; } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .container2 { margin-top: 10px; } 
 }

.page_company_main .container3 > article { width: 100%; } 

.page_company_main .container3 .list_box { border-width: 0; margin-top: 20px; } 

@media screen and (max-width: 1024px){
 .page_company_main .container3 .list_box { margin-top: 10px; } 
 }

.page_company_main .talent_search { margin-top: 50px; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_search { margin-top: 20px; } 
 }

.page_company_main .list_box { border: 1px solid #cfcfcf; margin-top: 10px; } 

.page_company_main .list_box > ul { height: 100%; } 

.page_company_main .announ_list { padding: 20px 25px; width: 100%; } 

@media screen and (max-width: 1024px){
 .page_company_main .announ_list { padding: 14px; } 
 }

.page_company_main .announ_list li { font-size: 15px; letter-spacing: -0.38px; line-height: 2; color: #000; width: 100%; } 

@media screen and (max-width: 1024px){
 .page_company_main .announ_list li { font-size: 13px; letter-spacing: -0.6px; } 
 }

.page_company_main .announ_list li::before { content: ''; display: inline-block; width: 3px; height: 3px; -webkit-transform: translateY(-4px); transform: translateY(-4px); background-color: #3269cc; border-radius: 50%; margin-right: 10px; } 

.page_company_main .talent_list { display: -webkit-box; display: -ms-flexbox; display: flex; } 

.page_company_main .talent_list li { cursor: pointer; width: 25%; padding: 18px 16px; border-right: 1px solid #cfcfcf; color: #666; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_list { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } 
 }

.page_company_main .talent_list li { cursor: pointer; width: 25%; padding: 18px 16px; border-right: 1px solid #cfcfcf; color: #666; font-size: 15px; letter-spacing: -0.38px; } 
.page_company_main .talent_list li a { width:100%; } 

@media screen and (max-width: 1260px){
 .page_company_main .talent_list li { padding: 18px 8px; } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li { font-size: 13px; letter-spacing: -0.6px; padding: 14px 8px; width: 100%; border-right-width: 0; border-bottom: 1px solid #cfcfcf; display: -webkit-box; display: -ms-flexbox; display: flex; } 
 .page_company_main .talent_list li a.link_wrap { display: flex; } 
 .page_company_main .talent_list li .pic_name_wrap { max-width: 112px; width: 112px; padding-right: 8px; } 
 .page_company_main .talent_list li:last-child { border-bottom: 0; } 
 }

.page_company_main .talent_list li hgroup { width: 100%; } 
.page_company_main .talent_list li .link_wrap{height: 100%;}
.page_company_main .talent_list li hgroup.style2 {display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; height: calc(100% - 44px);} 
.page_company_main .talent_list li hgroup.style2 .ellipsis{width:100%}
.page_company_main .talent_list li hgroup.style2 h4{margin-top: auto; }
@media screen and (max-width: 1024px){
 .page_company_main .talent_list li hgroup { width: calc(100% - 112px - 8px); display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 
 .page_company_main .talent_list li hgroup.style2 h1 { width: 100%; max-width: none; margin-bottom: 4px; } 
 .page_company_main .talent_list li hgroup.style2 h2 { padding-left: 0; } 
 .page_company_main .talent_list li hgroup.style2 h2:before { display: none; } 
 .page_company_main .talent_list li hgroup.style2 h4 { width: 100%; max-width: none; } 
 }

.page_company_main .talent_list li h1, .page_company_main .talent_list li h2, .page_company_main .talent_list li h3, .page_company_main .talent_list li h4 { font-size: inherit; letter-spacing: inherit; color: inherit; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li h1, .page_company_main .talent_list li h2, .page_company_main .talent_list li h3, .page_company_main .talent_list li h4 { max-width: 33.333%; padding: 0 5px; } 
 }

.page_company_main .talent_list li h1 { margin-top: 15px; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li h1 { margin-top: 0; padding-left: 0; } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li h2::before, .page_company_main .talent_list li h3::before { content: ''; display: inline-block; width: 1px; height: 10px; background-color: #d7d7d7; -webkit-transform: translateX(-5px); transform: translateX(-5px); } 
 }

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li h3 { padding-right: 0; } 
 }

.page_company_main .talent_list li h4 { margin-top: 10px; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li h4 { max-width: none; margin-top: 4px; padding: 0; } 
 }.page_company_main .talent_list li:last-child { border-right-width: 0; } 
.page_company_main .talent_list li .line3 { margin-top: 10px; } 

@media screen and (max-width: 1024px){
 .page_company_main .talent_list li .line3 { margin-top: 4px; width: 100%; max-width: none; padding-left: 0; -webkit-line-clamp: 1; text-overflow: ellipsis; white-space: nowrap; word-wrap: normal; overflow: hidden; display: block; } 
 .page_company_main .talent_list li .line3::before { display: none; } 
 }

.page_company_main .talent_list.wide { width: 100%; } 
.page_company_main .talent_list.wide li { width: calc(25% - 10px); border: 1px solid #cfcfcf; } 
.page_company_main .talent_list.wide li a { width: 100%; } 
.page_company_main .talent_list.wide li h1 { margin-top: 0; } 
.page_company_main .talent_list.wide li hgroup { display: flex; justify-content: space-between;} 
.page_company_main .talent_list.wide .pic_name_wrap { width: 40%; align-self: flex-start;} 
.page_company_main .talent_list.wide .pic_name_wrap+div{width: 60%; margin-top:13px}
.page_company_main .talent_list.wide li .ellipsis { color: black; } 
.page_company_main .talent_list.wide li .ellipsis span { color: #666; } 
.page_voucher .radio_wrap { width: auto; } 
@media screen and (max-width: 1260px){ 
  .page_company_main .talent_list.wide .pic_name_wrap+div{margin-top:7px}
}
@media screen and (max-width: 1024px){
 .page_company_main .talent_list.wide { -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; } 
 .page_company_main .talent_list.wide li { width: calc(50% - 5px); -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; padding: 11px 8px; } 
 .page_company_main .talent_list.wide li:nth-child(even) { margin-left: 5px; } 
 .page_company_main .talent_list.wide li:nth-child(n + 3) { margin-top: 5px; } 
 .page_company_main .talent_list.wide li h1, .page_company_main .talent_list.wide li h2, .page_company_main .talent_list.wide li h3 { width: 100%; max-width: 100%; } 
 .page_company_main .talent_list.wide li h2, .page_company_main .talent_list.wide li h3 { padding: 0; } 
 .page_company_main .talent_list.wide li h2:before, .page_company_main .talent_list.wide li h3:before { display: none; } 
 .page_company_main .talent_list.wide li hgroup { width: 100%; margin-bottom: 6px; display: block;} 
 .page_company_main .talent_list.wide .pic_name_wrap,
 .page_company_main .talent_list.wide .pic_name_wrap+div{width: 100%;}
 .page_company_main .talent_list.wide li hgroup .txt_blue { margin-top: 12.5px; } 

 }

.page_talent_search .icon_banner .icon { margin-right:10px; } 
.page_talent_search .myresume_view { padding-inline: 40px; }
.page_talent_search .myresume_view .profile_area .name_field { margin-top:0; width:100%; }
.page_talent_search .myresume_view .profile_area .profile_img { margin-inline: 0; }