@charset "utf-8";/*메이페이지 내 좌측 메뉴*/
#my_lnb { width:200px; margin-right: 20px; } 
#my_lnb .selected { display: none; } 
#my_lnb .menu { background: #fff; } 
#my_lnb .menu a { font-size:15px; color:#000; padding:15.5px 15px; display: block; width: 100%; box-sizing: border-box; border-bottom:1px solid #dedede; border-left:1px solid #999; border-right: 1px solid #999; } 
#my_lnb .menu li:first-child a { border-top:1px solid #999; } 
#my_lnb .menu li:last-child a { border-bottom-color:#999; } 
#my_lnb .menu .active { color:#fff; background: #3d7df2; border:1px solid #0c46ae !important; } 
.head_hunter_body #my_lnb .menu .active{background: #193b65}
.layout_my .list_top .list_search_area .search .btn_search{display: none;}

/*좌측메뉴 있을 경우 레이아웃*/
.layout_my { overflow: hidden; } 
.layout_my .sub_content_title { padding-bottom: 17px; font-size:20px; font-weight: bold; padding-left: 10px; } 
.layout_my #my_lnb { float:left; } 
.layout_my .container { width:1040px; float: right; } 
.layout_my .box { overflow: hidden; } 
.layout_my .sub_content_text{text-align: left; margin-bottom: 20px; color:#000;}
.layout_my .center_wrap { width: 1040px; }

/***************
정보관리
*****************/
/* 타이틀 텍스트 */
.layout_my .my_title { font-size: 20px; letter-spacing: -0.5px; color: #000; font-weight: 700; } 
.layout_my .my_title .info_txt { color:#666666; font-size:15px; font-weight: 400; display: block; margin-top: 10px; } 
.layout_my .my_title+.list_dot { margin-top:30px; } 
.layout_my .my_title+.table_view01 { margin-top:15px; } 
.layout_my .my_title_wrap+.table_view01 { margin-top:15px; } 
.layout_my .my_subtitle { margin-top: 10px; font-size: 15px; letter-spacing: -0.38px; color: #666; } 
/* 박스 상단 */
.layout_my .box_top { padding:30px; border-bottom: 1px solid #999; } 
.layout_my .box_top .my_title:not(:first-of-type) { margin-top: 20px; } 
/* 박스 컨텐츠 */
.layout_my .box_content { padding:30px; } 
.layout_my .box_content .my_title:not(:first-of-type) { margin-top: 30px; } 
/* 박스 외부 버튼 영역 */
.layout_my .box_out_button_area { margin-top: 30px; } 
.layout_my .box_out_button_area.one { text-align: center; } 
.layout_my .box_out_button_area.one .btn { min-width: 196px; } 


.my_profile .top_area{display: flex; justify-content: space-between;}
.page_my_info .title { color:#000; font-size:20px; font-weight: bold; height: 35px; line-height: 35px; } 
.page_my_info .title .point { color:#3d7df2; } 
.page_my_info .btn_area {display: flex; align-items: center; margin-bottom: 20px; } 
.page_my_info .btn_area .btn { font-size:15px; min-height: 35px; height: 35px; margin-left:10px; width:100px; letter-spacing: -0.05em; font-weight: bold; white-space: nowrap; } 
.page_my_info .btn_area .btn.line { font-weight: 400; } 
.page_my_info .state_wrap .state_box .title{white-space: nowrap;}

/*프로필*/
.my_profile { padding:3px 30px; margin:19px 0; width: 50%; float: left; overflow: hidden; } 
.my_profile .info_wrap{display:flex; justify-content: space-between;}
.my_profile .img_area { width: 157px;} 
.my_profile .profile_img { border:1px solid #dedede; width:157px; height: 200px; background: url("../img/sub/profile.gif") #f7f9fa no-repeat; background-position: center; overflow: hidden; display: flex; align-items: center; justify-content: center;} 
.my_profile .profile_img img { object-fit: cover; max-width: inherit; max-height: 100%;}
.my_profile .add_profile { display: none; } 
.my_profile .info_list { width:calc(100% - 187px); } 
.my_profile .info_list li { display:flex; margin-bottom: 15px; } 
.my_profile .info_list p { font-size:15px; } 
.my_profile .info_list .title_field { color:#666666; width: 84px; min-width: 84px; } 
.my_profile .info_list .info_field .button { border-radius: 100px; margin-right:5px; overflow: hidden; vertical-align:middle; margin-top: -4px; } 
.my_profile .tip_field { clear: both; font-size:14px; padding-top: 12px; } 

.my_profile .add_profile+label { font-size:15px; color:#333333; border:1px solid #dedede; width:100%; display: block; text-align: center; padding:12px 0; margin-top: 5px; } 
.my_profile_right { width:50%; float:right; margin:19px 0; border-left:1px solid #dedede; }

/*이력서*/
.my_resume_area { width: 100%; padding:3px 30px; } 
.my_resume_area .top_area{display: flex; justify-content: space-between; align-items: center;}
.my_resume_area .btn_area { margin-bottom: 0 } 
.my_resume_area .resume_list { clear: both; } 
.my_resume_area .resume_list p,
.my_resume_area .resume_list a { color:#000; font-size:15px; } 
.my_resume_area .resume_list a { display: block; width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; } 
.my_resume_area .resume_list li { display: flex; align-items: center; margin-bottom: 12px; }
.my_resume_area .resume_list li .title_field { width: calc(100% - 149px) } 
.my_resume_area .resume_list li:before { content: ''; display: block; width: 2px; height: 2px; background: #c4c4c4; margin-right: 7px; vertical-align: middle; } 
.my_resume_area .resume_list li .date_field { min-width: 90px; width:90px; margin-left: auto; text-align: center; } 
.my_resume_area .resume_list li .state_field { min-width: 45px; width: 50px; text-align: right; } 
.my_resume_area .resume_list li .state_field.ing { color:#cc3333; } 
.main_resume_select_area { display: flex; margin:20px 0; padding:12px 15px; align-items: center; justify-content: space-between; background-color: #f7f9fa;}
.main_resume_select_area .table_notice_badge { width: 52px; min-width: 52px; white-space: nowrap; text-align: center; font-size: 15px;}
.main_resume_select_area select { width: 100%; height:36px; min-height: 36px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin-left: 5px;}
.main_resume_select_area .btn_area { margin-bottom: 0; width:120px; margin-left: 10px}
.main_resume_select_area .btn_area .btn { margin: 0; width: 100%; height:36px; }

/*헤딩머니*/
.my_money { width: 100%; padding:5px 30px 3px; } 
.my_money .money_list { clear: both; padding-top: 12px; } 
.my_money .money_list li { display: flex; align-items: center; margin-bottom: 6px; } 
.my_money .money_list li:before { content: ''; display: block; width: 2px; height: 2px; background: #c4c4c4; margin-right: 7px; vertical-align: middle; } 
.my_money .money_list li .title_field { color:#666; font-size:15px; } 
.my_money .money_list .point { color:#3d7df2; font-weight: bold; font-size:20px; margin-left: auto; } 
.my_money .money_list .point .won { font-size:15px; font-weight: 400; margin-left: 5px; } 

/*나의 채용지원현황*/
.page_my_info .state_wrap .state_box { border-right: 1px solid #dedede; padding-top:0; padding-bottom:0; background: none; margin-right: 0; } 
.page_my_info .state_wrap .state_box:last-child { border:none; } 
.my_apply { float:left; border-top:1px solid #999999; padding:21px 30px; width:71% } 
.my_apply .state_wrap { clear: both; padding-top: 28px; flex-wrap: nowrap; }
.my_apply .state_box:last-child { min-width: 126px; } 

/*추천현황*/
.my_recommend { float:right; border-top:1px solid #999999; width: 29%; border-left:1px solid #dedede; padding:21px 0 } 
.my_recommend .title { padding:0 20px; } 
.my_recommend .state_wrap { clear: both; padding-top: 28px; } 
.my_recommend .state_wrap .state_box { width: 50%; } 
.my_recommend .state_wrap .state_box .title { padding:0; } 

/*회원탈퇴*/
.page_my_info .btn_leave { float: right; margin-top: 18px; font-size: 15px; color:#999999; background: url('../img/sub/icon_arrow.png') no-repeat; background-position: right 5px; padding-right: 14px } 
.page_leave .tip_field { color:#333; font-size:15px; padding:20px 0 0 10px; } 
.page_leave .input_form { margin-top:30px } 

/************
받은 제안 현황
*************/
.page_recommend .state_wrap .state_box .num, 
.page_suggest .state_wrap .state_box .num{color:#3d7df2;}

/***************
추천현황
***************/
.page_recommend .tab01 li { width: 50%; border-top:none; } 
.page_recommend .state_wrap .state_box { flex-direction: row; align-items: center; justify-content: space-between; padding-top:27px; padding-bottom:27px; } 
.page_recommend .state_wrap .state_box .num { color:#3d7df2; font-size:20px; } 
.page_recommend .state_wrap .state_box .title { width: auto; height: auto; margin-bottom: 0 } 
.page_recommend .tab01_wrap { margin-bottom: 10px; } 

/**************
관심채용 관리
***************/
.page_bookmark .tab01 li { width: 50%; text-align: center; margin-bottom: 20px; } 
.page_bookmark .list_top{display: flex; justify-content: flex-end;}
.page_bookmark .list_top select{width: auto; margin-bottom: 11px;}

/**************
헤딩머니
***************/
.page_money .top { display: flex; align-items: center; overflow: visible} 
.page_money .state_wrap { margin-left: auto; width: 70%; } 
.page_money .state_wrap .state_box { flex-direction: row; white-space: nowrap; align-items: center; } 
.page_money .state_wrap .state_box:last-child { min-width: 55%; } 
.page_money .state_wrap .state_box .title { height: auto; margin-bottom: 0; text-align: left; } 
.page_money .state_wrap .state_box .num_wrap{margin-left: auto; display: flex; justify-content: space-between; align-items: center}
.page_money .state_wrap .state_box .num { margin-left: auto; color:#3d7df2; font-size:20px; font-weight: bold; margin-top: 0 } 
.page_money .state_wrap .state_box .num .won { font-weight: 400; font-size:15px; margin-left: 5px; } 
.page_money .state_wrap { margin-top: 0 } 

.page_money .table_list01 td { padding-top:8px; padding-bottom:8px; font-size:14px; } 
.page_money .table_list01 .td_info { width:150px; } 

.page_money_write .table_view02 .info_field select { width:282px } 
.page_money_write .table_view02 .info_field .point_text { margin-left: 10px; display: inline-block; font-size:15px; } 
.page_money_write .table_view02 .info_field .point_text_before{margin-right:10px; display: inline-block;}
.page_money_write .table_view02 .info_field .point_text_before+.point_text{margin-left: 0;}
.page_money_write .table_view02 .input_type1 { width:134px } 

.page_money_write .my_title+.table_view02{margin-top: 13px;}
.page_money_write .my_title{display: flex; justify-content: space-between; align-items: center;}
.page_money_write .my_title .btn{min-height: 35px; height: 35px; font-size:15px;}

/***************
FAQ
*****************/
.page_my_faq .tab01 li { width:25%; text-align: center; margin-bottom: 20px; } 
.faq{border:1px solid #999999;}
.faq .item:first-of-type .q{border-top:none;}
.faq .item.open .q{background-image:url("../img/sub/icon_close.png")  }
.faq .item.open .a{display: block;}
.faq .q,
.faq .a{font-size:15px; padding:15px 20px; border-top:1px solid #dedede; white-space: pre-wrap; line-height: 150%;}
.faq .q{color:#000; background: url("../img/sub/icon_open.png") no-repeat; background-position: 98% center; padding-right: 5%; cursor: pointer; }
.faq .a{color:#666;background: #fafbfb; display: none;}
.page_my_faq .search_input_wrap { position:relative; width:90%; margin:12px auto; }
.page_my_faq .search_input_wrap [type="text"] { padding-right: 48px; }
.page_my_faq .search_input_wrap .btn_search { position:absolute; padding:13px; top:0; right:0; }

/***************
헤딩 컨설팅 서비스
*****************/
.page_my_consulting_service p { font-size: 15px; letter-spacing: -0.38px; } 
.page_my_consulting_service .box_content { padding-bottom: 54px; } 
.page_my_consulting_service .table_view01 { margin-top: 22px; } 
.page_my_consulting_service .table_view01 td { padding-top: 20px; padding-bottom: 20px; } 
.page_my_consulting_service .table_view01 p { display: inline-block; font-size: 15px; margin-right: 20px; } 
.page_my_consulting_service .resume_box_area { margin-top: 10px; border-top: 1px solid #d9d9d9; display: flex; flex-direction: column;} 
.page_my_consulting_service .resume_box { padding: 20px 10px; } 
.page_my_consulting_service .agree_area { margin-top: 24px; padding: 0 10px; display: flex; justify-content: space-between; } 
.page_my_consulting_service .agree_area label { font-size: 15px; letter-spacing: -0.38px; } 
.page_my_consulting_service .agree_area label +input[type=radio]+label { margin-left: 26px; } 
.page_my_consulting_service .tip_field { margin-top: 6px; } 
.page_my_consulting_service .right_wrap{display: flex; align-items: center; justify-content: center;}

.page_my_consulting_service .resume_box_top .title_field{font-size:15px; font-weight: bold; padding-right:0; }
.page_my_consulting_service .resume_box .table_notice_badge{background: #e5f0fd; position: relative; margin-left: 10px; border:none; top:auto; right: auto; font-weight: 400; font-size:15px; min-width: 40px; text-align: center;}

.page_my_consulting_service .resume_box_bottom {width:480px; display: flex; align-items: center;}
.page_my_consulting_service .resume_box_bottom .title_field{width: 170px; text-align: left;}
.page_my_consulting_service .resume_box_bottom .resume_progress{margin-top: 0}

.page_notice_view.board_view { margin-top:0; }
/***************
HR 담당자 소개
*****************/
.page_my_hr_pic .table_view01 { margin-top: 15px; } 
.page_my_hr_pic .table_view01 th { width: 160px; } 
.page_my_hr_pic .tip_field { margin-top: 15px; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width:1260px){
    #my_lnb{width:16%; margin-right: 0;}
    .layout_my .container{width:82.5%}
}
@media screen and (max-width:1200px){/*모바일 레이아웃*/
    .layout_my #my_lnb{display: none;}
    .layout_my .sub_content_title{display: none;}
    .layout_my .container{width:100%}
    .layout_my .list_top .list_search_area .search .btn_search{display: flex;}
    
    .my_profile_right{border-left: none;}
    .my_profile,
    .my_profile_right,
    .my_resume_area,
    .my_money,
    .my_apply,
    .my_recommend{clear: both; width: 100%;}
    .my_recommend{border-left:none;}
    .my_profile_right select { max-width:calc(100% - 52px - 10px - 10px - 120px); }
    
}
@media screen and (max-width:1024px){
    /********
    헤딩머니
    *********/
    .page_money .top{display: block;}
    .page_money .state_wrap{margin-left: 0; width: 100%; margin-top: 18px;}
    .page_money .table_list01 tr{display: flex; align-items: center; flex-wrap: wrap; position: relative; justify-content: flex-end}
    .page_money .table_list01 td{padding:0; width: 100%; padding-left:60%; white-space:normal;}
    .page_money .table_list01 td.no_data{padding-left: 0;}
    .page_money .table_list01 .td_title{order:1; position: absolute; top:0; left:18px; width:40%; height: 100%; padding-left:0; }
    .page_money .table_list01 .td_title a{white-space: normal; text-overflow:clip; overflow: visible;}
    .page_money .table_list01 .td_info{order:3; color:#000; width:auto; text-align: right;}
    .page_money .table_list01 .td_info:after{display: none; }
    .page_money .table_list01 .td_money{order:2; padding-right:0; width:auto; height: auto;}
    .table_list01 td.td_money .state1, .table_list01 td.td_money .state2, .table_list01 td.td_money .state3{margin-bottom: 3px;}
    .layout_my .center_wrap { width: 100%; }
}
@media screen and (min-width:769px){/*769이상*/
    /************
    받은제안 현황
    *************/
    .page_recommend .state_wrap .state_box,
    .page_suggest .state_wrap .state_box{flex-direction: row; justify-content: space-between;}
    .page_recommend .state_wrap .state_box .title,
    .page_suggest .state_wrap .state_box .title{height: auto; margin-bottom: 0; line-height: inherit; text-align: left;}
    .page_recommend .state_wrap .state_box .num,
    .page_suggest .state_wrap .state_box .num{margin-top:inherit;}
}
@media screen and (max-width:768px){/*모바일디자인 적용*/
    .layout_my{padding:0;}
    .layout_my .my_title+.list_dot{margin-top:14px;}
    .layout_my .my_title .info_txt{font-size:13px;}
    .layout_my .box_top,
    .layout_my .box_content{padding:20px 4%;}
    .layout_my .sub_content_text{padding:20px 4%; margin-bottom: 0}
    .layout_my .box_content+ .box_out_button_area{margin-top: 0}
    .layout_my .box_out_button_area{padding:0 4%;}
    .layout_my .box_out_button_area.one .btn{width: 100%;}
    
    .layout_my .my_title{font-size:16px;}
    .layout_my .my_subtitle{font-size:13px;}
    .page_my_info .title{font-size:15px;}
    .page_my_info .btn_area .btn{width: inherit; padding:0 12px; margin-left: 7px; font-size:12px;}
    
    /***************
    정보관리
    *****************/
    .my_profile{margin-top: 0}
    .my_profile,
    .my_money,
    .my_apply,
    .my_recommend,
    .my_resume_area{padding:22px 4%; border-bottom:1px solid #dedede; border-top:none; margin:0;}
    .my_profile,
    .my_resume_area{padding-top:10px;}
    .page_my_info .btn_area{margin-bottom: 0;}
    .my_recommend .title{padding-left:0; padding-right: 0;}
    .my_resume_area .resume_list li:last-of-type,
    .my_money .money_list li:last-of-type{margin-bottom: 0}
    .my_resume_area .resume_list p, 
    .my_resume_area .resume_list a,
    .my_money .money_list li .title_field{font-size:13px;}
    .my_money .money_list .point{font-size:16px;}
    .my_profile .add_profile+label{font-size:12px;}
    
    .page_my_info .btn_leave{font-size:13px; background-position: right center;}
    /*프로필*/
    .my_profile .info_list li{margin-bottom: 12px;}
    .my_profile .info_list p{font-size:13px;}
    .my_profile .tip_field{font-size:13px;}
    .main_resume_select_area { padding:12px 6px; }
    .main_resume_select_area .table_notice_badge { font-size:10px; width:38px; min-width: 38px; }
    .my_profile_right select { max-width: calc(100% - 38px - 6px - 6px - 90px); }
    .main_resume_select_area .btn_area { width:90px; }
    
    /*이력서*/
    .my_resume_area .resume_list li{margin-bottom: 8px;}
    
    /*헤딩머니 */
    .my_money .money_list{padding-top: 6px;}
    
    /*회원탈퇴*/
    .page_my_info .btn_leave{margin-bottom: 30px; margin-right: 4%;}
    .page_leave .tip_field{padding-left:0;}
    .page_leave .input_form{padding:7px 0 0; margin-top: 23px; border-bottom:none}
    
    /*채용지원현황*/
    .page_my_info .state_wrap .state_box{padding:15px 0 12px; background:#f7f9fa; border:none;}
    .my_apply .state_wrap{flex-wrap: wrap; justify-content: space-between; margin-bottom: -6px}
    .my_apply .state_wrap li{width:24.5%; max-width: 24.5%; margin-bottom:3px}
    .my_apply .state_box:last-child{min-width: inherit;}
    .page_my_info .state_wrap .state_box .title{white-space: normal; letter-spacing: -0.05em;}
    
    /*추천현황*/
    .my_recommend .state_wrap .state_box{width: 49.25%;}
    .my_recommend .state_wrap,
    .my_apply .state_wrap{padding-top: 3px}
    
    /*헤딩머니 상세*/
    .layout_my .my_title{font-size:15px;}
    .page_money_write .table_view02 .info_field .point_text{font-size:13px;}
    .page_money_write .box_content{padding-top:14px;}
    .page_money_write .my_title .btn{font-size:12px;}
    .page_money_write .my_title+.table_view02{margin-top: 8px;}
    .page_money_write .btn_bottom_area{padding:0 4%;}
    /************
    받은제안 현황
    *************/
    .page_suggest .sub_content_text{border-bottom:1px solid #e2e2e2;}
    
    
    /*************
    채용지원현황
    **************/
    .page_apply .state_wrap{flex-wrap: wrap; justify-content: space-between; margin-bottom: -6px}
    .page_apply .state_wrap .state_box{width:23.8%; margin-bottom: 6px; margin-right: 0}
    .page_apply .list_top .list_search_area .style_select{min-width: 75px; width:75px;}
    .page_apply .list_top .title_field{min-width: 85px; width:85px;}
    /************
    추천현황
    **************/
    .page_recommend .state_wrap{padding:0;}
    .page_recommend .tab01_contents{padding:18px 0 0 0;}
    
    /**************
    관심채용 관리
    ***************/
    .page_bookmark .recruit_card_wrap{width: 100%; margin-left: 0;}
    .page_bookmark .tab01{margin:18px 4% 0 4%;}
    .page_bookmark .tab01 li{margin-bottom: 9px;}
    .page_bookmark .list_top{margin-bottom: 9px; padding:0 4%;}
    .page_bookmark .recruit_card .link_wrapper{align-items: center;}
    
    /************
    헤딩머니
    ************/
    .page_money .state_wrap{flex-direction: column;}
    .page_money .state_wrap .state_box+.state_box{margin-top: 10px;}
    .page_money .state_wrap .state_box .num_wrap .btn{order:1; margin-right: 15px;}
    .page_money .state_wrap .state_box .num_wrap .num{order:2; font-size:16px;}
    .page_money .state_wrap .state_box .num .won{font-size:12px;}
    .page_money .table_list01 td{font-size:13px; }
    .page_money .table_list01 .td_money,
    .page_money .table_list01 .td_info{width:100%;}
    
    .page_money_write .table_view02 .info_field select { width:100% } 
    .page_money_write .table_view02 .info_field select + .point_text,
    .page_money_write .table_view02 .info_field input + .point_text,
    .page_money_write .table_view02 .info_field .input_wrap + .point_text{ margin-left: 0; display: block; margin-top: 8px;} 
    .page_money_write .table_view02 .input_type1 {width:100%;} 
    
    /************
    faq
    *************/
    .page_my_faq .tab01{margin:18px 4% 0 4%;}
    .faq{border:1px solid #dedede; border-width: 1px 0;}
    .faq .q { padding-right:28px; }
    
    /************
    헤딩컨설팅서비스
    *************/
    .page_my_consulting_service .resume_box{border:1px solid #dedede; margin-bottom: 6px;}
    .page_my_consulting_service .resume_box_area{border-top:none;}
    .page_my_consulting_service .agree_area{padding:0; flex-direction: column; margin-top: 12px;}
    .page_my_consulting_service .agree_area .right_wrap{text-align: center; margin-top: 24px;}
    

    .resume_box_bottom h1, .resume_box_bottom .title_field,
    .page_my_consulting_service p,
    .resume_box_bottom.step1 .title_field,
    .page_my_consulting_service .resume_box_top .title_field{font-size:13px;}
    .page_my_consulting_service .resume_box .table_notice_badge{font-size:9px}
    .page_my_consulting_service .table_view01 p{font-size:13px;}
    .page_my_consulting_service .resume_box_bottom .title_field{min-width: 110px;}
    
    .page_my_hr_pic .tip_field{font-size:13px;}
}
@media screen and (max-width:640px){ 
    /***************
    정보관리
    *****************/
    /*프로필*/
    .my_profile .img_area{width: 120px;}
    .my_profile .profile_img{width: 120px; height: 152px; background-size: 70%;}
    .my_profile .info_list{width: calc(100% - 135px);}
    
    .my_profile .add_profile+label{padding:6px 0;}
    
    /************
    헤딩컨설팅서비스
    *************/
    .page_my_consulting_service .resume_box_bottom{width: 100%;}
    
}
@media screen and (max-width:450px) {
    .page_my_hr_pic .email_field select{width:100% !important;}
}
@media screen and (max-width:375px){
    /*.my_profile .top_area,
    .my_resume_area .top_area{display: block;}*/
    .page_my_info .title{line-height: 17px; word-break: keep-all; align-items: center;}
    .page_my_info .btn_area .btn{margin-left: 0; margin-right: 3px; padding:0 10px;}
    
    .my_profile .img_area{width: 62.8px;}
    .my_profile .profile_img{width: 62.8px; height: 80px}
    .my_profile .info_list{width:calc(100% - 70px)}
    .my_profile .info_list .info_field .button{margin-bottom: 10px;}
    /*.my_profile .info_wrap{flex-direction: column;}
    .my_profile .img_area{margin-bottom:20px;}
    .my_profile .info_list{width: 100%;}*/
    
    /********
    헤딩머니
    *********/
    .page_money .table_list01 .td_title{position: relative; width: 100%; left:0;}
    .page_money .table_list01 td{padding-left: 0;}
}