.select-list {border-collapse:separate;border-spacing:0 10px;}
.select-list tr {min-height:50px;height:50px;vertical-align:top;border-bottom:1px solid #eee;}
.select-list tr:last-child{border-bottom:none;} 
.select-list tr th {width:20%; padding:0px 0px 0px 50px;text-align:left;}
.select-list .select-items {width:40%;}
.select-list .select-items select {border-radius:4px;display:inline-block;min-height:40px;padding-left:10px;width:100%;border:1px solid #e2e3e5;font-size:16px;box-sizing:border-box;}
.select-list .select-addition {width:8%;}
.select-list .select-addition.sel-label {text-align:right;padding:9px 10px 0px 0px;}
.select-list .select-btn-delete, .select-list .select-btn-add {height:40px;line-height:38px;background:#258afe;min-width:70px;color:#fff;font-size:15px;text-align:center;display:inline-block;font-weight:400;margin:0 2px;box-sizing:border-box;border-radius:4px;border:0;padding:0 10px;cursor:pointer;}
.select-list .select-input {width:16%;}
.select-list .select-input input {min-height:40px;}