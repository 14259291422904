@charset "utf-8";
.page_guide .pg_tit{text-align: center; padding-top:70px; padding-bottom: 60px; font-size:30px; font-weight: bold;}
.page_guide .tab01{margin-bottom: 83px; margin-top: 0;}
.page_guide .tab01 li{width:33.3%}
.page_guide .guide_section{display: flex; justify-content: space-between; padding-left: 12px;}
.page_guide .guide_section:not(:last-of-type){ margin-bottom: 100px;}
.page_guide .info_area .sub_tit{font-size:20px; letter-spacing: -0.025em; margin-bottom: 5px; color:#333;}
.page_guide .info_area .tit{font-size:36px; font-weight: bold; letter-spacing: -0.025em; color:#3d7df2; margin-bottom: 30px;}
.page_guide .info_area .txt{font-size:15px; line-height: 28px; letter-spacing: -0.025em; color:#333; padding-bottom: 120px; word-break: keep-all;}
.page_guide .info_area .txt .button{border:1px solid #3d7df2; height: 39px; color:#333; font-size:15px; letter-spacing: -0.05em; padding:0 35px 0 24px; line-height: 35px; background: url("img/sub/btn_next.png") no-repeat #fff; background-position:calc(100% - 16px) center;  background-size: 5px auto; border-radius: 100px; margin-top: 25px;}
.page_guide .img_area .mobile{display: none;}

@media screen and (max-width:768px){/*모바일 디자인 적용*/
  .page_guide .pg_tit{padding-top:18px; padding-bottom: 15px; font-size:18px;}
  .page_guide .tab01{margin-top: 0; margin-bottom: 55px;}

  .page_guide .guide_section{display: block;}
  .page_guide .guide_section:not(:last-of-type){ margin-bottom: 60px;}

  .page_guide .info_area {margin-bottom: 30px;}
  .page_guide .info_area .sub_tit{font-size:14px}
  .page_guide .info_area .txt + .sub_tit{margin-top: 45px;}
  .page_guide .info_area .tit{font-size:24px;}
  .page_guide .info_area .txt{font-size:14px; padding-bottom: 0;}
  .page_guide .info_area .txt .button{font-size:14px;}

  .page_guide .img_area .pc{display: none;}
  .page_guide .img_area .mobile{display: block; margin-bottom: 18px;}
}