@charset "UTF-8";.writer { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

.writer .bg_profile_img { width: 31px; height: 31px; border-radius: 50%; background-image: url(../img/sub/company_user.png); background-size: cover; background-repeat: no-repeat; background-position: center; margin-right: 9px; } 

@media screen and (max-width: 768px){
 .writer .bg_profile_img { width: 21px; height: 21px; margin-right: 5px; } 
 }

.writer .bg_profile_img + h1 { font-size: 15px; letter-spacing: -0.38px; color: #222; } 

@media screen and (max-width: 768px){
 .writer .bg_profile_img + h1 { font-size: 13px; letter-spacing: -0.6px; } 
 }


.page_announcement .radio_wrap { width: auto; } 
.page_announcement .radio_wrap .chk { position: absolute; left: -9999px; } 
.page_announcement .td_recrutype .radio_wrap { margin-right:10px; }

@media screen and (max-width: 1024px){
 .page_announcement .list_bottom { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: reverse; -ms-flex-direction: column-reverse; flex-direction: column-reverse; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_bottom .right_button_wrap { margin-top: 17px; position: relative; } 
 .page_announcement .list_bottom .right_button_wrap .btn_excel_download { width: 100%; height: 40px; font-size: 13px; letter-spacing: -0.6px; } 
 
 }


.page_announcement .sub_content_title_wrap { -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; } 

@media screen and (max-width: 768px){
 .page_announcement .sub_content_title_wrap { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column;  align-items: flex-start;} 
 .page_announcement .sub_content_title_wrap .input_area{align-self: flex-end;}
 }

.page_announcement .sub_content_title_wrap .sub_content_title { margin-bottom: 14px; } 

.page_announcement .sub_content_title_wrap .sub_content_title + * { margin-top: 30px; margin-bottom: 18px; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 768px){
 .page_announcement .sub_content_title_wrap .sub_content_title + * { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .sub_content_title_wrap .sub_content_title + * { margin-top: 0; margin-bottom: 10px; } 
 }

.page_announcement .sub_content_title { margin-top: 30px; margin-bottom: 18px; } 

.page_announcement .sub_content_title + .guide_text { padding: 0; padding-left: 14px; margin-top: -10px; margin-bottom: 10px; } 

.page_announcement .radio_wrap + .radio_wrap { margin-left: 30px; } 
.page_announcement [type=checkbox].chk:not(:nth-child(1)) + label { margin-left: 30px; } 

.page_announcement .form_area { border: 1px solid #999; padding: 0 20px 30px; } 
.page_announcement .table { font-size: 15px; letter-spacing: -0.38px; } 
.page_announcement .table.table_view01 .th { min-width: 160px; width: 160px; } 
@media screen and (max-width: 768px){
  .page_announcement .radio_wrap + .radio_wrap { margin-left: 10px; } 
  .page_announcement .radio_wrap + .radio_wrap.has_guide_secret { margin-left:0; }
  .page_annoncement .radio_wrap { margin-right: 16px; } 
  .page_announcement .form_area { border: none; padding: 0 20px; border-top: 1px solid #dedede; width: calc(100% + 4% + 4%); -webkit-transform: translateX(-4%); transform: translateX(-4%); } 
  .page_announcement .table { font-size: 13px; letter-spacing: -0.6px; } 
  .page_announcement .table.table_view01 .th, .page_announcement .table.table_view01 .td { min-height: 60px; } 

  .page_announcement .table.table_view01 .th { padding: 10px; min-width: 80px; width: 80px; } 
  .page_announcement .table.table_view01 .th br { display: none; } 
  .page_announcement .table.table_view01 .td.input_with_btn .btn { margin-left:10px; }
 }

.page_announcement .table.table_view01 .td p { font-size: inherit; letter-spacing: inherit; } 

.page_announcement .table.table_view01 .td .btn { font-size: inherit; letter-spacing: inherit; width: 90px; min-height: 40px; height: 40px; margin-left: 0; padding: 0; } 
@media screen and (min-width: 768px){
  .page_announcement .table.table_view01 .td_company_name .btn { min-height: 48px; }
  .page_announcement .table.table_view01 .td .btn { margin-left:10px; } 
  .page_announcement .table.table_view01 .td_logo img { margin-right:10px; }
  .page_announcement .table.table_view01 .td_logo .btn { margin-left:0; }
}
@media screen and (max-width: 768px){
  .page_announcement .table.table_view01 .td_company_name .btn { margin-left:10px; }
  .page_announcement .table.table_view01 .td_logo { flex-direction:column; }
  .page_announcement .table.table_view01 .td_logo img { margin-bottom:6px; }
  .page_announcement .table.table_view01 .td_logo .btn.line { width:100%; }
}

.page_announcement .table.table_view01 .datepicker_area { width: 320px; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; } 
.page_announcement .table.table_view01 .datepicker_area .react-datepicker-wrapper:nth-of-type(2){margin-left: 5px;}
.page_announcement .table.table_view01 .datepicker { width: 150px; } 
 
.page_announcement .table.table_view01 select { width: 320px; min-height:40px; } 
.page_announcement .table .td.workcategory { flex-wrap: wrap; } 
.page_announcement .table .td.workcategory .selected { width:100%; } 
.page_announcement .table.table_view01 .td_career [type=text],
.page_announcement .table.table_view01 .td_career [type=number] { width: 80px; } 

.page_announcement .table.table_view01 .td_recrutype { -ms-flex-wrap: wrap; flex-wrap: wrap; }



.page_announcement .table.table_view01 .td_total_money { -webkit-box-pack: end; -ms-flex-pack: end; /* justify-content: flex-end; */}

.page_announcement .table.table_view01 .experienced { font-size: 15px; letter-spacing: -0.38px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 
.page_announcement .table.table_view01 .td_career input[type=radio].chk+label:before { border-radius: 0; } 
.page_announcement .table.table_view01 .col3 .td { width: calc((100% / 3) - 160px); }

@media screen and (max-width: 1024px){
  .page_announcement .table.table_view01 select { width: 49%; } 

  .page_announcement .table.table_view01 .col3 { -ms-flex-wrap: wrap; flex-wrap: wrap; border-bottom: 0; } 
  .page_announcement .table.table_view01 .col3 .th, .page_announcement .table.table_view01 .col3 .td { border-bottom: 1px solid #e2e2e2; } 
  .page_announcement .table.table_view01 .col3 .td { width: calc(100% - 160px); } 
    }
 
 @media screen and (max-width: 768px){
   .page_announcement .table.table_view01 .datepicker_area { width:100% } 
   .page_announcement .table.table_view01 .react-datepicker-wrapper{width:45%}
   .page_announcement .table.table_view01 .datepicker_area .react-datepicker-wrapper:nth-of-type(2){margin-left: 0px;}
   .page_announcement .table.table_view01 .datepicker{width:100%;}
   .page_announcement .table.table_view01 select { width: 100%; } 
   .page_announcement .table.table_view01 .td_date { -webkit-box-orient: vertical; -webkit-box-direction: normal;  -webkit-box-align: start; -ms-flex-align: start; flex-direction: column; align-items: flex-start; } 
   .page_announcement .table.table_view01 .td_date label { margin-top: 10px; margin-left: 0 !important; } 
   .page_announcement .table.table_view01 .td_career { -ms-flex-wrap: wrap; flex-wrap: wrap; white-space: nowrap; } 
   .page_announcement .table.table_view01 .td_career [type=text],
   .page_announcement .table.table_view01 .td_career [type=number] { min-height: 25px; width: 30px; } 
   .page_announcement .table .td.workcategory .btn{width: 100%; margin-left: 0;}
 
   .page_announcement .table.table_view01 .td_recrutype .radio_wrap:nth-child(n+3) { width: 100%; margin-top: 4px; margin-left: 0 !important; } 
  .page_announcement .table.table_view01 .experienced { font-size: 13px; letter-spacing: -0.6px; } 
  .page_announcement .table.table_view01 .experienced { margin-bottom: 8px; width: 100%; }
  .page_announcement .table.table_view01 .experienced + [type=checkbox].chk + label { margin-left: 0 !important; } 
 }
 @media screen and (max-width: 360px){
  .page_announcement .table.table_view01 .experienced { margin-bottom: 8px; width: 100%; flex-wrap: wrap; } 
  .page_announcement .table.table_view01 .experienced label { width: 100%; margin-bottom: 4px; }
  .page_announcement .table.table_view01 .datepicker_area { flex-wrap: wrap; }
  .page_announcement .table.table_view01 .react-datepicker-wrapper { width: 100%; }
  .page_announcement .table.table_view01 .td_recrutype .radio_wrap { width: 100%; }
  .page_announcement .table.table_view01 .td_recrutype .radio_wrap + .radio_wrap { margin-top: 4px; margin-left:0; }
 }

 



.page_announcement .table.table_view01 .col3 select { width: 100%; } 

.page_announcement .table.table_view01 .col3 select.hide { pointer-events: none; background-color: #eee; } 

@media screen and (max-width: 768px){
 .page_announcement .table.table_view01 .col3 .td { width: calc(100% - 80px); } 
 }

.page_announcement .guide_text { color: #666; font-size: 15px; letter-spacing: -0.38px; font-weight: 400; line-height: 1.6; padding: 19px; } 

@media screen and (max-width: 768px){
 .page_announcement .guide_text { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .guide_text { padding: 15px 0 0; } 
 }

.page_announcement .guide_text p { font-size: inherit; letter-spacing: inherit; font-weight: inherit; line-height: inherit; } 

@media screen and (max-width: 768px){
 .page_announcement .guide_text p + p { margin-top: 10px; } 
 }

.page_announcement .guide_text .black { color: #000; } 

.page_announcement .guide_text .blue { color: #3d7df2; } 

.page_announcement .has_guide_secret { position: relative; } 
.page_announcement .has_guide_secret label { margin: 0 !important; } 
.page_announcement .has_guide_secret:hover .guide_secret { display: block; } 
.page_announcement .guide_secret { display: none; font-size: inherit; letter-spacing: inherit; color: #cc3333; position: absolute; top: 26px; width: 366px; left: 7px; z-index: 100; background-color: #fff; padding: 6px 10px; border-radius:4px; border: 1px solid #e2e2e2; } 
.page_announcement .tip_field2 { width:100%; margin-top: 8px; } 
.page_announcement .address_area { width: 100%; } 

.page_announcement .address_area input { width: 390px; } 
.page_announcement .address_area .btn { min-height: 48px; height: 48px; width: 90px; margin-left: 10px; } 

.page_announcement .address_area .address_search { display: -webkit-box; display: -ms-flexbox; display: flex; margin-bottom: 10px; font-size: 15px; letter-spacing: -0.38px; } 
.page_announcement .table.table_view01 .td .address_search .btn{height: 48px; min-height: 48px; margin-left: 10px;}

.page_announcement textarea { min-height: 100px; } 
.page_announcement textarea.big { min-height: 160px; } 

@media screen and (max-width: 1090px){
  .page_announcement .address_area .address_search{margin-bottom: 0;}
  .page_announcement .address_area input{ width: 100%; margin-left: 0;}
  .page_announcement .address_area input:not(:last-child){margin-bottom: 5px;} 
  .page_announcement .table.table_view01 .td.workcategory select{width:30%}
}
@media screen and (max-width: 768px){
  .page_announcement .guide_secret{width: calc(100vw - 42px); left: -89px;}
 .page_announcement .address_area .address_search { font-size: 13px; letter-spacing: -0.6px; } 
 .page_announcement .table.table_view01 .td .address_search .btn{height: 40px; min-height: 40px;}
 
 .page_announcement .table.table_view01 .td.workcategory select{margin-left:0; margin-bottom: 5px; width:100%}
 .page_announcement .address_area .btn { min-width: 60px; width: 60px; min-height: 40px; height: 40px; margin-left: 5px; } 
 .page_announcement textarea { min-height: 74px !important; } 
 }

.page_announcement .list_top { margin-top: 40px; margin-bottom: 20px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; } 

@media screen and (max-width: 1024px){
 .page_announcement .list_top { padding: 0 !important; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .list_top { margin-top: 18px; margin-bottom:10px;} 
 }

.page_announcement .list_top .state_area { display: -webkit-box; display: -ms-flexbox; display: flex; } 
.page_announcement .list_top .state_area li {display: flex;}
.page_announcement .list_top .state_area input[type="radio"] {padding: 0px;} 

@media screen and (max-width: 1024px){
 .page_announcement .list_top .state_area { width: 100%; } 
 }

.page_announcement .list_top .state_area li:first-of-type label { border-left: 1px solid #cfcfcf; } 

/* .page_announcement .list_top .state_area li:nth-child(3) label { width: 130px; } 

@media screen and (max-width: 1260px){
 .page_announcement .list_top .state_area li:nth-child(3) label { width: 114px; } 
 } */

@media screen and (max-width: 1024px){
 .page_announcement .list_top .state_area li:nth-child(3) label { width: 100%; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .state_area li { width: 25%; } 
 }

.page_announcement .list_top .state_area label { font-size: 15px; letter-spacing: -0.38px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; width: 100px; height: 48px; border: 1px solid #cfcfcf; border-left: 0; cursor: pointer; } 

@media screen and (max-width: 768px){
 .page_announcement .list_top .state_area label { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 1260px){
 .page_announcement .list_top .state_area label { width: 86px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .state_area label { width: 100%; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .list_top .state_area label { height: 40px; } 
 }

.page_announcement .list_top .state_area label span { margin-left: 4px; color: #3d7df2; } 

.page_announcement .list_top .state_area [type=radio]:checked + label { background-color: #3d7df2; border-color: #3d7df2; color: white; } 

.page_announcement .list_top .state_area [type=radio]:checked + label span { color: inherit; } 

.page_announcement .list_top .middle_wrap { font-size: 15px; letter-spacing: -0.38px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; } 

@media screen and (max-width: 768px){
 .page_announcement .list_top .middle_wrap { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .middle_wrap { margin-top: 20px; width: 100%; } 
 }

.page_announcement .list_top .middle_wrap select { margin-right: 0; } 

@media screen and (max-width: 1024px){
 .page_announcement .list_top .middle_wrap select { width: calc(100% - 80px - 5px); } 
 }

.page_announcement .list_top .select_label { display: inline-block; font-size: inherit; letter-spacing: inherit; } 

@media screen and (max-width: 1024px){
 .page_announcement .list_top .select_label { width: 80px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .select_label span { display: none; } 
 }

.page_announcement .list_top .last_wrap select { margin-left: 0; } 

@media screen and (max-width: 1024px){
 .page_announcement .list_top .last_wrap select { width: 80px; margin-right: 5px; } 
 }

@media screen and (max-width: 1260px){
 .page_announcement .list_top .last_wrap .search { width: 180px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .last_wrap .search { width: calc(100% - 80px - 5px - 70px - 5px); } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .list_top .last_wrap { margin-top: 9px; width: 100%; } 
 .page_announcement .list_top .last_wrap .btn { width: 70px; margin-left: 5px; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 
 }

.page_announcement .anno_list_area { border: 1px solid #999; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_area { border-width: 0; } 
 }

.page_announcement .anno_list_card { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card { border: 1px solid #dedede; } 
 }

.page_announcement .anno_list_card + .anno_list_card { border-top: 1px solid #d6d9dd; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card + .anno_list_card { margin-top: 10px; border-color: #dedede; } 
 }

.page_announcement .anno_list_card .left_wrap { padding: 30px; } 

@media screen and (min-width: 768px) and (max-width: 1024px){
 .page_announcement .anno_list_card .left_wrap { width: 55%; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .anno_list_card .left_wrap { padding: 20px 15px; } 
 }

.page_announcement .anno_list_card .left_wrap h1 { font-size: 15px; letter-spacing: -0.38px; color: #666; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .left_wrap h1 { font-size: 13px; letter-spacing: -0.6px; } 
 }

.page_announcement .anno_list_card .left_wrap h1 span + span::before { content: '|'; font-size: 12px; display: inline-block; padding: 0 4px; color: #ccc; font-weight: 100; -webkit-transform: translateY(-2px); transform: translateY(-2px); } 

.page_announcement .anno_list_card .left_wrap h2 { margin-top: 12px; font-size: 20px; letter-spacing: -0.5px; color: black; font-weight: 500; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .left_wrap h2 { margin-top: 6px; font-size: 15px; letter-spacing: -0.38px; } 
 }

.page_announcement .anno_list_card .left_wrap h2 b { font-size: inherit; letter-spacing: inherit; font-weight: inherit; } 

.page_announcement .anno_list_card .left_wrap h3 { font-size: 15px; letter-spacing: -0.38px; margin-top: 10px; color: #666; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .left_wrap h3 { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .anno_list_card .left_wrap h3 { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; } 
 .page_announcement .anno_list_card .left_wrap h3 span:first-child { width: 100%; margin-bottom: 4px; } 
 .page_announcement .anno_list_card .left_wrap h3 span:first-child + span i { display: none; } 
 }

.page_announcement .anno_list_card .left_wrap h4 { margin-top: 20px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; font-size: 15px; letter-spacing: -0.38px; color: #333; font-weight: 500; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .left_wrap h4 { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .left_wrap h4 { margin-top: 14px; } 
 }

.page_announcement .anno_list_card .left_wrap h4 img { margin-right: 5px; } 

.page_announcement .anno_list_card .left_wrap h4 b { font-size: inherit; letter-spacing: inherit; margin-left: 13px; } 

.page_announcement .anno_list_card .right_wrap { padding: 30px; } 

@media screen and (max-width: 1024px){
 .page_announcement .anno_list_card .right_wrap { padding: 15px 15px 15px 0; width: 45%; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .right_wrap { width: 100%; border-top: 1px dashed #dedede; } 
 }

.page_announcement .anno_list_card .right_wrap .state_area { display: -webkit-box; display: -ms-flexbox; display: flex; width: 100%; } 

.page_announcement .anno_list_card .right_wrap li { width: 90px; height: 68px; border-left: 1px solid #e2e2e2; padding: 8px 0; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; align-items: center; font-size: 15px; letter-spacing: -0.38px; color: #666; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .right_wrap li { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .anno_list_card .right_wrap li { width: 80px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .right_wrap li { width: 25%; height: auto; padding: 0; } 
 .page_announcement .anno_list_card .right_wrap li:first-of-type { border-left-width: 0; } 
 }

.page_announcement .anno_list_card .right_wrap li h1 { font-size: inherit; letter-spacing: inherit; } 

.page_announcement .anno_list_card .right_wrap li b { margin-top: 6px; font-size: 18px; font-weight: 500; color: #333; } 
.page_announcement .anno_list_card .right_wrap li b a{font-size: 18px; font-weight: 500; color: #333; }

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .right_wrap li b { font-size: 13px; } 
 .page_announcement .anno_list_card .right_wrap li b a{ font-size:13px; }
 }

.page_announcement .anno_list_card .bottom_wrap { width: 100%; padding: 20px 30px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; background-color: #f9fbfc; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .bottom_wrap { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-align: start; -ms-flex-align: start; align-items: flex-start; padding: 15px; } 
 }

.page_announcement .anno_list_card .bottom_wrap .button_wrap { display: -webkit-box; display: -ms-flexbox; display: flex; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .bottom_wrap .button_wrap { margin-top: 9px; width: 100%; } 
 }

.page_announcement .anno_list_card .bottom_wrap .button_wrap .btn { font-size: 15px; letter-spacing: -0.38px; min-height: auto; padding: 7px 15px; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .bottom_wrap .button_wrap .btn { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .bottom_wrap .button_wrap .btn { width: 25%; min-width: auto;} 
 }

.page_announcement .anno_list_card .bottom_wrap .button_wrap .btn + .btn { margin-left: 7px; } 

@media screen and (max-width: 768px){
 .page_announcement .anno_list_card .bottom_wrap .button_wrap .btn + .btn { margin-left: 5px; padding: 7px 4px; } 
 }

.page_announcement .support_list_area .table { font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 768px){
 .page_announcement .support_list_area .table { font-size: 13px; letter-spacing: -0.6px; } 
 }

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .table { font-size: 14px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table { border: 0; } 
 }

/* .page_announcement .support_list_area .table .tr { cursor: pointer; } */

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .tr { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; border: 1px solid #dedede; padding-bottom:10px; } 
 .page_announcement .support_list_area .table .tr + .tr { margin-top: 10px; } 
 .page_announcement .support_list_area .table .tr .no_data{margin-bottom:-10px;}
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .tr:first-of-type { display: none; } 
 }

.page_announcement .support_list_area .table .th, .page_announcement .support_list_area .table .td { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; font-size: inherit; letter-spacing: inherit; line-height: 1.3; width: auto; text-align: center; } 

.page_announcement .support_list_area .table .row_num, .page_announcement .support_list_area .table .row_state, .page_announcement .support_list_area .table .row_sort { min-width: 70px; width: 70px; } 

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .table .row_num, .page_announcement .support_list_area .table .row_state, .page_announcement .support_list_area .table .row_sort { min-width: 62px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_state { margin-right: 10px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_num { display: none; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_sort { font-size: 11px; width: 33.333%; padding: 0 8px; line-height: 17.5px; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; font-weight: 700; } 
 .page_announcement .support_list_area .table .row_sort a{font-size:11px; font-weight: 700;}
 }


.page_announcement .support_list_area .table .row_sort .m_th { display: none; color: #666; font-weight: 400; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_sort .m_th { display: inline-block; } 
 }

.page_announcement .support_list_area .table .row_sort.sort9 { min-width: 126px; width: 126px; } 

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .table .row_sort.sort9 { min-width: 62px; width: 62px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_sort.sort9 { min-width: auto; width: 33.333%; } 
 }

.page_announcement .support_list_area .table .row_name { min-width: 140px; width: 140px; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } 

.page_announcement .support_list_area .table .row_name span { color: #666; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_name { min-width: 100%; font-size: 11px; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; padding: 0; margin-bottom: 6px; } 
 .page_announcement.list.support .support_list_area .table .row_name { padding-block: 0; padding-inline: 6px; }
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .table .row_name br { display: none; } 
 }

.page_announcement .support_list_area .table .row_title { min-width: calc(100% - 965px); -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 
@media screen and (max-width: 1260px){
  .page_announcement .support_list_area .table .row_title { min-width: calc(100% - 822px); width: calc(100% - 80px); }
}
@media screen and (max-width: 1024px){
  .page_announcement .support_list_area .table .row_title { min-width: calc(100% - 80px); width: calc(100% - 80px); }
}

.page_announcement .support_list_area .table .row_title.td { text-align: left; } 

.page_announcement .support_list_area .th { padding: 12px 6px; } 

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .th { padding: 12px 4px; } 
 }

.page_announcement .support_list_area .th.row_sort { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } 

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .th.row_sort.sort9 { font-size: 10px; line-height: 1; } 
 }

.page_announcement .support_list_area .td { padding: 12px 10px; } 

@media screen and (max-width: 1260px){
 .page_announcement .support_list_area .td { padding: 12px 6px; } 
 }

.page_announcement .support_list_area .m_colgroup { width: 100%; } 

.page_announcement .support_list_area .m_colgroup.group1 { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; padding: 18px 15px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .m_colgroup.group1 { font-size: 14px; } 
 }

.page_announcement .support_list_area .m_colgroup .m_group:not(.row_name) { width: auto; min-width: auto; padding: 0; } 

.page_announcement .support_list_area .m_colgroup.group2 { padding: 17px 6px; border-top: 1px solid #e2e2e2; background-color: #f9fbfc; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_list_area .m_colgroup.group2 { border-top-style: dashed; display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; } 
 .page_announcement .support_list_area .m_colgroup.group2 .row_sort { -webkit-box-ordinal-group: 4; -ms-flex-order: 3; order: 3; } 
 .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(1), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(4), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(7) { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; } 
 .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(2), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(5), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(8) { -webkit-box-ordinal-group: 3; -ms-flex-order: 2; order: 2; } 
 .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(4), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(5), .page_announcement .support_list_area .m_colgroup.group2 .row_sort:nth-child(6) { border-left: 1px solid; border-right: 1px solid; border-color: #e2e2e2; } 
 }

.page_announcement .support_list_area .sort_wrap { border: 1px solid #b3b7bd; width: 30px; height: 19px; margin-top: 3px; display: -webkit-box; display: -ms-flexbox; display: flex; } 

.page_announcement .support_list_area .sort_wrap i { width: 50%; display: -webkit-box; display: -ms-flexbox; display: flex; background-image: url(../img/sub/btn_list_align.png); background-repeat: no-repeat; background-position: center; cursor: pointer; } 

.page_announcement .support_list_area .sort_wrap i.down { -webkit-transform: rotate(180deg); transform: rotate(180deg); border-right: 1px solid #b3b7bd; } 

.page_announcement .support_list_area .sort_wrap i:hover { background-color: whitesmoke; } 

.page_announcement .support_view_area .recruit_dashboard { border: 1px solid #999; margin-top: 30px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard { border-color: #dedede; margin-top: 16px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 { position: relative; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; padding: 30px 32px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; padding: 15px; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap { -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h1, .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h3 { font-size: 15px; letter-spacing: -0.38px; color: #666; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h1, .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h3 { font-size: 12px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h1 span, .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h3 span { font-weight: 100; color: #ccc; padding: 0 10px; } 

.page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h2 { font-size: 20px; letter-spacing: -0.5px; color: #000; font-weight: 700; padding: 10px 0; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h2 { font-size: 15px; letter-spacing: -0.38px; font-weight: 500; padding: 6px 0 8px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h2 span { color: #cc3333; padding-right: 10px; } 

@media screen and (max-width: 768px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .left_wrap h3 { line-height: 1.4; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap .button_area { display: -webkit-box; display: -ms-flexbox; display: flex; margin-bottom: 25px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap .button_area { margin-bottom: 15px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) { display:inline-flex; align-items: center; justify-content: center; border: 1px solid #ccc; font-size: 15px; letter-spacing: -0.38px; color: #333; height: 30px; padding: 0 19px; white-space: nowrap; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) { font-size: 12px; height: 27.5px; padding: 0; width: 17.5%; } 
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):first-child { width: 30%; } 
 }



@media screen and (max-width: 768px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) { width: 15%; font-size: 11px; } 
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):first-child { width: 35%; } 
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):nth-last-child(2) { width: 20%; } 

 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) img { margin-left: 10px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) img { margin-left: 4px; height: 12px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):not(:first-child) { margin-left: 10px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):not(:first-child) { margin-left: 4px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec1 .writer { position: absolute; right: 32px; bottom: 30px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec1 .writer { position: relative; right: 0; bottom: 0; -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin-top: 14px; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 
 .page_announcement .support_view_area .recruit_dashboard .sec1 .writer .bg_profile_img { min-width: 21px; width: 21px; height: 21px; margin-right: 5px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec2 { display: -webkit-box; display: -ms-flexbox; display: flex; padding: 23px 32px; font-size: 15px; letter-spacing: -0.38px; color: #333; font-weight: 500; border-top: 1px solid #e2e2e2; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec2 { -ms-flex-wrap: wrap; flex-wrap: wrap; font-size: 12px; padding: 17px 15px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec2 div { font-size: inherit; letter-spacing: inherit; font-weight: inherit; display: -webkit-box; display: -ms-flexbox; display: flex; align-items: center; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec2 div { width: 100%; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec2 div + div { margin-left: 120px; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 

.page_announcement .support_view_area .recruit_dashboard .sec2 div img { margin-right: 4px; } 

@media screen and (max-width: 1024px){
  .page_announcement .support_view_area .recruit_dashboard .sec2 div img { height:12px; } 
 .page_announcement .support_view_area .recruit_dashboard .sec2 div + div { margin-left: 0; margin-top: 8px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec2 b { font-weight: 700; color: #3d7df2; } 

.page_announcement .support_view_area .recruit_dashboard .sec3 { width: 100%; border-top: 1px solid #e2e2e2; background-color: #f9fbfc; padding: 15px 32px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 { padding: 17px 0; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec3 ul { width: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 ul { font-size: 11px; color: #666; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec3 li { display: -webkit-box; display: -ms-flexbox; display: flex; width: 33.333%; font-size: inherit; letter-spacing: inherit; color: inherit; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; width: 31%; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec3 li:first-of-type p:first-of-type { border-left: 0; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li:first-of-type p { border-left: 0; } 
 }

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li:last-of-type { width: 38%; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec3 li p { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-align: center; -ms-flex-align: center; align-items: center; width: 33.333%; font-size: inherit; letter-spacing: inherit; padding: 8px; border-left: 1px solid #e2e2e2; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li p { -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; width: 100%; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; padding: 0 15px; line-height: 17.5px; } 
 }

@media screen and (max-width: 768px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li p { padding: 0 10px; } 
 }

.page_announcement .support_view_area .recruit_dashboard .sec3 li b { margin-top: 20px; font-size: 18px; color: #333; } 

@media screen and (max-width: 1024px){
 .page_announcement .support_view_area .recruit_dashboard .sec3 li b { font-size: 12px; margin-top: 0; } 
 }

 /*공고/지원자관리>지원자관리>상세리스트*/
.page_announcement.view_sec.support .table_list01 { margin-top: 50px; }
.page_announcement.view_sec.support .table_list01 .td_title{text-align: center; padding-left:0;}
/*.page_announcement.view_sec.support .table_list01 .th, .page_announcement.view_sec.support .table_list01 .td { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; }  
.page_announcement.view_sec.support .table_list01 .td.row_applicant { -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 
.page_announcement.view_sec.support .table_list01 .row_num { width: 8%; } 
.page_announcement.view_sec.support .table_list01 .row_applicant { width: 38%; } 
.page_announcement.view_sec.support .table_list01 .row_career, .page_announcement.view_sec.support .table_list01 .row_date1, .page_announcement.view_sec.support .table_list01 .row_date2 { width: 14%; min-width: 14%; } 
.page_announcement.view_sec.support .table_list01 .row_state { width: 20%; } */
.page_announcement.view_sec.support .table_list01 .m_th { display: none; } 
/*.page_announcement.view_sec.support .table_list01 .m_line { display: none; } */
.page_announcement.view_sec.support .writer { -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; } 
.page_announcement.view_sec.support .bg_profile_img { min-width: 29px; width: 29px; height: 29px; } 
.page_announcement.view_sec.support .list_bottom .right_button_wrap{margin-top:28px;}
.page_announcement.view_sec.support .list_bottom .paging+.right_button_wrap{margin-top:0;}
.page_announcement.view_sec.support .paging { min-height: 56px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; } 
.page_announcement.view_sec.support .right_button_wrap button { min-width: 196px; } 
.page_announcement.view_sec.support .style_select{margin-block: 5px;}
.page_announcement.view_sec.support_carrer .pdf_down_wrap { text-align: right; margin-top: 30px; margin-bottom: 20px; }

@media screen and (max-width: 1024px){
  .page_announcement.view_sec.support .list_bottom .right_button_wrap { width: 100%; text-align: center; } 
  .page_announcement.view_sec.support .table_list01 { border-top: 1px solid #dedede; border-bottom: 1px solid #dedede; margin-top: 20px; } 
  /*.page_announcement.view_sec.support .table_list01 .tr { padding: 20px 15px; -ms-flex-wrap: wrap; flex-wrap: wrap; } 
  .page_announcement.view_sec.support .table_list01 .tr:first-of-type { display: none; } 
  .page_announcement.view_sec.support .table_list01 .row_num { display: none; } 
  .page_announcement.view_sec.support .table_list01 .row_applicant { width: 100%; margin-bottom: 8px; } 
  .page_announcement.view_sec.support .table_list01 .row_career, .page_announcement.view_sec.support .table_list01 .row_date1, .page_announcement.view_sec.support .table_list01 .row_date2 { width: auto;   min-width:auto; font-size: 11px; color: #666; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; }
  .page_announcement.view_sec.support .table_list01 .row_state { width: 100%; -webkit-box-ordinal-group: 2; -ms-flex-order: 1; order: 1; margin-top: 15px; -webkit-box-pack: start; -ms-flex-pack: start; justify-content: flex-start; } 
 .page_announcement.view_sec.support .table_list01 .row_state .m_th { margin-right: 10px; } 
 .page_announcement.view_sec.support .table_list01 .row_state select { width: 140px; } */
 .page_announcement.view_sec.support .table_list01 .m_th { display: inline-block; font-size: 11px; color: #666; } 
 /*.page_announcement.view_sec.support .table_list01 .m_line { display: inline-block; width: 3%; text-align: center; font-size: 10px; color: #e2e2e2; -webkit-transform: translateY(1px); transform: translateY(1px); } */
 .page_announcement.view_sec.support .writer h1 { font-weight: 700; } 
 .page_announcement.view_sec.support .bg_profile_img { min-width: 20px; width: 20px; height: 20px; } 
 .page_announcement.view_sec.support .list_bottom { -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } 
 .page_announcement.view_sec.support .list_bottom .right_button_wrap{margin-top:0;}
 .page_announcement.view_sec.support .right_button_wrap button { width: 100%; } 
 .page_announcement.view_sec.support_carrer .pdf_down_wrap { margin-top: 20px; } 
 .page_announcement.view_sec.support_carrer .pdf_down_wrap .btn_excel_download { width: 100%; height: 40px; } 

}
@media screen and (max-width: 768px){
.page_announcement.view_sec.support .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button) { width:25%; }
.page_announcement.view_sec.support .support_view_area .recruit_dashboard .sec1 .right_wrap :is(a, button):first-child { width:25%; }
}



.page_announcement .popup .content_wrap { padding: 20px; } 

@media screen and (max-width: 768px){
 .page_announcement .popup .content_wrap { padding: 10px; } 
 }

.page_announcement .popup .search_area { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; margin-bottom: 16px; } 

@media screen and (max-width: 768px){
 .page_announcement .popup .search_area { margin-bottom: 13px; } 
 }

.page_announcement .popup .search_area .btn { width: 70px; font-size: 16px; letter-spacing: 0.4px; font-weight: 300; margin-left: 10px; padding: 0; height: auto; min-height: auto; } 

@media screen and (max-width: 768px){
 .page_announcement .popup .search_area .btn { font-size: 13px; } 
 }

#popCareerDownload .content_wrap li { background-color: #fff; border: 1px solid #dedede; height: 56px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; padding: 0 20px; font-size: 15px; letter-spacing: -0.38px; } 

@media screen and (max-width: 768px){
 #popCareerDownload .content_wrap li { font-size: 13px; letter-spacing: -0.6px; } 
 }

#popCareerDownload .content_wrap li + li { margin-top: 10px; } 

#popCareerDownload .content_wrap li .chk + label { padding-left: 30px; } 

#popCareerDownload .content_wrap li .chk + label:before { margin-right: 10px; margin-left: -30px; } /*# sourceMappingURL=Announcement.css.map */

.supportListC_guide { margin-top: 10px; }
@media screen and (max-width: 1024px) {
  .supportListC_guide { margin-top: 6px; font-size:11px; }
}

@media screen and (max-width: 1024px){
  .page_announcement .btn_list_go { margin-top:20px; width: 100%;}
}

@media screen and (min-width: 768px){
  .page_announcement .form_area .experienced ~ label { margin-left: 10px; }
}

