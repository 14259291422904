@charset "utf-8";
.page_mytalk_list{padding-top:18px;}
.page_mytalk_list .top_recommend_area.view_pc { display:flex; }
.page_mytalk_list .top_recommend_area.view_m { display:none; }
.page_mytalk_list .top_recommend_area {justify-content: flex-start; margin:16px 0 48px; }
.page_mytalk_list .top_recommend_area h1 { font-size: 18px; line-height: 1.38888; letter-spacing: -0.025em; color: #000; font-weight: 300; }
.page_mytalk_list .top_recommend_area .writer { font-size: 14px; line-height: 1.2; font-weight: 500; color: #000; padding-top: 16px; margin-top:auto}
.page_mytalk_list .list_top { padding-left:20px; }
.page_mytalk_list .top_recommend_area .date_count_wrap { margin-top: 16px; display: flex; align-items: center; justify-content: space-between; }
.page_mytalk_list .top_recommend_area .date_count_wrap p { font-size: 14px; line-height: 1.2; color:#999; letter-spacing: -0.05em; }
.page_mytalk_list .top_recommend_area .date_count_wrap p span { color: #ececec; padding:0 4px;}

.page_mytalk_list .top_recommend { width:24%; border: 1px solid #999; margin-left:1.3333%; padding:19px; display:flex; flex-direction: column;}
.page_mytalk_list .top_recommend:hover{border-color:#3d7df2; background: rgba(61,125,242,0.06);}
.page_mytalk_list .top_recommend:first-of-type { margin-left: 0; }

.page_mytalk_list .top_recommend_area .btn_area { display: flex; align-items: center; justify-content: center; margin-top: 13px; }
.page_mytalk_list .top_recommend_area .swiper-pagination { display:flex; }
.page_mytalk_list .top_recommend_area .btn_stop { background: #efefef; margin-left: 3px; height:11px; width:12px; display: flex; align-items: center; justify-content: center;}
.page_mytalk_list .top_recommend_area .btn_stop img {height:9.5px;}


@media screen and (max-width:1260px) and (min-width:769px){
	.page_mytalk_list .top_recommend_area .date_count_wrap { flex-direction: column; align-items: flex-start; }
	.page_mytalk_list .top_recommend_area .date_count_wrap p + p { margin-top: 4px; }
}
@media screen and (max-width:1024px){
	.page_mytalk_list .list_top{padding-left: 0}
	.page_mytalk_list{padding-top:20px;}
}
@media screen and (max-width:768px){/*모바일 디자인 적용*/
	.page_mytalk_list{padding:0;}  
	.page_mytalk_list .list_top{flex-wrap: wrap; padding:18px 4% 0 4%;}
	
	.page_mytalk_list .sub_content_title { margin-top: 19px; margin-left: 4%; }
	.page_mytalk_list .top_recommend_area.view_pc { display:none; }
	.page_mytalk_list .top_recommend_area.view_m { display:block; }
	.page_mytalk_list .top_recommend_area { margin-bottom: 13px; }
	.page_mytalk_list .top_recommend { width: calc(100% - 1px); padding:16px 12px; }
	.page_mytalk_list .top_recommend_area h1 { font-size:14px; }
	.page_mytalk_list .top_recommend_area .writer { font-size: 12px; margin-top: 10px; }
	.page_mytalk_list .top_recommend_area .date_count_wrap { margin-top: 10px; }
	.page_mytalk_list .top_recommend_area .date_count_wrap p { font-size: 12px; }
	
	.page_mytalk_view .btn_bottom_area{padding:20px 4% 0; border-top:1px solid #dedede;}
	}
@media screen and (max-width:640px){}