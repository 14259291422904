@charset "utf-8";/* 테이블 뱃지 */
.table_notice_badge { color: #3d7df2; padding: 0 8px; line-height: 28px; border: 1px solid #3d7df2; border-radius: 100px;} 

/*2022-09-07 khs 추가 커서모양*/
.default_cursor {cursor: default;}

/* 리스트 테이블 디자인 */
.table_list01 { border: 1px solid #999; table-layout: fixed; } 
.table_list01 tr:last-child td { border-bottom: 0; } 
.table_list01 th, .table_list01 td { font-size: 15px; letter-spacing: -0.38px; /*line-height: 50px;*/ height:50px; border-bottom: 1px solid #dedede; } 
.table_list01 td .td_button{font-size:15px;}
/* .table_list01 th { color: #666; font-weight: 400; width:93px; white-space: nowrap; }  */
/*2022-09-07 khs 수정*/
.table_list01 th { color: #666; font-weight: 400; width:120px; white-space: nowrap; } 
.table_list01 td { width: 93px } 
.table_list01 .th_num { width:70px; } 
.table_list01 .th_writer { width: 130px; } 
.table_list01 .th_date { width: 110px; } 
.table_list01 .td_chk,
.table_list01 .th_chk { width: 70px } 
.table_list01 .th_hit { width: 80px; } 
.table_list01 .th_title,
.table_list01 .td_title { width: auto; } 
.table_list01 td { color: #000; text-align: center; } 
.table_list01 td.td_title { text-align: left; padding-left: 40px; } 
.table_list01 td.td_title .wrap { display: flex; align-items: center; } 
.table_list01 td.td_title .table_notice_badge { display:none; } 
.table_list01 td.td_title a { font-size:15px; color:#000; display: block; overflow: hidden; text-overflow: ellipsis; max-width: 100%; white-space: nowrap; letter-spacing: -0.05em; } 
.table_list01 td.td_title .re { color:#e14642; margin-left: 5px; font-size:15px; } 
.table_list01 td.td_title .icon_new { margin-left: 7px; min-width: 13px; width: 13px; height: 13px; } 
.table_list01 td.td_red { color: #e14642; } 
.table_list01 .td.no_data,
.table_list01 td.no_data{text-align: center !important; width:100% !important;}
/*리스트 내 버튼*/
.table_list01 .td_button_wrap { display:flex; align-items: center; justify-content: center; line-height: 1;}
.table_list01 .td .button,
.table_list01 td .button { color:#3d7df2; font-size:15px; letter-spacing: -0.05em; cursor: pointer; line-height: 1;} 
.table_list01 td .button.off { color:#000; } 
.table_list01 .field_tit { display: none; } 
.table_list01 .m_th{display: none;}

/*2022-09-07 추가*/
.table_list01 td .mr { margin-right: 10px;}
.table_list01 td .ml { margin-left: 10px;} 

/*머니*/
.table_list01 td.td_money { text-align: right; color:#666; padding-right: 30px; width: 200px; } 
.table_list01 td.td_money p { font-size:15px; text-align: right;} 
.table_list01 td.td_money .state1,
.table_list01 td.td_money .state2,
.table_list01 td.td_money .state3 { font-weight: bold; margin-bottom: 5px; } 

/*상태표시*/
.table_list01 .td_state { letter-spacing: -0.05em } 
.table_list01 .state1 { color:#333; } 
.table_list01 .state2 { color:#cc3333 } 
.table_list01 .state3 { color:#3d7df2 } 

.table_list01 .checked td,
.table_list01 .checked td a { color:#999999; } /*확인한 게시글*/

.table_list01 .td_button { cursor: pointer; }
.table_list01 .td_button .btn{min-height: 31px; height: 31px; width: 100%; font-size:15px; display: block; line-height: 31px;}
.table_list01 .td_button .btn:not(:first-of-type){margin-top:5px;}
.table_list01 .td_button .btn img{margin-left: 15px;}

.table_list01 .no_data{justify-content: center !important;}


/* 뷰 테이블 디자인 */
.view_top { overflow: hidden; padding-bottom: 15px; display:flex; justify-content: space-between; align-items: center; } 
.view_top .tip_field { font-size:15px; } 
.table_view01+.view_top,
.table_view02+.view_top{ margin-top: 20px; } 
.table_view01 { border-top: 1px solid #d9d9d9; } 
.table_view01 th, .table_view01 td { border-bottom: 1px solid #d9d9d9; font-size: 15px; letter-spacing: -0.38px; color: #222; text-align: left; } 
.table_view01 th { font-weight: 500; background-color: #fafbfb; padding: 20px; } 
.table_view01 td { font-weight: 400; padding: 10px; } 

.my_title+.table_view02,
.my_subtitle+.table_view02{margin-top: 20px;}
.table_view02{border-top: 1px solid #d9d9d9; display: flex; justify-content: space-between; flex-wrap: wrap;}/*ul로 작성*/
.table_view02 li{border-bottom: 1px solid #d9d9d9; width: 50%; display:flex; align-items: center;}
.table_view02 .title_field{background-color: #fafbfb; padding:20px; font-weight: 500; font-size: 15px; letter-spacing: -0.38px; color:#222; width: 34%; height: 100%; display:inline-flex; align-items: center; word-break: keep-all;}
.table_view02 .info_field{font-size: 15px; letter-spacing: -0.38px; width: 66%; padding:10px 20px;}
.table_view02 li.full{width: 100%;}
.table_view02 li.full .title_field{width: 17%;}
.table_view02 li.full .info_field{width: 83%}
.table_view02 .input_wrap{display: inline-flex; align-items: center;}
.table_view02 .email_field .text,
.table_view02 .hyphen{padding:0 5px;}

/*********
리스트 상단
**********/
.list_top { display: flex; justify-content: space-between; align-items: center; margin-bottom: 12px; } 
/*총게시글수*/
.list_top .total_count { font-size:15px; font-weight:700; color:#000; white-space: nowrap;} 
.list_top .total_count span { color:#3d7df2; } 
/*검색*/
.box+.list_top { padding:12px 0 0; } 
.list_top .search_wrap{display: flex; justify-content: space-between; align-items: center; width: 100%;}
.list_top .wrap{display: flex; justify-content: space-between; align-items: center;}
.list_top .list_search_area { margin-left: auto; display: flex; justify-content: space-between; align-items: center; } 
.list_top .list_search_area select,
.list_top .list_search_area .search,
.list_top .list_search_area input { height: 48px; } 
.list_top select { width: auto; min-width: 110px; margin-right: 10px; } 
.list_top .list_search_area .search { border:1px solid #cfcfcf; /*padding:0 9px 0 8px;*/ display: flex; justify-content: space-between; align-items: center; overflow: hidden; min-width: 260px; margin-left:0} 
.list_top .list_search_area .search input { border:none; padding:0 10px; } 
.list_top .list_search_area .search .btn_search{height: 100%; display: inline-flex; align-items: center; padding:0 10px}
.list_top .list_search_area select { margin-left: 30px; } 
.list_top .list_search_area .btn { min-height: 48px; height: 48px; width: 100%; margin-left: 7px; padding:0 20px; margin-right: 0;} 
.list_top .title_field { font-size:15px; white-space: nowrap; padding:0 10px 0 19px; } 
.list_top .title_field:first-child { padding-left: 0 } 
.list_top .datepicker { width: 130px; margin-right: 5px; } 
.list_top .react-datepicker-wrapper { width: 130px; margin-right: 5px; } 
.list_top .search_wrap .wrap .react-datepicker-wrapper:nth-of-type(2){margin-left:5px; margin-right: 0;}
.list_top .search_wrap .list_search_area select:last-of-type{margin-right:10px}
.list_top .division { margin-left: auto; } 
.list_top select.division{margin-right: 0}

/* 리스트 하단 */
.list_bottom { position: relative; }
.list_bottom .right_button_wrap { position: absolute; right: 0; top: 0; }

/* table 형태 ul */
.table { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.table .tr { width: 100%; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: stretch; font-size: inherit; letter-spacing: inherit; line-height: inherit; }
.table .tr .m_wrap { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.table .tr.col2 .td { width: 50%; }
.table .tr.col3 .td { width: 33.333%; }
.table .tr.col4 .td { width: 25%; }
.table .tr.col5 .td { width: 20%; }
.table .tr .td { width: 100%; }
.table.table_list01 { border: 1px solid #999; font-size: 15px; letter-spacing: -0.38px; line-height: 1.5; }
.table.table_list01 .tr { border-bottom: 1px solid #dedede; }
.table.table_list01 .tr:last-child { border-bottom-width: 0; }
.table.table_list01 .th, 
.table.table_list01 .td { font-size: inherit; letter-spacing: inherit; line-height: inherit; text-align: center; padding: 15px 32px; }
.table.table_list01 .th { color: #666; }
.table.table_list01 .td {color: #000;}
.table.table_view01 { border-top: 1px solid #e2e2e2; }
.table.table_view01 .tr { font-size: inherit; letter-spacing: inherit; border-bottom: 1px solid #e2e2e2;}
.table.table_view01 .th, .table.table_view01 .td { display: flex; align-items: center; color: #222; font-size: inherit; letter-spacing: inherit; min-height: 70px;}
.table.table_view01 .th { padding: 18px; background-color: #fafbfb; font-weight: 500; min-width: 210px; }
.table.table_view01 .td { width: 100%; padding: 10px; text-align: left; }
.table.table_view01 .tr.tr_logo .td img { max-width: 225px;}
@media screen and (max-width: 768px){
    .table.table_view01 .tr.tr_logo .td { flex-direction: column; align-items: flex-start;}
	.table.table_view01 .tr.tr_logo .td .btn { margin-left:0 !important; margin-top: 10px; }
}

/******************
view
*******************/
/*상세팝업*/
.popup .view_area .profile_area { border-top:none; padding-left:0; padding-right:0; padding-top:0; display:flex; overflow:hidden; align-items: center;} 
.popup .view_area .profile_img { width:55px; height: 55px; background: url("img/sub/icon_profile.png") #999999 no-repeat; border-radius: 100px; overflow: hidden; background-position: center; background-size: 100%;  margin-right: 12px; aspect-ratio: 1 / 1; display: inline-flex; align-items: center; justify-content: center;}
.popup .view_area .profile_img img{object-fit: cover; max-width: none; max-height: 100%;}
.popup .view_area .profile_area .id {  font-size:20px; color:#000; } 
.popup .view_area .profile_area .company_name {  font-size:15px; color:#999999; } 
.popup .view_area li { display: flex; border-top:1px solid #dedede; border-bottom:0; padding:17px 5px; } 
.popup .view_area li:last-child { padding-bottom:0 } 
.popup .view_area .title_field { color:#666666; font-size:15px; min-width: 73px; line-height: 25px; } 
.popup .view_area .info_field { color:#000; font-size:15px; line-height: 25px; white-space: pre-line; } 

/* 기본형 */
.board_view{margin-top:50px}
/*.board_view .view{position:relative;}
.board_view .view .top ul{border-top:1px solid #ddd; display: flex; flex-wrap:wrap;}
.board_view .view .top ul:after{content: ''; width: 100%; height: 1px; display: block; background: #ddd; position: absolute; bottom:0;}
.board_view .view li{display: flex; width:50%; border-bottom:1px solid #ddd; box-sizing: border-box;}
.board_view .view li:nth-child(2n){border-left:1px solid #ddd;}
.board_view .view li .field_title,
.board_view .view li .field_info{padding:20px 14px;font-size:16px;}
.board_view .view .field_title{ color:#222; font-weight: 500; min-width: 90px;}
.board_view .view li .field_info{word-break: break-all;}
.board_view .view li.col2{width:50%;}
.board_view .view li.line{border-left:1px solid #ddd;}

.board_view .view + .top .field_title{margin-top: 50px;}
*/
.board_view .top{padding:25px 50px;}
.board_view .top .field_title{font-size:28px; font-weight: bold; margin-bottom: 8px; color:#000; letter-spacing: -0.05em;}
.board_view .top .field_info{display: flex;  justify-content: flex-start; flex-wrap: wrap; }
.board_view .top .field_info p{color:#888; font-size:15px;white-space: nowrap;}
.board_view .top .field_info p:after{content: ''; width:1px; height: 10px; background: #d1d1d1; display: inline-block; margin:0 8px;}
.board_view .top .field_info p:last-child:after{display: none;}

.board_view .view .content{border-top:1px solid #999; padding:25px 20px !important; margin: 0 30px; font-size:15px; color:#000; white-space: pre-wrap; line-height: 180%;}

.board_view .comment{border-top:1px solid #ddd; padding:20px 0 50px; margin: 0 30px;}
.board_view .comment .write_area,
.board_view .comment .comment_area .edit_talk_reply {display: flex; justify-content: space-between; align-items: center; margin-bottom: 20px;}
.board_view .comment .write_area textarea,
.board_view .comment .comment_area .edit_talk_reply textarea {height:95px;}
.board_view .comment .write_area .button,
.board_view .comment .comment_area .button {color:#fff; background: #3d7df2; min-width: 130px;  height:95px; line-height: 95px; text-align: center; font-size:15px;}
.board_view .comment .comment_area .edit_talk_reply.hide {display:none}

.board_view .comment .comment_area .item{padding:26px 0; border-top:1px solid #ddd;font-weight:500;}
.board_view .comment .comment_area .item .info_area{display: flex; align-items: center; margin-bottom: 10px;}
.board_view .comment .comment_area .item .info_area .field_profile{width:39px; height: 39px; overflow: hidden; border-radius: 100%; margin-right: 20px;}
.board_view .comment .comment_area .item .info_area .field_writer{color:#000; font-size:16px; margin-right: 10px;}
.board_view .comment .comment_area .item .info_area .field_date{color:#a4a4a4; font-size:14px;}
.board_view .comment .comment_area .item{padding-left:10px;}
.board_view .comment .comment_area .item .field_btn{margin-left: auto;}
.board_view .comment .comment_area .item .field_btn .btn{font-size:15px; color:#333333; border:1px solid #cccccc; background:#fff; min-height: 31px; height: 31px; vertical-align: middle;}
.board_view .comment .comment_area .item .field_btn .btn+ .btn{margin-top: 0; margin-left: 0;}
.board_view .comment .content{padding-left:59px !important; font-size:15px; color:#000; line-height: 150%;}
.board_view .comment_area .paging{border-top:1px solid #ddd; padding-top: 20px; margin-top: 0}

.board_view .view_list ul{border-top:1px solid #ddd; padding:20px 40px; display: flex; justify-content: space-between; width: 100%;}
.board_view .view_list .field_icon{color:#888; min-width: 125px;}
.board_view .view_list .field_icon img{margin-right:16px;}
.board_view .view_list .field_date{margin-left: auto; color:#888; min-width: 125px; text-align: right;}
.board_view .view_list .field_title{font-weight: 500; overflow: hidden;}
.board_view .view_list .field_title a{overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:100%}


/***********
write
************/
/*기본형*/
.board_write .sub_content_title{margin-top:30px; margin-bottom: 18px;}
.board_write .write .section{display: flex; border-bottom:1px solid #e6e6e6; width: 100%;}
.board_write .write .section:last-of-type{border:none;}
.board_write .write .title_field{padding:37px 30px; font-size:15px; color:#000; min-width:130px; width:130px;}
.board_write .write .input_field{padding:20px 30px; width: 100%;}
.board_write .write .input_field input,
.board_write .write .input_field textarea{width: 100%;}
.board_write .write .input_field textarea{height: 480px;}
.board_write .write .input_field .email_field input[type=text]:first-child{max-width: 300px;}
.board_write .write .input_field .email_field input[type=text]:last-of-type{max-width: 150px;}
.board_write .write .input_field .email_field select{max-width: 150px}
.board_write .write .input_wrap{display: flex;}
.board_write .write .input_field select{min-height: 48px; border-radius: 0; border:1px solid #cfcfcf}

@media screen and (max-width:1260px) {
    .list_top .list_search_area .search{min-width:100px; width: 100%;}
    .list_top .list_search_area .btn{width: 100px; white-space: nowrap; font-size:15px; }
    _:-ms-fullscreen, :root .list_top .list_search_area .btn{min-width: 110px;}

} 
@media screen and (max-width:1024px){
    /*list*모바일디자인 적용*/
    .table_list01 colgroup { display:none; }
    .table_list01 .field_tit { display: inline-block; color:#666;} 
    .table_list01 thead { display: none; } 
    .table_list01 tr { display:block; padding:18px 15px; position: relative;} 
    .table_list01 tr:not(:last-of-type){ border-bottom:1px solid #dedede;}
    .table_list01 td { display: inline-block; border:none; height: 22px; white-space: nowrap; width: auto;} 
    .table_list01 .m_th{display: inline-block;}
    .table_list01 .td_num { display: none; } 
    .table_list01 td.td_title { display: flex; margin-bottom: 5px; align-items: center; padding-left: 0;  font-size:15px;} 
    .table_list01 td.td_title.with_btn{width: calc(100% - 70px);}
    .table_list01 td.td_title.with_btn.two_btn{width: calc(100% - 104px);}
    .table_my_talk_list.table_list01 td.td_title.with_btn.two_btn{display: block;}
    .table_list01 td.td_title a{font-size:15px;}
    .table_list01 td.td_title .table_notice_badge { display:inline-block; margin-right: 5px; } 
    .table_list01 td.td_title .wrap { width:calc(100% - 60px) } 
    .table_list01 td { font-size:13px; color:#000; } 
    .table_list01 td .field_tit { margin-right: 5px; } 
    .table_list01 .td_info:after { content: ''; width: 1px; height: 10px; background: #ddd; display: inline-block; margin: 0 10px; } 
    .table_list01 .td_info.last:after { display: none; } 
    .table_list01.with_chk tr { padding-left:calc(4% + 25px); position: relative; } 
    .table_list01.with_chk .td_chk { position: absolute; left:30px; top:15px; width: 20px !important;} 
    .table_list01.with_chk tr.notice_area { padding-left: 30px } 
    
    .table_list01 td.td_button{position: absolute; top:13px; right:30px}
    .table_list01 td .button,
    .table_list01 td.td_button .state1,
    .table_list01 td.td_button .state2{border:1px solid #3d7df2; font-size:13px; min-height: 26px; height: 26px; display: inline-flex; justify-content: center; align-items: center; padding:0 7px;}
    .table_list01 td .button.off,
    .table_list01 td.td_button .state1{border-color:#333333;}
    .table_list01 td.td_button .state2{border-color:#cc3333;}
    .table_list01 td.td_state{position: absolute; top:16px; right: 4%; font-size:15px;}
    
    .list_top{/*padding-left:30px !important; padding-right: 30px !important;*/ flex-direction: column; align-items: flex-start;}
    .list_top .search_wrap{order:1;}
    .list_top .total_count{order:2; padding-top: 20px;}
  /*search*모바일 디자인 적용*/
    .list_top .search_wrap{display: block; width: 100%;}
    .list_top .search_wrap .wrap{margin-bottom: 10px;}
    .list_top select{margin-right: 0}
    .list_top .react-datepicker-wrapper,
    .list_top .search_wrap .style_select,
    .list_top .search_wrap input[type=text]{width: 100%;}
    .list_top .datepicker:last-of-type{margin-right: 0}
    .list_top .list_search_area {display: flex; justify-content: space-between;}
    .list_top .list_search_area .style_select{width:80px; min-width: 80px; margin-right: 10px; margin-left: 0}
    .list_top .list_search_area .btn{font-size:15px;}
    .list_top .title_field{width: 90px;min-width: 90px;}
    
    .table_view02 li{width: 100%;}/*모바일 레이아웃 적용*/
    .table_view02 .title_field{width:110px !important}
    .table_view02 .info_field{width: calc(100% - 110px) !important;}
 }
@media screen and (max-width:768px) { /*모바일 디자인 적용*/
    /*********
    리스트 상단
    **********/
    .box+.list_top{border-top:1px solid #dedede;}
    .list_top { align-items: flex-end; padding-top:18px; margin-bottom: 18px; padding-left:4% !important; padding-right: 4% !important;} 
    .list_top .title_field{font-size:13px;}
    .list_top .total_count { order:3; width: 100%; padding-top: 18px; font-size:12px; } 
    .list_top .list_search_area select,
    .list_top .list_search_area .search,
    .list_top .list_search_area input,
    .list_top .btn { min-height: 40px; height: 40px; } 
    .list_top .list_search_area select { width: auto; min-width: 80px; } 
    .list_top .list_search_area .search { width: 100%; } 
    .list_top .list_search_area .btn { margin-left: 8px; min-width: 84px; width: 84px; min-height:40px; height: 40px; font-size:13px; } 
    /*리스트*/
    .table_notice_badge { line-height: 17px; margin-right: 5px; font-size:10px; padding:0 9px; } 
    .table_list01 { border-width: 1px 0 0 0 ; border-top-color:#dedede}
    .table_list01 tr{padding:18px 4%;  border-bottom:1px solid #dedede;}
    .table_list01 td {height: auto;}
    .table_list01 td.td_title a{font-size:13px;} 
    .table_list01 td .td_button,
    .table_list01 td { font-size:12px;} 
    .table_list01 td.td_title .re{font-size:inherit;}
    .table_list01 td.td_title .icon_new{min-width: 10px; width: 10px; height: 10px;}
    .table_list01 td .button, 
    .table_list01 td.td_button .state1, 
    .table_list01 td.td_button .state2,
    .table_list01 td.td_state{font-size:12px;}
    .table_list01 td.td_button{right:4%;}
    .table_list01 .td_button .btn{font-size:12px; min-height: 26px; height: 26px; line-height: 26px;}
    .table_list01 td.td_money p{font-size:13px;}
    .table_list01 td.td_money .state1, 
    .table_list01 td.td_money .state2, 
    .table_list01 td.td_money .state3{font-size:14.5px;}
    .table_list01.with_chk tr.notice_area{padding-left: 4%;}
    .table_list01.with_chk .td_chk{left:4%; width: 16px !important}
    
    .table_list01 .style_select{min-height: 30px; width:auto;}

    .view_top{display: block;}
    .view_top .tip_field{margin-top: 5px; font-size:13px;}
    /*****************
    view table디자인
    ******************/
    .table_view01+.view_top, .table_view02+.view_top{margin-top: 22px;}
    .my_title+.table_view02{margin-top: 11px;}
    .table_view02 .title_field,
    .table_view02 .info_field{font-size:13px; padding:20px 18px}
    
    /******************
    view
    *******************/
    /*상세팝업*/
    .popup .view_area .title_field,
    .popup .view_area .info_field,
    .popup .view_area .profile_area .id,
    .popup .view_area .profile_area .company_name{font-size:13px;}
    .popup .view_area .info_field{line-height: 20px;}

    .table.table_list01 { border: 0; }
    .table.table_list01 .th { display: none; }
    .table.table_list01 .td { border-bottom: 0; padding: 0; }    
    /*기본*/
    .board_view{padding:0; margin-top: 0;}
    .board_view .top{padding:20px 4%;}
    .board_view .top .field_title{font-size:16px;}
    .board_view .top .field_info p{font-size:12px;}
    
    .board_view .view .content{margin: 0; padding:18px 4%; border-color:#dedede; font-size:13px;}
    
    .board_view .comment{margin: 0; padding:18px 4%;}
    
    .board_view .comment .comment_area .edit_talk_reply textarea, 
    .board_view .comment .write_area textarea{height: 70px;}
    
    .board_view .comment .comment_area .button,
    .board_view .comment .write_area .button{margin-left: 5px; font-size:13px; min-width: 80px; height: 70px; line-height: 70px; letter-spacing: -0.03em;}
    
    .board_view .comment .comment_area .item{padding:13px 0;}
    .board_view .comment .comment_area .item .info_area .field_profile{width: 28px; min-width: 28px; height: 28px; margin-right: 10px;}
    .board_view .comment .comment_area .item .info_area{padding-left: 5px; margin-bottom: 5px;}
    .board_view .comment .comment_area .item .info_area .field_writer,
    .board_view .comment .comment_area .item .info_area .field_date{font-size:12px;}
    .board_view .comment .comment_area .item .info_area .field_date{white-space:nowrap; margin-right: 5px;}
    .board_view .comment .content{font-size:13px; padding:5px 10px 5px 42px !important;}
    .board_view .comment .comment_area .item .field_btn .btn{font-size:11px; min-width: 38px;}
    .board_view .btn_bottom_area{padding-left:4%; padding-right: 4%;}
    
    /*********
    write
    **********/
    /*기본*/
    .board_write .sub_content_title{margin-top:24px; margin-bottom: 0; border-bottom:1px solid #dedede; padding:0 4% 18px}
    .board_write{padding:0;}
    .board_write .write .section{display: block; padding:0 4%; border-bottom:none;}
    .board_write .write .title_field{padding:20px 0 10px 0; font-size:13px;}
    .board_write .write .input_field{padding:0;}
    .board_write .btn_bottom_area{padding:0 4%; margin-top: 25px;}
 }
@media screen and (max-width:640px) {
    /*********
    리스트 상단
    **********/
    /*검색*/
    .list_top .list_search_area .btn{min-width: inherit; width:inherit; white-space: nowrap; padding:0 10px;}
    .list_top .list_search_area .search{min-width: 90px;}
    
}
@media screen and (max-width:450px){
    .board_write .write .input_field .email_field input[type=text]:first-child,
    .board_write .write .input_field .email_field input[type=text]:last-of-type{max-width: none;}
    .board_write .write .input_field .email_field select{max-width:none; width: 100% !important;}
}
@media screen and (max-width:375px){
    .list_top .title_field{width:70px; min-width: 70px;}
    .list_top .list_search_area .style_select{width:60px; min-width: 60px;}
    /* .list_top .list_search_area .btn{display: none;} 22-11-11 ios에서 조회 버튼 안 보이는 현상 이거 때문! */
    .board_view .comment .comment_area .item .field_btn .btn{font-size:10px;}
    .board_view .comment .comment_area .item .info_area{padding-left: 0;}
    .board_view .comment .comment_area .item .info_area .field_writerk,
    .board_view .comment .comment_area .item .info_area .field_date{font-size:11px;}
    .board_view .comment .content{font-size:13px; padding-left:38px !important;}
    .table_view01 .btn{font-size:11px !important}
}

.page_my_faq { margin-top: 30px; }
.page_notice, .page_notice_view.board_view { margin-top: 30px; }

/** 회색 **/
.gray { color: 	#808080 !important;}

.td_default { cursor: default;}
.td_pointer { cursor: pointer;}