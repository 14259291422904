.write-list,
.write-list2 {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.write-list tr,
.write-list2 tr {
  min-height: 50px;
  height: 50px;
  border-bottom: 1px solid #eee;
}
.write-list tr:last-child,
.write-list2 tr:last-child {
  border-bottom: none;
  vertical-align: top;
}
.write-list tr th,
.write-list2 tr th {
  width: 30%;
  padding: 0px 0px 0px 50px;
  text-align: left;
}
.write-list2 tr th {
  width: 20%;
}
.write-list tr td,
.write-list2 tr td {
  width: 70%;
  padding: 0px;
  text-align: left;
}
.write-list2 tr td {
  width: 80%;
}
.write-list .write-input,
.write-list2 .write-input {
  width: 100%;
}
.write-list .write-input input {
  width: 70%;
  min-height: 40px;
}
.write-list2 .write-input input {
  width: 98%;
  min-height: 40px;
}
.write-list .write-date-readonly input {
  pointer-events: none;
  background-color: #eee;
  width: 50%;
}
.write-list .write-radio {
  width: 100%;
  text-align: left;
}
.write-list .write-textarea {
  height: 200px;
  width: 100%;
}
.write-list .write-textarea textarea {
  height: 100%;
  width: 95%;
  border: 1px solid #eee;
  padding: 5px;
}
.write-list2 select {
  width: 200px;
}

.chkf-delete {
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.rad-button {
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.board-list input[type="checkbox"] {
  display: none;
}
.board-list input[type="checkbox"] + label {
  cursor: pointer;
  height: 24px;
  margin-left: 10px;
  padding-left: 24px;
  background: url("../img/check-off.png") left/24px no-repeat;
  background-size: contain;
}
.board-list input[type="checkbox"]:checked + label {
  background-image: url("../img/check-on.png");
}

.write-list .write-radio input[type="radio"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  background: url("../img/check-off.png") left/18px no-repeat;
}
.write-list .write-radio input[type="radio"]:checked {
  background-image: url("../img/check-on.png");
}

.write-list .write-input input.disabled {
  pointer-events: none;
  background-color: #ccc;
}

.write .write-radio input[type="radio"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  vertical-align: text-top;
  background: url("../img/check-off.png") left/18px no-repeat;
}
.write .write-radio input[type="radio"]:checked {
  background-image: url("../img/check-on.png");
}

.write button {
  padding: 10px 20px;
  margin-left: 10px;
}

.board_view .main-box .comment {
  border-top: 1px solid #ddd;
  padding: 20px 0 20px;
  margin: 0 30px;
}

.board_view {
  margin-bottom: 50px;
}

/*dash borad*/

.p_dashboard .dashboard{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.p_dashboard .dashboard .main-box{
  width:calc((100% - 100px) / 6);
  margin-left:20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.p_dashboard .dashboard .main-box .tit_field{
  font-size:15px;
  color:#666;
  border-bottom:1px solid #f4f4f4;
  display: block;
  width: 100%;
  padding:10px;
  text-align: center;
  min-height: 60px;
}
.p_dashboard .dashboard .main-box .num_field{
  font-size:30px;
  font-weight: bold;
  color: #000;
  padding-block: 20px;
}
.p_dashboard .dashboard .main-box:nth-child(6n+1){
  margin-left:0;
}