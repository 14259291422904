@charset "utf-8";
/*헤드헌터 전용관 상세*/
.myheadhunter .myrecruit_view_top{position: relative; margin-bottom: 30px;}
.myheadhunter .myrecruit_view_top:before{content: ''; background: #3d7df2; position: absolute; z-index: -1; top:-48px;left:calc(-50vw + 630px); bottom:-33px; right:calc(-50vw + 630px); box-sizing: border-box;}
.myheadhunter .myrecruit_view_top .left_wrap .title_field,
.myheadhunter .myrecruit_view_top .left_wrap ul li,
.myheadhunter .myrecruit_view_top .right_wrap li .title_field,
.myheadhunter .myrecruit_view_top .right_wrap .icon_wrap b,
.myheadhunter .myrecruit_view_top .right_wrap .title_field a {color:#fff !important;}
.myheadhunter .myrecruit_view_top .right_wrap li:last-of-type{border-left-color:rgba(255,255,255,.1);}
.myheadhunter .myrecruit_view_top .left_wrap li:not(:last-of-type){border-right-color:rgba(255,255,255,.1)}
.myheadhunter .myrecruit_view_top .left_wrap li .table_notice_badge {color: #fff; padding: 0 8px; line-height: 28px; border: 1px solid #fff; border-radius: 100px;}
@media screen and (max-width:1260px){
    body{overflow-x: visible;}
    .myheadhunter .myrecruit_view_top:before{left:-30px; right: -30px;}
}
@media screen and (max-width: 1024px){
    .myheadhunter .myrecruit_view_top:before{top: -20px; bottom:-20px;}
    .myheadhunter .myrecruit_view_top{margin-bottom: 20px;}
}
@media screen and (max-width:768px){
    .myheadhunter .myrecruit_view_top:before{left:-4%; right: -4%;}
}