@charset "utf-8";
.com_message .page_message .box{margin-top:20px}
.page_message .box{display: flex; padding:0; height: 90vh;}
.page_message .icon_new{font-family: arial; color:#fff; font-weight: 400; font-size:10px; border-radius: 100px; background: #3d7df2; width:14px; height: 14px; display: inline-block; margin-left: 5px; text-align: center; line-height: 15px; vertical-align: 3px; font-weight: bold;}
.page_message .chat_list_area{width:30%; border-right: 1px solid #d4d4d4;}
.page_message .chat_list_area .search_area{height: 69px; border-bottom: 1px solid #d4d4d4; padding:0 14px 0 19px; display: flex; justify-content: space-between; align-items: center;}
.page_message .chat_list_area .search_area .button{min-width: 30px;}
.page_message .chat_list_area .search_area input[type=text]{border:none; font-size:15px; width:80%; padding:0;}

.page_message .chat_list_area .chat_list{overflow-y: auto; height: calc(100% - 69px); border-bottom-left-radius: 30px;}
.page_message .chat_list_area .chat_list .item{border-bottom:1px solid #d4d4d4; padding:18px 20px; cursor: pointer;}
.page_message .chat_list_area .chat_list .date{color:#b3b9c4; font-size:13px; float: right; padding-top:2px; font-weight: 400;}
.page_message .chat_list_area .chat_list .name{color:#333; font-size:15px; font-weight: bold; float: left;}
/*.page_message .chat_list_area .chat_list .item .company{color:#b3b9c4; font-size:13px;}*/
.page_message .chat_list_area .chat_list .preveiw{color:#666; font-size:15px; padding-top: 8px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; clear: both;}

.page_message .chat_area{width: 70%; display: flex; flex-direction: column;}

.chat_area .top{height:69px; border-bottom:1px solid #d4d4d4; padding:20px; display: flex; align-items: center;}
.chat_area .top .img{border-radius: 100px; overflow: hidden; height: 50px; width: 50px; margin-right: 15px; min-width: 50px;}
.chat_area .top .nickname{font-weight: bold; color:#333; font-size:20px;}
.chat_area .top .company{color:#b3b9c4; font-size:13px; margin-left: 15px; font-weight: 400;}
.chat_area .top .offerer_info{overflow: hidden;}
.chat_area .top .recruit_title{white-space: nowrap; overflow: hidden; text-overflow: ellipsis; color:#666; font-size:15px; color:#666;}
.chat_area .content{height:calc(100% - 128px); display: flex; flex-direction: column; width: 100%; padding:0 !important}
.chat_area .top .btn{margin-left: auto; font-size:15px; height: 30px; min-height: 30px; padding:0 15px}

.chat_area .wrap{padding:29px 20px;  box-sizing:border-box; overflow-y: auto; width: 100%}
.chat_area .field_button{display:flex;}
.chat_area .button{border-radius:100px; height:35px; line-height:35px;}
.chat_area .field_view{width: 100%;}
.chat_area .field_view .message{padding:10px 20px; font-size:16px; color:#222; width:auto;}
.chat_area .field_view .field_date{color:#b9b9b9; font-size:12px; margin-top:7px;}
.chat_area .field_view .get{max-width: 40%;}
.chat_area .field_view .get{align-items:flex-start; display: flex;}
.chat_area .field_view .get .info_area{display: flex; flex-direction: column; align-items: flex-start; width:calc(100% - 48px)}
.chat_area .field_view .get .profile_img_area{min-width: 48px;}
.chat_area .field_view .get .profile_img{border-radius: 100px; overflow: hidden; height:40px; width: 40px;}
.chat_area .field_view .get .message{background:#f0f3f5; border-radius:20px 20px 20px 0; word-break: break-all;}
body{word-wrap: break-word}

.chat_area .field_view .get+.get,
.chat_area .field_view .send+.send,
.chat_area .field_view .message+.img,
.chat_area .field_view .img+.img,
.chat_area .field_view .get+.send,
.chat_area .field_view .send+.get{margin-top:20px;}
.chat_area .field_view .img{ overflow:hidden;}
.chat_area .field_view .img img{width:100%;border-radius:10px;}

.chat_area .field_view .send{align-items:flex-end; display:flex; flex-direction:column;}
.chat_area .field_view .send .message{background:#dde9f6; border-radius:20px 20px 0 20px; max-width: 40%;}
.chat_area .field_view .send  .img{max-width: 40%;}


.chat_area .field_write{border-top:1px solid  #ddd; display:flex; align-items:center; padding:0 20px; height: 59px;}
.chat_area .field_write input[type=text]{width:100%; border:none;}
.chat_area .field_write .btn{border-radius: 20px; width:57px; min-height: 35px; height: 35px; padding:0; font-size:15px;}

/*높이 800이하*/
@media screen and (max-height:800px){
    .chat_area .wrap{height: 88%;}
    .chat_area .field_write{height: 12%;}
}
/*높이 700이하*/
@media screen and (max-height:700px){
    .chat_area .top,
    .page_message .chat_list_area .search_area{height: 15%;}
    .page_message .chat_list_area .chat_list,
    .chat_area .content{height: 85%;}
}
@media screen and (max-width:1000px){
    .page_message .box{display: block; height: inherit;}
    .page_message .chat_list_area{width: 100%; border-right: none;}
    .page_message .chat_list_area .search_area{height: 70px;}
    .page_message .chat_list_area .chat_list{max-height: 242px; height: inherit; border-bottom-left-radius: 0;}
    
    .page_message .chat_area{width: 100%; border-top:2px solid #000;}
    .chat_area .wrap{ max-height: 80vh;}
    .chat_area .field_write{height: 60px}
}
@media screen and (max-width:768px){
    .page_message .chat_list_area .search_area{height: 50px; padding:0 15px;}
    .page_message .chat_list_area .search_area input[type=text]{font-size:14px;}
    .page_message .chat_list_area .chat_list .item{padding:15px;}
    .page_message .chat_list_area .chat_list .item .name{font-size:14px;}
    .page_message .chat_list_area .chat_list .item .company,
    .page_message .chat_list_area .chat_list .item .date{font-size:11px;}
    .page_message .chat_list_area .chat_list .item .preveiw{font-size:12px;}
    .page_message .chat_list_area .chat_list{max-height: 210px;}
    .chat_area .top .nickname{font-size:14px;}
    .chat_area .top .company{font-size:11px;}
    .chat_area .top .recruit_title{font-size:12px;}
    .chat_area .field_view .message{font-size:13px;}
    .chat_area .field_view .get,
    .chat_area .field_view .send{margin-bottom: 18px;}
    .chat_area .field_view .get,
    .chat_area .field_view .send .message{max-width: 80%;}
    .chat_area .field_view .field_date{font-size:10px;}
    .chat_area .field_write .btn{font-size:13px;}
}

    
