@charset "utf-8";
/*로고 및 상단 링크*/
#ribbon{display: flex; justify-content: space-between; align-items: center; margin:22px auto 35px; width:1240px;}
#ribbon .link{font-size:15px;}
#ribbon .link a { font-size: inherit; }
#ribbon .link:before{content: '';width: 1px; height: 14px; background-color: #d3d3d3; display: inline-block; margin:2px 16px 0;}
/*#ribbon .link.login{margin-left: auto;}
#ribbon .link.login:before{display: none;}
#ribbon .icon_notice + .link.login{margin-left: 20px;}*/
#ribbon .logo{margin-right: auto;}
#ribbon .logo+.login:before{display: none;}
#ribbon .id_field{font-size:15px;}

#ribbon .button{font-size: 15px;color: #03329d;height: 31px; margin-left:23px; padding: 0 16px;  border-radius: 15px; border: solid 1px #10a1fb; display:inline-flex; justify-content: center; align-items: center; text-align: center;}
#ribbon .btn_my,
#ribbon .btn_bar{display: none;}

#ribbon .icon_notice{position: relative; margin-right: 20px;}
#ribbon .icon_notice .icon{display:inline-flex; background:#ececec; border-radius: 100px; width: 33px; height: 33px; justify-content: center; align-items: center;}
#ribbon .icon_notice .num{display:block; min-width:18px; padding:0 5px; height:18px ; border-radius: 100px; background:#3d7df2; position: absolute; right:-9px; top:-1px; color:#fff; font-size:10px; text-align: center; line-height: 18px;}

/*gnb*/
#head{position: relative;}
#head .center_wrap{display: flex; justify-content: space-between; align-items: flex-start; position: relative; width: 1240px;}
#head:after{content: ''; background:#3d7df2 ; width:100%; height: 50px; display: block; position: absolute; bottom:0; z-index: -1;}

/*상단 검색*/
#head .search_area{border:none; border-bottom:1px solid #0f0f0f; margin-left: 80px; margin-bottom: 15px; margin-top: -15px; width: 307px; min-width: 307px; display: flex; justify-content: space-between; align-items: center;}
#head .search_area input[type=text]{font-size:15px; border:none; background: none;}
#head .search_area input[type=text]::-webkit-input-placeholder,
#head .search_area input[type=text]:-ms-input-placeholder,
#head .search_area input[type=text]::placeholder,
#head .search_area input[type=text]:-ms-input-placeholder{color:#fff;}
#head .search_area input[type=text]:focus{background: none; outline: none;}
#head .search_area select{border:none; font-size:15px; background: none; width: auto;}
#head .search_area .button{min-width: 23px; width:23px; height:23px; background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 27'%3E%3Cpath stroke='%230F0F0F' stroke-width='2' fill='none' d='M11.5 1.999c5.246 0 9.5 4.254 9.5 9.5C21 16.746 16.746 21 11.5 21 6.253 21 2 16.746 2 11.499a9.5 9.5 0 0 1 9.5-9.5ZM23.692 23.958l-5.384-5.917'/%3E%3C/svg%3E"); background-size:100%; background-repeat:no-repeat; background-position: center; }

/*메뉴(gnb)*/
#head .nav{width: 100%;}
#head .nav{display: flex; justify-content: space-between; align-items: center; white-space: nowrap;}
#head .nav .depth1{padding-bottom: 72px;}
#head .nav .depth1:not(:last-child){ padding-right: 50px;}
#head .nav .depth1 > .menu{font-size: 20px; font-weight: bold;  letter-spacing: -1px; color:#000;}
#head .nav .depth1.my .depth2{left: 0;}
.company_body #head .nav .depth1.my .depth2{right: inherit;}
#head .nav .depth1:hover .menu{color:#3d7df2;}
#head .nav .depth1:hover .depth2{display:block;}
#head .nav .depth2{display: none; position: absolute;}

/*삼선메뉴*/
#head  .mobile_content{display: none;}

@media screen and (min-width:1025px){/*너비 1025 이상*/
    /*gnb*/
    #head .nav .depth1.my .menu:before{content:''; display:inline-block; width: 19px; height: 21px; background:url("../img/common/gnb_myheding.png") no-repeat; margin-right: 3px; background-size: contain;}
    #head .nav .depth1.active .menu{color:#3d7df2;}
    #head .nav .depth1:hover .depth2,
    #head .nav .depth1.active .depth2{display:flex;}
    #head .nav .depth1.my:hover .menu:before{background-image:url("../img/common/gnb_myheding_on.png") }
    #head .nav .depth1:hover .depth2{z-index: 10}
    #head .nav .depth1:hover{z-index: 9;}
    #head .nav .depth1.active{z-index: 8;}
    #head .nav .depth1:hover:after{content: ''; height: 50px; width: 100%; background: #3d7df2; display: block; position: absolute; bottom:0; left:0; right: 0;}
    #head .nav .depth2{ bottom:0; height: 50px; flex-wrap: wrap; align-items: center; justify-content: flex-start}
    #head .nav .depth2 li{float: left;}
    #head .nav .depth2 li a{border-bottom:2px solid #3d7df2; padding-bottom: 2px;}
    #head .nav .depth2 li.active a{border-bottom-color:#fff;}
    #head .nav .depth2 a{font-size:15px; color:#fff; margin-right: 32px;}
    #head .nav .depth2 li:last-of-type a { margin-right:10px; }
    #head .nav .depth1.my .point{color:#3d7df2; padding-left:5px;}
    
    /********
    메인
    *********/
    #main_wrap #head:after{ display: none; bottom:-50px;  z-index: 2; box-shadow: 0px 5px 5px rgba(0,0,0,.2)}
    #main_wrap #head.on:after{display: block; }
    #main_wrap #head .nav .depth1{padding-bottom: 23px}
    #main_wrap #head .nav .depth2{bottom:-50px;}
    #main_wrap #head .nav .depth1:hover:after{bottom:-50px;}
    #main_wrap{position: relative; z-index: 1;}
    #main_wrap #head .nav .depth1.active .depth2{display: none;}
    #main_wrap #head .nav .depth1.active:hover .depth2{display: flex;}

    /*********
    헤드헌터
    **********/
    .head_hunter_body  #head .nav .depth1:hover:after{background: #193b65;}
    .head_hunter_body  #head .nav .depth2 li a{border-bottom:2px solid #193b65;}
    .head_hunter_body  #head .nav .depth2 li.active a{border-bottom-color:#fff;}
    .head_hunter_body #head:after{content: ''; background:#193b65 ; width:100%; height: 50px; display: block; position: absolute; bottom:0; z-index: -1;}
    
    /*헤드헌터 전용관 상세*/
    .headhunter_view{overflow-x: hidden;}
    .headhunter_view #head:after{ display: none; bottom:-50px;  z-index: 2; box-shadow: 0px 5px 5px rgba(0,0,0,.2)}
    .headhunter_view #head.on:after{display: block; }
    .headhunter_view #head .nav .depth1{padding-bottom: 23px}
    .headhunter_view #head .nav .depth2{bottom:-50px;}
    .headhunter_view #head .nav .depth1:hover:after{bottom:-50px;}
    .headhunter_view{position: relative; z-index: 1;}
    .headhunter_view #head .nav .depth1.active .depth2{display: none;}
    .headhunter_view #head .nav .depth1.active:hover .depth2{display: flex;}
    
    /*기업>이용권 구매*/
    .no_depth_body,
    .company_main_body,
    .voucher_body{position: relative; z-index: 1;}
    .no_depth_body #head,
    .company_main_body #head,
    .voucher_body #head{border-bottom:1px solid #3d7df2;}
    .no_depth_body #head:after,
    .company_main_body #head:after,
    .voucher_body #head:after{display: none; bottom:-50px;  z-index: 2;}
    .no_depth_body #head.on:after,
    .company_main_body #head.on:after,
    .voucher_body #head.on:after{ display: block;}
    .no_depth_body #head .nav .depth1,
    .company_main_body #head .nav .depth1,
    .voucher_body #head .nav .depth1{ padding-bottom: 23px;}
    .no_depth_body #head .nav .depth1:hover:after,
    .company_main_body #head .nav .depth1:hover:after,
    .voucher_body #head .nav .depth1:hover:after{bottom:-50px;}
    .no_depth_body #head .nav .depth1.active .depth2,
    .company_main_body #head .nav .depth1.active .depth2,
    .voucher_body #head .nav .depth1.active .depth2{display: none;}
    .no_depth_body #head .nav .depth1.active:hover .depth2,
    .company_main_body #head .nav .depth1.active:hover .depth2,
    .voucher_body #head .nav .depth1.active:hover .depth2{display: flex;}
    .no_depth_body #head .nav .depth2,
    .company_main_body #head .nav .depth2,
    .voucher_body #head .nav .depth2{bottom:-50px;}
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1) {
    #head .nav .depth1.my .menu:before { background-image:url("../img/common/gnb_myheding@2x.png"); }
    #head .nav .depth1.my:hover .menu:before { background-image:url("../img/common/gnb_myheding_on@2x.png"); }
}
@media screen and (max-width:1260px){
    #ribbon{width: 100%;}
    /*상단 검색*/
    #head .search_area{margin-left: 15px;width:279px; min-width: 279px;}
    
    /*gnb*/
    #head .nav .depth1:not(:last-child){padding-right: 20px;}
    #head .nav .depth1 > .menu{font-size:18px;}
    #head .center_wrap{width: 100%;}
    #head .nav .depth2 a { margin-right:13px; }
    #head .nav .depth2 li:last-of-type a { margin-right:4px; }
    #head .nav .depth1.my .depth2 { left: 30px; }
}

@media screen and (min-width:1025px){/*1025이상*/
    #head .nav_wrap{display: block !important;}
    #head .nav_wrap .nav{ height: auto !important;}
}
@media screen and (max-width:1024px){/*모바일 레이아웃 적용*/
    
    
    /*로고 및 상단 링크*/
    #ribbon .btn_my,
    #ribbon .btn_bar{display: inline-block;}
    #ribbon .icon_notice+.btn_my{margin-left: 0;}
    #ribbon .id_field,
    #ribbon .link{display: none;}
    #ribbon .button,
    #ribbon .btn_bar{margin-left: 10px;}
    #ribbon .icon_notice{margin-right: 10px;}
    
    #head{border-bottom:5px solid #f4f6f8;}
    /*상단 검색*/
    #head .search_area{width: 100%; border: 1px solid #0f0f0f; margin-left: 0; min-width: inherit;}
    #head .search_area select{display: none;}
    #head .search_area .button{margin-right: 20px;}
    
    /*삼선메뉴*/
    #head .nav_wrap{display:none;}
               
    #head .nav .depth1{padding-right: 0 !important;}

    #head .mobile_content{display: block;}
    #head .nav_wrap{background: #fff; position: fixed; padding:0; left:0; top:0; width: 100vw; height: 100vh; z-index: 999; }
    #head .nav_wrap .mobile_content{position: relative; background: #fff; z-index: 999; padding-bottom: 25px;}
    #head .nav_wrap .profile_area,
    #head .nav_wrap .top_area,
    #head .nav_wrap .member_area,
    #head .nav_wrap .btn_area{padding-left:20px; padding-right: 20px;}
    #head .nav_wrap .profile_area{display: flex; justify-content: space-between; align-items: center;}
    #head .nav_wrap .profile_area .button{margin-left: auto; border:1px solid #ccc; border-radius: 100px; min-height:35px ; height:35px; display: inline-flex; align-items: center; justify-content: center; padding:0 15px; font-size:15px;}
    #head .nav_wrap .profile_area .profile_img{width: 35px; height: 35px; border-radius:100%; background: url("../img/sub/icon_profile.png") no-repeat #8f98a4; background-size: cover;}
    #head .nav_wrap .profile_area .id_field{margin-left: 10px; font-size:18px; font-weight: 500;}
    #head .nav_wrap .top_area{display:flex; justify-content: space-between; padding-top:15px; padding-bottom:25px; align-items: center;}
    #head .nav_wrap .top_area .title_field{margin-right: auto; color:#333; font-size:15px;}
    #head .nav_wrap .top_area .button{margin-left:15px; font-size:16px;}
    #head .nav_wrap .btn_area{display: flex; justify-content: space-between; align-items: center;}
    #head .nav_wrap .btn_area .btn{width: 100%; border-radius: 100px; font-size:18px; margin-top: 20px; font-weight: 500}
    #head .nav_wrap .btn_area .btn+.btn{margin-left:20px;}
    #head .nav_wrap .btn_area .btn.white{color:#333; border-color:#666;}
    
    #head .member_area{display: flex; justify-content: space-between; align-items: center;}
    #head .member_area .title_field{font-size:18px; font-weight: 500;}
    #head .member_area .title_field .point{color:#3d7df2;}
    #head .member_area .button{border:1px solid #3d7df2; border-radius: 100px; min-height: 35px; height:35px; display: inline-flex; padding:0 15px; color:#3d7df2; font-size:15px; align-items: center;}
    
    /*gnb*/
    #head .center_wrap{display: block;}
    #head:after{display: none;}
    #head .nav{background: #3d7df2; flex-direction: column; align-items: flex-start; height: 100%;justify-content: flex-start; position: relative; overflow-y: auto; border-top:1px solid #ccc;}
    #head .nav:before{content: ''; background: #f7f7f7; width: 200px; height: 100%; display: block; position: fixed; left:0; bottom:0; top:0; z-index: 1;}
    #head .nav .depth1{padding: 0;}
    #head .nav .depth1 > .menu{width: 200px; height: 45px; display: flex; align-items: center; color:#333; position: relative; z-index: 2; background-color: #f7f7f7; padding-left: 20px;}
    #head .nav .depth1 > .menu:hover{font-size:20px; font-weight: bold; color:#3d7df2; background-image: url("../img/common/nav_on_m.png"); background-repeat: no-repeat; background-position: 95% center;}
    #head .nav .depth2{left:220px; top:0;}
    #head .nav .depth1.my .depth2{left: 220px;}
    #head .nav .depth2 a{color:#fff; font-size:18px; height: 45px; display: flex; align-items: center;}
    
    /*******
    헤드헨터
    *******/
    .head_hunter_body  #head .nav{background: #193b65}
}
@media screen and (max-width:768px){
    /*상단 검색*/
    #head .search_area input[type=text]{min-height: 45px;}
    #head .search_area input[type=text]::-webkit-input-placeholder,
    #head .search_area input[type=text]:-ms-input-placeholder,
    #head .search_area input[type=text]::placeholder,
    #head .search_area input[type=text]:-ms-input-placeholder{color:#636363;}
}
@media screen and (max-width:640px){
    #ribbon .logo img{width: 85px;}
    #ribbon .btn_my img{width: 20px;}
    #ribbon .btn_bar img{width:20px; }
    #ribbon .button{padding:0 5px; font-size:12px; height: 26px;}
    #ribbon .icon_notice .icon{width: 25px; height: 25px;}
    /* #ribbon .icon_notice .icon img{width: 50%;} */
    
    /*상단 검색*/
    #head .search_area input[type=text]{margin-left: 0}
    #head .search_area .button{margin-right: 13px;}
    #ribbon .icon_notice .num{min-width: 15px; height: 15px; line-height: 18px;}
    
    /*삼선클릭시*/
    #head .member_area .button{font-size:12px;}
    #head .nav_wrap .btn_area .btn{font-size:13px;}
    #head .nav_wrap .profile_area .id_field{font-size:15px;}
    #head .nav_wrap .profile_area .button{font-size:13px;}
    #head .member_area .title_field{font-size:15px;}

    /*gnb*/
    #head .nav:before,
    #head .nav .depth1 {width: 40%; max-width: 180px; } /* 스크롤때문에 pc에서 너비가 배경이랑 안 맞는 현상 있음 */
    #head .nav .depth1 > .menu{width: 100%; word-break:keep-all; white-space: normal; font-size:14px; padding-right: 20px;}
    #head .nav .depth1 > .menu:hover{font-size:15px}
    #head .nav .depth2{left:40%; width:53%; padding-left: 3%;}
    #head .nav .depth1.my .depth2{left:40%; width:53%; padding-left: 3%;}
    #head .nav .depth2 a{word-break: keep-all; white-space:normal;font-size:14px; }
    #head .nav .depth1 > .menu,
    #head .nav .depth2 a{height: 38px;}
}
@media screen and (max-width:320px){
    #head .nav .depth1, #head .nav:before {
        width: 38%;
    }
}