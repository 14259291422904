/* MyResume.css */
.myresume_write .profile_area .info_area .date-birthday .react-datepicker-wrapper {
    color: #000;
    font-size: 15px;
    width: 197px;
    min-width: 197px;
}
.myresume_write .profile_area .info_area .date-birthday .react-datepicker-wrapper .input_birthdate {
  width:100%;
}

.myresume_write .profile_area .info_area .date-income .react-datepicker-wrapper {
  color: #000;
  font-size: 12px;
  width: 55px;
  min-width: 55px;
  min-height:20px;
}
.myresume_write .profile_area .info_area .date-income .react-datepicker-wrapper .input_income_lyear {
  font-size: 12px;
  width: 55px;
  min-width: 55px;
  min-height:20px;
  padding:0;
  text-align:center;
}

/*Search*/
.talent_search .search_detail.hide {
  display:none;
}

/*Talk*/
.board_view .comment .edit-talk-reply { 
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
} 
.board_view .comment .edit-talk-reply.hide {
  display:none;
}
.board_view .comment .edit-talk-reply textarea {
  height:95px;
}
.board_view .comment .edit-talk-reply .button {
  color: #fff;
  background: #3d7df2;
  min-width: 130px;
  height: 95px;
  line-height: 95px;
  text-align: center;
  font-size: 15px;
}
/* Common.css */
.page_company_main .talent_list.wide {
  width: 100%;
  display:flex;
  justify-content:space-between;
}
.btn.upperline {
  background: #fff;
  border: 1px solid #3d7df2;
  color: #3d7df2;
  height:36px;
  min-height:36px;
  font-size:15px;
  padding:5px 15px;
}

/* Company.css */
.page_company_main .talent_list li:nth-child(4) {
  border-right-width: 0;
}

/* Sub.css */
.select_box .button{display: block; padding:3px 10px; font-size:15px;cursor:pointer;}

/* Announcement.css*/
#em_career_year.none {
  pointer-events: none;
  background-color:#eee;
}

#em_career_end.none {
  pointer-events: none;
  background-color:#eee;
}

#read_company_detail:none {
  pointer-events: none;
  background-color:#eee;
}

/* MyResume.css */

.disabled {
    display: none;
}

/* MAIN VISUAL */
.link_object {
  position:absolute;
  left:100px;
  top:240px;
  font-size:18px;
  padding:10px 30px;
  color:#fff;
  background-color:#3d7df2;
  border:solid 3px #fff;
  border-radius:25px;
  height:50px;
  cursor:pointer;
}

/* qr 이미지 - 경력증명서/이력서보기 */
.qr-image {
  width:75px;
  height:75px;
}

/* 이력서 */
.input_area .career-descriptions {margin-bottom:10px;}