@charset "utf-8";
.page_login .tab01_contents{padding-top:30px; padding-bottom:30px;}

.login{border:none;}
.login input { border-color: #dedede; }
.login input[type=password]{ margin-left:0; margin-top: 14px; }

.login .link_area{display: flex; justify-content:space-between; padding:20px 10px 0; align-items: center;}
.login .btn_join{font-size:15px; color:#3d7df2; text-decoration:underline; text-underline-offset:2px; margin-right: auto;}
.login .btn_find{font-size:14px;}
.login.member_form .bottom_btn_area{margin-top: 20px;}

.login_toggle_btn { position: absolute; left: -9999px; }
.login_toggle_btn + label { display: flex; align-items: center; justify-content: space-between; padding: 20px 0; font-size:15px; color: #000; cursor: pointer;}
.login_toggle_btn + label .arrow { display: inline-block; background-color:#3d7df2; border-radius:50%; background-image: url(../img/sub/btn_next2.png); background-position: center; background-repeat: no-repeat; width:27px; height:27px; transform: rotate(90deg); transition: all .2s; }
.login_toggle_btn:checked + label .arrow { transform: rotate(270deg); }
.login_toggle_btn:checked ~ .login_toggle_wrap { height:auto; max-height: 230px; }
.login_toggle_wrap { height:auto; max-height: 0; overflow:hidden; transition:0.3s ease; }

/*다른계정 로그인*/
.login_sns{position: relative; text-align: center; }
.login_sns .title_field {font-size:15px; line-height:1.2; text-align: center; background: #fff; padding:9px 19px; margin: 0 auto; color:#222; position: relative; z-index: 5; display: inline-flex; width: auto; box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2); height:36px; margin-bottom:16px; border-radius:20px; }
.login_sns .title_field img { margin-right:5px; width: 11px; }
.login_sns .title_field span { color: #d02928; }
.login_sns .title_field::before { content:''; display:block; position:absolute; bottom:0px; left:50%; transform: translateX(-50%); background-color:#fff; width: 20px; height:7px; z-index: 1;}
.login_sns .title_field::after { content:''; display:block; position:absolute; bottom:-5px; left:50%; transform: translateX(-50%) rotate(-45deg); width:10px; height:10px; background-color: #fff; box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.2); }
.login_sns .btn{width: 100%; margin-bottom: 15px;font-size:15px; min-height: 55px; color:#000; justify-content: flex-start; background-image:url("../img/sub/btn_next.png"); background-repeat: no-repeat; background-position: 95% center; padding-left:20px; margin-left:0; margin-right:0; }
.login_sns .btn.naver{background-color: #47b749; color:#fff; background-image:url("../img/sub/btn_next2.png");}
.login_sns .btn.kakao{background-color: #fff200;}
.login_sns .btn.google{background-color: #fff; border:1px solid #d4d4d4;}
.login_sns .btn:before{content: ''; height: 55px; width: 52px; background-position: left center; background-repeat: no-repeat; background-size: 16px auto;}

.login_sns .btn.naver:before{background-image: url("../img/sub/btn_naver2.png");}
.login_sns .btn.kakao:before{background-image: url("../img/sub/btn_kakao2.png"); background-size: auto 20px;}
.login_sns .btn.google:before{background-image: url("../img/sub/btn_google2.png");background-size: auto 20px;}
.login_sns .auto_login { text-align: left; padding: 20px 0; border-bottom: 1px solid #edf0f6; }
.login .auto_login { text-align: left; padding: 20px 0; border-bottom: 1px solid #edf0f6; }
.login_sns .auto_login label { font-size: 15px; color: #333; }
.login .auto_login label { font-size: 15px; color: #333; }


/* 애플로그인 */
.signin-button {
    width: 100%;
    height: 55px;
}
.signin-button text{/*font-size:9px !important; font-weight: normal !important; letter-spacing: 0 !important;*/}
.login_sns .btn.apple{border: none; width:100%; margin-bottom: 0; text-align: left; display: block; padding:0; background: #000; overflow: hidden;}
.login_sns .btn.apple > div{max-width:100% !important; width:100% !important;}
.login_sns .btn.apple > div > div{width: 100% !important; /*display: flex;*/}

/* 자동로그인 팝업 */
.pop_auto_login .title_wrap { padding:14px 25px; justify-content: flex-start;}
.pop_auto_login .title_wrap figure { width:52px; height:52px; border-radius:50%; background-position: center; background-repeat: no-repeat; background-size: cover; margin-right:14px; }
.pop_auto_login .title_wrap h1 { font-size:18px; color:#000; font-weight:700; }
.pop_auto_login .agree_form { margin:0; border:none; padding:0; }

/*구글 로그인 글자 색상*/
/*.tab01_contents, .tab01_contents p, .tab01_contents div, .tab01_contents span{ color: #fff !important;} 다른 곳에도 영향있어서 제거*/

@media screen and (max-width:768px){/*모바일 디자인 적용*/
    .page_login .tab01_wrap{border:1px solid #999; padding:0;}
    .page_login .tab01_wrap .tab01{border-width: 0 0 1px 0}
    
    .login .link_area{padding-top: 20px;}
    .login.member_form .bottom_btn_area{margin-top: 28px;}
    .login_sns{margin-top: 23px;}
    .login_sns .title_field{margin-bottom: 17px;}
    .login_sns .btn{margin-bottom: 9px; font-size:13px;}
    
    .login .btn_join{font-size:12px; word-break: keep-all;}
    .login .btn_find{font-size:12px; white-space: nowrap;}

    /*.signin-button text{font-size:7px !important; letter-spacing: 0 !important; direction: inherit;}*/
}
@media screen and (max-width:640px){
    .login .link_area{padding-left:0; padding-right:0;}
}