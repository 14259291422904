.myrecruit * { font-size: 15px; letter-spacing: -0.38px; } 
.myrecruit .sub_content_title { font-size: 20px; letter-spacing: -1px; margin-top: 40px; } 
.myrecruit .mainRecruitBlue {border-bottom: 1px solid #3d7df2; margin-left: 0 !important; display:flex; }
.myrecruit .sub_content_title button { width:162px; height:45px; border: 1px solid #3d7df2; border-bottom: 0; display:inline-flex; align-items: center; justify-content: center; padding-right: 20px; font-size: 16px; color:#000; background: url(../img/common/select_arrow_active.png) #f5f5f5 no-repeat calc(100% - 10px) center;}
.myrecruit .sub_content_title button.active { background-image:url(../img/common/select_arrow.png); }
.myrecruit .sub_content_title button img { margin-right: 6px; }
.page_myrecruit .list_top { margin-bottom: 18px; padding-left: 10px; padding-bottom: 10px; border-bottom: 1px solid #999; margin-top: 48px; } 
.page_myrecruit .list_top .total_count { font-size: 22px; } 
.page_myrecruit .list_top .total_count span { font-size: 22px; color: inherit; } 
.page_myrecruit .recruit_card_wrap + .list_top { margin-top:50px } 
.page_myrecruit .tab01{display: none;}
/******************************************
 MyRecruit.html
******************************************/
.myrecruit_detail_search { display: none; flex-wrap: wrap; border: 1px solid #999; }
.myrecruit_detail_search .detail_wrap { width: 33.333%; } 
.myrecruit_detail_search .detail_wrap:nth-child(2),
.myrecruit_detail_search .detail_wrap:nth-child(3) { border-left:1px solid #d4d4d4; } 
.myrecruit_detail_search .detail_wrap:last-child { border-top: 1px solid #999; width: 100%; } 
.myrecruit_detail_search .detail_wrap .depth01 { position:relative; font-weight: 700; letter-spacing: 0.38px; color: #000; display: flex; justify-content: space-between; align-items: center; width: 100%; height:50px; padding: 0 20px; border-bottom:1px solid #d4d4d4; cursor:pointer; } 
.myrecruit_detail_search .detail_wrap .depth01::after { content:''; position:absolute; top:23px; right:20px; display: inline-block; width:15px; height:8px; background: url(../img/common/select_arrow.png) no-repeat; } 
.myrecruit_detail_search .detail_wrap .depth01.active { background-color:#3d7df2; color: #fff; } 
.myrecruit_detail_search .detail_wrap .depth01.active::after { background: url(../img/common/select_arrow_on.png) no-repeat; } 
.myrecruit_detail_search .detail_wrap .depth02 { padding: 7px 0; height: 178px; overflow-y: auto; } 
.myrecruit_detail_search .detail_wrap .depth02 li { font-weight: 500; padding: 0 22px; line-height: 35px; color: #333; } 
.myrecruit_detail_search .detail_wrap .depth02 li:hover,
.myrecruit_detail_search .detail_wrap .depth02 li.active { cursor: pointer; background-color: #f2f8fe; color: #3d7df2; } 
.myrecruit_detail_search .detail_wrap .criteria_wrap { padding: 33px 22px; } 
.myrecruit_detail_search .detail_wrap .criteria_wrap .label { display: inline-flex; color: #000; font-weight: 700; margin-right: 12px; } 
.myrecruit_detail_search .detail_wrap .criteria_wrap .criteria { display: inline-flex; color: #3d7df2; margin-right: 32px; } 
.myrecruit_detail_search .detail_wrap .criteria_wrap .btn_criteria_delete { display: flex; justify-content: center; align-items: center; margin-left: 6px; width: 19px; height: 19px; background: #d7dde6; border-radius: 10px; text-align: center; cursor: pointer; background: #d7dde6 url(../img/sub/btn_delete01.png) no-repeat center; } 

.myrecruit_detail_search .detail_wrap .search_wrap { display: flex; justify-content: space-between; border-top: 1px solid #dedede; padding: 21px; } 
.myrecruit_detail_search .detail_wrap .search_wrap input,
.myrecruit_detail_search .detail_wrap .search_wrap select { width:auto; margin-left: 0; } 
.myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select { margin-left: 5px; } 
.myrecruit_detail_search .detail_wrap .search_wrap .left_wrap { width:55%; display: flex; justify-content: space-between; } 
.myrecruit_detail_search .detail_wrap .search_wrap .left_wrap select { width:120px; margin-right:10px; } 
.myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap { position: relative; width:calc(100% - 120px); } 
.myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap input { width:100%; padding-right: 48px; } 
.myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap .btn_search { position:absolute; padding: 13px; top:0; right:0; } 
.myrecruit_detail_search .detail_wrap .col2{display: flex; justify-content: space-between;}
.myrecruit_detail_search .detail_wrap .col2 .depth02{width:100%}
.recruit_top_search_reset { width: 100px; height:48px; min-height:auto; } 
.myrecruit_detail_search .detail_wrap .career .btn{margin-left:20px; min-height:48px; height: 48px;}

/******************************************
 MyRecruitView.html
******************************************/
.page_myrecruit_view { display:flex; flex-wrap: wrap; align-items: flex-start; } 
.page_myrecruit_view .tab01_wrap .tab01 li { width:130px; } 
.page_myrecruit_view .tab01_wrap .tab01 li:last-child { border-right: 1px solid #d4d4d4 } 
.page_myrecruit_view .tab01_wrap .tab01_contents { padding:0; } 
.page_myrecruit_view .tab01_wrap .tab01_contents > li { padding:20px 30px; } 
.page_myrecruit_view .tab01_wrap .tab01_contents > li > h2 { font-weight: 700; } 
.page_myrecruit_view .tab01_wrap .tab01_contents > li + li { border-top:1px solid #ccc; } 
.myrecruit_view_top { width:100%; margin-top: 48px; display:flex; } 
.myrecruit_view_top .left_wrap,
.myrecruit_view_left { /* width: 890px; */
 width: 70.6%; } 
.myrecruit_view_top .left_wrap { margin-left: 10px; } 
.myrecruit_view_top .left_wrap .title_field { font-size: 26px; color: #000; letter-spacing: -1.3px; font-weight: 700; } 
.myrecruit_view_top .left_wrap ul { margin-top: 23px; display: flex; align-items: center; } 
.myrecruit_view_top .left_wrap li:first-of-type .table_notice_badge { display: none; } 
.myrecruit_view_top .left_wrap li:not(:last-of-type) { margin-right: 10px; border-right: 1px solid #d1d1d1; padding-right: 10px; line-height: 1; } 
.myrecruit_view_top .right_wrap { display: flex; } 
.myrecruit_view_top .right_wrap li { width: 50%; } 
.myrecruit_view_top .right_wrap li:last-of-type { border-left: 1px solid #d1d1d1; } 
.myrecruit_view_top .right_wrap li img { margin-right: 12px; } 
.myrecruit_view_top .right_wrap li .title_field { text-align: center; } 
.myrecruit_view_top .right_wrap .icon_wrap { margin-top: 8px; display: flex; justify-content: center; align-items: center; } 
.myrecruit_view_top .right_wrap .icon_wrap b { color: #3d7df2; font-size: 20px; letter-spacing: -1px; line-height: 1; } 
.myrecruit_view_top .right_wrap .title_field { color: #333; letter-spacing: -0.75px; margin-top: 27px; } 
.myrecruit_view_left { margin-top: 20px; } 
.myrecruit_view_left .tab01_wrap { min-height: 720px; } 
.page_myrecruit_view .tab01_wrap .tab01 li a { min-width: 120px; } 
.myrecruit_view_left .tab01_contents > li > ul li { color:#666666; letter-spacing: -0.05em; } 
.myrecruit_view_left .tab01_contents .highlight { color:#000; } 
.myrecruit_view_left .tab2 { border: 1px solid #999; } 
.myrecruit_view_left .sub_content_title { padding-left: 10px; margin-top: 39px; } 
.myrecruit_view_left .another { margin-top: 22px; border: 1px solid #999; } 
.myrecruit_view_left .another_box { position: relative; display: flex; align-items: center; width: 100%; padding: 26px 20px; border-bottom: 1px solid #dedede; } 
.myrecruit_view_left .another_box:last-of-type { border-bottom: none; } 
.myrecruit_view_left .another_box .btn_bookmark { top: 24px; right: 18px; } 
.myrecruit_view_left #map { margin-top:10px; } 
.myrecruit_view_left .location_text { color: #333; margin-top: 10px; } 
.myrecruit_view_left .location_map_wrap { margin-top: 30px; width: 100%; } 
.another_box .btn_bookmark { position: absolute; right: 0; } 
.another_box .left_wrap { font-size: 20px; letter-spacing: -1px; color: #000; min-width: 172px; width: 172px; } 
.another_box .right_wrap { border-left: 1px solid #e5e5e5; margin-left: 20px; padding-left: 20px; width: calc(100% - 172px - 20px); } 
.another_box .right_wrap .title_field { display: flex; align-items: flex-start; font-size: 20px; letter-spacing: -1px; color: #000; } 
.another_box .right_wrap .title_field a { font-size: 20px; letter-spacing: -1px; color: #000; font-weight: 700; } 
.another_box .right_wrap .title_field .table_notice_badge { margin-left: 14px; white-space: nowrap; } 
.another_box .right_wrap .info_field { display: flex; align-items: center; margin-top: 12px; color: #666; word-break: break-word; } 
.another_box .right_wrap .info_field hr { display: inline-block; border: 0; border-left: 1px solid #d1d1d1; width: 1px; height: 15px; margin: 0 10px; } 
.another_box .bottom_wrap { display: flex; align-items: center; justify-content: space-between; width: 100%; margin-top: 16px; } 
.another_box .bottom_wrap .compensation { display: flex; align-items: center; } 
.another_box .bottom_wrap .compensation .total { display: flex; align-items: center; font-weight: 500; color: #333; } 
.another_box .bottom_wrap .compensation .total img { margin-right: 3px; } 
.another_box .bottom_wrap .compensation .amount { font-weight: 700; color: #3d7df2; margin-left: 17px; } 
.another_box .bottom_wrap .date { color: #666; } 

.myrecruit_view_right { margin-top: 20px; } 
.recruit_card_big { position: relative; border: 1px solid #dedede; border-bottom: none; padding: 21px 20px; background-color: #fff; } 
.recruit_card_big .btn_bookmark { position: absolute; top: 30px; right: 25px; z-index: 1; } 
.recruit_card_big .link_wrapper { width: 100%; } 
.recruit_card_big .image_wrap { text-align: center; display: flex; align-items: center; justify-content: center; overflow: hidden; } 
.recruit_card_big .image_wrap img { max-width:none; min-width: 100%; /* max-height: 200px; */
 object-fit:cover; } 
.recruit_card_big .content_wrap .type_wrap { display: flex; margin-top: 20px; } 
.recruit_card_big .content_wrap .type_wrap .type { display: inline-block; font-size: 12px; line-height: 1; letter-spacing: -0.3px; color: #666; padding: 3px 5px; border: 1px solid #d6d6d6; border-radius: 2px; margin-right: 5px; font-weight: 300; } 
.recruit_card_big .content_wrap .type_wrap .new_badge { width: 36px; height: 20px; background: url(../img/sub/new2.png) no-repeat center; background-size: 36px 20px; } 
.recruit_card_big .content_wrap .title { margin-top: 16px; font-size: 24px; font-weight: 700; line-height: 1.3; letter-spacing: -0.5px; color: #000; text-overflow: ellipsis; word-wrap: normal; overflow: hidden; white-space: normal; -webkit-line-clamp: 2; white-space: normal; display: -webkit-box; -webkit-box-orient: vertical; } 
.recruit_card_big .content_wrap .recruit_info { margin-top: 16px; } 
.recruit_card_big .content_wrap .recruit_info * { font-size: 15px; line-height: 30px; letter-spacing: -0.75px; color: #999; font-weight: 400; text-align: left; } 
.recruit_card_big .content_wrap .recruit_info th { min-width: 78px; } 

.recruit_card_big .content_wrap .recruit_info td,
.recruit_card_big .content_wrap .recruit_info td * { color: #333; } 
.myrecruit_view_right .compensation { display: flex; justify-content: space-between; align-items: center; background: #fafbfb; padding: 13px 20px; border: 1px solid #3d7df2; } 
.myrecruit_view_right .compensation li:not(:last-child) { margin-bottom:12px; } 
.myrecruit_view_right .compensation li { color: #000; line-height: 1.2; display: flex; } 
.myrecruit_view_right .compensation li b { color: #3d7df2; margin-left: 35px; } 
.myrecruit_view_right .button_wrap { display: flex; justify-content: space-between; margin-top: 20px; } 
.myrecruit_view_right .button_wrap .btn { font-size: 20px; font-weight: 700; letter-spacing: -1px; width: 100%; min-height: 50px; margin:0; width:100%} 
.myrecruit_view_right .button_wrap .btn+.btn{margin-left: 10px;}
.popup .recommen_comment { padding-top: 15px; font-size: 15px; color: #000; line-height: 25px; letter-spacing: -0.38px; } 
.myrecruit_view_right .compensation .compensation_title { white-space: nowrap; min-width: 100px; } 

.recruitview01,
.recruitview02,
.recruitview03,
.recruitview04,
.recruitview05 { white-space: pre-wrap; line-height: 180%; } 

.tab01_contents_box1 { margin-bottom: 20px; } 

/*헤딩 이력서 선택 팝업*/
.resume_style .radio_wrap { width: 100%; } 

@media screen and (min-width:1260px){
 .myrecruit_view_top .right_wrap,
 .myrecruit_view_right { width: calc(29.4% - 4%); margin-left: 4%; } 
 }
@media screen and (max-width:1260px){
 .myrecruit_view_top .right_wrap,
 .myrecruit_view_right { width: calc(29.4% - 2%); margin-left: 2%; } 
 }
@media screen and (max-width:1024px){
 .page_myrecruit .list_top { padding-left: 12px !important; } 
 .page_myrecruit_view .tab01_wrap .tab01 li a { min-width:0; } 
 .myrecruit_detail_search .detail_wrap .search_wrap { flex-wrap: wrap; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .left_wrap { width: 100%; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap { display: flex; width: 100%; margin-top: 10px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select { margin-right: 5px; margin-left:0; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select:first-child { width: 50%; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select:nth-child(2) { width: 30%; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select:last-child { width: 20%; margin-right: 0; } 
 .page_myrecruit_view { flex-direction: column; } 
 .myrecruit_view_top { margin-top: 20px; flex-direction: column; } 
 .myrecruit_view_left { order: 1; margin-top: 35px; } 
 .myrecruit_view_top .left_wrap { margin-left: 0 } 
 .myrecruit_view_top .left_wrap,
 .myrecruit_view_left { width: 100%; } 
 .myrecruit_view_top .right_wrap,
 .myrecruit_view_right { width: 100%; margin-left: 0; } 
 .myrecruit_view_top .right_wrap { margin-top: 40px; } 
 .myrecruit_view_top .right_wrap .title_field { margin-top: 0; margin-right: 9px; } 
 .myrecruit_view_top .left_wrap ul { margin-top: 6px; } 
 .myrecruit_view_top .right_wrap li { display: inline-flex; flex-direction: row-reverse; justify-content: flex-end; align-items: center; width: auto; } 
 .myrecruit_view_top .right_wrap li img { margin-right: 6px; } 
 .myrecruit_view_top .right_wrap li:last-of-type { margin-left: 10px; padding-left: 10px; } 
 .myrecruit_view_top .right_wrap .icon_wrap { display: inline-flex; margin-top: 0; } 
 .recruit_card_big .image_wrap img { max-width: 100%; } 
 .myrecruit_view_left .sub_content_title { padding-left: 0 } 
}
@media screen and (min-width:769px) { /*769이상일때*/
 .page_myrecruit .sub_content_title { margin-left: 10px; } 
 }
@media screen and (max-width:768px){
 .myrecruit * { font-size: 11px; letter-spacing: normal; } 
 .page_myrecruit .sub_content_title { margin-top: 20px; font-size:16px; } 
 .page_myrecruit .list_top { padding-left: 0 !important; } 
 .page_myrecruit .tab01{display: flex;}
 .another_box .right_wrap .title_field { align-items: center; } 
 .another_box .right_wrap .title_field a { font-size: 14px; } 
 .recruit_top_search_reset { height:40px; } 
 .recruit_top_search_reset img { height: 11px; } 
 .page_myrecruit_view .tab01_wrap .tab01_contents > li { padding:20px 4%; } 
 .page_myrecruit .list_top { margin-top: 10px; } 
 }
@media screen and (max-width: 1260px){
 .myrecruit_view_top .right_wrap,
 .myrecruit_view_right { width: calc(29.4% - 2%); margin-left: 2%; } 
 }
@media screen and (max-width: 1024px){
 .page_myrecruit .list_top { padding-left: 12px !important; } 
 .page_myrecruit_view .tab01_wrap .tab01 li a { min-width: 0; } 
 .myrecruit_detail_search .detail_wrap .search_wrap { flex-wrap: wrap; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .left_wrap { width: 100%; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap { display: flex; width: 100%; margin-top: 10px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap select { margin-right: 5px; } 
 .myrecruit_detail_search
 .detail_wrap
 .search_wrap
 .right_wrap
 select:first-child { width: 50%; } 
 .myrecruit_detail_search
 .detail_wrap
 .search_wrap
 .right_wrap
 select:nth-child(2) { width: 30%; } 
 .myrecruit_detail_search
 .detail_wrap
 .search_wrap
 .right_wrap
 select:last-child { width: 20%; margin-right: 0; } 
 .page_myrecruit_view { flex-direction: column; } 
 .myrecruit_view_top { margin-top: 20px; flex-direction: column; } 
 .myrecruit_view_left { order: 1; margin-top: 35px; } 
 .myrecruit_view_top .left_wrap { margin-left: 0; } 
 .myrecruit_view_top .left_wrap,
 .myrecruit_view_left { width: 100%; } 
 .myrecruit_view_top .right_wrap,
 .myrecruit_view_right { width: 100%; margin-left: 0; } 
 .myrecruit_view_top .right_wrap { margin-top: 40px; } 
 .myrecruit_view_top .right_wrap .title_field { margin-top: 0; margin-right: 9px; } 
 .myrecruit_view_top .left_wrap ul { margin-top: 6px; } 
 .myrecruit_view_top .right_wrap li { display: inline-flex; flex-direction: row-reverse; justify-content: flex-end; align-items: center; width: auto; } 
 .myrecruit_view_top .right_wrap li img { margin-right: 6px; } 
 .myrecruit_view_top .right_wrap li:last-of-type { margin-left: 10px; padding-left: 10px; } 
 .myrecruit_view_top .right_wrap .icon_wrap { display: inline-flex; margin-top: 0; } 
 .recruit_card_big .image_wrap img { max-width: 100%; } 
 .myrecruit_view_left .sub_content_title { padding-left: 0; } 
 }
@media screen and (min-width: 769px) { /*769이상일때*/
 .page_myrecruit .sub_content_title { margin-left: 10px; } 
 }
@media screen and (max-width: 768px){
 .myrecruit * { font-size: 11px; letter-spacing: normal; } 
 .page_myrecruit .sub_content_title { margin-top: 20px; font-size: 16px; } 
 .page_myrecruit .list_top { padding-left: 0 !important; } 
 /******************************************
 MyRecruit.html
 ******************************************/
 .myrecruit_detail_search { margin-top: 10.5px; margin-bottom: 20px; } 
 .myrecruit_detail_search .detail_wrap { width: 100%; } 
 .myrecruit_detail_search .detail_wrap:nth-child(2), .myrecruit_detail_search .detail_wrap:nth-child(3) { border-left:0; border-top: 1px solid #d4d4d4; } 
 .myrecruit_detail_search .detail_wrap .depth01 { font-size: 12px; font-weight: 500; height: 30px; padding: 0 10px; border-bottom: 0; } 
 .myrecruit_detail_search .detail_wrap .depth01::after { top: 12px; right: 10px; transition: all .2s; width:10px; height:5px; background: url(../img/common/select_arrow_m.png) no-repeat; } 
 .myrecruit_detail_search .detail_wrap .depth01.active::after { background: url(../img/common/select_arrow_on_m.png) no-repeat; transform: rotate(-180deg); } 
 .myrecruit_detail_search .detail_wrap .depth02 { padding: 0; height: 75px; /*display: none;*/ } 
 .myrecruit_detail_search .detail_wrap .depth01.active + .depth02 { display: block; } 
 .myrecruit_detail_search .detail_wrap .depth02 li { font-size: 11px; padding: 0 10px; line-height: 25px; } 
 .myrecruit_detail_search .detail_wrap .criteria_wrap { padding: 11px 10px; } 
 .myrecruit_detail_search .detail_wrap .criteria_wrap .label { font-size: 12px; display: block; margin-bottom: 9px; } 
 .myrecruit_detail_search .detail_wrap .criteria_wrap .criteria { font-size: 11px; margin-right: 12px; margin-top: 4px; } 
 .myrecruit_detail_search .detail_wrap .criteria_wrap .btn_criteria_delete { width: 13.5px; height: 13.5px; border-radius: 7px; margin-left: 3px; background-image: url(../img/sub/btn_delete01_m.png); } 
 .myrecruit_detail_search .detail_wrap .criteria_wrap .btn_criteria_delete img { width: 7px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap { padding: 10px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .left_wrap select { width: 22%; margin-right: 5px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap { width: calc(100% - 22% - 5px); } 
 .myrecruit_detail_search .detail_wrap .search_wrap .right_wrap { margin-top: 5px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap input { padding-right: 30px; } 
 .myrecruit_detail_search .detail_wrap .search_wrap .search_input_wrap .btn_search { padding: 14px; } 
 .page_myrecruit .list_top { margin-bottom: 10px; } 
 /******************************************
 MyRecruitView.html
 ******************************************/
 .myrecruit_view_top .left_wrap .title_field { font-size: 16px; } 
 .myrecruit_view_top .left_wrap ul { margin-top: 11px; flex-wrap: wrap; } 
 .myrecruit_view_top .left_wrap li:nth-child(n + 2):nth-child(-n + 5) { order: 1; } 
 .myrecruit_view_top .left_wrap li:first-of-type { margin-right: 0; padding-right: 0; border-right: 0; width: 100%; margin-bottom: 10px; } 
 .myrecruit_view_top .left_wrap li:first-of-type .table_notice_badge { margin-left: 10px; display: inline-block; } 
 .myrecruit_view_top .left_wrap li:nth-child(5) { margin-right: 0; padding-right: 0; border-right: 0; } 
 .myrecruit_view_top .right_wrap .icon_wrap b { font-size: 12px; } 
 .myrecruit_view_top .right_wrap { margin-top: 20px; } 
 .recruit_card_big { padding: 10px; } 
 .recruit_card_big .btn_bookmark { top: 10px; right: 10px; } 
 .recruit_card_big .content_wrap .type_wrap { margin-top: 15px; } 
 .recruit_card_big .content_wrap .type_wrap .type { font-size: 11px; } 
 .recruit_card_big .content_wrap .type_wrap .new_badge { width: 32px; height: 18px; background: url(../img/sub/new2_m.png) no-repeat center; background-size: 32px 18px; } 
 .recruit_card_big .content_wrap .title { margin-top: 10px; font-size: 15px; line-height: 1.2; } 
 .recruit_card_big .content_wrap .recruit_info { margin-top: 12px; } 
 .recruit_card_big .content_wrap .recruit_info * { font-size: 11px; line-height: 20px; letter-spacing: -1.1px; } 
 .myrecruit_view_right .compensation { padding: 10px; } 
 .myrecruit_view_right .compensation li { line-height: 20px; } 
 .myrecruit_view_right .button_wrap { margin-top: 10px; } 
 .myrecruit_view_right .button_wrap .btn { font-size: 12px; letter-spacing: -1.2px; min-height: 35px; } 
 .myrecruit_view_left .tab01_wrap { min-height: auto; border: 1px solid #999; padding: 0; } 
 .myrecruit_view_left .tab01_wrap .tab01 { border-top: none; border-left: none; border-right: none; } 
 .page_myrecruit_view .tab01_wrap .tab01 li { width: 100%; } 
 .tab01_wrap .tab01 li a { min-width: auto; width: 100%; text-align: center; } 
 .page_myrecruit_view .tab01_wrap .tab01 li:last-of-type { border-right: none; } 
 .page_myrecruit_view .tab01_contents { padding: 10px; } 
 .myrecruit_view_left .sub_content_title { margin-top: 18px; padding-left: 0; font-size: 16px; } 
 .myrecruit_view_left .another { margin-top: 6px; } 
 .myrecruit_view_left .another_box { padding: 13px 10px; } 
 .myrecruit_view_left .another_box .btn_bookmark { top: auto; bottom: 13px; right: 10px; } 
 .another_box .left_wrap { min-width: auto; width: 25%; font-size: 12px; } 
 .another_box .right_wrap { margin-left: 10px; padding-left: 10px; width: calc(75% - 10px); } 
 .another_box .right_wrap .title_field { font-size: 13px; flex-direction: column; align-items: flex-start;} 
 .another_box .right_wrap .title_field .link{order:2}
 .another_box .right_wrap .title_field .table_notice_badge {order:1; margin-left: 0; margin-bottom: 3px; line-height: 14px; padding: 0 7.5px; font-size: 9px; margin-right: 0; } 
 .another_box .right_wrap .info_field { flex-wrap: wrap; margin-top: 7.5px; } 
 .another_box .right_wrap .info_field hr { margin: 0 4px; } 
 .another_box .bottom_wrap { margin-top: 6px; flex-direction: column; align-items: flex-start; } 
 .another_box .bottom_wrap .date { margin-top: 12px; } 
 .myrecruit .popup input[type="number"],
 .myrecruit .popup input[type="password"],
 .myrecruit .popup input[type="tel"],
 .myrecruit .popup input[type="text"],
 .myrecruit .popup select,
 .myrecruit .popup textarea { min-height: 48px; } 
 .popup .recommen_comment { font-size: 12px; line-height: 15px; } 
 }
@media screen and (max-width: 640px) { } 
@media screen and (max-width: 450px){
 .myrecruit .popup .email_field select { width: 100% !important; } 
 }

 .detail_wrap .depth02 .career input.careerstart { width:50px; height:5px; font-size:20px; margin-left:10px; margin-right: 10px;} 
 .detail_wrap .depth02 .career input.careerstart:first-of-type{margin-left:20px;}