/*성별등 타이틀과 input항목이 나란히*/
.page_join .member_form .col{display: flex; margin-top:30px}
.page_join .member_form .col .title_field{margin-right: 20px;}
.page_join .member_form .col input+label{font-size:15px;}

/*필수항목*/
.must_input{color:#cc3333 !important;}

/*동의*/
.agree_form{border:1px solid #edf0f6; border-width: 1px 0; padding:26px 12px; margin-top: 30px;}
.agree_form .chk+label:before{vertical-align: -5px;}
.agree_form .agree_all{margin-bottom: 20px;}
.agree_form .agree_all label{font-size:16px; font-weight: 500; color:#333; font-weight: 500;}
.agree_form .agree_list{margin-left: 10px;}
.agree_form .agree_list label{color:#666666;}
.agree_form .agree_list li{display: flex; justify-content: space-between; padding: 5px 0; align-items: center}
.agree_form .agree_list li .button{color:#999; font-size:14px;}

.agree_form2{border-bottom:1px solid #edf0f6; display: flex; justify-content: space-between; align-items: center;padding:15px 12px;}
.agree_form2 .title_field{color:#333; font-size:16px; font-weight: 500;}
.agree_form2 .title_field .tip_icon{display:inline-flex; font-style: normal; color:#fff; font-family: 'roboto'; font-size:15px; background: #b3b9c4; border-radius: 100px; width: 22px; height: 22px; line-height: 22px; align-items: center; justify-content: center; margin-left: 10px;}
.agree_form2 .input_field label{font-size:16px; color:#333;}

.page_join .btn_bottom_area{display: block;}
.page_join .btn_bottom_area .btn{width: 100%;}
.page_join .btn_bottom_area .btn+.btn{margin-left: 0; margin-top: 20px;}

.form_top{display: flex; margin-bottom: 26px; align-items: center;}
.form_top .title_field{font-size:20px; font-weight: bold; color:#000; }
.form_top .must_input{font-size:15px; margin-left: 20px;}
.member_form + .form_top{border-top:1px solid #ececec; padding-top: 40px; margin-top: 34px;}

/*회원가입완료*/
.page_join_ok .notice_area img{max-width: 84px;}
.page_join_ok .btn{word-break: keep-all;}

@media screen and (max-width: 768px){
    .page_join .tab01_wrap{border:1px solid #999; padding:0;}
    .page_join .tab01_wrap .tab01{border-width: 0 0 1px 0}
    
    .agree_form .agree_all{margin-bottom: 10px;}
    .agree_form .agree_all label{font-size:13px;}
    .agree_form{padding:20px 0; margin-top: 20px;}
    .agree_form2 .title_field,
    .agree_form2 .input_field label,
    .agree_form .agree_list li .button{font-size:13px;}
    .agree_form2{padding-right: 0}
    
    .page_join .btn_bottom_area .btn+.btn{margin-top: 12px;}
    
    .form_top{display: block;}
    .form_top .title_field{font-size:14px;}
    .form_top .must_input{font-size:12px; margin-left: 0; margin-top: 10px;}
}
@media screen and (max-width: 430px){
    .page_join  input:-ms-input-placeholder{font-size:9px;}
    .page_join  input::-ms-input-placeholder{font-size:9px;}
    .page_join  input::placeholder{font-size:9px;}
    
    .agree_form .agree_list li .button{white-space: nowrap; font-size:11px; padding-left: 8px;}
    .agree_form .agree_list label{font-size:11px;}
}