@charset "utf-8";
/*노출동의*/
.page_myresume_list .myresume_setting{border:1px solid #dedede; border-width: 1px 0; padding:33px 30px;}
.page_myresume_list .myresume_setting .text{font-size:15px; display: inline-block; margin-right: 14px; padding-left: 14px;}
.page_myresume_list .myresume_setting .text:before{content: '';width:5px; height: 5px; background: #3d7df2; border-radius: 10px; display: inline-block; margin-right: 9px; vertical-align: 3px; margin-left: -14px}

/*리스트 하단 버튼*/
.page_myresume_list .btn_bottom_area{ justify-content: flex-end}

/*리스트 상단*/
.page_myresume_list .list_top .sub_content_title{margin-right: 50px; margin-left: 10px;}
.page_myresume_list .list_top .btn.gray{margin-left: auto;}
.page_myresume_list .list_top .btn{font-size:15px; min-height:31px; height: 31px; padding:0 13px;}
.page_myresume_list .list_top .btn+.btn{margin-left:7px;}
.page_myresume_list td.view_pc{display: table-cell}


/*인재노출 장점*/
.myresume_tip{margin-top: 44px; margin-left: 25px;}
.myresume_tip .title_area{font-size: 20px;  font-weight: bold; letter-spacing: -0.5px; margin-bottom: 18px;}
.myresume_tip .title_area img{padding-right: 11px; vertical-align: middle;}
.myresume_tip .list{margin-left: 37px; margin-right: 18px;}
.myresume_tip .list li:before{content: ''; display: inline-block; width: 4px; height: 1px; background: #333; margin-right: 5px; margin-left: -9px; vertical-align: 4px;}
.myresume_tip .list li{color:#333; padding-left: 9px; font-size:15px; margin-bottom: 10px;}
.myresume_tip .list li .point{color:#326dd1;}

/*열람제한 기업설정*/
.restricted_setting{background: #f5f5f5; padding:33px; margin-top: 60px;}
.restricted_setting .title_field{font-size:20px; font-weight: bold; width: 100%; margin-bottom: 20px;}
.restricted_setting .wrap{display: flex;  justify-content: space-between;}
.restricted_setting .text{font-size:15px; color:#666666}
.restricted_setting .btn_area .btn{font-size:15px; height: 40px; min-height: 40px; padding:0 15px;}
.restricted_setting  .point{color:#326dd1;}

.page_restricted .list_top{flex-wrap: wrap; margin-bottom: 25px}
.page_restricted .list_top .btn{padding: 0 20px; height: 50px; min-height: 50px}
.page_restricted .list_top .search_wrap{width: 100%; margin-top: 12px;}
.page_restricted .list_top .list_search_area{margin-left: 22%; width: 66%;}
.page_restricted .list_top .list_search_area .search{width: 100%;}

.page_restricted .table_list01 td.td_button{padding-right:20px; width:120px; padding-block: 10px; }
.page_restricted .table_list01 td.td_button .btn{white-space: nowrap;}
.page_restricted .table_list01 td.with_btn { width: calc(100% - 88px); margin-bottom: 0; }

.page_restricted .paging+.list_top{margin-top:65px}

/****************
view
*****************/
.page_myresume_view .pdf-area { margin: 10px auto; text-align:right; }


.myresume_view{border:1px solid #999999; padding:42px 100px 0;}
.myresume_view .resume_title{display: flex; justify-content: space-between; align-items: center; font-size: 26px; font-weight: bold; line-height: 1.15; letter-spacing: -1.3px; padding:0 0 58px 8px;}
.myresume_view .resume_title .title_field{font-size: 26px; font-weight: bold; line-height: 1.15;}
.myresume_view .resume_title .qr{min-width: 49px; margin-left: 40px; text-align: right;}
/*프로필*/
.myresume_view .profile_area{ position:relative; padding:0 20px 80px; overflow: hidden; display:flex; justify-content: space-between;}
.myresume_view .profile_area .right_wrap { width:calc(100% - 236px); }
.myresume_view .profile_area .profile_img{ /*background:url('../img/sub/profile.gif') no-repeat;*/ background-repeat: no-repeat; background-position: center; width: 157px; height: 200px; aspect-ratio: 156 / 200; margin-inline: 80px;}
.myresume_view .profile_area .profile_img img { max-height:100%; }
.myresume_view .profile_area .name_field,
.myresume_view .profile_area .info_area {width: calc(100%);}
.myresume_view .profile_area .info_area { display:flex; justify-content: space-between;}
.myresume_view .profile_area .name_field{font-size: 28px; font-weight: bold; line-height: 1.5; letter-spacing: -0.65px; color:#000; padding:0 0 27px; margin-top: -5px;}
.myresume_view .profile_area .info_area ul:first-child {width:60%;}
.myresume_view .profile_area .info_area ul:last-child {width:38%;}
.myresume_view .profile_area .info_area li{ position:relative; padding-left:126px; font-size:17px; padding-bottom: 7px;color:#585858;}
.myresume_view .profile_area .info_area .title_field{ position:absolute; left:0; display: inline-flex; align-items: center; justify-content: space-between; }
.myresume_view .profile_area .info_area ul:first-child .title_field{width:126px}
.myresume_view .profile_area .info_area ul:last-child li { padding-left:78px; }
.myresume_view .profile_area .info_area ul:last-child .title_field{width:78px; }
.myresume_view .profile_area .info_area .title_field:after{content: ":"; display: inline-block; padding:0 6px 0 5px;}
.myresume_view .profile_area .tip_field { position: absolute; left:25px; bottom:38px; color:#666666}
.myresume_view .tip_field,
.myresume_write .tip_field{letter-spacing: -0.38px; color: #cc3333; font-size:17px;}
.myresume_view .tip_field.gray,
.myresume_write .tip_field.gray { color:#999; }
/**/
.myresume_view .section{clear: both; border-top:1px solid #ccc; overflow: hidden; position: relative; padding:44px 0;}
.myresume_view .profile_area+.section{border-top:1px solid #333;}
.myresume_view .section .title_area{float: left; width: 160px; padding:0 25px;letter-spacing: -1px; font-size:18px;font-weight: bold; color:#666666; line-height:31px; }
.myresume_view .section .info_area,
.myresume_view .section .tip_field,
.myresume_view .section .total_field{float: right; width:calc(100% - 160px); padding:0 5px;}
.myresume_view .section .tip_field{padding:0 0 28px 0;  color:#666}
.myresume_view .section .total_field{padding-bottom: 23px; padding-top:40px;color:#333; font-size:15px; letter-spacing: -0.25px; text-align: right;}
.myresume_view .section .tip_field+.total_field{margin-top:-80px;}
.myresume_view .section .total_field .point{color:#d02928;}
.myresume_view .section .total_field+.info_area{border-top:1px solid #f0f0f0;}
.myresume_view .section .info_area .title_field{letter-spacing: -0.5px; font-size:20px; color:#333; font-weight: bold;}



.myresume_view .section.type2 .info_area{padding-top:38px; padding-bottom: 38px;}
.myresume_view .section .info_area:not(:last-of-type){padding-bottom:40px}
.myresume_view .section .info_area,
.myresume_view .section .info_area p{font-size:17px; color:#000; letter-spacing: -0.38px; line-height: 1.7;}
.myresume_view .section .info_area {display: inline-block;}
.myresume_view .section .info_area .division{margin-top:35px;}
.myresume_view .section .info_area .title_field2{color:#666;}
.myresume_view .section .info_area .title_field {padding-right: 17px; width:calc(100% - 200px); display: inline-flex; flex-direction: column; align-items: flex-start;}
.myresume_view .section .info_area .title_field .part_field{letter-spacing: -0.38px; font-size:17px; color:#666; font-weight: 400; width:30%; min-width: 30%; line-height:1.47; }
.myresume_view .section .info_area .title_field .part_field {letter-spacing: -0.38px; font-size:17px; color:#666; font-weight: 400; width:30%; min-width: 30%; line-height:1.47; }
.page_talent_view .myresume_view .section .info_area .title_field .part_field { width: 100%; min-width: 100%; }
.myresume_view .section .info_area .date_field{ display:inline-flex; align-items:flex-end; flex-direction: column; width:100%; font-size:17px; font-weight: 500; color:#333; letter-spacing: -0.38px; width:200px; min-width: 200px; white-space: nowrap; text-align: right; vertical-align: top;}
.myresume_view .section .info_area .date_field .left_wrap{ display:inline-block; width:200px; text-align:right; }
.myresume_view .section .info_area .career_detail:first-of-type{margin-top: 14px;}
.myresume_view .section .info_area .career_detail + .career_detail { margin-top: 10px; }
.myresume_view .section .info_area .subtitle_field{clear: both; font-size:17px; line-height: 1.47; color:#666; letter-spacing: -0.38px; margin-bottom: 4px; padding-left: 11px;}
.myresume_view .section .info_area .subtitle_field:before{content:''; background: #333333; width: 5px; height: 5px; margin-right: 6px; margin-left: -11px; display: inline-block; border-radius: 10px; vertical-align: 3px;}
.myresume_view .section .info_area .text{color:#666; letter-spacing: -0.38px; font-size:17px; line-height: 1.67; padding-left:17px;}

.myresume_view .section .title_cell{width:92px; display: inline-flex; justify-content: space-between; align-items:center;}
.myresume_view .section .title_cell:after{content: ":";  margin-right:3px}

/*추가하기 버튼*/
.myresume_view .section .add_btn{position: absolute; bottom:34px; right: 25px; font-size:15px; letter-spacing: -0.38px; color:#000;}
.myresume_view .section .add_btn img{margin-right: 9px;}

.myresume_view .section.school .info_area .title_field{font-weight: 700;}
.myresume_view .section.school .date_field{font-size:17px}
.myresume_view .section.portfolio .info_area p{display: block;}
.myresume_view .section.portfolio .link{margin-left: 2px; font-size:15px; cursor:pointer;}
.myresume_view .section.work_condition p{display: block;}
.page_myresume_view .btn_bottom_area{justify-content: space-between;}

.myresume_view .section.sec_career { padding-top:74px; }
.myresume_view .section.sec_career .tip_field { position:absolute; left:25px; top:40px; }
.myresume_view .total_career_text { font-size: 17px; margin-bottom: 8px; }

/********************
write
*********************/
.page_myresume_write{display: flex; justify-content:space-between;}
.myresume_write input[type=file],
.myresume_write a{cursor: pointer;}
.myresume_write{border:1px solid #999;}
.myresume_write .btn_bottom_area{padding-top:28px;}
.myresume_write .btn_bottom_area.type_resume_write { padding-top:10px; justify-content: flex-start; }
.myresume_write .btn_bottom_area .btn{height:50px; min-height: 50px;padding:0 10px; min-width: 220px; white-space: nowrap}
.myresume_write .btn_bottom_area .btn.txt { height: auto; min-height: auto; min-width: auto; font-size: 18px; }

/*좌측*/
.page_myresume_write .container{width: 890px;}
.page_myresume_write .container .top_area{display: flex; justify-content: space-between; padding-left: 10px;}
.page_myresume_write .container .top_area .chk+label{margin-left: auto; font-size:15px;}
.page_myresume_write .container .top_area .button{margin-left: 30px; font-size:15px; color:#000; letter-spacing: -0.25px;}
.myresume_write{margin-top: 12px;}
.myresume_write .resume_title{border:none; height: 70px; border-bottom:1px solid #d4d4d4; padding: 27px 20px;}
.myresume_write .title_area{font-size:20px; letter-spacing: -0.5px; color:#000; font-weight: bold; border-bottom:1px solid #999; padding:30px 0  17px; margin: 0 19px; display: flex; justify-content: space-between; align-items: center;}
.myresume_write .title_area .title{font-size:20px; letter-spacing: -0.5px; color:#000; font-weight: bold;}
.myresume_write .title_area .btn{height: 17px; min-height: auto; margin-right: auto;}
.myresume_write .title_area .chk+label{font-size:15px; font-weight: 400;}
.myresume_write .title_area .total_field{margin-left: auto; color:#333; font-size:15px; letter-spacing: -0.25px; text-align: right;}
.myresume_write .title_area .total_field .point{color:#d02928;}
.myresume_write .title_area .title+.chk+label{margin-left:12px;}
.myresume_write .title_area .chk+label+.tip_field{margin-left: 8px;}

/*프로필영역*/
.myresume_write .profile_area{display: flex; justify-content: space-between; margin: 0 19px; padding:20px 0;}
/*프로필 이미지*/
.myresume_write .profile_area .profile_img{min-width: 177px; width:177px; border:1px solid #e2e2e2; padding:10px; position:relative}
.myresume_write .profile_area .profile_img .img{background: #f7f9fa; width: 157px; height: 200px; text-align: center; display: flex; justify-content: center; align-items: center; flex-direction: column;}
.myresume_write .profile_area .profile_img .img img{position:relative; z-index: 2; max-height: 200px; max-width: 157px;}
.myresume_write .profile_area .profile_img .img span{display: block; color:#999999; font-size:12px; padding-top: 12px; position:absolute; z-index: 1; bottom:55px;}
.myresume_write .profile_area .profile_img .add_file{display: none;}
.myresume_write .profile_area .profile_img .add_file+label{color:#000; font-size:15px; letter-spacing: -0.5px; text-align: center; margin-top: 10px; display: block; cursor: pointer;}
.myresume_write .profile_area .tip_field .txt{display: block;}
/*프로필 입력*/
.myresume_write .profile_area .info_area{display: flex; justify-content: space-between; flex-wrap: wrap; width: calc(100% - 179px); align-items: center;}
.myresume_write .profile_area .info_area li{width: 50%; padding:0 22px; display: flex; align-items: center;}
.myresume_write .profile_area .info_area .full{width: 100%;}
.myresume_write .profile_area .info_area .title_field{width:65px; min-width: 52px; padding-right:10px; color:#333; font-size:15px;white-space: nowrap;}
.myresume_write .profile_area .info_area input[type=text]{color:#000; font-size:15px; width: 197px; min-width: 197px;}
.myresume_write .profile_area .info_area .unit{color:#333; font-size:15px; margin-left: 5px;}
.myresume_write .profile_area .chk+label{font-size:15px;}
.myresume_write .profile_area .info_area .email_field .text{margin:0 5px; font-size:15px;}

.myresume_write .section{margin:0 19px;}
.myresume_write .section+.section{margin-top: 55px;}
.myresume_write .section:last-of-type { padding-bottom:50px; }
.myresume_write .section .wrap{max-height: 535px; overflow-y: auto;}
/* ::-webkit-scrollbar{display:none;} 22-09-26 왜 있는지 모르겠음? 이것때문에 스크롤이 안 보여서 일단 주석함 */

.myresume_write .input_area{margin-top: 18px; position: relative;}
.myresume_write .input_area+.input_area{margin-top:28px;}
.myresume_write .input_area input,
.myresume_write .input_area select{float:left; margin-bottom: 12px; margin-left: 0 !important;}
.myresume_write .input_area input+input{margin-left: 0;}
.myresume_write .input_area .input_title{width:19.3%; margin-right: 1.2%;}
.myresume_write .input_area .input_title.wd-xs{width:19.3%}
.myresume_write .input_area .input_part{width:18%; margin-right: 1.2%}
.myresume_write .input_area .input_part2{width: 13%; margin-right: 1.2%;}
.myresume_write .input_area .input_part3{width:75.2%; margin-right: 1.2%}
.myresume_write .input_area .input_part4{width:60%; margin-right: 1.2%}
.myresume_write .input_area .input_part5{width:calc(100% - 29px - 1.2%); margin-right: 1.2%}
.myresume_write .input_area .input_date{width: 14.1%; margin-right: 1.2%;}
.myresume_write .input_area .react-datepicker-wrapper {width:14.1%;margin-right:1.2%; float: left;}
.myresume_write .input_area .react-datepicker-wrapper .input_date{width: 100%; margin-right: 0}
.myresume_write .input_area .input_state{width: 9.8%; margin-left: 0}
.myresume_write .input_area .input_subtitle{clear: both; width: 94.2%; margin-left: 0; margin-top: 5px;}
.myresume_write .input_area .content{width: 95.2%; height: 92px; }
.myresume_write .input_area .btn_add{float:right; margin-right: 4.8%; font-size:15px; color:#000;}
.myresume_write .input_area .btn_add img{margin-right: 5px;}
.myresume_write .input_area:after{content: ''; width: 100%; display: block; clear: both; height: 1;}
.myresume_write .input_area+.btn{margin: 0 auto; padding:0 24px;}
.myresume_write .btn_del{position: absolute; top:10px; right: 0; width: 29px; height: 29px; line-height: 27px; border-radius: 100px; background: #d7dde6; text-align: center; vertical-align: 2px;}
.myresume_write .bg_on { background-color:#f2f2f2; }

.myresume_write .section .title_area{margin: 0;}
.myresume_write .section.school .input_area+.input_area{padding-top:0;}
.myresume_write .section.school .input_area input{margin-bottom:0;}
.myresume_write .section.school .input_area:after{height: 1px;}

/*자기소개서*/
.myresume_write .textarea_tip{font-size:15px; padding-top:12px; color:#000; float: left;}
.myresume_write .textarea_tip.type2{float: right; color:#999}

/*포트폴리오*/
.myresume_write .portfolio_file{clear: both; font-size:15px; padding-left:10px;}
.myresume_write .portfolio_file+.portfolio_file{padding-top:8px;}
.myresume_write .portfolio_file .btn_del{width: 19px; height: 19px; line-height: 17px; position: relative; top:auto; right: auto; margin-left: 10px;}

/*희망근무조건*/
.myresume_write .section.work_condition .input_area input,
.myresume_write .section.work_condition .input_area select{width:auto; margin-right: 10px; }
.myresume_write .section.work_condition .input_area .chk+label{font-size:15px;line-height: 47px;}
.myresume_write .section.work_condition .top{display: flex; align-items: center;}
.myresume_write .section.work_condition .btn{background: #3269cc; min-height: 47px; height: 47px; font-size:15px; width:130px;}
.myresume_write .section.work_condition .tip_field {margin-left:10px; display: inline-block; vertical-align: middle; color:#999; }
.myresume_write .work_condition .select_area{padding:30px 0; border-top:1px solid #e2e2e2;}
.myresume_write .work_condition .select_area:last-of-type{padding-bottom: 0;}
:is(.myresume_write .work_condition, .page_announcement .table) .selected{clear: both; padding-top: 20px; overflow: hidden;}
:is(.myresume_write .work_condition, .page_announcement .table) .selected li{float: left; margin-right: 30px; font-size:15px;}
:is(.myresume_write .work_condition, .page_announcement .table) .selected .btn_del{position: relative; right: auto; top:auto; width:19px; height: 19px; line-height: 17px; margin-left: 5px;}
.myresume_write .preference select{width:calc(100% - 38px)}
.myresume_write .preference .input_part3{width:calc(100% - 38px)}
.page_announcement .table .selected { padding-top:0; }
.page_announcement .table .selected li { padding-top:10px; display: flex; align-items: center; line-height: 1.5; letter-spacing: 2px; word-spacing: 2px; font-size:13px}
.page_announcement .table .btn_del { border-radius: 100px; background: #d7dde6; text-align: center; vertical-align: 2px; }


/*이직희망여부*/
.change_jobs{display: flex; align-items: center; padding-bottom:50px;}
.change_jobs .title_area{border-bottom:none; padding:0 40px 0  0; }
.change_jobs input+label{font-size:15px;}
.change_jobs .input_area{margin-top: 0;font-size:15px; padding-left: 5px;}

/*우측*/
.page_myresume_write .aside{width:330px; margin-top: 36px;}

/*완성도*/
.page_myresume_write .aside .state_area{display: flex; align-items: center; justify-content: space-around; border:1px solid #dedede; border-bottom:none; padding:20px 0 13px;}
.page_myresume_write .aside .state_area .title_field{font-size:22px; font-weight: bold; color:#000; padding-left: 10px;}
.page_myresume_write .aside .state_area  .diagram{width: 95px; height: 95px; text-align: center; line-height:95px; font-size:22px; font-family: 'Montserrat'; font-weight: bold; background-position: center; background-size: 100%;background-image: url(../img/sub/diagram0.jpg); background-repeat: no-repeat;}
.page_myresume_write .aside .state_area .diagram.step1{background-image: url(../img/sub/diagram1.jpg)}
.page_myresume_write .aside .state_area .diagram.step2{background-image: url(../img/sub/diagram2.jpg)}
.page_myresume_write .aside .state_area .diagram.step3{background-image: url(../img/sub/diagram3.jpg)}
.page_myresume_write .aside .state_area .diagram.step4{background-image: url(../img/sub/diagram4.jpg)}
.page_myresume_write .aside .state_area .diagram .per{font-size:15px;}

.page_myresume_write .aside .stat_chek{background: #3d7df2; padding:25px 23px 6px; border-bottom:1px solid #dedede; margin-bottom:20px;}
.page_myresume_write .aside .stat_chek li{display: flex; justify-content: space-between; padding-bottom: 14px; font-size:15px; color:#fff; letter-spacing: -0.5px;}
.page_myresume_write .aside .stat_chek input:after{content: ''; display: block; width:21px; height: 16px; background: url("../img/sub/icon_chk.png") no-repeat; opacity: 0.2;}
.page_myresume_write .aside .stat_chek  input:checked:after{opacity: 1;}
.page_myresume_write .aside .stat_chek input:read-only, 
.page_myresume_write .aside .stat_chek input.read-only{background: none;}

.page_myresume_write .aside .btn{width:100%; margin-bottom:10px; margin-left: 0; font-weight: bold;}

.c_red{color: #cc3333 !important;}

/*작성팁*/
.myresume_write .title_area .guide{margin-right: auto; margin-left:10px; position:relative;}
.myresume_write .title_area .guide .guide_btn{display: inline-flex; align-items: center; cursor: pointer;}
.myresume_write .title_area .guide .guide_btn .tit{font-weight: normal; font-size:15px;}
.myresume_write .title_area .guide .guide_btn .icon{background: url("../img/sub/ico_tip.png") no-repeat; width: 15px; height: 15px; display: inline-block; background-size: contain; margin-left: 5px; margin-top: 2px;}
.myresume_write .title_area .guide .guide_secret {
  display: none;
  font-size: inherit;
  letter-spacing: inherit;
  position: absolute;
  top: 20px;
  width: 400px;
  left: 0;
  z-index: 100;
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  box-shadow: 2px 2px 2px rgba(0,0,0,.1);
  word-break: break-all;
  inline-size: max-content;
}
.myresume_write .title_area .guide .guide_secret_tit{color:#3269cc; font-weight: bold; margin-bottom: 5px; font-size:15px;}
.myresume_write .title_area .guide .guide_secret_txt{font-size:14px; line-height: 1.5;}
.myresume_write .title_area .guide:hover .guide_secret{display: block;}

.myresume_write .title_area.has_guide_secret .btn{margin-right: 0;}


@media screen and (min-width:1260px) {
    .fly {
        position:absolute;width:330px; margin-top: 36px;
    }
    .fly2 {
        position:absolute;width:330px;
    }
}
@media screen and (max-width: 1260px) and (min-width: 1024px) {
    .fly {
        position:absolute; margin-top: 36px; width:calc(100% - 70.6% - 37px);
    }
    .fly2 {
        position:absolute; width:calc(100% - 70.6% - 37px);
    }
}
@media screen and (max-width:1260px){
    .myresume_view{border:none; padding-top:0; padding-bottom:0; padding-inline: 30px;}
    .myresume_view .resume_title{padding-top:0;}
    .myresume_view .profile_area .profile_img { margin-inline: 60px; }
    .page_myresume_write{display: block;}
    .page_myresume_write .container{width: 100%;}
    .page_myresume_write .aside{width: 100%}
    .myresume_view .section .tip_field+.total_field{margin-top: 0; padding-top:0;}

    .myresume_view.PDF{border:1px solid #999999; padding:42px 80px 0 80px; min-width: 1260px;}
    .myresume_view.PDF .section .tip_field+.total_field{margin-top:-80px;}
}
@media screen and (max-width:1024px){
    .page_myresume_list .list_top{margin:10px 0; flex-direction: row;  justify-content: space-between; align-items: center; padding:0 !important;}
    .page_myresume_list .list_top .sub_content_title{margin-left: 0}
    .page_myresume_list td.view_pc{display: none;}
    .myresume_view .profile_area .info_area ul { width: 100% !important; }
    .myresume_view .profile_area .info_area ul:last-child li { padding-left: 126px; }
    .myresume_view .profile_area .info_area ul:last-child .title_field { width: 126px; }
    .myresume_view .profile_area .profile_img { margin-inline: 30px; }
    
    .page_restricted .list_top{flex-direction: row}
    .page_restricted .table_list01 td.td_button{top:3px;}
    
    /****************
    view
    *****************/
    .myresume_view .profile_area .info_area{display: block;}
    .myresume_view .profile_area .info_area li{width: 100%;}
    .myresume_view.PDF .profile_area .info_area ul{width:50%; float: left; clear: none;}
    /****************
    write
    *****************/
    .page_myresume_write .container .top_area{padding-left:0;}
    .myresume_write .profile_area{display: block;}
    .myresume_write .profile_area .profile_img{width: 177px; margin: 0 auto 19px;}
    .myresume_write .profile_area .tip_field {margin-bottom: 10px;text-align: center;}
    .myresume_write .profile_area .tip_field .txt{display: inline;}
    .myresume_write .profile_area .info_area{display: block; width: 100%;}
    .myresume_write .profile_area .info_area li{margin-bottom:15px; padding-left: 13px; width: 100%;}
    .myresume_write .profile_area .info_area .title_field{width: 100px; min-width: 100px;}
    .myresume_write .profile_area .info_area input[type=text]{width: 100%;}
    
    .myresume_write .section .title_area{flex-wrap: wrap;}
    .myresume_write .section .title_area .title{order:1;}
    .myresume_write .section .title_area .btn.txt{order:2;}
    .myresume_write .title_area .title+.chk+label{order:2;}
    .myresume_write .title_area .tip_field{order:5; width: 100%; margin-left:0 !important; margin-top:10px;}
    .myresume_write .title_area .total_field{order:4; margin-left: auto}
    .myresume_write .title_area .guide{order:3; margin-left: 10px; }
    .myresume_write .input_area .input_title,
    .myresume_write .input_area .input_part,
    .myresume_write .input_area .input_date,
    .myresume_write .input_area .react-datepicker-wrapper,
    .myresume_write .input_area .input_title.wd-xs{width: 34%;}
    .myresume_write .input_area .input_part3{width: 59%;}
    .myresume_write .input_area .input_part2{width: 25%;}
    .myresume_write .input_area .input_part4{width: 25%;}
    .myresume_write .input_area .input_state{width: 25%;}
    .myresume_write .input_area .input_subtitle{width:95.4%;}
    
    /*학력*/
    .myresume_write .section.school .input_area input{margin-bottom: 12px;}

    /*취업우대사항*/
    .myresume_write .preference select,
    .myresume_write .preference .input_part3{width:calc(100% - 25px)}
}
@media screen and (max-width:768px){/*모바일 디자인 적용*/
    .page_myresume_list{padding:0;} 
    .page_myresume_list .list_top{padding:0 4% !important; }
    .page_myresume_list .list_top .btn{font-size:12px; min-height:28px; height: 28px; padding:0 7px;}
    .page_myresume_list .list_top .sub_content_title{padding-right: 0; margin-right: 0}
    .page_myresume_list .table_list01.with_chk .td_chk { top:18px; }
    .page_myresume_list .table_list01 td.td_title {text-overflow: ellipsis; white-space: nowrap; word-wrap: normal; overflow: hidden; max-width: 57%; display: block; }

    .page_myresume_list .btn_bottom_area,
    .page_myresume_list .myresume_setting{margin-left: 4%; margin-right: 4%; display: flex; justify-content: space-between; align-items: center; padding:18px 0;}
    .page_myresume_list .myresume_setting .text{font-size:13px;}
    .page_myresume_list .myresume_setting .setting{min-width: 52px;}
    .page_myresume_list .myresume_setting .setting input[type="checkbox"]{margin-top: -10px;}
    
    .myresume_tip{margin-top: 30px; margin-left: 4%;}
    .myresume_tip .title_area{font-size:13px; margin-bottom: 9px;}
    .myresume_tip .list li{font-size:12px;}
    
    .restricted_setting{padding:20px 4%; margin-top: 30px;}
    .restricted_setting .title_field{font-size:13px; margin-bottom: 9px;}
    .restricted_setting .text{font-size:12px;}
    .restricted_setting .wrap{display: block;}
    .restricted_setting .btn_area{margin-top: 10px;}
    .restricted_setting .btn_area .btn{font-size:12px; min-height: 26px; height: 26px;}
    
    
    .page_restricted .list_top{padding-left:0 !important; padding-right: 0 !important; margin-top: 18px}
    .page_restricted .list_top .btn{height: 26px; min-height: 26px;}
    .page_restricted .list_top .list_search_area{width: 100%; margin: 0;}
    .page_restricted .table_list01 td.td_button{top: 5px; padding-right: 0; width: 83px}
    /****************
    view
    *****************/
    .myresume_view{padding:0 4%; padding-block: 0;}
    .myresume_view .section .tip_field+.total_field{padding-top:18px;}
    .myresume_view .tip_field{font-size:11px; padding-top:18px;}
    .myresume_view .resume_title .title_field{font-size:16px;}
    .myresume_view .resume_title{padding:20px 0 18px; line-height: 24px; font-size:18px;}
    
    /*프로필*/
    .myresume_view .profile_area{padding:22px 0 22px 1px; justify-content: flex-start;}
    .myresume_view .profile_area .right_wrap { width: auto; padding-left:20px; }
    
    .myresume_view .profile_area .profile_img{width: 120px; height: 152px; margin-inline: 0;}
    .myresume_view .profile_area .name_field, 
    .myresume_view .profile_area .info_area{width: 100%;}
    .myresume_view .profile_area .info_area ul{width: 100%; clear: both;}
    .myresume_view .profile_area .tip_field{width: 100%; clear: both; bottom: 0; left: 0;}
    .myresume_view .profile_area .info_area li{font-size:13px; padding-bottom: 5px; padding-left: 104px;}
    .myresume_view .profile_area .info_area ul:last-child li { padding-left: 104px; }
    .myresume_view .profile_area .name_field{font-size:14px; padding-bottom: 15px;} 
    .myresume_view .profile_area .info_area ul:last-child .title_field,
    .myresume_view .profile_area .info_area ul:first-child .title_field{width:100px}
    /**/
    .myresume_view .profile_area+.section{border-top:none}
    .myresume_view .section{border:none; padding:25px 0; }
    .myresume_view .section.sec_career { padding-top: 25px; }
    .myresume_view .section .title_area,
    .myresume_view .section .info_area, 
    .myresume_view .section .tip_field{ width: 100%;}
    .myresume_view .section .title_area{border-bottom:1px solid #000; padding:18px 0 12px; font-size:14px; margin-bottom: 13px;}
    
    .myresume_view .section.type2 .info_area,
    .myresume_view .section .total_field{padding:18px 0;}
    .myresume_view .section .tip_field{padding-bottom:18px; }
    .myresume_view .section.sec_career .tip_field { left:0; top:20px; }
    .myresume_view .total_career_text { font-size: 13px; position: absolute; right: 0; top: 44px; }
    .myresume_view .section .info_area:not(:last-of-type){padding-bottom:18px}
    .myresume_view .section .info_area .title_field{font-size:13px; width:calc(100% - 136px);}
    .myresume_view .section .info_area .title_field .part_field,
    .myresume_view .section .info_area .date_field,
    .myresume_view .section .info_area .text{font-size:12px; width: 100%;}
    .myresume_view .section .info_area .date_field{width: 136px; min-width:136px; }
    .myresume_view .section .info_area .subtitle_field{font-size:12px;  padding-left: 10px; margin-bottom: 0;}
    .myresume_view .section .info_area .subtitle_field:before{width:5px; height: 5px; margin-right: 5px; margin-left: -10px;}
    
    .myresume_view .section .info_area .text+.subtitle_field{border-top:1px solid #f0f0f0; margin-top: 18px;}
    
    .myresume_view .section .info_area .career_detail:first-of-type{margin-top:5px}
    .myresume_view .section .total_field{font-size:12px; width: 100%; padding-top: 8px;}
    .myresume_view .section .info_area,
    .myresume_view .section .info_area p{line-height: 17px;}
    
    .myresume_view .section .title_cell{width:68px}
    /*학력*/
    .myresume_view .section.school .info_area{overflow: hidden;}
    
    /*자기소개서*/
    .myresume_view .section .info_area, .myresume_view .section .info_area p{font-size:12px;}
    .myresume_view .section .info_area .division{margin-top: 0}
    
    /*포트폴리오*/
    .myresume_view .section.portfolio .link{font-size:12px; }
    
    /***********
    pdf
    *************/
    .myresume_view.PDF .profile_area{padding:0 20px 63px;}
    .myresume_view.PDF .profile_area .name_field{font-size:26px;}
    .myresume_view.PDF .profile_area .profile_img{width: 156px; height: 200px;}
    .myresume_view.PDF .profile_area .name_field, 
    .myresume_view.PDF .profile_area .info_area{width: calc(100% - 200px);}
    .myresume_view.PDF .profile_area .info_area ul{width: 50%; clear: none;}
    .myresume_view.PDF .profile_area .tip_field{width: calc(100% - 200px); clear: none;}
    .myresume_view.PDF .profile_area .info_area li{font-size:15px; padding-bottom: 7px;}
    .myresume_view.PDF .profile_area .name_field{padding-bottom: 27px}
    .myresume_view.PDF .profile_area .info_area ul:first-child .title_field{width:111px}
    .myresume_view.PDF .profile_area .info_area ul:last-child .title_field{width:69px}  
    /**/
    .myresume_view.PDF .profile_area+.section{border-top:1px solid #333}
    .myresume_view.PDF .section{border-top:1px solid #ccc; padding:44px 0}
    .myresume_view.PDF .section .title_area{width: 160px;}
    .myresume_view.PDF .section .info_area{width:calc(100% - 160px);} 
    .myresume_view.PDF .section .title_area{border-bottom:none; padding:0 25px; font-size:16px; margin-bottom: 0;}
    
    .myresume_view.PDF .section.type2 .info_area{padding-top:38px; padding-bottom: 38px;}
    .myresume_view.PDF .section .total_field{padding:0 5px;}
    .myresume_view.PDF .section .tip_field{padding:0 0 28px 0; width: calc(100% - 160px); font-size:15px}
    .myresume_view.PDF .section .info_area:not(:last-of-type){padding-bottom:40px}
    .myresume_view.PDF .section .info_area .title_field{font-size:18px; width:calc(100% - 175px);}
    .myresume_view.PDF .section .info_area .title_field .part_field,
    .myresume_view.PDF .section .info_area .date_field,
    .myresume_view.PDF .section .info_area .text{font-size:15px;}
    .myresume_view.PDF .section .info_area .date_field{width:175px; min-width: 175px;}
    .myresume_view.PDF .section .info_area .subtitle_field{font-size:15px;  padding-left: 11px; margin-bottom: 4px;}
    .myresume_view.PDF .section .info_area .subtitle_field:before{width:5px; height: 5px; margin-right: 6px; margin-left: -11px;}
    
    /*.myresume_view.PDF .section .info_area .text+.subtitle_field{border-top:1px solid #f0f0f0; margin-top: 18px;}*/
    
    .myresume_view.PDF .section .info_area .career_detail:first-of-type{margin-top:14px}
    .myresume_view.PDF .section .total_field{font-size:15px;width:calc(100% - 160px); padding-bottom: 23px; padding-top:40px;}
    .myresume_view.PDF .section .info_area,
    .myresume_view.PDF .section .info_area p{line-height: 25px;}

    /*학력*/
    /*.myresume_view.PDF .section.school .info_area{overflow: hidden;}*/
    .myresume_view.PDF .section .title_cell{width:78px}
    /*자기소개서*/
    .myresume_view.PDF .section .info_area, 
    .myresume_view.PDF .section .info_area p{font-size:15px;}
    .myresume_view.PDF .section .info_area .division{margin-top: 35px}
    
    /*포트폴리오*/
    .myresume_view.PDF .section.portfolio .link{font-size:15px; }




    /*이직의향*/
    .change_jobs{flex-direction: column;}
    .change_jobs .input_area{width: 100%; text-align: left; padding-top: 18px; padding-left: 0; font-size:12px;}
    /************
    이력서 작성
    *************/
    .page_myresume_write{padding:0;}
    .page_myresume_write .myresume_write{border:none; border-top:1px solid #999;}
    .page_myresume_write .container .top_area{margin-top: 20px; align-items: center; padding:0 4%;}
    .page_myresume_write .container .top_area .chk+label{font-size:12px;}
    .page_myresume_write .container .top_area .button{font-size:12px; margin-left: 10px;}
    .page_myresume_write .container .top_area .button img{margin-right: 3px; height: 12px; vertical-align: -1px;}
    .myresume_write .resume_title{height: 58px; padding:10px 4%;}
    
    .myresume_write .title_area{font-size:15px; padding:20px 0 12px; margin: 0 4%;}
    .myresume_write .title_area .title{font-size:15px;}
    .myresume_write .title_area .title+.chk+label{font-size:11px;}
    .myresume_write .title_area .guide .guide_btn .tit{font-size:12px;}

    
    /*프로필*/
    .myresume_write .profile_area{margin:0 4%;}
    .myresume_write .profile_area .profile_img .img span{font-size:9px;}
    .myresume_write .profile_area .profile_img .add_file+label{font-size:11px;}
    .myresume_write .profile_area .info_area .title_field{font-size:13px; min-width: 60px; width: 60px;}
    .myresume_write .profile_area .info_area .unit{font-size:13px;}
    .myresume_write .profile_area .info_area li{font-size:12px; margin-bottom: 10px; padding-left:0; padding-right: 0;}
    .myresume_write .profile_area .info_area input[type=text]{min-width: inherit;}
    
    /*공통*/
    .myresume_write .section{margin: 0 4%;}
    .myresume_write .section+.section{margin-top:10px }
    .myresume_write .section:last-of-type { padding-bottom: 10px; border-bottom:1px solid #cdcdcd; }
    .myresume_write .input_area .btn_add{font-size:12px;}
    .myresume_write .title_area .total_field{font-size:12px;}
    .myresume_write .title_area .chk+label+.tip_field{font-size:12px;}
    .myresume_write .btn_bottom_area .btn{width: 100%; font-weight: bold;}
    .myresume_write .btn_del{width:20px; height: 20px; line-height: 16px;}
    .myresume_write .btn_del img{width: 50%;}
    .myresume_write .input_area .input_subtitle{width:91.4%;}
    .myresume_write .title_area .guide{position:initial; margin-left: 5px;}
    .myresume_write .title_area .btn.txt+ .guide{margin-left: 0;}
    .myresume_write .title_area.has_guide_secret{position:relative}
    .myresume_write .title_area .guide .guide_secret{top:45px; left:0; width:100%;}
    .myresume_write .title_area .guide .guide_secret_tit{font-size:13px;}
    .myresume_write .title_area .guide .guide_secret_txt{font-size:12px;}
    
    /*자기소개서*/
    .myresume_write .textarea_tip{font-size:12px;}
    
    /*포트폴리오*/
    .myresume_write .portfolio_file{font-size:13px;}
    
    /*근무희망조건*/
    .myresume_write .work_condition .select_area{padding:20px 0;}
    .myresume_write .section.work_condition .input_area .chk+label{font-size:13px;}
    .myresume_view .tip_field, 
    .myresume_write .tip_field{font-size:13px; white-space: normal; word-break: keep-all;}
    .myresume_write .profile_area .tip_field .txt{font-size:13px}
    .myresume_write .section.work_condition .btn{font-size:13px; min-height: 40px; height: 40px; width:105px;}
    .myresume_write .work_condition .selected li{font-size:13px;}
    .myresume_write .section.work_condition .input_area [type="text"] + label { font-size:13px; display:inline-block; margin:13px 0 0 0; }
    /*이직희망여부*/
    .myresume_write .change_jobs{margin-top: 40px !important; padding-bottom: 30px;}
    .myresume_write .change_jobs .title_area{padding:20px 0 12px 0; border-bottom:1px solid #999; display: block; width: 100%; }
    .myresume_write .change_jobs label{margin-right:10px;}
    .myresume_write .change_jobs label+input[type=radio]+label{margin-left: 0;}
    .change_jobs input+label{font-size:13px;}
    .change_jobs{padding-bottom: 25px;}
    
    /*우측*/
    .page_myresume_write .aside{margin: 24px 4% 0; width: 92%}
    .page_myresume_write .aside .state_area{justify-content: space-between; padding-top:13px; }
    .page_myresume_write .aside .state_area .title_field{font-size:15px; padding-left:4%;}
    .page_myresume_write .aside .state_area .diagram{margin-right: 4%; font-size:16px; width:76px; height:76px; line-height:76px;}
    .page_myresume_write .aside .state_area .diagram .per{font-size:15px; line-height:76px; }
    .page_myresume_write .aside .stat_chek{padding:15px 4% 5px;}
    .page_myresume_write .aside .stat_chek li{font-size:13px; padding-bottom: 8px;}
    .page_myresume_write .aside .btn{margin-bottom: 6px;}

    .page_myresume_write .aside .stat_chek input:after { width:17px; height:12px; background-size: cover; background-position: center center; }


    }
@media screen and (max-width:640px){
    .myresume_write .section.work_condition .input_area [type="text"] + label { font-size:11px; }
}
@media screen and (max-width:450px) {
    .page_myresume_write .email_field .text{width:calc(10% - 10px) !important;}
    .page_myresume_write .email_field input[type=text]{width:calc(45% - 30px) !important;}
    .page_myresume_write .email_field input[type=text]:read-only { width: 100% !important; }
    .page_myresume_write .email_field select{width:calc(100% - 60px) !important; margin-left: 60px;}
    .page_myresume_list .list_top{ flex-wrap: wrap; justify-content: flex-end;}
    .page_myresume_list .list_top .btn.gray { margin-left: 0; }
    .page_myresume_list .list_top .btn+.btn { margin-left: 2px; }
    .page_myresume_list .list_top h2 { width:100%; margin-bottom:10px; }
    .myresume_view .profile_area .tip_field { font-size:11px; bottom: 6px; }
    .myresume_view .section.sec_career .tip_field { font-size: 11px; top: 10px;}
}
@media screen and (max-width:375px){
    .myresume_view .profile_area .profile_img{width: 100px; height: 127px;}
    .myresume_view .profile_area .profile_img{border-width: 3px; background-size: 80%;}
    .page_myresume_list .table_list01 td.td_title { max-width: 50%; }
    .myresume_view .profile_area .right_wrap { padding-left: 10px; }
}
@media screen and (max-width: 340px) {
    .myresume_view .profile_area { padding-bottom: 30px; }
    .myresume_view .profile_area .tip_field { bottom: 0px; }
}

/* 이력서 수정불가 항목 색상 */
.color_readOnly {
    background-color: #cfcfcf !important;
    cursor: auto;
}

/**이력서 상세 총경력*/

.align_end {text-align: end;}



