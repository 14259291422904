/* ! p_resume_style 이 붙은 페이지에 관한 스타일 */
@media print {
	@page { size: A4; margin: 0; }
	h1, h2, h3, p, span, table, tr, th, td, figure, img { -webkit-print-color-adjust:exact; page-break-inside:avoid; page-break-after:auto; }
	.myresume_view { border:0; padding-bottom:0; }
	.work_condition { padding-bottom: 0; }
}
/* .p_resume {
	--w: 100%;
} */
.p_resume_style { margin: 0 !important; }
.p_resume_style * {
	font-family: 'NanumBarunGothic', sans-serif;
}
.p_resume_style .logo {
	position: absolute;
	width: calc(calc(var(--w) / calc(2470/248)));
	height: calc(calc(var(--w) / calc(2470/70)));
	right: calc(calc(var(--w) / calc(2470/250)));
	top: calc(calc(var(--w) /calc(2470/110)));
}
.p_resume_style .logo img {
	width: 100%;
	object-fit: contain;
}
.p_resume_style .qr {
	text-align: right;
	margin-top: calc(calc(var(--w) / calc(2470/75)));
	width: calc(calc(var(--w) / calc(2470/120)));
	display: block;
	margin-left: auto;
}
.p_resume_style .qr .qr-image { width:100%; height:100%; }
.p_resume_style .tip_field {
	padding: 0 !important;
	font-size: calc(calc(var(--w) / calc(2470/46)));
	width: 100%;
}
.p_resume_style .total_career_text {
	padding: 0 !important;
	font-size: calc(calc(var(--w) / calc(2470/46)));
	letter-spacing: -0.025em;
}
.p_resume_style .myresume_view {
	position: relative;
	width: 1400px;
	/* 원래 디자인 여백 사이즈 */
	/* padding: calc(var(--w) / 9.356) calc(var(--w) / 9.536); */
	/* PDF 기본 여백 제외하고 나머지 계산한 여백 사이즈 82, 162 */
	padding: calc(calc(var(--w) / calc(2470/260))) calc(calc(var(--w) / calc(2470/250)));
}
.p_resume_style .myresume_view .section {
	padding-block: calc(calc(var(--w) / calc(2470/110)));
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	border-top: 2px solid #000;
}
.p_resume_style .myresume_view .section.sec_career {
	padding-bottom: calc(calc(var(--w) / calc(2470/115)));
}
.p_resume_style .myresume_view .section.sec_career2 {
	padding-bottom: calc(calc(var(--w) / calc(2470/115)));
}
.p_resume_style .myresume_view .section .info_area,
.p_resume_style .myresume_view .section .tip_field,
.p_resume_style .myresume_view .section .total_field {
	float: none !important;
}
.p_resume_style .myresume_view .section .title_area {
	padding-inline: 0;
	font-size: calc(calc(var(--w) / calc(2470/52)));
	font-weight: 700;
	color: #3d3d3d;
	width: calc(calc(var(--w) / calc(2470/427)));
	letter-spacing: 0.075em;
  border-bottom:none !important;
}
.p_resume_style .myresume_view .section .title_area.has_total {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: flex-end;
}
.p_resume_style .myresume_view .section .title_area.has_total .total_career_text {
	font-weight: 700;
	line-height: 1;
	margin-bottom: 0;
}
.p_resume_style .myresume_view .section .info_area {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	padding: 0;
}
.p_resume_style .myresume_view .section .info_area * {
	color: #3d3d3d !important;
	line-height: 1.2;
}
.p_resume_style .myresume_view .section .info_area p {
	width: 100%;
}
.p_resume_style .myresume_view .section .info_area .career_detail {
	width: 100%;
	margin-top: 0;
}
.p_resume_style .myresume_view .section .info_area .career_detail:first-of-type {
	margin-top: 0;
}
.p_resume_style .myresume_view .section:is(.portfolio, .work_condition) .info_area :is(p, p a) {
	font-size: calc(calc(var(--w) / calc(2470/46))) !important;
	line-height: calc(calc(var(--w) / calc(2470/70))) !important;
	letter-spacing: 0;
	color: #3d3d3d;
	font-weight: 400;
}
.p_resume_style .myresume_view .section .info_area .title_field {
	width: calc(100% - (calc(calc(var(--w) / calc(2470/550)))));
	padding-right: 5%;
	font-size: calc(calc(var(--w) / calc(2470/54)));
	font-weight: 700;
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .myresume_view .section .info_area .title_field .part_field {
	margin-top: calc(calc(var(--w) / calc(2470/10)));
	font-size: calc(calc(var(--w) / calc(2470/46)));
	font-weight: 400;
	width: 100%;
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .myresume_view .section .info_area .date_field {
	width: calc(calc(var(--w) / calc(2470/550)));
	min-width: auto;
	font-size: calc(calc(var(--w) / calc(2470/46)));
	font-weight: 700;
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .myresume_view .section .info_area .date_field .left_wrap {
	width: auto;
}
.p_resume_style .resume_title {
	align-items: flex-start;
	padding: 0;
}
.p_resume_style .resume_title .qr {
	font-size: calc(calc(var(--w) / calc(2470/100)));
}
.p_resume_style .resume_title .tit_field {
	font-size: calc(calc(var(--w) / calc(2470/62)));
	font-weight: 400;
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .profile_area {
	/* flex-wrap: wrap; */
	margin-top: calc(calc(var(--w) / calc(2470/120)));
	padding: 0;
	justify-content: flex-start;
}
.p_resume_style .profile_area__txt_wrap {
	width: 100%;
}
.p_resume_style .profile_area__txt_wrap * {
	font-size: calc(calc(var(--w) / calc(2470/46))) !important;
	color: #3d3d3d !important;
}
.p_resume_style .profile_area__txt_wrap .name {
	font-size: calc(calc(var(--w) / calc(2470/62))) !important;
	font-weight: 700;
	letter-spacing: 0;
	color: #333 !important;
	line-height: 1;
}
.p_resume_style .profile_area__txt_wrap .info_area {
	justify-content: flex-start;
	float: none;
	margin-block: calc(calc(var(--w) / calc(2470/70)));
}
.p_resume_style .profile_area__txt_wrap .info_area li {
	padding-left: 0;
	padding-bottom: 0;
	line-height: 1.5217;
}.p_resume_style .profile_area__txt_wrap .tip_field {
	color: #cc3333 !important;
}
.p_resume_style .profile_area .profile_img {
	width: calc(calc(var(--w) / calc(2470/320))); /* 314px */
	aspect-ratio: 320/422;
	height: auto;
	margin-inline: 0;
}
.p_resume_style .profile_area .profile_img img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.p_resume_style .profile_area .profile_img + .profile_area__txt_wrap {
	padding-left: calc(calc(var(--w) / calc(2470/90)));
	width: calc(100% - calc(calc(var(--w) / calc(2470/320))));
	max-width: calc(100% - calc(calc(var(--w) / calc(2470/320))));
}
.p_resume_style .profile_area .info_area ul:first-child {
	width: auto;
	margin-right: calc(calc(var(--w) / calc(2470/100)));
}
.p_resume_style .profile_area .info_area ul:last-child {
	width: auto;
}
.p_resume_style .profile_area .info_area ul:last-child li {
	padding-left: 0;
}
.p_resume_style .profile_area .tip_field {
	position: static;
	width: 100%;
	/* margin-top: calc(var(--w) / 77.1875); */
	font-size: calc(calc(var(--w) / calc(2470/46)));
	line-height: 1;
}
.p_resume_style .myresume_view .sec_school {
	margin-top: calc(calc(var(--w) / calc(2470/120))) !important;
}
.p_resume_style .myresume_view .section.sec_career {
	padding-top: calc(calc(var(--w) / calc(2470/110)));
}
.p_resume_style .myresume_view .section.sec_career .tip_field {
	position: static;
	margin-bottom: calc(calc(var(--w) / calc(2470/110)));
	width: 100%;
}
.p_resume_style .myresume_view .sec_detail_wrap {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: calc(100% - calc(calc(var(--w) / calc(2470/427))));
}
.p_resume_style .myresume_view .has_total ~ .sec_detail_wrap {
	/* width: 100%; */
	margin-top: calc(calc(var(--w) / calc(2470/20)));
	margin-left: auto;
}
.p_resume_style .myresume_view .sec_detail_wrap .info_area {
	padding-block: calc(calc(var(--w) / calc(2470/90)));
	border-top: 1px solid #e1e1e1;
}
.p_resume_style .myresume_view .sec_detail_wrap .info_area:first-of-type {
	border-top-width: 0;
}
.p_resume_style .myresume_view .sec_detail_wrap .info_area:last-of-type {
	padding-bottom: 0;
}
.p_resume_style .myresume_view .section .ql-editor,
.p_resume_style .myresume_view .section .ql-editor * {
	font-size: calc(calc(var(--w) / calc(2470/46)));
	line-height: calc(calc(var(--w) / calc(2470/70)));
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .myresume_view .section .info_area .subtitle_field {
	position: relative;
	font-size: calc(calc(var(--w) / calc(2470/42)));
	line-height: calc(calc(var(--w) / calc(2470/62)));
	/* padding-left: calc(var(--w) / 49.5); */
	padding-left: 0;
	margin-block: calc(calc(var(--w) / calc(2470/70))) 0 !important;
	letter-spacing: 0;
	color: #3d3d3d;
}
.p_resume_style .myresume_view .section .info_area .subtitle_field::before {
	display: none;
	/* width: calc(var(--w) / 117.619);
	height: calc(var(--w) / 117.619);
	margin-left:0;
	position: absolute;
	left: 0;
	top: calc(var(--w) / 160.83333); */
}
.p_resume_style .myresume_view .section.has_no_red .sec_detail_wrap .info_area:first-of-type {
	padding-top: 0;
}
.p_resume_style .myresume_view .section.has_no_red .sec_detail_wrap .info_area:last-of-type {
	padding-bottom: 0;
}