.ql-container {
  min-height: 10rem;
  height: 480px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ql-editor {
  height: 100%;
  flex: 1;
  overflow-y: auto; /*visible*/
  width: 100%;
}