@charset "utf-8";

#footer{border-top:1px solid #eeeeee; padding-top:43px; padding-bottom: 50px;}
#footer .center_wrap{}
#footer .logo{float: left; padding-right: 32px; padding-left: 10px; width: 160px;}
#footer .link{float: left;  margin-bottom: 18px; padding-top: 5px; /*width: 600px;*/}
#footer .link a{font-size:15px; color:#616161; margin-right: 26px; vertical-align: middle; letter-spacing: -0.38px; font-weight: 500;}
/* #footer .link a:nth-child(2){color:#000;} */
#footer .sns_link{float: right; display: flex; justify-content: space-between; align-items: center; min-width: 213px;}
#footer .sns_link a{margin-right: 12px;}
#footer .company_info{clear: both; margin:0 213px 0 160px; font-style: normal; width: calc(100% - 160px);}
#footer .company_info p{color:#666; font-size: 15px; line-height: 22px; display: inline-block; margin-right: 32px;}
#footer .company_info .copy{margin-top:36px; font-weight: 300;  letter-spacing: 0; display: block;}

#footer .notice_area{display: none;}

.nav_bottom{display: none;}

@media screen and (max-width:1260px){
    #footer .center_wrap{padding:0;}
    #footer .link{width:calc(100% - 373px); padding-top:0;}
    #footer .link a{margin-bottom: 18px;}
}
@media screen and (max-width:1024px){}
@media screen and (max-width:768px){/*모바일 디자인 적용*/
    #footer{padding-top: 25px; padding-bottom: 68px;}
    #footer .center_wrap{display: flex; flex-direction: column; align-items: center;}
    #footer .link{order:1; text-align: center; margin-bottom: 8px; width: 80%;}
    #footer .link a{font-size:13px; margin:0 9px 9px;}
    #footer .logo{order:2; margin-bottom: 18px; padding:0; text-align: center;}
    #footer .sns_link{order:3; margin-bottom: 28px;}
    #footer .company_info{order:4; margin: 0; padding:0 4%; width: 100%;}
    #footer .company_info p{font-size:12px;}
    #footer .company_info .copy{margin-top: 18px;}
    #footer .notice_area{display: block; order:5; background:#e0e0e0; padding:16px 4%; font-size:12px;  line-height: 19px; letter-spacing: -0.5px; width: 100%; text-align: center; color:#666; margin-top: 26px;}
    .nav_bottom{position: fixed; bottom:0; left:0; display: flex; align-items: center; justify-content: space-around; text-align: center; border-top:1px solid #c2c2c2; width: 100%; box-shadow: 0 0 5px rgba(0,0,0,.1); padding:10px 0; background: #fff; z-index: 99;}
    .nav_bottom a{display: flex; flex-direction: column; align-items: center; font-size:13px; color:#666666; width: 25%;}
    .nav_bottom .text{padding-top: 7px;}
    .nav_bottom img{height: 23px;}
}
@media screen and (max-width:640px){
    #footer .sns_link a{margin-right: 8px;}
    #footer .logo img{width:60px;}
    
}
@media screen and (max-width:340px){
    .nav_bottom a{display: flex; flex-direction: column; align-items: center; font-size:12px; color:#666666; width: 25%;}
}