@charset "UTF-8";
.my_title_wrap { position: relative; margin-top: 30px; display: flex; } 
.my_title_wrap .btn { position: absolute; right: 0; bottom: 0; min-height: 35px; height: 35px; font-size: 15px; letter-spacing: -0.38px; font-weight: 700; display: flex; align-items: center; padding: 0 14px; } 

.my_title_wrap .btn .icon_plus { position: relative; width: 13px; height: 13px; margin-right: 6px; } 
.my_title_wrap .btn .icon_plus::before { content: ""; position: absolute; top: 0; left: 50%; transform: translate(-50%); display: inline-block; width: 1px; height: 100%; background-color: #3d7df2; } 
.my_title_wrap .btn .icon_plus::after { content: ""; position: absolute; top: 50%; left: 50%; transform: translate(-50%); display: inline-block; width: 100%; height: 1px; background-color: #3d7df2; } 

#add_submember + .table_view01,
.my_title_wrap + #add_submember .table_view01{margin-top:15px}


.page_com_myheding01 .guide_text { margin-top: 16px; font-size: 15px; letter-spacing: -0.38px; color: #999; line-height: 1.6; width:100%; } 
.page_com_myheding01 .guide_text li { position: relative; font-size: inherit; letter-spacing: inherit; color: inherit; line-height: inherit; padding-left: 10px; } 
.page_com_myheding01 .guide_text li::before { position: absolute; left: 0; content: "-"; } 
.page_com_myheding01 .sec01 { display: flex; align-items: center; padding: 25px; margin-top: 18px; border-top: 1px solid; border-bottom: 1px solid; border-color: #e2e2e2; } 
.page_com_myheding01 .sec01 .wrap { display: flex; flex-wrap: wrap; font-size: 15px; letter-spacing: -0.38px; margin-left: 20px; width: 100%; } .page_com_myheding01 .sec01 .wrap input { width: 160px; font-size: inherit; letter-spacing: inherit; } 
.page_com_myheding01 .sec01 .wrap button { margin-left: 5px; min-height: 48px; height: 48px; font-size: inherit; letter-spacing: inherit; padding-left: 16px; padding-right: 16px; } 
.page_com_myheding01 .table_view01 { font-size: 15px; letter-spacing: -0.38px; font-weight: 400; } 
.page_com_myheding01 .table_view01 .tr, .page_com_myheding01 .table_view01 .th, .page_com_myheding01 .table_view01 .td { font-size: inherit; letter-spacing: inherit; font-weight: 400; min-height: 50px; } 
.page_com_myheding01 .table_view01 .tr.col2 .td { width: calc(50% - 160px); } 
.page_com_myheding01 .table_view01 .th { min-width: 160px; } 
.page_com_myheding01 .table_view01 .th br { display: none; } 
.page_com_myheding01 .table_view01 .td.flex_wrap { flex-wrap: wrap; } 
.page_com_myheding01 .table_view01 .td.sub_id input { width: 100%; max-width: none; min-width: 50%; } 
.page_com_myheding01 .table_view01 .td.sub_id span { white-space: nowrap; } 
.page_com_myheding01 .table_view01 .btn { font-size: inherit; letter-spacing: inherit; width: 120px; min-height: 48px; height: 48px; } 
.page_com_myheding01 .table_view01 .btn.hide { display:none; }
.page_com_myheding01 .table_view01 .input_wrap { width: 100%; display: flex; font-size: 15px; letter-spacing: -0.38px; } 
.page_com_myheding01 .table_view01 .input_wrap .btn { min-width: 100px; width: 100px; margin-left: 10px; } 
.page_com_myheding01 .table_view01 .input_wrap .btn.hide { display:none; }
.page_com_myheding01 .table_view01 .input_wrap + input[type=text], .page_com_myheding01 .table_view01 .input_wrap + .guide_text { margin-top: 10px; } 
.page_com_myheding01 .table_view01 .sub_account_use_wrap { width: 100%; display: flex; justify-content: center; align-items: center; padding-top: 13px; }
.page_com_myheding01 .table_view01 .sub_account_use_wrap .radio_wrap { width: auto; margin-right: 30px; } 
.page_com_myheding01 .table_view01 .sub_account_use_wrap .btn { font-size: 15px; letter-spacing: -0.38px; min-width: 80px; width: 80px; min-height: 35px; height: 35px; font-weight: 700; } 
.page_com_myheding01 .sub_account_area { margin-bottom: 70px; }
.page_com_myheding01 .btn_bottom_area { position: relative; } 
.page_com_myheding01 .btn_leave { position: absolute; right: 0; font-size: 15px; letter-spacing: -0.38px; color: #999; background: url(img/sub/icon_arrow.png) no-repeat; background-position: right 5px; padding-right: 14px; } 
.page_com_myheding01 .sub_account .box_content { display: flex; flex-wrap: wrap; padding: 0px; margin-top: 30px;}
.page_com_myheding01.sub_account .box_content .my_title { width: 100%; margin-bottom: 18px; } 
.page_com_myheding01.sub_account .box_content .left_wrap { width: 157px; margin-right: 27px; } 
.page_com_myheding01.sub_account .box_content .left_wrap figure { width: 157px; height: 200px; } 
.page_com_myheding01.sub_account .box_content .left_wrap figure img { width: 100%; height: 200px; } 
.page_com_myheding01.sub_account .box_content .left_wrap .wrap { display: flex; flex-direction: column; } 
.page_com_myheding01.sub_account .box_content .left_wrap .btn { margin-top: 5px; min-height: 40px; height: 40px; border-color: #dedede; font-size: 15px; letter-spacing: -0.38px; color: #333; width: 100%; } 
.page_com_myheding01.sub_account .box_content .left_wrap .guide_text { margin-top: 12px; font-size: 14px; line-height: 18px; } 
.page_com_myheding01.sub_account .box_content .right_wrap { width: calc(100% - 157px - 27px); }

.page_com_myheding03 .state_box { flex-direction: row; padding: 23px 25px; } 
.page_com_myheding03 .state_box .title { height: auto; margin-bottom: 0; text-align: left; color: #333; } 
.page_com_myheding03 .state_box .num { margin-top: 0; margin-left: auto; font-size: 20px; letter-spacing: -0.5px; color: #3d7df2; font-weight: 700; } 
/*.page_com_myheding03 .table_list01 figure { width: 44px; height: 44px; border-radius: 50%; margin-right: 14px; background-image: url(img/sub/company_user.png); background-size: cover; background-repeat: no-repeat; background-position: center; } 
.page_com_myheding03 .table_list01 .th.row_pic, .page_com_myheding03 .table_list01 .td.row_pic { display: none; } */

.page_com_myheding03 .popup .view_area .title_field { line-height: 20px; } 


.page_com_myheding04 .box_content { padding: 0 !important; } 
.page_com_myheding04 .subtop_info_area li { font-size: 15px; letter-spacing: -0.38px; height: 70px; padding: 0 24px; } 
.page_com_myheding04 .tab01 { margin-top: 18px; margin-bottom: 10px; width: 100%; } 
.page_com_myheding04 .tab01 li { width: 50%; } 
.page_com_myheding04 .list_top form { width: 100%; justify-content: flex-end; } 
.page_com_myheding04 .list_top form .btn { width: 90px; min-height: 48px; height: 48px; margin-left:10px; } 
.page_com_myheding04 .table_list01 .m_th { display: none; color: #666; } 
.page_com_myheding04 .table_list01 .td { font-size: 15px; letter-spacing: -0.38px; } 

.page_com_myheding04 .table_list01 .td.row_state { color: #999; }
.page_com_myheding04.paylist .table_list01 .row_type, 
.page_com_myheding04.paylist .table_list01 .row_date { width: 25.25%; } 
.page_com_myheding04.paylist .table_list01 .row_price, 
.page_com_myheding04.paylist .table_list01 .row_payment,
.page_com_myheding04.paylist .table_list01 .row_state,
.page_com_myheding04.paylist .table_list01 .row_return { width: 16.5%; } 
.page_com_myheding04.uselist .list_top .search_wrap { justify-content: space-between; } 
.page_com_myheding04.uselist .table_list01 .row_people { width: 33.3%; } 
.page_com_myheding04.uselist .table_list01 .row_user { width: 33.3%; } 
.page_com_myheding04.uselist .table_list01 .row_date { width: 33.3%; } 
/*.page_com_myheding04.uselist .table_list01 .row_type { width: 23%; } 
.page_com_myheding04.uselist .table_list01 .row_remain { width: 25%; }*/ 
.comheding_profile_textarea { min-height:100px; }

@media screen and (max-width: 1024px){
  .page_com_myheding01 .sec01 { border-width: 0; padding: 0; flex-direction: column; } 
  .page_com_myheding01 .sec01 .wrap { margin-left: 0; margin-top: 10px; } 
  .page_com_myheding01 .sec01 .wrap input { width: 40%; margin-right: 2%; } 
  .page_com_myheding01 .sec01 .wrap button { width: 28%; margin-left: 0; margin-top: 0; word-break: keep-all; } 
  .page_com_myheding01 .sec01 .wrap button.black { margin-left: 2%; } 
  .page_com_myheding01 .table_view01 .tr.col2 { flex-wrap: wrap; } 
  .page_com_myheding01 .table_view01 .tr.col2 .th:nth-child(n+3), .page_com_myheding01 .table_view01 .tr.col2 .td:nth-child(n+3) { border-top: 1px solid #e2e2e2; } 
  .page_com_myheding01 .table_view01 .tr.col2 .td { width: calc(100% - 95px); } 
  .page_com_myheding01 .table_view01 .th { width: 95px; min-width: 95px; padding: 10px; } 
  .page_com_myheding01 .table_view01 .th br { display: block; } 
  .page_com_myheding01 .table_view01 .input_wrap + input[type=text], .page_com_myheding01 .table_view01 .input_wrap + .guide_text { margin-top: 5px; } 
  .page_com_myheding01 .table_view01 .sub_account_use_wrap { justify-content: space-between; }
  .page_com_myheding01 .sub_account_area { margin-bottom: 0; } 
  .page_com_myheding01 .sub_account_area + .sub_account_area { margin-top: 20px; } 
  .page_com_myheding01 .btn_bottom_area { padding-top: 20px; padding-left: 4%; padding-right: 4%; display: flex; flex-direction: column-reverse; } 
  .page_com_myheding01 .btn_leave { position: relative; margin-bottom: 20px; margin-left: auto; } 
  .page_com_myheding01.sub_account .table_view01 { margin-top: 10px !important; } 
  .page_com_myheding01.sub_account .box_content { flex-direction: column; } 
  .page_com_myheding01.sub_account .box_content .my_title { margin-bottom: 10px; } 
  .page_com_myheding01.sub_account .box_content .left_wrap { width: 100%; display: flex; align-items: flex-end; padding-bottom: 20px; border-bottom: 1px solid #e2e2e2; margin-bottom: 20px; margin-right: 0; } 
  .page_com_myheding01.sub_account .box_content .left_wrap figure { margin-right: 15px; } 
  .page_com_myheding01.sub_account .box_content .left_wrap .guide_text { margin-top: 0; margin-bottom: 14px; } 
  .page_com_myheding01.sub_account .box_content .right_wrap { width: 100%; } 
  /*.page_com_myheding03 .table_list01 figure { width: 31px; height: 31px; } */
  .page_com_myheding03 .table_list01 td.td_title b{display: block; overflow: hidden; text-overflow:ellipsis;}
  
  .page_com_myheding04 .tab01 { padding: 0 4%; } 
  .page_com_myheding04.paylist .tab01 { padding: 0; }
  .page_com_myheding04.uselist .tab01 { padding: 0; }
  .page_com_myheding04 .list_top form .btn { min-width: 90px; } 
  .page_com_myheding04 .table_list01 { border: 0; border-top: 1px solid #dedede; border-bottom: 1px solid #dedede; }
  .page_com_myheding04 .table_list01 .tr { position: relative; padding: 20px 10px; flex-wrap: wrap; } 
  .page_com_myheding04 .table_list01 .tr:first-child { display: none; } 
  .page_com_myheding04 .table_list01 .td { padding: 0; text-align: left; } 
  .page_com_myheding04 .table_list01 .m_th { display: inline-block; } 
  .page_com_myheding04.paylist .table_list01 .row_price, .page_com_myheding04.paylist .table_list01 .row_payment, 
  .page_com_myheding04.paylist  .table_list01 .row_state { width: auto; } 
  .page_com_myheding04.paylist .table_list01 .row_type { width: 100%; padding-right:52px; margin-bottom: 10px; } 
  .page_com_myheding04.paylist .table_list01 .row_date { width: auto; }
  .page_com_myheding04.paylist .table_list01 .row_date::after { content: "|"; display: inline-block; margin: 0 4px; font-size: 12px; color: #d1d1d1; transform: translateY(-1px); } 
  .page_com_myheding04.paylist .table_list01 .row_date.non_date::after { display: none; }
  .page_com_myheding04.paylist .table_list01 .row_payment { display: none; } 
  .page_com_myheding04.paylist .table_list01 .row_state { position: absolute; right: 10px; width: auto; } 
  .page_com_myheding04.paylist .table_list01 .row_return { width:100%; }
  .page_com_myheding04.paylist .table_list01 .row_return .button{ margin-top: 4px; }
  .page_com_myheding04.uselist .table_list01 .tr { padding: 20px 15px; } 
  .page_com_myheding04.uselist .table_list01 .row_people { width: 100%; font-size: 14px; margin-bottom: 10px; } 
  .page_com_myheding04.uselist .table_list01 .row_people .m_th { color: #000; font-size: 12px; margin-left: 10px; } 
  .page_com_myheding04.uselist .table_list01 .row_user { display: none; } 
  .page_com_myheding04.uselist .table_list01 .row_date { width: auto; } 
  .page_com_myheding04.uselist .table_list01 .row_type { width: auto; } 
  .page_com_myheding04.uselist .table_list01 .row_type::before { content: "|"; display: inline-block; margin: 0 4px; font-size: 12px; color: #d1d1d1; transform: translateY(-1px); } 
  .page_com_myheding04.uselist .table_list01 .row_remain { width: 100%; } 
 }
 @media screen and (max-width: 768px){
  .my_title_wrap .btn { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01 .guide_text { font-size: 13px; letter-spacing: -0.6px; }
  .page_com_myheding01 .sec01 .wrap { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01 .sec01 .wrap button { height: 40px; min-height: 40px; } 
  .page_com_myheding01 .table_view01 { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01 .table_view01 .btn { min-height: 40px; height: 40px; } 
  .page_com_myheding01 .table_view01 .input_wrap { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01 .table_view01 .input_wrap .btn { width: 80px; min-width: 80px; margin-left: 5px; } 
  .page_com_myheding01 .table_view01 .sub_account_use_wrap .btn { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01 .btn_bottom_area { padding-top: 0; } 
  .page_com_myheding01 .btn_leave { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding01.sub_account .box_content .left_wrap .btn { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding03 .table_list01 { border-top: 1px solid #dedede; border-bottom: 1px solid #dedede; } 
  .page_com_myheding03 .state_box { padding: 8px 14px; flex-direction: column; } 
  .page_com_myheding03 .state_box .title { text-align: center; } 
  .page_com_myheding03 .state_box .num { font-size: 15px; letter-spacing: -0.38px; } 
  .page_com_myheding03 .state_box .num { margin-top: 7px; font-weight: 400; text-align: center; margin-left: 0; } 
  .page_com_myheding04 .subtop_info_area li { font-size: 13px; letter-spacing: -0.6px; }
  .page_com_myheding04 .table_list01 .td { font-size: 13px; letter-spacing: -0.6px; } 
  .page_com_myheding04.paylist .table_list01 .row_return .button{ font-size: 13px; }
  
  .page_com_myheding04 .list_top .search_wrap .wrap { flex-wrap: wrap; }
  .page_com_myheding04 .list_top .search_wrap .wrap .title_field { width: 100%; margin-bottom: 6px;}
  .page_com_myheding04 .list_top .react-datepicker-wrapper { width: 46.5%; }
  .page_com_myheding04 .list_top form .btn { width: 100%; min-height: 48px; height: 48px; margin-top: 6px; margin-left: 0;}
  .page_com_myheding04.paylist .tab01 { padding-inline: 4%; }
  .page_com_myheding04.uselist .tab01 { padding-inline: 4%; }

  .myresume_write .input_area .input_title, 
  .myresume_write .input_area .input_part, 
  .myresume_write .input_area .input_date, 
  .myresume_write .input_area .react-datepicker-wrapper, 
  .myresume_write .input_area .input_title.wd-xs{width:32%}
  .myresume_write .input_area .input_part3{width:58%}
 }
