@charset "utf-8";

/* 콘텐츠 타이틀 */
.sub_content_title {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -1px;
    color: #000;
}

.sub_content_text {
    color: #666;
    font-size: 15px;
    letter-spacing: -0.05em;
    text-align: center;
    margin-bottom: 30px;
}

.sub_content_title+.sub_content_text {
    margin-top: -15px;
}

.sub_content_title_wrap {
    display: flex;
}

.sub_content_title_wrap.spacebetween {
    justify-content: space-between;
}

/*텍스트간 구분선*/
.divisionBar {
    width: 1px;
    height: 12px;
    background: #d8d8d8;
    margin: 0 12px;
}

/* 스크롤 스타일 */
.style_scroll::-webkit-scrollbar {
    opacity: 0;
    width: 10px;
    height: 10px;
}

.style_scroll::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    height: 10px;
}

.style_scroll::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    width: 10px;
}

/* 관심 별 아이콘 
i.icon_star { display: inline-block; width: 28px; height: 25px; background-image: url(img/sub/bookmark.png); background-size: cover; background-repeat: no-repeat; background-position: center; } 
i.icon_star.active { background-image: url(img/sub/icon_star.png); } */

/************
셀렉트 스타일 
*************/
/*selectbox
.style_select { padding: 0 35px 0 10px; background-image: url(../Img/Common/select_arrow.png); background-size: 15px 8px; background-repeat: no-repeat; background-position: calc(100% - 12.5px) center; appearance: none; } */

/*스크롤박스 select(이력서>희망직종선택 등)*/
.select_box {
    border: 1px solid #dedede;
    background: #fff;
    overflow: hidden;
}

.select_box .wrap {
    max-height: 250px;
    overflow: auto;
}

.select_box.col2 {
    display: flex;
    justify-content: space-between;
}

.select_box.col2 .wrap {
    width: 50%;
    padding: 10px;
}

.select_box .button {
    display: block;
    padding: 3px 10px;
    font-size: 15px;
}

.select_box .button:hover,
.select_box .button.active {
    color: #3d7df2;
}

.select_box.inline {
    padding: 20px 10px;
}

.select_box.inline .button {
    display: inline-block;
    vertical-align: middle;
}

.select_wrap {
    position: relative;
}

.select_wrap .selected {
    font-size: 20px;
    padding: 0 30px 0 0;
    letter-spacing: -0.05em;
    font-weight: 700;
    color: #000;
    background: url("../img/sub/select_arrow.gif") no-repeat;
    background-position: right center;
    cursor: pointer;
    display: inline-block
}

.select_wrap .select_div {
    position: absolute;
    left: 0;
    border: 1px solid #999;
    background: #fff;
}

.select_wrap .select_div.hide {
    display: none;
}

.select_wrap .select_div .item {
    display: block;
    padding: 10px;
    font-size: 15px;
}

.select_wrap .select_div .item:hover {
    background: #999;
    color: #fff;
}

.select_wrap .select_div li {
    border-bottom: 1px solid #aaa;
}

.select_wrap .select_div li:last-child {
    border-bottom: none;
}

.wd-fix-wd30 {
    width: 120px
}

/* 서브 하단 배너 */
.sub_bottom_banner {
    margin: 130px auto 67px;
    display: block;
}

/* 마이 헤딩 현황판 */
.my_title+.state_wrap {
    margin-top: 20px;
}

.state_wrap {
    display: flex;
    justify-content: space-between;
}

.state_wrap .state_box {
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    padding: 17px 12px;
    width: 100%;
    background: #f7f9fa;
    margin-right: 10px;
}

.state_wrap .state_box .btn {
    font-size: 15px;
    min-height: 35px;
    height: 35px;
    margin-left: 15px;
}

.state_wrap .state_box:last-child {
    border: none;
    margin-right: 0
}

.state_wrap .state_box .title {
    font-size: 15px;
    color: #666;
    font-weight: normal;
    height: 31px;
    line-height: 17px;
    margin-bottom: 11px;
    width: 100%;
    text-align: center;
    word-break: keep-all
}

_:-ms-fullscreen,
:root .state_wrap .state_box .title {
    width: auto;
}

.state_wrap .state_box .num {
    font-size: 18px;
    margin-top: auto;
    color: #333;
    text-align: center;
    white-space: nowrap;
}

.state_wrap .state_box .num.point1 {
    color: #d02928
}

/*미열람*/
.state_wrap .state_box .num.point2 {
    color: #3d7df2
}

/*합격*/

/* 팝업스타일 */
/* popup.scss */
.popup.active {
    display: block;
}

.popup {
    background: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    display: none;
}

.popup_wrap {
    position: relative;
    background: #fff;
    max-width: 90vw;
    margin: 0 auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
}

.popup_wrap .content_wrap {
    max-height: 80vh;
    overflow-y: auto;
}
.popup_wrap.terms_pop_type .content_wrap {
    max-height: 64vh;
}

/* 프로필 수정하기 팝업이 스크롤이 생겨서 max-height 늘림 - 기존 50vh */
.pop_privacy_time .content_wrap {
    max-height: 70vh;
}

.popup_wrap .content_wrap::-webkit-scrollbar {
    opacity: 0;
    width: 10px;
    height: 10px;
}

.popup_wrap .content_wrap::-webkit-scrollbar-thumb {
    background-color: #e3e3e3;
    height: 10px;
}

.popup_wrap .content_wrap::-webkit-scrollbar-track {
    background-color: #f3f3f3;
    width: 10px;
}

.popup .email_field {
    align-items: center;
}

.popup01 {
    width: 440px;
    background: #fff;
}

.popup01 .btn2 {
    font-weight: 500;
}

.popup01 .title_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 77px;
    border-bottom: 2px solid #dedede;
    padding: 24px 20px;
}

.popup01 .title_wrap h1 {
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -0.65px;
    color: #333;
}

.popup01 .title_wrap h1 .point {
    color: #3c7cf2
}

.popup01 .arrow_button_wrap {
    display: flex;
    align-items: center;
}

.popup01 .arrow_button_wrap .btn_arrow {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid #636a7b;
    font-size: 14px;
    letter-spacing: -0.35px;
    color: #b2b5c1;
    background-image: url(img/sub/btn_pop_prev_on.png);
    background-repeat: no-repeat;
    background-position: 10px center;
    cursor: pointer;
}

.popup01 .arrow_button_wrap .btn_arrow.disabled {
    border-color: #c1c1c1;
    background-image: url(img/sub/btn_pop_prev.png);
}

.popup01 .arrow_button_wrap .btn_arrow+.btn_arrow {
    margin-left: 8px;
    transform: rotate(180deg);
}

.popup01 .right_wrap .btn_text {
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #373737;
}

.popup01 .content_wrap:not(.resume_style) {
    background: #f6f8fb;
    padding: 30px 20px;
    border-bottom: 1px solid #dedede;
}

.popup01 .content_wrap .field:not(:first-of-type) {
    margin-top: 26px;
}

.popup01 .content_wrap .only_input .field:not(:first-of-type) {
    margin-top: 15px;
}

.popup01 .content_wrap .field .label {
    display: block;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: -0.38px;
    color: #000;
    margin-bottom: 9px;
}

.popup01 .content_wrap .field input {
    background: white;
    border: 1px solid #dedede;
}

.popup01 .content_wrap .field label {
    font-size: 15px;
}

.popup01 .content_wrap .field.col {
    display: flex;
    align-items: center;
}

.popup01 .content_wrap .field.col .label {
    display: inline-block;
    padding-right: 15px;
    margin-bottom: 0
}

.popup01 .content_wrap .field.col .text {
    display: inline-block;
    font-size: 20px;
}

.popup01 .content_wrap .field.col input:first-child {
    margin-left: 0
}

.popup01 .content_wrap fieldset+.list_dot,
.popup01 .content_wrap .field+.list_dot {
    margin-top: 24px;
}

.popup01 .content_wrap .input_button {
    display: flex;
}

.popup01 .content_wrap .input_wrap .btn,
.popup01 .content_wrap .input_button .btn {
    margin-left: 7px;
    font-size: 15px;
    white-space: nowrap;
    padding: 0 10px;
    min-height: 48px;
    height: 48px;
}

_:-ms-fullscreen,
:root .popup01 .content_wrap .input_wrap .btn,
_:-ms-fullscreen,
:root .popup01 .content_wrap .input_button .btn {
    min-width: 72px;
    padding: 0 1px;
}

.popup01 .content_wrap .chk[type=radio]+label:before {
    width: 30px;
    height: 30px;
}

.popup01 .content_wrap .chk[type=radio]:checked+label:before {
    background-size: 28px 30px
}

.popup01 .content_wrap .chk[type=radio]+label {
    display: inline-flex;
    align-items: center;
}

.popup01 .content_wrap .input_wrap {
    display: flex;
    align-items: center;
}

.popup01 .content_wrap .input_wrap+.select_box {
    margin-top: 20px;
}

/* 학력 검색 */
.popup01 .content_wrap .input_wrap.radio-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 1rem 0;
}

.popup01 .button_wrap {
    padding: 30px 20px 33px;
    text-align: center;
}

.popup01 .button_wrap .btn {
    padding: 0 10px
}

.popup01 .button_wrap .point_text {
    margin-bottom: 30px;
    margin-top: -12px;
    font-size: 15px;
}

.popup01 .button_wrap .big_button_wrap.one a,
.popup01 .button_wrap .big_button_wrap.one button {
    width: 100%;
}

.popup01 .button_wrap .big_button_wrap.two {
    display: flex;
    justify-content: space-between;
}

.popup01 .button_wrap .big_button_wrap.two a,
.popup01 .button_wrap .big_button_wrap.two button {
    width: 49%;
}

.popup01 .button_wrap .big_button_wrap .btn {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -0.38px;
}

.popup01 .close_button_wrap {
    margin-top: 22px;
    text-align: right;
}

.popup .close_button_wrap a,
.popup .close_button_wrap button {
    font-size: 15px;
    text-decoration: underline;
    text-underline-offset: 5px;
    font-weight: 500;
    cursor: pointer;
}

/*인증경력*/
.popup .myresume_write {
    width: auto;
    max-width: 500px;
    max-height: 90vh;
}

.popup .myresume_write .title_area {
    margin: 0;
    padding-top: 0;
}

.popup .myresume_write .input_area {
    margin-top: 18px;
    position: relative;
}

.popup .myresume_write .input_area+.input_area {
    padding-top: 15px;
}

.popup .myresume_write .input_area input,
.popup.myresume_write .input_area select {
    float: left;
    margin-bottom: 12px;
    margin-left: 0 !important;
}

.popup .myresume_write .input_area input+input {
    margin-left: 0;
}

.popup .myresume_write .input_area .input_title {
    width: 28%;
    margin-right: 1.2%;
}

.popup .myresume_write .input_area .input_part {
    width: 18%;
    margin-right: 1.2%
}

.popup .myresume_write .input_area .input_part2 {
    width: 19%;
    margin-right: 1.2%;
}

.popup .myresume_write .input_area .input_date {
    width: 14.1%;
    margin-right: 1.2%;
}

.popup .myresume_write .input_area .react-datepicker-wrapper {
    width: 25%;
    margin-right: 1.2%;
    float: left;
}

.popup .myresume_write .input_area .react-datepicker-wrapper .input_date {
    width: 100%;
    margin-right: 0
}

.popup .myresume_write .input_area .input_state {
    width: 18%;
    margin-left: 0
}

.popup .myresume_write .input_area .input_subtitle {
    clear: both;
    width: 95.2%;
    margin-left: 0
}

.popup .myresume_write .input_area .content {
    width: 95.2%;
}

.popup .myresume_write .input_area .btn_add {
    float: right;
    margin-right: 4.8%;
    font-size: 15px;
    color: #000;
}

.popup .myresume_write .input_area .btn_add img {
    margin-right: 5px;
}

.popup .myresume_write .input_area:after {
    content: '';
    width: 100%;
    display: block;
    clear: both;
    height: 1;
}

.popup .myresume_write .input_area+.btn {
    margin: 0 auto;
    padding: 0 24px;
}

.popup .myresume_write .btn_del {
    position: absolute;
    top: 10px;
    right: 0;
    width: 29px;
    height: 29px;
    line-height: 27px;
    border-radius: 100px;
    background: #d7dde6;
    text-align: center;
    vertical-align: 2px;
}
.popup .myresume_write .content_wrap {
    max-height: 65vh;
}

/*회사검색*/
.popup .company_search .search_area .btn {
    height: 48px;
    min-height: 48px;
    font-size: 15px
}

.popup .company_list_area {
    max-height: 245px;
    border: 1px solid #b3b9c4;
    background-color: #fff;
    overflow-y: auto;
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #222;
}

.popup .company_list_area>div {
    font-size: inherit;
    letter-spacing: inherit;
    width: 100%;
}

.popup .company_list_area>div [type=radio] {
    position: absolute;
    left: -9999px;
}

.popup .company_list_area>div [type=radio]:checked+label {
    background-color: rgba(61, 125, 242, 0.0509803922);
    color: #3d7df2;
}

.popup .company_list_area>div label {
    display: block;
    width: 100%;
    font-size: inherit;
    letter-spacing: inherit;
    padding: 10px 19px;
    cursor: pointer;
}

.popup .search_area+.company_list_area {
    margin-top: 10px;
}

.popup .company_list_area .nodata {
    text-align: center;
    padding: 10px;
}

.popup .select_box+.frm-input-box {
    margin-top: 5px
}

/*팝업 안내문구*/
.popup .notice_area {
    text-align: center;
    letter-spacing: -0.05em;
}

.popup .notice_area .title_field {
    font-size: 20px;
    color: #222;
    line-height: 30px;
    margin-bottom: 5px;
}

.popup .notice_area .text_field {
    color: #666666;
    font-size: 15px;
}

.popup .notice_area .point {
    color: #cc3333;
}

.popup .notice_area .point.blue {
    color: #3d7df2;
}

.popup .notice_area .icon {
    height: 59px;
    width: 59px;
    border-radius: 100px;
    background: #3d7df2;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
}

.popup .notice_area .icon.red {
    background-color: #cc3333;
}

.popup .notice_area+.field {
    margin-top: 50px !important;
}

.resume_box {
    position: relative;
    display: flex;
    width: 100%;
    background: #fff;
    padding: 24px 20px;
    border-bottom: 1px solid #dedede;
    cursor: pointer;
}

.resume_box .table_notice_badge {
    line-height: 22px;
    font-size: 14px;
    white-space: nowrap;
    margin-left: 10px;
}

.resume_box .v_box_wrap {
    width: 20px;
}

.resume_box .v_box {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 1px solid #dedede;
    transition: all .2s;
    background: #fff;
}

.resume_box .v_box::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 15px;
    top: 1px;
    left: 1px;
    background: url(img/sub/check_blue.png) no-repeat center;
    background-size: 17px 15px;
    transform: scale(0);
    transition: all .2s;
}

.resume_box_content {
    width: calc(100% - 20px);
    padding-left: 12px;
}

.resume_box_top {
    display: flex;
    min-height: 22px;
    align-items: center;
}

.resume_box_top h1,
.resume_box_top .title_field {
    font-size: 20px;
    letter-spacing: -0.5px;
    color: #000;
    line-height: 1;
    text-overflow: ellipsis;
    word-wrap: normal;
    overflow: hidden;
    white-space: nowrap;
}

.resume_box_button {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.resume_box_bottom {
    margin: 14px 0;
}

.resume_box_bottom h1,
.resume_box_bottom .title_field {
    font-size: 15px;
    letter-spacing: -0.38px;
    color: #333;
    text-align: right;
}

.resume_box_bottom h1 b,
.resume_box_bottom .title_field b {
    color: #000;
}

.resume_progress {
    margin-top: 7px;
    height: 10px;
    width: 100%;
    border-radius: 4.5px;
    background-color: #d1d4da;
}

.resume_progress_bar {
    border-radius: 4.5px;
    height: 10px;
    animation: progress-animation 1.6s ease-in-out;
}

.step1 .resume_progress_bar {
    background-color: #58c8fa;
    max-width: 25%
}

.step2 .resume_progress_bar {
    background-color: #2c78f6;
    max-width: 50%
}

.step3 .resume_progress_bar {
    background-color: #0052db;
    max-width: 75%
}

.step4 .resume_progress_bar {
    background-color: #1630ac;
    max-width: 100%
}

.resume_box_bottom.step1 .title_field {
    text-align: left;
}

.resume_box_bottom.step2 .title_field {
    width: 50%
}

.resume_box_bottom.step3 .title_field {
    width: 75%
}

.resume_box_bottom.step4 .title_field {
    width: 100%
}

.popup .tems_box {
    max-height: 50vh;
    overflow-y: auto;
}

/*간편인증*/
.Certify .selectCertify {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px
}

.Certify .selectCertify li {
    position: relative;
}

.Certify .selectCertify label {
    margin-right: 0;
    padding-left: 0;
    padding-bottom: 10px;
}

.Certify .selectCertify .icon {
    border-radius: 100px;
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: inline-block
}

.Certify .selectCertify .chk+label {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Certify .selectCertify .chk+label:before {
    margin: 10px 0 0 0 !important
}

/*이용약관 등 팝업*/
.terms_pop_type h2.page_title {
    display: none;
}

.terms_pop_type .terms_area {
    border: none;
    padding: 0;
}

@-webkit-keyframes progress-animation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

@keyframes progress-animation {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.radio_wrap [type="radio"]:checked+.resume_box {
    background: #f6f8fb;
}

.radio_wrap [type="radio"]:checked+.resume_box .v_box {
    border-color: #3d7df2;
}

.radio_wrap [type="radio"]:checked+.resume_box .v_box::after {
    transform: scale(1);
}

/* 탭 스타일 */
.tab01 {
    display: flex;
    align-items: center;
}

.tab01 li {
    background: #fafbfb;
    border: 1px solid #d4d4d4;
    border-left: 0;
    text-align: center;
}

.tab01 li a {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 15px;
    color: #666;
    letter-spacing: -0.75px;
    word-break: keep-all;
    cursor: pointer;
}

.tab01 li:first-of-type {
    border-left: 1px solid #d4d4d4;
}

.tab01 li.active {
    background: #fff;
    border-bottom-color: #fff;
}

.tab01 li.active a {
    color: #3d7df2;
    font-weight: bold;
}

/* tab01에 테두리 및 내용 있는 경우 */
.tab01_wrap {
    border: 1px solid #999;
}

.tab01_wrap .tab01 {
    border-bottom: 1px solid #d4d4d4;
}

.tab01_wrap .tab01 li {
    border-top: none;
    border-bottom: none;
    width: 100%;
}

.tab01_wrap .tab01 li:first-of-type {
    border-left: none;
}

.tab01_wrap .tab01 li:last-of-type {
    border-right: none;
}

.tab01_wrap .tab01 li.active a {
    position: relative;
    border-bottom-color: #fff;
    width: 100%;
    font-weight: bold;
}

.tab01_wrap .tab01 li.active a:after {
    content: '';
    display: inline-block;
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: white;
}

.tab01_contents {
    padding: 20px 30px;
}

.tab01_contents,
.tab01_contents p,
.tab01_contents div,
.tab01_contents span {
    color: #666;
}

.tab01_contents h1 {
    color: #000;
    padding: 7.5px 0;
    font-size: 15px;
    letter-spacing: -0.38px;
}

.tab01_contents h1:not(:first-of-type) {
    margin-top: 60px;
}

.tab01_contents li {
    padding: 7.5px 0;
    font-size: 15px;
    letter-spacing: -0.38px;
}

.sub_content_title+.blue.tab01 {
    margin-top: 20px;
}

.page_title {
    margin-top: 65px;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.05em;
    text-align: center;
    margin-bottom: 20px;
}

/*탭01 내에 있는 탭*/
.tab02 {
    border-bottom: 1px solid #ececec;
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
}

.tab02 li {
    width: 100%;
}

.tab02 .tab {
    width: 100%;
    padding: 0 0 8px;
    font-size: 15px;
    text-align: center;
}

.tab02 li.active {
    border-bottom: 2px solid #3d7df2;
}

.tab02 li.active .tab {
    color: #3d7df2;
    font-weight: bold;
}

/* 북마크 별 */
.btn_bookmark {
    cursor: pointer;
    width: 27px;
    height: 24px;
    background: url(img/common/bookmark.png) no-repeat center;
    background-size: 24px auto;
}

.btn_bookmark.active {
    background-image: url(img/common/bookmark_on.png);
}

/* 채용공고 카드 */
.recruit_card_wrap {
    display: flex;
    flex-wrap: wrap;
}

.recruit_card {
    position: relative;
    border: 1px solid #cfcfcf;
    margin-right: 20px;
    width: calc(25% - 15px);
}

.recruit_card .btn_bookmark {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
}

.recruit_card .link_wrapper {
    width: 100%;
}

/*마우스 오버*/
.recruit_card .link_wrapper:hover {
    box-shadow: 1px 1px 20px #3a3434;
}

.recruit_card .image_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    aspect-ratio: 6 / 4;
}

.recruit_card .image_wrap img {
    min-width: 100%;
    height: auto;
    min-height: 100%;
    object-fit: contain;
    aspect-ratio: 6 / 4;
}

.recruit_card .content_wrap .type_wrap {
    display: flex;
    margin-top: 20px;
    padding: 0 20px;
}

.recruit_card .content_wrap .type_wrap .type {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    letter-spacing: -0.3px;
    color: #666;
    padding: 3px 5px;
    border: 1px solid #d6d6d6;
    margin-right: 5px;
    font-weight: 300;
}

.recruit_card .content_wrap .type_wrap .new_badge {
    width: 36px;
    height: 20px;
    background: url(img/sub/new2.png) no-repeat center;
    background-size: 36px 20px;
}

/*.main_recruit .recruit_card_wrap .recruit_card .content_wrap .title {2022/11/22 삭제, 메인 외 공고카드가 틀어짐
    margin-top: 19px;
    padding: 0 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.5px;
    color: #000;
    text-overflow: ellipsis;
    word-wrap: normal;
    overflow: hidden;
    white-space: normal;
    -webkit-line-clamp: 2;
    white-space: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    min-height: 56px;
}*/
.recruit_card .content_wrap .title { margin-top: 19px; padding: 0 20px; font-size: 20px; font-weight: 500; line-height: 28px; letter-spacing: -0.5px; color: #000; text-overflow: ellipsis; word-wrap: normal; overflow: hidden; white-space: normal; -webkit-line-clamp: 2; white-space: normal; display: -webkit-box; -webkit-box-orient: vertical; min-height: 56px;} 

.recruit_card .content_wrap .recruit_info {
    margin-top: 17px;
    padding: 0 20px;
    font-size: 15px;
    letter-spacing: -0.05em;
    color: #999;
    font-weight: 400;
    display: flex;
    width: 100%;
    white-space: nowrap;
}

.recruit_card .content_wrap .recruit_info .company {
    display: inline-block;
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: inherit;
}

.recruit_card .content_wrap .compensation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background: #f5f5f5;
    padding: 15px 16px;
    min-height: 50px;
}

.recruit_card .content_wrap .compensation * {
    font-size: 15px;
    letter-spacing: -0.75px;
}

.recruit_card .content_wrap .compensation .left_wrap {
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
}

.recruit_card .content_wrap .compensation .left_wrap img {
    margin-right: 5px;
}

.recruit_card .content_wrap .compensation .right_wrap {
    letter-spacing: 0;
    font-weight: 700;
    color: #3d7df2;
}

/******************************************
list_dot형
인재DB > 상세
*******************************************/
.list_dot.basics li:first-of-type {
    margin-top: 0
}

.list_dot.basics li {
    padding-left: 12px;
    margin-top: 12px;
    color: #222222;
    font-size: 15px;
}

.list_dot.basics li:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background: #3d7df2;
    border-radius: 100px;
    margin-right: 7px;
    margin-left: -12px;
    vertical-align: 3px;
}

/******************************************
타이틀+정보형
my heding > 정보관리 > 프로필
*******************************************/
.list_dot.info_type {
    display: table
}

.list_dot.info_type li {
    display: table-row
}

.list_dot.info_type li p {
    display: table-cell;
    font-size: 16px;
    padding: 8px 30px 8px 0;
}

.list_dot.info_type li p.Title {
    color: #888888;
}

.list_dot.info_type li p.Info {
    color: #000000;
}

.list_dot.info_type li .Button {
    margin-right: 14px;
}

/******************************************
텍스트형
my heding > 정보관리 > 회원탈퇴안내
*******************************************/
.list_dot.text_type {
    color: #888;
}

.list_dot.text_type li {
    padding-left: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    font-size: 15px;
}

.list_dot.text_type li:before {
    margin-left: -8px;
    vertical-align: 3px;
    content: '';
    background: #c4c4c4;
    width: 2px;
    height: 2px;
    margin-right: 6px;
    display: inline-block;
}

.list_dot.text_type a {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: middle;
    width: 90%;
}

.tip_field {
    color: #d02928;
}

.point_text {
    color: #d02928;
}

/******************************************
'-' 하이픈형
my heding > 정보관리 > 회원탈퇴안내
*******************************************/
.list_dot.hyphen_type {
    color: #333;
    margin-top: -20px;
}

.list_dot.hyphen_type li {
    padding-left: 8px;
    margin-top: 20px;
    font-size: 15px;
}

.list_dot.hyphen_type li:before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 1px;
    background: #333;
    margin-right: 3px;
    margin-left: -8px;
    vertical-align: 5px;
}


/*************************************
입력 폼
**************************************/
.input_form {
    display: table;
    padding: 30px 0;
    width: 100%;
    border: 1px solid #999999;
    border-width: 1px 0;
}

.input_form li {
    display: table-row;
    font-size: 15px
}

.input_form li p {
    display: table-cell;
    padding: 7px;
    vertical-align: top;
    font-weight: 500;
}

.input_form li .title_field {
    padding: 22px 20px 0 10px;
    width: 14%;
    font-size: 15px;
}

.input_form li input[type=text],
.input_form li input[type=password],
.input_form li textarea {
    width: 100%;
}

.input_form select+textarea {
    margin-top: 10px;
}

/*회원관련*/
.member_form .title_field {
    font-size: 15px;
    margin-bottom: 7px;
    margin-top: 30px;
    color: #000;
}

.member_form .title_field:first-of-type {
    margin-top: 0;
}

.member_form.box {
    padding: 30px;
}

.member_form .input_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.member_form .input_wrap .btn {
    margin-left: 10px;
    font-size: 15px;
    white-space: nowrap;
    padding: 0 15px;
    width: inherit;
    min-height: 48px;
    height: 48px;
}

_:-ms-fullscreen,
:root .member_form .input_wrap .btn {
    min-width: 120px;
    padding: 0 10px;
}

.member_form .input_wrap+input {
    margin-top: 14px
}

.member_form .email_field .text {
    color: #000;
    font-size: 15px;
}

.member_form .bottom_btn_area .btn {
    width: 100%;
}

.member_form .bottom_btn_area .btn+.btn {
    margin-top: 20px;
}

.member_form .bottom_btn_area {
    margin-top: 30px;
    clear: both;
}

.member_form .notice_area {
    text-align: center;
    margin-bottom: 50px;
}

.member_form .notice_area img {
    padding: 34px 0;
}

.member_form .notice_area .title_field {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.05em;
    margin-bottom: 10px;
}

.member_form .notice_area .text_field {
    color: #666666;
    letter-spacing: -0.05em;
    font-size: 15px;
}

.member_form .notice_area .text_field+.text_field {
    margin-top: 10px;
}

.member_form .notice_area .point {
    color: #3d7df2;
    font-weight: bold;
}

.member_form .tip_field {
    font-size: 15px;
    margin-top: 10px;
    color: #999
}

.member_form .agree_field {
    font-size: 15px;
    margin-top: 10px;
}

.email_field {
    align-items: center;
}

.email_field .text {
    padding: 0 5px;
    display: inline-block;
}


@media screen and (min-width:1260px) {}

@media screen and (max-width:1260px) {}

@media screen and (min-width:1025px) {

    /*1025이상*/
    .recruit_card:nth-child(4n) {
        margin-right: 0;
    }

    .recruit_card:nth-child(n+5) {
        margin-top: 30px;
    }

    .sub_content_title.indent {
        margin-left: 10px;
    }
}

@media screen and (max-width:1024px) {
    .recruit_card {
        width: calc(33.333% - 15px);
    }

    .recruit_card:nth-child(3n) {
        margin-right: 0;
    }

    .recruit_card:nth-child(n+4) {
        margin-top: 30px;
    }

    /*인증경력*/
    .popup .myresume_write {
        max-width: 90vw;
        max-height: 90vh;
    }
    .tab02 .tab{
      font-size:13px;
    }
}

.member_form .tip_field {
    font-size: 15px;
    margin-top: 10px;
    color: #999;
}

.email_field {
    align-items: center;
}

.email_field .text {
    padding: 0 5px;
    display: inline-block;
}

@media screen and (min-width: 1025px) {
    .recruit_card:nth-child(4n) {
        margin-right: 0;
    }

    .recruit_card:nth-child(n + 5) {
        margin-top: 30px;
    }

    .sub_content_title.indent {
        margin-left: 10px;
    }
}

@media screen and (max-width: 1024px) {
    .recruit_card {
        width: calc(33.333% - 15px);
    }

    .recruit_card:nth-child(3n) {
        margin-right: 0;
    }
}

@media screen and (max-width:768px) {
    .sub_content_title {
        font-size: 16px;
    }

    .sub_content_title+.sub_content_text {
        margin-top: -5px
    }

    .sub_content_text {
        margin-bottom: 20px;
        font-size: 13px;
    }

    .page_title {
        font-size: 21px;
        margin: 22px 0 13px;
    }

    /* 셀렉트 스타일 
 .style_select { padding: 0 25px 0 7px; background-image: url(../Img/Common/select_arrow_m.png); background-size: 10px 5px; background-repeat: no-repeat; background-position: calc(100% - 7px) center; appearance: none; } */

    .select_box .button {
        font-size: 13px;
    }

    /* bookmark 별 */
    .btn_bookmark.m_resize {
        width: 19px;
        height: 17px;
        background-size: 19px 17px;
        background: url(../img/common/bookmark_m.png) no-repeat center;
    }

    .btn_bookmark.m_resize.active {
        background: url(../img/common/bookmark_on_m.png) no-repeat center;
    }

    /*************
 채용공고 카드
 **************/
    .recruit_card .content_wrap .compensation * {
        font-size: 12px;
    }

    .recruit_card_wrap {
        width: calc(100% + (4% *2));
        margin-left: -4%;
    }

    .recruit_card .link_wrapper {
        display: flex;
    }

    .recruit_card {
        margin-right: 0;
        width: 100%;
        border-right-width: 0;
        border-bottom-width: 0;
        border-left-width: 0;
        padding: 20px 4%;
    }

    .recruit_card:nth-child(n+4) {
        margin-top: 0;
    }

    .recruit_card:nth-child(n+5) {
        margin-top: 0;
    }

    .recruit_card:last-of-type {
        border-bottom-width: 1px;
    }

    .recruit_card .content_wrap {
        width: 74%;
    }

    .recruit_card .content_wrap .type_wrap {
        margin-top: 0;
        padding: 0 16px;
    }

    .recruit_card .content_wrap .type_wrap .new_badge {
        width: 32px;
        height: 18px;
        background: url(img/sub/new2_m.png) no-repeat center;
        background-size: 32px 18px;
    }

    /*.main_recruit .recruit_card_wrap .recruit_card .content_wrap .title { 2022/11/22 삭제, 메인 외 공고카드가 틀어짐
        margin-top: 11px;
        padding: 0 16px;
        line-height: 1.2;
        -webkit-line-clamp: 1;
        font-size: 15px;
        min-height: 18px;
    }*/
    .recruit_card_wrap .recruit_card .content_wrap .title {
      margin-top: 11px;
      padding: 0 10px;
      line-height: 1.2;
      -webkit-line-clamp: 1;
      font-size: 15px;
      min-height: 18px;
    }

    .recruit_card .content_wrap .recruit_info {
        margin-top: 9.5px;
        padding: 0 16px;
        letter-spacing: -1.1px;
        font-size: 12px;
    }

    .recruit_card .content_wrap .compensation {
        letter-spacing: -1.1px;
        margin-top: 9px;
        background: transparent;
        padding: 0 16px;
        justify-content: flex-start;
        min-height: auto;
    }

    .recruit_card .content_wrap .compensation .right_wrap {
        margin-left: 6px;
    }

    .recruit_card .btn_bookmark {
        top: 20px;
        right: 4%;
    }

    .recruit_card .image_wrap {
        position: relative;
        overflow: hidden;
        height: 0;
        width: 26%;
        min-width: 26%;
        padding-bottom: calc(4/6 * 26%);
    }

    .recruit_card .image_wrap img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    /*탭*/
    .tab01_wrap {
        border: none;
        border-bottom: 1px solid #dedede;
        padding: 18px 4%;
    }

    .tab01_wrap .tab01 {
        border: 1px solid #d4d4d4;
    }

    .tab01_contents {
        padding: 20px
    }

    .tab01_contents h1 {
        font-size: 12px;
        letter-spacing: normal;
    }

    .tab01_contents li {
        font-size: 12px;
        letter-spacing: normal;
    }

    .tab01 li a {
        font-size: 13px;
        height: 40px;
    }

    .center_wrap_member .tab01_wrap {
        border: 1px solid #999;
        padding: 0;
    }

    .center_wrap_member .tab01_wrap .tab01 {
        border-width: 0 0 1px 0;
    }

    .tab02 a {
        font-size: 13px;
    }

    .tab01_contents li {
        padding: 3px 0;
    }

    /*하단배너*/
    .sub_bottom_banner {
        margin: 44px 0 0;
        padding: 0;
    }

    .sub_bottom_banner img {
        width: 100%;
    }

    /******************************************
 list_dot
 *******************************************/
    .list_dot.basics li {
        font-size: 13px;
    }

    .list_dot.basics li {
        margin-top: 0px;
    }

    .list_dot.text_type li {
        font-size: 13px;
    }

    .list_dot.hyphen_type li {
        font-size: 13px;
    }

    /*************************************
 입력 폼
 **************************************/
    .input_form {
        border-color: #dedede;
    }

    .input_form li p {
        display: block;
        padding: 7px 0 0 0;
    }

    .input_form li .title_field {
        width: 100%;
        padding: 25px 0 0 0;
    }

    .input_form li:first-of-type .title_field {
        padding-top: 15px;
    }

    .member_form.box {
        padding: 20px 4%;
        border: 1px solid #999;
    }

    .member_form .title_field {
        margin-top: 20px;
        font-size: 13px;
    }

    .member_form .bottom_btn_area {
        margin-top: 24px;
    }

    .member_form .bottom_btn_area .btn {
        font-size: 13px;
    }

    .member_form .bottom_btn_area .btn+.btn {
        margin-top: 12px;
    }

    .member_form .notice_area {
        margin-bottom: 30px;
    }

    .member_form .notice_area img {
        padding: 25px 0;
    }

    .member_form .input_wrap input+.btn,
    .member_form .input_wrap select+.btn {
        font-size: 11px;
        padding: 0 8px;
        margin-left: 5px;
        min-height: 40px;
        height: 40px;
    }

    .member_form .notice_area .title_field {
        font-size: 16px;
        word-break: keep-all;
    }

    .member_form .notice_area .text_field {
        font-size: 13px;
        line-height: 1.5em;
        word-break: keep-all;
    }

    .member_form .email_field .text {
        font-size: 13px;
    }

    .member_form .tip_field {
        font-size: 13px;
    }

    /*마이헤딩 현황판*/
    .state_wrap .state_box .title {
        margin-bottom: 6px;
        white-space: normal;
        font-size: 13px;
    }

    .state_wrap .state_box .num {
        font-size: 15px;
    }

    .state_wrap .state_box .btn {
        font-size: 13px;
        min-height: 30px;
        height: 30px
    }

    /***********
 팝업
 ************/
    .popup01 .button_wrap,
    .popup01 .title_wrap,
    .popup01 .content_wrap:not(.resume_style) {
        padding: 18px;
    }

    .popup01 .button_wrap .point_text {
        margin-bottom: 20px;
        margin-top: -10px;
        font-size: 12px;
    }

    .popup01 .content_wrap .chk[type=radio]+label:before {
        width: 17px;
        height: 17px;
    }

    .popup01 .content_wrap .chk[type=radio]:checked+label:before {
        background-size: 110% auto;
    }

    .popup01 .title_wrap {
        min-height: 50px;
    }

    .popup01 .title_wrap h1 {
        font-size: 17px;
    }

    .popup .notice_area .title_field {
        font-size: 13px;
        line-height: inherit;
    }

    .popup .notice_area .text_field {
        font-size: 13px;
    }

    .popup .notice_area .icon {
        margin-bottom: 18px;
    }

    .popup .notice_area+.field {
        margin-top: 24px !important;
    }

    .popup01 .content_wrap .only_input .field:not(:first-of-type) {
        margin-top: 10px;
    }

    .popup01 .content_wrap .field .label {
        font-size: 13px;
    }

    .popup01 .button_wrap .big_button_wrap .btn {
        font-size: 13px;
    }

    .popup .button_wrap .close_button_wrap a {
        font-size: 12px;
    }

    .popup01 .content_wrap .field.col .text {
        font-size: 13px;
    }

    .popup01 .content_wrap .input_wrap .btn,
    .popup01 .content_wrap .input_button .btn {
        min-height: 40px;
        height: 40px;
        font-size: 13px;
    }

    /*채용공고 팝업*/
    .resume_box_top h1,
    .resume_box_top .title_field {
        font-size: 13px;
    }

    .resume_box .table_notice_badge {
        font-size: 9px;
    }

    /*간편인증*/
    .Certify .selectCertify .icon {
        border-radius: 5px;
        width: 40px;
        height: 40px;
    }

    /*기업검색*/
    .popup .company_list_area {
        font-size: 13px;
        letter-spacing: -0.6px;
    }

    .popup .company_list_area>div label {
        padding: 7px 9.5px;
    }

    .popup .company_search .search_area .btn {
        height: 40px;
        min-height: 40px;
    }

}

@media screen and (max-width:640px) {
    .divisionBar {
        margin: 0 5px;
    }
}

@media screen and (max-width:560px) {
    .recruit_card .content_wrap .type_wrap {
        padding: 0 0 0 10px;
    }
    .recruit_card_wrap .recruit_card .content_wrap .title {
        padding: 0 0 0 10px; margin-top: 8px; min-height: auto;
    }
    .recruit_card .content_wrap .recruit_info {
        padding: 0 0 0 10px;
    }
    .recruit_card .content_wrap .compensation {
        padding: 0 0 0 10px;
    }
}

@media screen and (max-width:450px) {
    .email_field {
        flex-wrap: wrap;
    }

    .email_field input[type=text] {
        width: 44.1% !important
    }

    .email_field .text {
        width: 11% !important;
        padding: 0;
        text-align: center;
    }

    .email_field select {
        margin-left: 0;
        margin-top: 5px;
        width: 68% !important;
    }

    .email_field .btn {
        width: 28% !important;
        margin-top: 5px;
        min-width: 0 !important;
    }
}

@media screen and (max-width:375px) {
    .state_wrap .state_box {
        padding: 15px 7px;
    }

    .state_wrap .state_box .title {
        word-break: break-all;
        height: auto;
    }
}