@charset "utf-8";
/**********
공통
***********/

#main{position: relative; z-index: 1;}
/*버튼*/
.button_more{border:1px solid #3d7df2; border-radius: 100px; min-height:42px; line-height: 42px; padding:0 33px; display: inline-flex; justify-content:center; align-items: center; font-size:15px; box-shadow: 0 5px 5px rgba(0,0,0,.2); color:#3d7df2; letter-spacing: -0.05em; white-space: nowrap;}
.button_more img{margin-left: 10px; height: 12px;}

/**********
비주얼
***********/
.main_visual{position:relative; --swiper-theme-color:#fff; --swiper-navigation-size:38px; }
.main_visual .button{position:absolute; color:#fff; border:1px solid #fff; border-radius: 100px; height: 60px; display: inline-flex; justify-content: center; align-items: center; font-size:15px; padding:0 70px 0 47px; background: url(../img/sub/icon_arrow4.png) no-repeat; background-position: calc(100% - 45px) center; left:7.5%; bottom:17%;}
.main_visual .swiper-slide { aspect-ratio: 1260/340; }
.swiper-container .view_pc { width: 100%; height:100%; object-fit: cover; }
.swiper-container .view_m { width: 100%; height:100%; object-fit: cover; }
/**********
상단링크배너
***********/
.main_link{margin-top: 57px;}
.main_link .title_area{text-align: center; margin-bottom: 32px; color:#000; letter-spacing: -0.05em; font-size:26px; font-weight: bold;}
.main_link .link{display: flex; justify-content: space-between;}
.main_link .link li{border:1px solid #cccccc; width:24%; }
.main_link .link li a{padding:30px 23px; display: flex; flex-wrap: wrap; justify-content: space-between; height: 100%; min-height: 187px;}
.main_link .link .title_field{line-height: 30px; font-size:20px; color:#000; letter-spacing: -0.05em; width: 100%; font-weight: 300;}
.main_link .link .title_field b{display: block; font-weight: 500; letter-spacing: -0.075em}
.main_link .link .txt_field{font-size:15px; color:#000; letter-spacing: -0.05em; width: 50%; margin-top: auto;}
.main_link .link .txt_field img{height: 12px; vertical-align: -1px; margin-left: 10px;}
.main_link .link .img_field{width: 50%; margin-top: auto; text-align: right;}



/************
배너
*************/
#main .sub_bottom_banner{margin-top:45px}
.main_banner{margin-top: 70px;}
.main_banner .btn_area{display: flex; justify-content: center; margin-top: 13px;}
.main_banner .btn_stop{padding:1px 5px; background: #efefef; margin-left: 3px;}
/**************
채용공고,헤드헌터 전용관
***************/
.main_recruit{margin-top: 60px; }
.main_recruit .title_area{color:#000; font-size:26px; font-weight: bold; margin-bottom: 25px;}
.main_recruit .search_area{width: 100%; margin-bottom: 28px; display: flex; justify-content: space-between; align-items: center;}
.main_recruit .search_area .btn_field .button{border:1px solid #5c5c5c; color:#000; font-size:20px; letter-spacing: -0.05em; min-width: 160px; min-height:56px; line-height: 56px; display: inline-flex; justify-content: center; align-items: center; padding:0 30px; width:100%; white-space: nowrap;}
.main_recruit .search_area .btn_field{display: flex; justify-content: space-between; align-items: center;}
.main_recruit .search_area .btn_field li:not(:first-child){margin-left:10px;}
.main_recruit .search_area .btn_field .active .button{color:#fff; font-weight: bold; background:#3d7df2; border-color:#3d7df2; box-shadow: 0 10px 10px rgba(0,0,0,.2)}
.main_recruit .search_area .search{border-bottom:2px solid #333; display: flex; justify-content: space-between; align-items: center; width: 400px}
.main_recruit .search_area .search input[type=text]{border:none; width: 100%;}
.main_recruit .search_area .search input[type=text]::-webkit-input-placeholder{font-size:20px;}
.main_recruit .search_area .search input[type=text]:-ms-input-placeholder{font-size:20px;}
.main_recruit .search_area .search input[type=text]::-ms-input-placeholder{font-size:20px;}
.main_recruit .search_area .search input[type=text]::placeholder{font-size:20px;}
.main_talk .btn_area,
.main_recruit .btn_area{text-align: center; margin-top: 40px;}
.main_talk .pagination,
.main_talk .btn_stop,
.main_recruit .pagination,
.main_recruit .btn_stop{display: none;}

/***********
이직톡
************/
.main_talk{margin-top: 85px;}
.main_talk .title_area{color:#000; font-size:26px; font-weight: bold; margin-bottom: 25px;}
.main_talk .list{display:flex; flex-wrap: wrap; }
.main_talk .list li{margin-right: 1.7%; margin-bottom:1.7%;  width: 23.7%; border:1px solid #cfcfcf;}
.main_talk .list li:nth-child(4n){margin-right: 0}
.main_talk .list li a{padding:28px 20px;}
.main_talk .list .title_field{font-size:20px; color:#000; margin-bottom: 10px; line-height: 28px;}
.main_talk .list .txt_field{color:#999; letter-spacing: -0.05em; font-size:15px; line-height: 20px;}
.main_talk .list .title_field,
.main_talk .list .txt_field{overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2;}


/************
공지,FAQ
*************/
.main_text_list{margin-top:86px; display: flex; justify-content: space-between;}
.main_text_list .wrap{width: calc(50% - 1px); background: #f8f8f8; padding:26px; display: flex; justify-content: space-between; align-items: center;}
.main_text_list .title_area{color:#000; font-size:26px; letter-spacing: -0.05em; font-weight: bold; white-space: nowrap; width: 70px;}
.main_text_list .list_area{width: calc( 100% - 92px - 70px); padding-right: 50px;}
.main_text_list .list_area a{color:#666666; font-size:15px;display: flex; align-items: center; justify-content: space-between;}
.main_text_list .list_area .field_title{display: block; overflow: hidden; text-overflow: ellipsis; padding-right: 20px; white-space: nowrap}
.main_text_list .list_area .field_date{white-space: nowrap}
.main_text_list .btn_area{width: 92px; display: flex; justify-content: space-between; align-items: center;}
.main_text_list .btn_area .btn_down,
.main_text_list .btn_area .btn_up{background: #fff; width: 31px; height: 31px; line-height: 28px; text-align: center; border-radius: 5px; border:1px solid #b2b2b2;cursor:pointer;}
.main_text_list .btn_area .btn_down{margin-left: auto;}
.main_text_list .btn_area .btn_up{margin-left: 4px;}
.main_text_list .btn_area a{cursor: pointer;}

/**************
하단 링크 배너
***************/
.main_bottom_link{display: flex; justify-content: space-between; align-items: center; margin-top: 75px; margin-bottom: 57px;}
.main_bottom_link .wrap{width: 33.3%; display: flex; justify-content: center; align-items: center; position: relative;}
.main_bottom_link .wrap:not(:first-child):before{content: ''; display: inline-block; background: #ccc; height: 33px; width: 1px; position: absolute; left:0;}
.main_bottom_link .wrap:first-child .img_field{margin-right: 19px;}
.main_bottom_link .wrap:nth-child(2) .img_field{margin-right: 53px;}
.main_bottom_link .wrap:last-child .img_field{margin-right: 30px;}
.main_bottom_link .title_field{color:#000; font-size:20px; font-weight: 500;}
.main_bottom_link .text_field{color:#666666; font-size:15px; font-weight: 300;}
.main_bottom_link .btn_area{display: flex; align-items: center; margin-left: -6px; margin-top: 11px;}
.main_bottom_link .btn_area .button{color:#666666; font-size:12px; font-weight: 300; min-height: 28px; border-radius: 100px; display: inline-flex; justify-content: center; align-items: center; padding:0 13px; min-width: 84px; background: #f0f0f0; border:1px solid #b2b2b2;}
.main_bottom_link .btn_area .button:not(:first-of-type){margin-left: 6px;}

/******************
회사배너
*******************/
.main_company_banner{ position: relative; border-top:1px solid #eeeeee; padding:37px; --swiper-theme-color:#424242; --swiper-navigation-size:20px; }
.main_company_banner .center_wrap{position:relative; padding:0 40px;}
.main_company_banner .swiper-slide a{aspect-ratio:6 / 4; display: flex; align-items: center; justify-content: center; overflow: hidden;}
.main_company_banner .swiper-slide a img{object-fit:cover; max-width: 100%; min-width: 100%; height: auto; max-height: 100%;}

/******************
앱으로 보기 팝업
*******************/
.pop_appdown .popup_wrap { width:300px; max-width: calc(100% - 62px); background-color: transparent; }
.pop_appdown .top { display: flex; align-items: center; }
.pop_appdown .top .logo_wrap { border-radius:15px; background-color: #fff; width: 86.5px; height: 86.5px; background-image: url(../img/common/logo.png); background-repeat: no-repeat; background-position:
center center; background-size: calc(100% - 14px); }
.pop_appdown .top h1 { margin-left: 16.5px; color: #fff; font-size: 27.5px; line-height: 1.272727272727; letter-spacing: -0.05em; font-weight: 400;}
.pop_appdown .bottom { margin-top: 45px; text-align: center;}
.pop_appdown .btn_appdown { background-color: #3269cc; color:#fff; border-radius:22px; height: 45px; width:100%; max-width: 100%; display: flex; align-items: center; justify-content: center; font-weight: 700; font-size: 20px; letter-spacing: 0; font-weight: 500;}
.pop_appdown .btn_webview { margin-top: 21px; font-size: 12px; color: #fff; font-weight: 300; padding-bottom: 4px; border-bottom: 1px solid #fff; letter-spacing: 0;}

@media screen and (max-width:1260px){
    /**********
    비주얼
    ***********/
    .main_visual .swiper-button-prev{left:40px;}
    .main_visual .swiper-button-next{right: 40px;}
    
    /**********
    상단링크배너
    ***********/
    .main_link .link .title_field b{display: inline;}
    
    /**************
    채용공고,헤드헌터 전용관
    ***************/
    .main_recruit .search_area{display: block;}
    .main_recruit .search_area .btn_field li{width: auto; min-width: auto;}
    .main_recruit .search_area .search{display: none;}

    /******************
    회사배너
    *******************/
    .main_company_banner{padding:18px 2%; }
}
@media screen and (min-width:1025px){/*1025이상*/
    /**********
    상단링크배너
    ***********/
    .main_link .link li:hover{background: #ffdd00; border-color:#ffdd00; box-shadow: 0 10px 10px rgba(0,0,0,.2);}
    #main{position: relative; z-index: 1;}
}
@media screen and (max-width:1024px){/*모바일 디자인 적용*/
    /**********
    비주얼
    ***********/
    .main_visual .swiper-button-prev{left:10px;}
    .main_visual .swiper-button-next{right: 10px;}
    .main_visual{margin-top: -4px; padding:0;}
    .main_visual .button{padding:0 10px; height:45px; background: none;}
    /**********
    상단링크배너
    ***********/
    .main_link .link{flex-wrap: wrap;}
    .main_link .link li{margin-right: 3%; margin-bottom: 3%; width: 48.5%}
    .main_link .link li:nth-child(2n){margin-right: 0}
    .main_link .link .txt_field{display: none;}
    .main_link .link a{flex-wrap: nowrap; justify-content: space-between; align-items: center;}
    .main_link .link .title_field{order:2; width: calc(100% - 63px)}
    .main_link .link .title_field b{display: block;}
    .main_link .link .img_field{order:1; width: 63px; margin-top: inherit; text-align: left;}
    .main_link .link .img_field img{width:80%;}
    
    /**************
    채용공고,헤드헌터 전용관
    ***************/
    .main_recruit .search_area .btn_field .active .button{min-width:0; }
    
    /************
    공지,FAQ
    *************/
    .main_text_list{flex-direction: column;}
    .main_text_list .wrap{width: 100%;}
    .main_text_list .wrap:first-child{margin-bottom: 2px; border-radius: 10px 10px 0 0;}
    .main_text_list .wrap:last-child{border-radius: 0 0 10px 10px;}
    
    /**************
    하단 링크 배너
    ***************/
    .main_bottom_link{flex-wrap: wrap;}
    .main_bottom_link .wrap .img_field{width: 56px; margin-right: 19px !important;}
    .main_bottom_link .wrap{width: 50%; margin-bottom: 58px; justify-content: flex-start}
    .main_bottom_link .wrap:not(:first-child):before{display: none;}
    .main_bottom_link .wrap:first-child{order:1;}
    .main_bottom_link .wrap:nth-child(2){order:3; width: 100%;  margin-bottom: 0}
    .main_bottom_link .wrap:last-child{order:2;}
}
@media screen and (max-width:768px){
    /**********
    비주얼
    ***********/
    .main_visual .button{height:30px; bottom:39%; width:auto !important; font-size:14px;}
    .main_visual .swiper-slide a,
    .main_visual .swiper-slide a img{width: 100%;}
    .main_visual{--swiper-navigation-size:20px; }
    .main_visual .swiper-slide { aspect-ratio: 720/400; }
    

    /**********
    상단링크배너
    ***********/
    .main_link{margin-top: 18px;}
    .main_link .title_area{font-size:13px; margin-bottom: 14px;}
    .main_link .link li a{min-height: 0; padding:12px; align-items: center; justify-content: center; }
    .main_link .link .img_field { text-align: center; }
    .main_link .link .title_field{font-size:13px; line-height: 20px; width:100%; text-align: center; margin-top: 6px;}

    /************
    배너
    *************/
    .main_banner{padding:0; margin-top: 35px; margin-bottom: 24px;}
    .main_banner_slider li,
    .main_banner_slider a,
    .main_banner_slider img{width: 100%;}
    
    .button_more{font-size:12px; min-height: 30px; line-height:30px; padding:0 15px; box-shadow: 0 1px 5px rgb(0 0 0 / 20%);}
    .button_more img{height: 8px;}

    /**************
    채용공고,헤드헌터 전용관
    ***************/
    .main_recruit{padding-top: 18px; margin-top: 0;  border-top:5px solid #f4f6f8;}
    .main_recruit .title_area{font-size:13px; margin-bottom: 14px;}
    .main_recruit .search_area{margin-bottom: 15px;}
    .main_recruit .search_area .btn_field{justify-content: flex-start; overflow-x: auto; }
    .main_recruit .search_area .btn_field li{width: auto;}
    .main_recruit .search_area .btn_field li:not(:first-child){margin-left: 20px;}
    .main_recruit .search_area .btn_field li .button{border:none; min-width: 0; width:inherit; display: inline-block; font-size:13px; min-height: 0; padding:0;}
    .main_recruit .search_area .btn_field .active .button{color:#3d7df2; background: url("../img/common/chk_blue.png") no-repeat; background-size: contain; padding-left:20px; box-shadow: none; line-height: 1;}
    .main_talk .btn_area,
    .main_recruit .btn_area{display: flex; justify-content: center; margin-top: 15px;}
    .main_talk .pagination,
    .main_talk .btn_stop,
    .main_recruit .pagination,
    .main_recruit .btn_stop{display: block;}
    .main_talk .btn_stop,
    .main_recruit .btn_stop{margin-left: 3px; background: #efefef; padding:1px 4px;}
    /***********
    이직톡
    ************/
    .main_talk{margin-top: 35px;}
    .main_talk .title_area{font-size:13px; margin-bottom: 14px;}
    .main_talk .list li{width:100%;}
    .main_talk .list li{margin-right: 0}
    .main_talk .list li:last-child{margin-bottom: 0;}
    .main_talk .list li a{width: 100%; padding:12px;}
    .main_talk .list .title_field{font-size:13px; margin-bottom: 0px;}
    .main_talk .list .txt_field{font-size:12px;}
    .main_talk .list .title_field, 
    .main_talk .list .txt_field{white-space: nowrap; display: block; -webkit-line-clamp:1; width: 100%;}

    /************
    공지,FAQ
    *************/
    .main_text_list{margin-top: 35px;}
    .main_text_list .wrap{padding:12px;}
    .main_text_list .title_area{font-size:13px; font-weight: 400; align-self: flex-start; width: 13px;}
    .main_text_list .list_area{width: calc( 100% - 92px - 50px); padding-right: 0;}
    .main_text_list .list_area a{display: block; font-size:13px;}
    .main_text_list .list_area .field_title { padding-right: 0; }
    
    .main_bottom_link .btn_area .button{min-height: 20px; min-width: 78px; padding:0 8px;}
    
    /**************
    하단 링크 배너
    ***************/
    .main_bottom_link{margin:35px 0;}
    .main_bottom_link .wrap{margin-bottom: 25px; width: 100%;}
    .main_bottom_link .title_field,
    .main_bottom_link .text_field{font-size:13px;}
    .main_bottom_link .btn_area .button{font-size:10px;}
    .main_bottom_link .wrap .img_field{width:17%}
    .main_bottom_link .wrap:nth-child(2) .img_field{width:8.5%}
    .main_bottom_link .img_field img{max-width: 90%; max-height: 36px;}
    /******************
    회사배너
    *******************/
    .main_company_banner{padding:18px 0; }
    .main_company_banner .center_wrap{padding:0 25px;}
    .main_company_banner .swiper-button-next:after, 
    .main_company_banner .swiper-button-prev:after{--swiper-navigation-size:10px;}

    .pop_appdown.active { display:block !important; width: 100vw; }
}
@media screen and (max-width:440px){
    /*************
    메인비주얼
    **************/
    .main_visual .button{bottom:33%;}
    .main_bottom_link .wrap:nth-child(2) .img_field{width:17%}
}

@media screen and (max-width:360px){
    .pop_appdown .popup_wrap { max-width: 94%; }
}

































































