@charset "UTF-8";
.page_voucher > .table_list01 { margin-top: 32px; } 
.page_voucher > .table_list01 .tr{width: 100%;}
.page_voucher > .table .tr .m_wrap{width: 100%;}
.page_voucher > .table_list01 .row_kind { width: 35%; text-align: left; } 
.page_voucher > .table_list01 .row_date { width: 15%; } 
.page_voucher > .table_list01 .row_send { width: 15%; } 
.page_voucher > .table_list01 .row_price { width: 25%;  min-width: 298px; } 
.page_voucher > .table_list01 .row_btn { width: 10%; } 
.page_voucher > .table_list01 .m_th { display: none; } 
.page_voucher > .table_list01 .td.row_price { text-align: right; } 
.page_voucher > .table_list01 .td.row_price .price_align { width: 188px; text-align: right; } 
.page_voucher > .table_list01 .td.row_price del { color: #999; } 
.page_voucher > .table_list01 .td.row_price strong { width: 43px; margin-left: 10px; font-weight: 400; color: #d12928; } 
.page_voucher > .table_list01 .row_btn { min-width: 118px; padding: 9px; } 
.page_voucher .btn_buy { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; width: 90px; line-height: 33px; border: 1px solid #ccc; font-size: 15px; letter-spacing: -0.38px; } 
.page_voucher .btn_buy .nanumGothic { font-size: 12px; letter-spacing: -0.6px; line-height: inherit; color: #333; margin-left: 8px; line-height: 1;} 
.page_voucher .notice { margin: 43px 25px 78px; } 
.page_voucher .notice h1 { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; font-size: 20px; letter-spacing: -0.5px; font-weight: 700; } 
.page_voucher .notice h1 .icon_mark { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; border-radius: 50%; width: 30px; height: 30px; background-color: #3d7df2; color: white; font-weight: 700; font-size: inherit; letter-spacing: inherit; line-height: 1; margin-right: 10px; } 
.page_voucher .notice ul { margin-left: 30px; margin-top: 14px; } 
.page_voucher .notice li { position: relative; font-size: 15px; letter-spacing: -0.38px; padding: 5px 0 5px 10px; line-height: 1.4; } 
.page_voucher .notice li::before { content: '-'; display: inline-block; position: absolute; left: 0; } 
.page_voucher.pay .sub_content_title { margin-top: 32px; margin-bottom: 19px; } 
.page_voucher.pay .sub_content_title label { font-size: inherit; letter-spacing: inherit; font-weight: inherit; } 
.page_voucher.pay .radio_wrap + .radio_wrap { margin-left: 46px; } 
.page_voucher.pay > .table_list01 { margin-top: 0; } 
.page_voucher.pay > .table_list01 .tr:last-child { border-bottom: 0; } 
.page_voucher.pay .row_kind { text-align: center; } 
.page_voucher.pay .row_amount { width: 15%; } 
.page_voucher.pay .payment_area { border: 1px solid #999; padding: 20px;} 
.page_voucher.pay .payment_area .pay_type_select{ display:flex; margin-bottom: 10px;}
.page_voucher.pay .payment_area .radio_wrap2:not(:first-child){margin-left:30px}
.page_voucher.pay > .table_list01 .td.row_price { text-align: center; } 
.page_voucher.pay input[type=text], 
.page_voucher.pay select { width: 320px; margin-left: 0; } 
.page_voucher.pay .cashreport_area input, .page_voucher.pay .cashreport_area select { margin-top: 10px; } 
.page_voucher.pay .cashreport_area .radio_wrap { margin-top: 10px; } 
.page_voucher.pay .cashreport_area .cashreport1, .page_voucher.pay .cashreport_area .cashreport2 { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; margin-top: 8px; } 
.page_voucher.pay .agree_check li { font-size: 15px; letter-spacing: -0.38px; color: #666; padding: 5px 10px; } 
.page_voucher.pay .agree_check label { font-size: inherit; letter-spacing: inherit; margin-right: 0; } 
.page_voucher.pay .agree_check a { font-size: inherit; letter-spacing: inherit; color: #3d7df2; margin-left: 5px; } 

@media screen and (max-width: 1024px){
  .page_voucher > .table_list01 .row_kind { width: 25%; } 
  .page_voucher > .table_list01 .row_date, .page_voucher > .table_list01 .row_send, .page_voucher > .table_list01 .row_amount { width: 20%; } 
  .page_voucher > .table_list01 .row_btn { display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: end; -ms-flex-pack: end; justify-content: flex-end; } 
  .page_voucher.pay > .table_list01 .row_price { width: 20%; min-width: auto; } 
  .page_voucher.pay .payment_area .table_view01 th{min-width: 95px;}
 }

@media screen and (max-width: 890px){
  /*.chk + label:before { width: 16px; height: 16px; } 
  .circle:checked + label::after { width: 8px; height: 8px; top: 4px; left: 4px; } */
  .company_voucher { padding: 0; } 
  .page_voucher > .table_list01 { margin-top: 0; border-top: 0; border-left:none; border-right: none; } 
  .page_voucher > .table_list01 .m_th { display: inline-block; color: #666; font-weight: 100; } 
  .page_voucher > .table_list01 .tr { position:relative; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; -webkit-box-align: center; -ms-flex-align: center; flex-wrap: wrap; align-items: center; padding: 20px 15px; } 
  .page_voucher > .table_list01 .tr:first-of-type { display: none; } 
  .page_voucher > .table_list01 .td { width:100%; padding: 0; text-align: left; font-size:12px; } 
  .page_voucher > .table_list01 .td.row_price { text-align: left; }
  .page_voucher > .table_list01 .row_btn { position: absolute; right:5%; top:50%; transform: translateY(-50%); }
  .page_voucher > .table_list01 .m_wrap { -ms-flex-wrap: wrap; flex-wrap: wrap; font-size: 13px; letter-spacing: -0.6px; width: calc(100% - 68px - 10px); } 
  .page_voucher > .table_list01 .m_wrap .td { width: auto; text-align: left; } 
  /*.page_voucher > .table_list01 .m_wrap .td:first-of-type, 
  .page_voucher > .table_list01 .m_wrap .td:last-of-type { width: 100%; } 
  .page_voucher > .table_list01 .m_wrap .td:first-of-type { margin-bottom: 5px; } 
  .page_voucher > .table_list01 .m_wrap .td:last-of-type { margin-top: 10px; } */
  .page_voucher > .table_list01 .m_wrap .row_date,
  .page_voucher > .table_list01 .m_wrap .row_send,
  .page_voucher > .table_list01 .m_wrap .row_amount{ display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 
  .page_voucher > .table_list01 .m_wrap .row_date:after,
  .page_voucher > .table_list01 .m_wrap .row_send:after { content: '|'; display: inline-block; padding: 0 5px; font-size: 10px; color: #d7d7d7; } 
  .page_voucher > .table_list01 .tr{flex-wrap: wrap;}
  .page_voucher > .table_list01 .row_kind { font-size: 15px; font-weight: 700; } 
  .page_voucher > .table_list01 .row_btn { padding: 0; width: 64px; } 
  .page_voucher .btn_buy .nanumGothic { margin-left: 5px; }
  .page_voucher > .table_list01 .btn_buy { width: 68px; font-size: 12px; line-height: 27.5px; } 
  .page_voucher > .table_list01 .row_price{min-width:auto;}
  .page_voucher.buy > .table_list01 .tr{justify-content: flex-start; position:relative}
  .page_voucher.buy > .table_list01 .td.row_kind,
  .page_voucher.buy > .table_list01 .td.row_price{width:100%; padding-right: 70px; text-align: left;}
  .page_voucher.buy > .table_list01 .row_date{display: inline-block; width:auto;}
  .page_voucher.buy > .table_list01 .row_date::after{content: ''; width:1px; height: 10px; background: #d7d7d7; margin:0 5px; display: inline-block;}
  .page_voucher.buy > .table_list01 .row_send{display: inline-block; width:auto; padding-right: 70px;}  
  .page_voucher .notice { margin: 21px 16px; } 
  .page_voucher .notice h1 { font-size: 13px; letter-spacing: -0.6px; } 
  .page_voucher .notice h1 .icon_mark { width: 15px; height: 15px; font-size: 10px; margin-right: 5px; } 
  .page_voucher .notice ul { margin-left: 20px; margin-top: 8px; } 
  .page_voucher .notice li { font-size: 13px; letter-spacing: -0.6px; padding: 5px 0 5px 10px; } 
  .page_voucher.pay input[type=text], 
  .page_voucher.pay select { width: 100%; } 
  .page_voucher.pay .sub_content_title { margin-top: 20px; margin-bottom: 10px; } 
  .page_voucher.pay > .table_list01 { border-top: 1px solid; border-color: #e2e2e2; } 
  .page_voucher.pay > .table_list01 .tr { position: relative; } 
  .page_voucher.pay > .table_list01 .m_wrap { width: 100%; } 
  .page_voucher.pay > .table_list01 .m_wrap .td:first-of-type { margin-bottom: 13px; } 
  .page_voucher.pay > .table_list01 .m_wrap .td:last-of-type { width: auto; margin-top: 0; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; } 
  /*.page_voucher.pay > .table_list01 .m_wrap .td:last-of-type::before { content: '|'; display: inline-block; padding: 0 5px; font-size: 10px; color: #d7d7d7; } */
  .page_voucher.pay > .table_list01 .td.row_price {width:50%; color: #000; font-weight: 700; order:2; margin-bottom: 15px; text-align: right;} 
  .page_voucher.pay > .table_list01 .row_kind{width: 50%; order:1; text-align: left; }
  .page_voucher.pay > .table_list01 .row_date{order:3;}
  .page_voucher.pay > .table_list01 .row_send{order:4;}
  .page_voucher.pay > .table_list01 .row_amount{order:5;}
  .page_voucher.pay .payment_area { border: 0; border-top: 1px solid #e2e2e2; border-bottom: 1px solid #e2e2e2; padding: 17px 0 0; } 
  .page_voucher.pay .radio_wrap + .radio_wrap { margin-left: 15px; } 
  .page_voucher.pay .cashreport_area { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; } 
  .page_voucher.pay .cashreport_area input, .page_voucher.pay .cashreport_area select { margin-top: 5px; } 
  .page_voucher.pay .cashreport_area .radio_wrap + .radio_wrap { margin-left: 0; } 
  .page_voucher.pay .table_view01 tr:last-child th, 
  .page_voucher.pay .table_view01 tr:last-child td { border-bottom: none; }
  .page_voucher.pay .table_view01 th, .page_voucher.pay .table_view01 td { padding: 15px; } 
  .page_voucher.pay .table_view01 th { font-size: 13px; letter-spacing: -0.6px; width: 95px; } 
  .page_voucher.pay .agree_check li { font-size: 13px; letter-spacing: -0.6px; padding: 4px 0; } 
  .page_voucher.pay .page_bottom_button_area { margin: 25px 0; } 
 }

@media screen and (max-width: 768px){
  .page_voucher.pay > .table_list01 { border: 1px solid #e2e2e2; } 
 }
@media screen and (max-width: 640px){
  .page_voucher > .table_list01 .row_btn { min-width: inherit; } 
 }/*# sourceMappingURL=Voucher.css.map */