@charset "utf-8";
/* 회사소개 */
.page_aboutus:before{content: ''; display: block; position: absolute; width: 100%; height: 1px; margin-top:-1px; left:0; background: #fff;}
.page_aboutus{text-align: center;}
.page_aboutus .title_area{background: url("../img/sub/aboutus02.jpg") no-repeat; margin-bottom: 80px; height: 350px; color:#fff; display: flex; flex-direction: column; justify-content: center;}
.page_aboutus .title_area p{font-size:35px; font-weight:bold;; letter-spacing: -0.05em; line-height: 1.5; word-break: keep-all; text-shadow: 0 0 9px rgba(0,0,0,.33);}
.page_aboutus .title_area .point{font-size:65px; font-weight: 900; letter-spacing: -0.025em; margin-top: 20px; text-shadow: 0 0 9px rgba(0,0,0,.33);}
.page_aboutus .title_area .point .normal_text{font-weight: 300;}
.page_aboutus .img_area{margin-bottom: 80px;}
.page_aboutus .text_area{font-size:26px; line-height: 1.8; letter-spacing: -0.05em; word-break: keep-all;}
.page_aboutus .text_area .point_txt{color:#326dd1; font-weight: bold;}

@media screen and (max-width:768px){
	.page_aboutus .title_area{margin-top: 36px; margin-bottom: 40px}
	.page_aboutus .title_area p{font-size:18px;}
	.page_aboutus .title_area .point{font-size: 30px}
	.page_aboutus .img_area{margin-bottom: 40px;}
	.page_aboutus .text_area{font-size:15px;}
}

@media screen and (max-width:360px){
	.page_aboutus .title_area{margin-top: 36px; margin-bottom: 40px}
	.page_aboutus .title_area p{font-size:24px;}
	.page_aboutus .title_area .point{font-size: 30px}
	.page_aboutus .img_area{margin-bottom: 40px;}
	.page_aboutus .text_area{font-size:16px;}
}