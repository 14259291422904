@charset "utf-8";
/* input[type=chekbox],input[type=radio]{display: block !important;} 22-11-10 khj - 왜 있는지? */
.Popup{position:fixed; background: rgba(0,0,0,.8); z-index: 999; left:0; top:0; bottom:0; width:100%; display: none;}
.Popup .Wrap{display: flex; width: 100%; height: 100%; align-items: center; justify-content: center;}
.Popup .Wrap .Box{ max-width: 440px; overflow: auto; background: #fff; border-radius: 20px; box-sizing: border-box; width: inherit; max-height: 95vh;}
.Popup .Top{padding:20px; color:#333;  display: flex; justify-content: space-between; align-items: center; border-bottom:2px solid #dde0e6;}
.Popup .Top.block{display: block;}
.Popup .Top .Title{font-size:23px;}
.Popup .Top .Title.has_check { width:100%; }
.Popup .Top .btn_close{width:20px; height: 22px; background: url(../img/common/btn_close.png); display: inline-block; background-size: cover;}
.Popup .Top .Text{font-size:17px; font-weight: 400; color:#666666; margin-top: 15px;}
.Popup .Top .Button{font-size:14px;}
.Popup .PopContents{max-height: 70vh; overflow: auto;position: relative;}
.Popup.Active{display:block;}

.Popup .Section{background:#f6f8fb; padding:30px 20px; box-sizing:border-box; border-bottom:1px solid #edf0f6}
.Popup .ButtonBottom{display: flex; justify-content: space-between; align-items: center; padding:0 20px;}
.Popup .ButtonBottom .btn{width:100%; margin-right: 2%; font-size:18px; height: 54px; line-height: 54px;}
.Popup .ButtonBottom .btn:first-child{margin-left: 0;}
.Popup .ButtonBottom .btn:last-child{margin-right: 0;}

.PopBottom{ padding:30px 20px; text-align: right;}
.PopBottom .Button{text-decoration: underline;}



/* 예시용팝업 */
.popup_h500 {
    height: 500px;
    background: #fff;
}


/*프로필수정하기*/
.Popup .logincons{border:none; border-radius: 0; max-width: inherit;}
.Popup .logincons .stit:first-child{padding-top: 0;}
.Popup .logincons .phonebtn .btnslineblue{min-width:30%;}


/*my> 받은제안 view*/
.Popup .Top.OffererArea{display: flex;}

.Popup .Top.OffererArea Img{width:50px; min-width: 50px; height: 50px; border-radius: 100px; overflow: hidden; margin-right: 10px;}
.Popup .Top.OffererArea .OffererInfo{width: 100%;}
.Popup .Top.OffererArea .Nickname{color:#333; font-size:20px; font-weight: bold;}
.Popup .Top.OffererArea .Company{color:#b3b9c4; font-size:14px;}
.Popup .ContentView{background:#f6f8fb; padding:20px; border-bottom:1px solid #edf0f6;}
.Popup .ContentView .FieldTitle,
.Popup .ContentView .FieldJob,
.Popup .ContentView .FieldContent{border:1px solid #b3b9c4; border-radius: 5px; padding:15px; color:#666; font-size:16px; background: #ffffff;}
.Popup .ContentView .FieldTitle,
.Popup .ContentView .FieldJob{margin-bottom: 14px;}
.Popup .ContentView .FieldContent{min-height: 230px;}

.BoardSearch input,
.BoardSearch select{height: 50px; line-height: 50px; padding-right: 0; border-color:#b3b9c4;}
.BoardSearch select{width: 30%;}
.BoardSearch input{width: 70%}

.Popup .SelectBox{border:1px solid #b3b9c4; border-radius: 5px; background: #fff; margin-top: 10px; overflow: hidden;}
.Popup .SelectBox .wrap{max-height:250px; overflow: auto;}
.Popup .SelectBox.col2{display: flex; justify-content: space-between;}
.Popup .SelectBox.col2 .wrap{width: 50%;}
.Popup .SelectBox.col2 {}
.Popup .SelectBox .button{display: block; padding:3px 10px; font-size:15px; cursor:pointer;}
.Popup .SelectBox .button:hover,
.Popup .SelectBox .button.active{background: #f2f8fe; color:#0070eb;}
.Popup .SelectBox.inline .button{display: inline-block; vertical-align: middle;}

/*간편인증*/
.Certify .selectCertify{display: flex; justify-content: space-between; margin-bottom: 20px}
.Certify .selectCertify .termschkwon{position:relative; display: flex; flex-direction: column; align-items: center; margin-left: 0}
.Certify .selectCertify label{margin-right: 0; padding-left:0; padding-bottom:30px;}
.Certify .selectCertify .icon{border-radius: 100px; width:60px; height: 60px; overflow: hidden; display:inline-block }
/* .Certify .selectCertify .termschkwon .chk1+i{bottom:0; top:inherit; left:50%; margin-left: -17px;} */
.Certify .selectCertify .termschkwon .chk1{position:absolute; bottom:10px; top:inherit; left:50%; transform:translateX(-50%); display:block !important;  -webkit-appearance: checkbox !important; }
.Certify .BoardWrite .Write li input[type=text]{width:100%}
.Certify .BoardWrite li{margin-bottom: 10px}
.Certify  .BoardWrite .Write li .FieldTitle, .Certify .BoardWrite .Write li .FieldInfo{min-height:0; padding:0;}
.Certify.Popup .Section{border-bottom:none;}
.Certify.Popup .BoardWrite .Write ul:after{display: none;}
.Certify .popup_wrap .content_wrap { max-height: none; }
.popup01 .content_wrap .chk[type=radio] + label:before { height: 20px; width:20px; }
.Certify .popup01 .content_wrap .chk[type=radio]:checked + label:before { background-size: contain; }

/* 간편인증 레이어 위 팝업 */
.return-msg-box { display:block; }
.return-msg-box .popup01 .button_wrap .close_button_wrap { margin-top: 0; }
/* .return-msg-box {position: absolute; left:50%; top:50%; transform:translate(-50%, -50%); height: auto; min-height: auto; z-index: 3;background-color:#fff; max-width: 50vh; max-height: 44vh;} */
/* .return-msg-box .inner-box{position: relative;width:100%;height: 100%;}
.return-msg-box .msg-box{padding:10px;overflow-y: auto;width:100%;height:calc(100% - 28px);box-sizing: border-box;background-color: #ffffff;}
.return-msg-box .btn-box{display: flex;justify-content: flex-end;align-items: center;margin-bottom:10px;width:100%;box-sizing: border-box;padding:10px;} */

/*간편인증 상단 라디오 버튼*/
.TopRadio{position:absolute; left:-9999px; -webkit-appearance: checkbox !important; }
.Top_ul{list-style: none;display:flex;align-items: center; justify-content: center; width: 100%;}
.Top_li{float: left; margin-right: 15px;}
.Top_li:last-child{margin-right:0;}
.Top_li_label{margin-left: 15px;}

.pop_career_update .update_choice_continaer { border-block:1px solid #edf0f6; height:80px; display:flex; align-items: center; justify-content: space-between;}
.pop_career_update .update_cate_area { height:100%; display:flex; align-items: center; }
.pop_career_update .update_cate_area:first-child { width:53%; }
.pop_career_update .update_cate_area:last-child { width:47%; }
.pop_career_update .update_cate_area label { width:calc(100% - 15px); height:100%; display:flex; align-items: center; }
.pop_career_update .update_cate_area label span { display:flex; align-items: center; width:calc(100% - 25px); text-align: right; }
.pop_career_update .update_cate_area label span img { margin-right:14px; margin-left:6px; }
.pop_career_update .update_cate_area:first-child label span img { padding-bottom:10px; }
.pop_career_update .PopBottom .btn { width:100%; border-radius: 10px; }

.pop_career_update .warring { color: red; font-size: initial; font-weight: bold; }
@media screen and (max-width:1000px){
    .Popup .logincons{width: 100%}
    .Popup .logincons .phonebtn .btnslineblue{white-space: nowrap; min-width:40%;}
}
    
@media screen and (max-width:640px){
    .Popup .Top{padding:15px;}
    .Popup .Top .Title{font-size:18px}
    .Popup .Section{padding:15px;}
    .Popup .ButtonBottom{padding:0 15px;}
    
    .Popup .ButtonBottom .btn{height: 45px; line-height: 45px; font-size:16px;}
    .Certify.Popup .Wrap{align-items: flex-end; }
    .Certify.Popup .Wrap .Box{border-radius: 20px 20px 0 0; width:100%; max-width: 100vw; max-height: 100vh;}
    .Certify.Popup .PopBottom{display: none;}
    .Certify.Popup .PopContents{max-height: 90vh;}
    .Certify.Popup .ButtonBottom{margin-bottom:30px;}
    .Certify.Popup .btn_close{display: block; width:20px; height: 20px; margin-top: -10px;}
    .Certify.Popup .Top{align-items: center;}
    .Certify .selectCertify { margin-bottom:0; }
    .Certify .selectCertify li { width: 18%; }
    .Certify .selectCertify .icon{border-radius: 20px; width:100%; height:100%; }

    .pop_career_update .update_choice_continaer { height: 87px; }
    .pop_career_update .update_cate_area:first-child { width:100%; }
    .pop_career_update .update_cate_area:last-child { width:100%; }
    .pop_career_update .PopBottom { display:block !important; padding: 15px; }
}
@media screen and (max-width:440px){
    .pop_career_update .update_cate_area label { width:auto; justify-content: center; }
    .pop_career_update .update_cate_area label span { width:auto; flex-direction: column; justify-content: center; }
    .pop_career_update .update_cate_area label span img { margin-right:0; padding-bottom: 6px !important;}
}